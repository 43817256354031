export const ADD_BOOKMARK_REQUEST = 'ADD_BOOKMARK_REQUEST';
export const BATCH_CLOSE_PRODUCT_INSTANCES_FAILURE =
  'BATCH_CLOSE_PRODUCT_INSTANCES_FAILURE';
export const BATCH_CLOSE_PRODUCT_INSTANCES_REQUEST =
  'BATCH_CLOSE_PRODUCT_INSTANCES_REQUEST';
export const BATCH_CLOSE_PRODUCT_INSTANCES_SUCCESS =
  'BATCH_CLOSE_PRODUCT_INSTANCES_SUCCESS';
export const BATCH_EDIT_PRODUCT_INSTANCES_FAILURE =
  'BATCH_EDIT_PRODUCT_INSTANCES_FAILURE';
export const BATCH_EDIT_PRODUCT_INSTANCES_REQUEST =
  'BATCH_EDIT_PRODUCT_INSTANCES_REQUEST';
export const BATCH_EDIT_PRODUCT_INSTANCES_SUCCESS =
  'BATCH_EDIT_PRODUCT_INSTANCES_SUCCESS';
export const CANCEL_RESERVATION_FAILURE = 'CANCEL_RESERVATION_FAILURE';
export const CANCEL_RESERVATION_REQUEST = 'CANCEL_RESERVATION_REQUEST';
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
export const BULK_CANCEL_RESERVATION_FAILURE =
  'BULK_CANCEL_RESERVATION_FAILURE';
export const BULK_CANCEL_RESERVATION_REQUEST =
  'BULK_CANCEL_RESERVATION_REQUEST';
export const BULK_CANCEL_RESERVATION_SUCCESS =
  'BULK_CANCEL_RESERVATION_SUCCESS';
export const BULK_CANCEL_RESERVATION_TIME_OUT = 'BULK_CANCEL_TIME_OUT';
export const CANCEL_RESERVATION_WITH_FEE_FAILURE =
  'CANCEL_RESERVATION_WITH_FEE_FAILURE';
export const CANCEL_RESERVATION_WITH_FEE_REQUEST =
  'CANCEL_RESERVATION_WITH_FEE_REQUEST';
export const CANCEL_RESERVATION_WITH_FEE_SUCCESS =
  'CANCEL_RESERVATION_WITH_FEE_SUCCESS';
export const CHECKIN_RESERVATION_REQUEST = 'CHECKIN_RESERVATION_REQUEST';
export const CHECKIN_RESERVATION_SUCCESS = 'CHECKIN_RESERVATION_SUCCESS';
export const CHECKIN_RESERVATION_FAILURE = 'CHECKIN_RESERVATION_FAILURE';
export const CHECK_AVAILABILITY_REQUEST = 'CHECK_AVAILABILITY_REQUEST';
export const CHECK_AVAILABILITY_FAILURE = 'CHECK_AVAILABILITY_FAILURE';
export const CHECK_AVAILABILITY_SUCCESS = 'CHECK_AVAILABILITY_SUCCESS';
export const UNDO_CHECKIN_RESERVATION_REQUEST =
  'UNDO_CHECKIN_RESERVATION_REQUEST';
export const UNDO_CHECKIN_RESERVATION_SUCCESS =
  'UNDO_CHECKIN_RESERVATION_SUCCESS';
export const UNDO_CHECKIN_RESERVATION_FAILURE =
  'UNDO_CHECKIN_RESERVATION_FAILURE';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PRODUCT_INSTANCES_SELECTED_PRODUCT =
  'CHANGE_PRODUCT_INSTANCES_SELECTED_PRODUCT';
export const CHANGE_PRODUCT_INSTANCES_SELECTED_DATE_RANGE =
  'CHANGE_PRODUCT_INSTANCES_SELECTED_DATE_RANGE';
export const CHANGE_USER_ATTRIBUTES_FAILURE = 'CHANGE_USER_ATTRIBUTES_FAILURE';
export const CHANGE_USER_ATTRIBUTES_REQUEST = 'CHANGE_USER_ATTRIBUTES_REQUEST';
export const CHANGE_USER_ATTRIBUTES_SUCCESS = 'CHANGE_USER_ATTRIBUTES_SUCCESS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CONFIRM_RESERVATION_FAILURE = 'CONFIRM_RESERVATION_FAILURE';
export const CONFIRM_RESERVATION_REQUEST = 'CONFIRM_RESERVATION_REQUEST';
export const CONFIRM_RESERVATION_SUCCESS = 'CONFIRM_RESERVATION_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';
export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';
export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';
export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_AGENT_REFERENCE_FAILURE =
  'CREATE_PRODUCT_AGENT_REFERENCE_FAILURE';
export const CREATE_PRODUCT_AGENT_REFERENCE_REQUEST =
  'CREATE_PRODUCT_AGENT_REFERENCE_REQUEST';
export const CREATE_PRODUCT_AGENT_REFERENCE_SUCCESS =
  'CREATE_PRODUCT_AGENT_REFERENCE_SUCCESS';
export const CREATE_RESERVATION_FAILURE = 'CREATE_RESERVATION_FAILURE';
export const CREATE_RESERVATION_REQUEST = 'CREATE_RESERVATION_REQUEST';
export const CREATE_RESERVATION_SUCCESS = 'CREATE_RESERVATION_SUCCESS';
export const DECLINE_RESERVATION_FAILURE = 'DECLINE_RESERVATION_FAILURE';
export const DECLINE_RESERVATION_REQUEST = 'DECLINE_RESERVATION_REQUEST';
export const DECLINE_RESERVATION_SUCCESS = 'DECLINE_RESERVATION_SUCCESS';
export const BULK_DECLINE_RESERVATION_FAILURE =
  'BULK_DECLINE_RESERVATION_FAILURE';
export const BULK_DECLINE_RESERVATION_REQUEST =
  'BULK_DECLINE_RESERVATION_REQUEST';
export const BULK_DECLINE_RESERVATION_SUCCESS =
  'BULK_DECLINE_RESERVATION_SUCCESS';
export const BULK_DECLINE_RESERVATION_TIME_OUT = 'BULK_DECLINE_TIME_OUT';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';
export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ORGANIZATION_FAILURE = 'DELETE_ORGANIZATION_FAILURE';
export const DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';
export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_AGENT_REFERENCE_FAILURE =
  'DELETE_PRODUCT_AGENT_REFERENCE_FAILURE';
export const DELETE_PRODUCT_AGENT_REFERENCE_REQUEST =
  'DELETE_PRODUCT_AGENT_REFERENCE_REQUEST';
export const DELETE_PRODUCT_AGENT_REFERENCE_SUCCESS =
  'DELETE_PRODUCT_AGENT_REFERENCE_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';
export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DISMISS_CHANGE_NOTIFICATION = 'DISMISS_CHANGE_NOTIFICATION';
export const DOWNLOAD_MANIFEST_CSV_FAILURE = 'DOWNLOAD_MANIFEST_CSV_FAILURE';
export const DOWNLOAD_MANIFEST_CSV_REQUEST = 'DOWNLOAD_MANIFEST_CSV_REQUEST';
export const DOWNLOAD_MANIFEST_CSV_SUCCESS = 'DOWNLOAD_MANIFEST_CSV_SUCCESS';
export const DOWNLOAD_MANIFEST_PDF_FAILURE = 'DOWNLOAD_MANIFEST_PDF_FAILURE';
export const DOWNLOAD_MANIFEST_PDF_REQUEST = 'DOWNLOAD_MANIFEST_PDF_REQUEST';
export const DOWNLOAD_MANIFEST_PDF_SUCCESS = 'DOWNLOAD_MANIFEST_PDF_SUCCESS';
export const FETCH_ACCOUNTS_FAILURE = 'FETCH_ACCOUNTS_FAILURE';
export const FETCH_ACCOUNTS_REQUEST = 'FETCH_ACCOUNTS_REQUEST';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_INQUIRIES_SUCCESS = 'FETCH_INQUIRIES_SUCCESS';
export const FETCH_INQUIRIES_FAILURE = 'FETCH_INQUIRIES_FAILURE';
export const FETCH_INQUIRIES_REQUEST = 'FETCH_INQUIRIES_REQUEST';
export const FETCH_INQUIRY_SUCCESS = 'FETCH_INQUIRY_SUCCESS';
export const FETCH_INQUIRY_FAILURE = 'FETCH_INQUIRY_FAILURE';
export const FETCH_INQUIRY_REQUEST = 'FETCH_INQUIRY_REQUEST';
export const REPLY_TO_INQUIRY_SUCCESS = 'REPLY_TO_INQUIRY_SUCCESS';
export const REPLY_TO_INQUIRY_FAILURE = 'REPLY_TO_INQUIRY_FAILURE';
export const REPLY_TO_INQUIRY_REQUEST = 'REPLY_TO_INQUIRY_REQUEST';
export const UPDATE_INQUIRY_SUCCESS = 'UPDATE_INQUIRY_SUCCESS';
export const UPDATE_INQUIRY_FAILURE = 'UPDATE_INQUIRY_FAILURE';
export const UPDATE_INQUIRY_REQUEST = 'UPDATE_INQUIRY_REQUEST';
export const SEND_INQUIRY_BULK_EMAIL_SUCCESS =
  'SEND_INQUIRY_BULK_EMAIL_SUCCESS';
export const SEND_INQUIRY_BULK_EMAIL_FAILURE =
  'SEND_INQUIRY_BULK_EMAIL_FAILURE';
export const SEND_INQUIRY_BULK_EMAIL_REQUEST =
  'SEND_INQUIRY_BULK_EMAIL_REQUEST';
export const SEND_INQUIRY_BULK_SMS_SUCCESS = 'SEND_INQUIRY_BULK_SMS_SUCCESS';
export const SEND_INQUIRY_BULK_SMS_FAILURE = 'SEND_INQUIRY_BULK_SMS_FAILURE';
export const SEND_INQUIRY_BULK_SMS_REQUEST = 'SEND_INQUIRY_BULK_SMS_REQUEST';
export const SEND_INQUIRY_SMS_SUCCESS = 'SEND_INQUIRY_SMS_SUCCESS';
export const SEND_INQUIRY_SMS_FAILURE = 'SEND_INQUIRY_SMS_FAILURE';
export const SEND_INQUIRY_SMS_REQUEST = 'SEND_INQUIRY_SMS_REQUEST';
export const SEND_INQUIRY_EMAIL_SUCCESS = 'SEND_INQUIRY_EMAIL_SUCCESS';
export const SEND_INQUIRY_EMAIL_FAILURE = 'SEND_INQUIRY_EMAIL_FAILURE';
export const SEND_INQUIRY_EMAIL_REQUEST = 'SEND_INQUIRY_EMAIL_REQUEST';
export const FETCH_INQUIRY_SMS_STATS_REQUEST =
  'FETCH_INQUIRY_SMS_STATS_REQUEST';
export const FETCH_INQUIRY_SMS_STATS_SUCCESS =
  'FETCH_INQUIRY_SMS_STATS_SUCCESS';
export const FETCH_INQUIRY_SMS_STATS_FAILURE =
  'FETCH_INQUIRY_SMS_STATS_FAILURE';
export const SET_LAST_EXECUTED_INQUIRY_SEARCH_CONDITION =
  'SET_LAST_EXECUTED_INQUIRY_SEARCH_CONDITION';
export const SET_INQUIRY_ROW_COUNT = 'SET_INQUIRY_ROW_COUNT';
export const SET_INQUIRY_CURRENT_PAGE = 'SET_INQUIRY_CURRENT_PAGE';
export const FETCH_SYSTEM_FEE_INVOICES_REQUEST =
  'FETCH_SYSTEM_FEE_INVOICES_REQUEST';
export const FETCH_SYSTEM_FEE_INVOICES_FAILURE =
  'FETCH_SYSTEM_FEE_INVOICES_FAILURE';
export const FETCH_SYSTEM_FEE_INVOICES_SUCCESS =
  'FETCH_SYSTEM_FEE_INVOICES_SUCCESS';
export const FETCH_SYSTEM_FEE_INVOICE_REQUEST =
  'FETCH_SYSTEM_FEE_INVOICE_REQUEST';
export const FETCH_SYSTEM_FEE_INVOICE_FAILURE =
  'FETCH_SYSTEM_FEE_INVOICE_FAILURE';
export const FETCH_SYSTEM_FEE_INVOICE_SUCCESS =
  'FETCH_SYSTEM_FEE_INVOICE_SUCCESS';
export const FETCH_SYSTEM_FEE_INVOICE_CSV_REQUEST =
  'FETCH_SYSTEM_FEE_INVOICE_CSV_REQUEST';
export const FETCH_SYSTEM_FEE_INVOICE_CSV_SUCCESS =
  'FETCH_SYSTEM_FEE_INVOICE_CSV_SUCCESS';
export const FETCH_SYSTEM_FEE_INVOICE_CSV_FAILURE =
  'FETCH_SYSTEM_FEE_INVOICE_CSV_FAILURE';
export const UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_REQUEST =
  'UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_REQUEST';
export const UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_SUCCESS =
  'UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_SUCCESS';
export const UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_FAILURE =
  'UPDATE_SYSTEM_FEE_INVOICE_FARE_ADJUSTMENTS_FAILURE';
export const CONFIRM_SYSTEM_FEE_INVOICE_REQUEST =
  'CONFIRM_SYSTEM_FEE_INVOICE_REQUEST';
export const CONFIRM_SYSTEM_FEE_INVOICE_SUCCESS =
  'CONFIRM_SYSTEM_FEE_INVOICE_SUCCESS';
export const CONFIRM_SYSTEM_FEE_INVOICE_FAILURE =
  'CONFIRM_SYSTEM_FEE_INVOICE_FAILURE';
export const MARK_SYSTEM_FEE_INVOICE_PAID_REQUEST =
  'MARK_SYSTEM_FEE_INVOICE_PAID_REQUEST';
export const MARK_SYSTEM_FEE_INVOICE_PAID_SUCCESS =
  'MARK_SYSTEM_FEE_INVOICE_PAID_SUCCESS';
export const MARK_SYSTEM_FEE_INVOICE_PAID_FAILURE =
  'MARK_SYSTEM_FEE_INVOICE_PAID_FAILURE';
export const RE_READY_FOR_CONFIRMATION_SYSTEM_FEE_INVOICE_REQUEST =
  'RE_READY_FOR_CONFIRMATION_SYSTEM_FEE_INVOICE_REQUEST';
export const RE_READY_FOR_CONFIRMATION_SYSTEM_FEE_INVOICE_SUCCESS =
  'RE_READY_FOR_CONFIRMATION_SYSTEM_FEE_INVOICE_SUCCESS';
export const RE_READY_FOR_CONFIRMATION_SYSTEM_FEE_INVOICE_FAILURE =
  'RE_READY_FOR_CONFIRMATION_SYSTEM_FEE_INVOICE_FAILURE';
export const FETCH_INVOICE_BY_ID_FAILURE = 'FETCH_INVOICE_BY_ID_FAILURE';
export const FETCH_INVOICE_BY_ID_REQUEST = 'FETCH_INVOICE_BY_ID_REQUEST';
export const FETCH_INVOICE_BY_ID_SUCCESS = 'FETCH_INVOICE_BY_ID_SUCCESS ';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';
export const FETCH_INVOICES_REQUEST = 'FETCH_INVOICES_REQUEST';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const ISSUE_INVOICE_FAILURE = 'ISSUE_INVOICE_FAILURE';
export const ISSUE_INVOICE_REQUEST = 'ISSUE_INVOICE_REQUEST';
export const ISSUE_INVOICE_SUCCESS = 'ISSUE_INVOICE_SUCCESS';
export const CONFIRM_INVOICE_FAILURE = 'CONFIRM_INVOICE_FAILURE';
export const CONFIRM_INVOICE_REQUEST = 'CONFIRM_INVOICE_REQUEST';
export const CONFIRM_INVOICE_SUCCESS = 'CONFIRM_INVOICE_SUCCESS';
export const MARK_INVOICE_AS_PAID_FAILURE = 'MARK_INVOICE_AS_PAID_FAILURE';
export const MARK_INVOICE_AS_PAID_REQUEST = 'MARK_INVOICE_AS_PAID_REQUEST';
export const MARK_INVOICE_AS_PAID_SUCCESS = 'MARK_INVOICE_AS_PAID_SUCCESS';
export const DOWNLOAD_INVOICE_PDF_FAILURE = 'DOWNLOAD_INVOICE_PDF_FAILURE';
export const DOWNLOAD_INVOICE_PDF_REQUEST = 'DOWNLOAD_INVOICE_PDF_REQUEST';
export const DOWNLOAD_INVOICE_PDF_SUCCESS = 'DOWNLOAD_INVOICE_PDF_SUCCESS';
export const DOWNLOAD_INVOICE_CSV_FAILURE = 'DOWNLOAD_INVOICE_CSV_FAILURE';
export const DOWNLOAD_INVOICE_CSV_REQUEST = 'DOWNLOAD_INVOICE_CSV_REQUEST';
export const DOWNLOAD_INVOICE_CSV_SUCCESS = 'DOWNLOAD_INVOICE_CSV_SUCCESS';
export const FARE_ADJUST_INVOICE_FAILURE = 'FARE_ADJUST_INVOICE_FAILURE';
export const FARE_ADJUST_INVOICE_REQUEST = 'FARE_ADJUST_INVOICE_REQUEST';
export const FARE_ADJUST_INVOICE_SUCCESS = 'FARE_ADJUST_INVOICE_SUCCESS';
export const FARE_ADJUST_RESERVATION_FAILURE =
  'FARE_ADJUST_RESERVATION_FAILURE';
export const FARE_ADJUST_RESERVATION_REQUEST =
  'FARE_ADJUST_RESERVATION_REQUEST';
export const FARE_ADJUST_RESERVATION_SUCCESS =
  'FARE_ADJUST_RESERVATION_SUCCESS';
export const FETCH_DISPATCH_RESERVATIONS_FAILURE =
  'FETCH_DISPATCH_RESERVATIONS_FAILURE';
export const FETCH_DISPATCH_RESERVATIONS_REQUEST =
  'FETCH_DISPATCH_RESERVATIONS_REQUEST';
export const FETCH_DISPATCH_RESERVATIONS_SUCCESS =
  'FETCH_DISPATCH_RESERVATIONS_SUCCESS';
export const SET_INVOICE_SUMMARY_VISIBLE_COLUMNS =
  'SET_INVOICE_SUMMARY_VISIBLE_COLUMNS';
export const SET_INVOICE_SUMMARY_VISIBLE_ROWS =
  'SET_INVOICE_SUMMARY_VISIBLE_ROWS';
export const SET_INVOICE_RESERVATION_VISIBLE_COLUMNS =
  'SET_INVOICE_RESERVATION_VISIBLE_COLUMNS';
export const FETCH_MANIFEST_FAILURE = 'FETCH_MANIFEST_FAILURE';
export const FETCH_MANIFEST_REQUEST = 'FETCH_MANIFEST_REQUEST';
export const FETCH_MANIFEST_SUCCESS = 'FETCH_MANIFEST_SUCCESS';
export const FETCH_CONTRACTED_ORGANIZATIONS_FAILURE =
  'FETCH_CONTRACTED_ORGANIZATIONS_FAILURE';
export const FETCH_CONTRACTED_ORGANIZATIONS_REQUEST =
  'FETCH_CONTRACTED_ORGANIZATIONS_REQUEST';
export const FETCH_CONTRACTED_ORGANIZATIONS_SUCCESS =
  'FETCH_CONTRACTED_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATIONS_FAILURE = 'FETCH_ORGANIZATIONS_FAILURE';
export const FETCH_ORGANIZATIONS_REQUEST = 'FETCH_ORGANIZATIONS_REQUEST';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const REFRESH_ORGANIZATIONS_FAILURE = 'REFRESH_ORGANIZATIONS_FAILURE';
export const REFRESH_ORGANIZATIONS_REQUEST = 'REFRESH_ORGANIZATIONS_REQUEST';
export const REFRESH_ORGANIZATIONS_SUCCESS = 'REFRESH_ORGANIZATIONS_SUCCESS';
export const FETCH_ORGANIZATION_BY_ID_FAILURE =
  'FETCH_ORGANIZATION_BY_ID_FAILURE';
export const FETCH_ORGANIZATION_BY_ID_REQUEST =
  'FETCH_ORGANIZATION_BY_ID_REQUEST';
export const FETCH_ORGANIZATION_BY_ID_SUCCESS =
  'FETCH_ORGANIZATION_BY_ID_SUCCESS';
export const FETCH_PRODUCT_TAGS_REQUEST = 'FETCH_PRODUCT_TAGS_REQUEST';
export const FETCH_PRODUCT_TAGS_SUCCESS = 'FETCH_PRODUCT_TAGS_SUCCESS';
export const FETCH_PRODUCT_TAGS_FAILURE = 'FETCH_PRODUCT_TAGS_FAILURE';
export const FETCH_PRODUCTS_BY_ID_FAILURE = 'FETCH_PRODUCTS_BY_ID_FAILURE';
export const FETCH_PRODUCTS_BY_ID_REQUEST = 'FETCH_PRODUCTS_BY_ID_REQUEST';
export const FETCH_PRODUCTS_BY_ID_SUCCESS = 'FETCH_PRODUCTS_BY_ID_SUCCESS';
export const FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_FAILURE =
  'FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_FAILURE';
export const FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_REQUEST =
  'FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_REQUEST';
export const FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_SUCCESS =
  'FETCH_PRODUCT_AGENT_REFERENCE_BY_ID_SUCCESS';
export const FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_FAILURE =
  'FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_FAILURE';
export const FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_REQUEST =
  'FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_REQUEST';
export const FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_SUCCESS =
  'FETCH_PRODUCT_AGENT_REFERENCES_BY_PRODUCT_ID_SUCCESS';
export const FETCH_PRODUCT_FOR_EDITING_FAILURE =
  'FETCH_PRODUCT_FOR_EDITING_FAILURE';
export const FETCH_PRODUCT_FOR_EDITING_REQUEST =
  'FETCH_PRODUCT_FOR_EDITING_REQUEST';
export const FETCH_PRODUCT_FOR_EDITING_SUCCESS =
  'FETCH_PRODUCT_FOR_EDITING_SUCCESS';
export const FETCH_PRODUCT_GROUP_BY_ID_FAILURE =
  'FETCH_PRODUCT_GROUP_BY_ID_FAILURE';
export const FETCH_PRODUCT_GROUP_BY_ID_REQUEST =
  'FETCH_PRODUCT_GROUP_BY_ID_REQUEST';
export const FETCH_PRODUCT_GROUP_BY_ID_SUCCESS =
  'FETCH_PRODUCT_GROUP_BY_ID_SUCCESS';
export const FETCH_PRODUCT_GROUPS_FAILURE = 'FETCH_PRODUCT_GROUPS_FAILURE';
export const FETCH_PRODUCT_GROUPS_REQUEST = 'FETCH_PRODUCT_GROUPS_REQUEST';
export const FETCH_PRODUCT_GROUPS_SUCCESS = 'FETCH_PRODUCT_GROUPS_SUCCESS';
export const FETCH_PRODUCT_INSTANCE_BY_ID_FAILURE =
  'FETCH_PRODUCT_INSTANCE_BY_ID_FAILURE';
export const FETCH_PRODUCT_INSTANCE_BY_ID_REQUEST =
  'FETCH_PRODUCT_INSTANCE_BY_ID_REQUEST';
export const FETCH_PRODUCT_INSTANCE_BY_ID_SUCCESS =
  'FETCH_PRODUCT_INSTANCE_BY_ID_SUCCESS';
export const FETCH_PRODUCT_INSTANCES_FAILURE =
  'FETCH_PRODUCT_INSTANCES_FAILURE';
export const FETCH_PRODUCT_INSTANCES_REQUEST =
  'FETCH_PRODUCT_INSTANCES_REQUEST';
export const FETCH_PRODUCT_INSTANCES_SUCCESS =
  'FETCH_PRODUCT_INSTANCES_SUCCESS';
export const CLEAR_PRODUCT_INSTANCES = 'CLEAR_PRODUCT_INSTANCES';
export const FETCH_PRODUCTS_CANCELED = 'FETCH_PRODUCTS_CANCELED';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCT_PRICES_FAILURE = 'FETCH_PRODUCT_PRICES_FAILURE';
export const FETCH_PRODUCT_PRICES_REQUEST = 'FETCH_PRODUCT_PRICES_REQUEST';
export const FETCH_PRODUCT_PRICES_SUCCESS = 'FETCH_PRODUCT_PRICES_SUCCESS';
export const FETCH_PRODUCT_TRANSLATIONS_REQUEST =
  'FETCH_PRODUCT_TRANSLATIONS_REQUEST';
export const FETCH_PRODUCT_TRANSLATIONS_SUCCESS =
  'FETCH_PRODUCT_TRANSLATIONS_SUCCESS';
export const FETCH_PRODUCT_TRANSLATIONS_FAILURE =
  'FETCH_PRODUCT_TRANSLATIONS_FAILURE';
export const FETCH_PRODUCT_AVAILABILITY_FAILURE =
  'FETCH_PRODUCT_AVAILABILITY_FAILURE';
export const FETCH_PRODUCT_AVAILABILITY_REQUEST =
  'FETCH_PRODUCT_AVAILABILITY_REQUEST';
export const FETCH_PRODUCT_AVAILABILITY_SUCCESS =
  'FETCH_PRODUCT_AVAILABILITY_SUCCESS';
export const BATCH_UPDATE_PRODUCT_PRICES_FAILURE =
  'BATCH_UPDATE_PRODUCT_PRICES_FAILURE';
export const BATCH_UPDATE_PRODUCT_PRICES_REQUEST =
  'BATCH_UPDATE_PRODUCT_PRICES_REQUEST';
export const BATCH_UPDATE_PRODUCT_PRICES_SUCCESS =
  'BATCH_UPDATE_PRODUCT_PRICES_SUCCESS';
export const DOWNLOAD_PRODUCT_PRICES_PDF_REQUEST =
  'DOWNLOAD_PRODUCT_PRICES_PDF_REQUEST';
export const DOWNLOAD_PRODUCT_PRICES_PDF_FAILURE =
  'DOWNLOAD_PRODUCT_PRICES_PDF_FAILURE';
export const DOWNLOAD_PRODUCT_PRICES_PDF_SUCCESS =
  'DOWNLOAD_PRODUCT_PRICES_PDF_SUCCESS';
export const DOWNLOAD_PRODUCT_PRICES_CSV_REQUEST =
  'DOWNLOAD_PRODUCT_PRICES_CSV_REQUEST';
export const DOWNLOAD_PRODUCT_PRICES_CSV_FAILURE =
  'DOWNLOAD_PRODUCT_PRICES_CSV_FAILURE';
export const DOWNLOAD_PRODUCT_PRICES_CSV_SUCCESS =
  'DOWNLOAD_PRODUCT_PRICES_CSV_SUCCESS';
export const FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_FAILURE =
  'FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_FAILURE';
export const FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_REQUEST =
  'FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_REQUEST';
export const FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_SUCCESS =
  'FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_SUCCESS';
export const FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_FAILURE =
  'FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_FAILURE';
export const FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_REQUEST =
  'FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_REQUEST';
export const FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_SUCCESS =
  'FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_SUCCESS';
export const FETCH_RESERVATION_BY_ID_FAILURE =
  'FETCH_RESERVATION_BY_ID_FAILURE';
export const FETCH_RESERVATION_BY_ID_REQUEST =
  'FETCH_RESERVATION_BY_ID_REQUEST';
export const FETCH_RESERVATION_BY_ID_SUCCESS =
  'FETCH_RESERVATION_BY_ID_SUCCESS';
export const FETCH_RESERVATIONS_BY_ID_FAILURE =
  'FETCH_RESERVATIONS_BY_ID_FAILURE';
export const FETCH_RESERVATIONS_BY_ID_REQUEST =
  'FETCH_RESERVATIONS_BY_ID_REQUEST';
export const FETCH_RESERVATIONS_BY_ID_SUCCESS =
  'FETCH_RESERVATIONS_BY_ID_SUCCESS';
export const SEARCH_RESERVATIONS_FAILURE = 'SEARCH_RESERVATIONS_FAILURE';
export const SEARCH_RESERVATIONS_REQUEST = 'SEARCH_RESERVATIONS_REQUEST';
export const SEARCH_RESERVATIONS_SUCCESS = 'SEARCH_RESERVATIONS_SUCCESS';
export const FETCH_RESERVATION_SEARCH_CSV_REQUEST =
  'FETCH_RESERVATION_SEARCH_CSV_REQUEST';
export const FETCH_RESERVATION_SEARCH_CSV_SUCCESS =
  'FETCH_RESERVATION_SEARCH_CSV_SUCCESS';
export const FETCH_RESERVATION_SEARCH_CSV_FAILURE =
  'FETCH_RESERVATION_SEARCH_CSV_FAILURE';
export const FETCH_RESERVATIONS_FAILURE = 'FETCH_RESERVATIONS_FAILURE';
export const FETCH_RESERVATIONS_REQUEST = 'FETCH_RESERVATIONS_REQUEST';
export const FETCH_RESERVATIONS_SUCCESS = 'FETCH_RESERVATIONS_SUCCESS';
export const FETCH_RESERVATION_STATS_FAILURE =
  'FETCH_RESERVATION_STATS_FAILURE';
export const FETCH_RESERVATION_STATS_REQUEST =
  'FETCH_RESERVATION_STATS_REQUEST';
export const FETCH_RESERVATION_STATS_SUCCESS =
  'FETCH_RESERVATION_STATS_SUCCESS';
export const BATCH_FETCH_RESERVATION_STATS_BY_DATE_FAILURE =
  'BATCH_FETCH_RESERVATION_STATS_BY_DATE_FAILURE';
export const BATCH_FETCH_RESERVATION_STATS_BY_DATE_REQUEST =
  'BATCH_FETCH_RESERVATION_STATS_BY_DATE_REQUEST';
export const BATCH_FETCH_RESERVATION_STATS_BY_DATE_SUCCESS =
  'BATCH_FETCH_RESERVATION_STATS_BY_DATE_SUCCESS';
export const FETCH_RESERVATION_STATS_BY_DATE_FAILURE =
  'FETCH_RESERVATION_STATS_BY_DATE_FAILURE';
export const FETCH_RESERVATION_STATS_BY_DATE_REQUEST =
  'FETCH_RESERVATION_STATS_BY_DATE_REQUEST';
export const FETCH_RESERVATION_STATS_BY_DATE_SUCCESS =
  'FETCH_RESERVATION_STATS_BY_DATE_SUCCESS';
export const FETCH_RESERVATIONS_CSV_FAILURE = 'FETCH_RESERVATIONS_CSV_FAILURE';
export const FETCH_RESERVATIONS_CSV_REQUEST = 'FETCH_RESERVATIONS_CSV_REQUEST';
export const FETCH_RESERVATIONS_CSV_SUCCESS = 'FETCH_RESERVATIONS_CSV_SUCCESS';
export const FETCH_RESERVATION_VOUCHER_FAILURE =
  'FETCH_RESERVATION_VOUCHER_FAILURE';
export const FETCH_RESERVATION_VOUCHER_REQUEST =
  'FETCH_RESERVATION_VOUCHER_REQUEST';
export const FETCH_RESERVATION_VOUCHER_SUCCESS =
  'FETCH_RESERVATION_VOUCHER_SUCCESS';
export const FETCH_TASK_COUNTS_FAILURE = 'FETCH_TASK_COUNTS_FAILURE';
export const FETCH_TASK_COUNTS_REQUEST = 'FETCH_TASK_COUNTS_REQUEST';
export const FETCH_TASK_COUNTS_SUCCESS = 'FETCH_TASK_COUNTS_SUCCESS';
export const FETCH_TASKS_FAILURE = 'FETCH_TASKS_FAILURE';
export const FETCH_TASKS_REQUEST = 'FETCH_TASKS_REQUEST';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_CANCELED = 'FETCH_TASKS_CANCELED';
export const FETCH_RESERVATION_NOTIFICATIONS_FAILURE =
  'FETCH_RESERVATION_NOTIFICATIONS_FAILURE';
export const FETCH_RESERVATION_NOTIFICATIONS_REQUEST =
  'FETCH_RESERVATION_NOTIFICATIONS_REQUEST';
export const FETCH_RESERVATION_NOTIFICATIONS_SUCCESS =
  'FETCH_RESERVATION_NOTIFICATIONS_SUCCESS';
export const DELETE_RESERVATION_NOTIFICATION_FAILURE =
  'DELETE_RESERVATION_NOTIFICATION_FAILURE';
export const DELETE_RESERVATION_NOTIFICATION_REQUEST =
  'DELETE_RESERVATION_NOTIFICATION_REQUEST';
export const DELETE_RESERVATION_NOTIFICATION_SUCCESS =
  'DELETE_RESERVATION_NOTIFICATION_SUCCESS';
export const DELETE_ALL_RESERVATION_NOTIFICATIONS_FAILURE =
  'DELETE_ALL_RESERVATION_NOTIFICATIONS_FAILURE';
export const DELETE_ALL_RESERVATION_NOTIFICATIONS_REQUEST =
  'DELETE_ALL_RESERVATION_NOTIFICATIONS_REQUEST';
export const DELETE_ALL_RESERVATION_NOTIFICATIONS_SUCCESS =
  'DELETE_ALL_RESERVATION_NOTIFICATIONS_SUCCESS';
export const FETCH_USER_ATTRIBUTES_FAILURE = 'FETCH_USER_ATTRIBUTES_FAILURE';
export const FETCH_USER_ATTRIBUTES_REQUEST = 'FETCH_USER_ATTRIBUTES_REQUEST';
export const FETCH_USER_ATTRIBUTES_SUCCESS = 'FETCH_USER_ATTRIBUTES_SUCCESS';
export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED';
export const FORGOT_PASSWORD_REQUEST_CANCEL = 'FORGOT_PASSWORD_REQUEST_CANCEL';
export const FORGOT_PASSWORD_REQUEST_FAILURE =
  'FORGOT_PASSWORD_REQUEST_FAILURE';
export const FORGOT_PASSWORD_REQUEST_SUCCESS =
  'FORGOT_PASSWORD_REQUEST_SUCCESS';
export const FORGOT_PASSWORD_RESET_FAILURE = 'FORGOT_PASSWORD_RESET_FAILURE';
export const GET_FARES_REQUEST = 'GET_FARES_REQUEST';
export const GET_FARES_SUCCESS = 'GET_FARES_SUCCESS';
export const GET_FARES_FAILURE = 'GET_FARES_FAILURE';
export const GET_FARES_CANCELED = 'GET_FARES_CANCELED';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE';
export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';
export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';
export const UPDATE_PRODUCT_AGENT_REFERENCE_FAILURE =
  'UPDATE_PRODUCT_AGENT_REFERENCE_FAILURE';
export const UPDATE_PRODUCT_AGENT_REFERENCE_REQUEST =
  'UPDATE_PRODUCT_AGENT_REFERENCE_REQUEST';
export const UPDATE_PRODUCT_AGENT_REFERENCE_SUCCESS =
  'UPDATE_PRODUCT_AGENT_REFERENCE_SUCCESS';
export const UPDATE_PRODUCT_INSTANCE_FAILURE =
  'UPDATE_PRODUCT_INSTANCE_FAILURE';
export const UPDATE_PRODUCT_INSTANCE_REQUEST =
  'UPDATE_PRODUCT_INSTANCE_REQUEST';
export const UPDATE_PRODUCT_INSTANCE_SUCCESS =
  'UPDATE_PRODUCT_INSTANCE_SUCCESS';
export const UPDATE_RESERVATION_FAILURE = 'UPDATE_RESERVATION_FAILURE';
export const UPDATE_RESERVATION_REQUEST = 'UPDATE_RESERVATION_REQUEST';
export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
export const UPDATE_TASKS_PAGE_TOKENS = 'UPDATE_TASKS_PAGE_TOKENS';
export const PUT_MANIFEST_VIEW = 'PUT_MANIFEST_VIEW';
export const REQUIRED_PASSWORD_RESET_FAILURE =
  'REQUIRED_PASSWORD_RESET_FAILURE';
export const REQUIRED_PASSWORD_RESET_REQUEST =
  'REQUIRED_PASSWORD_RESET_REQUEST';
export const REQUIRED_PASSWORD_RESET_SUCCESS =
  'REQUIRED_PASSWORD_RESET_SUCCESS';
export const REMOVE_BOOKMARK_REQUEST = 'REMOVE_BOOKMARK_REQUEST';
export const SEND_MANIFEST_EMAIL_REQUEST = 'SEND_MANIFEST_EMAIL_REQUEST';
export const SEND_MANIFEST_EMAIL_SUCCESS = 'SEND_MANIFEST_EMAIL_SUCCESS';
export const SEND_MANIFEST_EMAIL_FAILURE = 'SEND_MANIFEST_EMAIL_FAILURE';
export const SET_ACTOR_NAME = 'SET_ACTOR_NAME';
export const SET_DASH_VISIBLE_COLUMNS = 'SET_DASH_VISIBLE_COLUMNS';
export const SET_DISPATCH_VISIBLE_COLUMNS = 'SET_DISPATCH_VISIBLE_COLUMNS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_IMPERSONATED_USER_ID = 'SET_IMPERSONATED_USER_ID';
export const SET_MANIFEST_EXCLUDED_FORM_FIELD_KEYS =
  'SET_MANIFEST_EXCLUDED_FORM_FIELD_KEYS';
export const SET_MANIFEST_SPLIT_START_TIMES = 'SET_MANIFEST_SPLIT_START_TIMES';
export const SET_PRODUCT_FILTERED = 'SET_PRODUCT_FILTERED';
export const SET_PRODUCT_PAGE = 'SET_PRODUCT_PAGE';
export const SET_PRODUCT_PAGE_SIZE = 'SET_PRODUCT_PAGE_SIZE';
export const SET_PRODUCT_SORTED = 'SET_PRODUCT_SORTED';
export const SET_PRODUCT_VISIBLE_COLUMNS = 'SET_PRODUCT_VISIBLE_COLUMNS';
export const SET_PRODUCT_RESPONSIBLE_AGENT_ID_REQUEST =
  'SET_PRODUCT_RESPONSIBLE_AGENT_ID_REQUEST';
export const SET_PRODUCT_RESPONSIBLE_AGENT_ID_SUCCESS =
  'SET_PRODUCT_RESPONSIBLE_AGENT_ID_SUCCESS';
export const SET_PRODUCT_RESPONSIBLE_AGENT_ID_FAILURE =
  'SET_PRODUCT_RESPONSIBLE_AGENT_ID_FAILURE';
export const SET_PRODUCT_AGENT_UNIT_MAPPINGS_FAILURE =
  'SET_PRODUCT_AGENT_UNIT_MAPPINGS_FAILURE';
export const SET_PRODUCT_AGENT_UNIT_MAPPINGS_REQUEST =
  'SET_PRODUCT_AGENT_UNIT_MAPPINGS_REQUEST';
export const SET_PRODUCT_AGENT_UNIT_MAPPINGS_SUCCESS =
  'SET_PRODUCT_AGENT_UNIT_MAPPINGS_SUCCESS';
export const SET_RESERVATION_VISIBLE_COLUMNS =
  'SET_RESERVATION_VISIBLE_COLUMNS';
export const SET_RESERVATION_DEFAULT_VISIBLE_COLUMNS =
  'SET_RESERVATION_DEFAULT_VISIBLE_COLUMNS';
export const SET_RESERVATION_CURRENT_PAGE = 'SET_RESERVATION_CURRENT_PAGE';
export const SET_RESERVATION_ROW_COUNT = 'SET_RESERVATION_ROW_COUNT';
export const SET_LAST_EXECUTED_SEARCH_CONDITION =
  'SET_LAST_EXECUTED_SEARCH_CONDITION';
export const SET_LAST_RESERVATION_SEARCH_QUERY =
  'SET_LAST_RESERVATION_SEARCH_QUERY';
export const SET_LAST_ANNUAL_PASS_RESERVATION_SEARCH_QUERY =
  'SET_LAST_ANNUAL_PASS_RESERVATION_SEARCH_QUERY';
export const SET_SHOULD_SHOW_DETAILED_CONDITIONS =
  'SET_SHOULD_SHOW_DETAILED_CONDITIONS';
export const SET_REVIEW_VISIBLE_COLUMNS = 'SET_REVIEW_VISIBLE_COLUMNS';
export const SET_TASKS_ACTION_ITEM_QUERY = 'SET_TASKS_ACTION_ITEM_QUERY';
export const SET_TASKS_CREATED_AT_FROM = 'SET_TASKS_CREATED_AT_FROM';
export const SET_TASKS_CREATED_AT_TO = 'SET_TASKS_CREATED_AT_TO';
export const SET_TASKS_DUE_DATE_FROM = 'SET_TASKS_DUE_DATE_FROM';
export const SET_TASKS_DUE_DATE_TO = 'SET_TASKS_DUE_DATE_TO';
export const SET_TASKS_FILTERS = 'SET_TASKS_FILTERS';
export const SET_TASKS_IS_ASCENDING = 'SET_TASKS_IS_ASCENDING';
export const SET_TASKS_ORDER_BY = 'SET_TASKS_ORDER_BY';
export const SET_TASKS_PAGE_SIZE = 'SET_TASKS_PAGE_SIZE';
export const SET_TASKS_RESERVATION_ID_QUERY = 'SET_TASKS_RESERVATION_ID_QUERY';
export const SET_ALTERNATE_ORGANIZATION_IS_ACTIVE =
  'SET_ALTERNATE_ORGANIZATION_IS_ACTIVE';
export const SET_ALTERNATE_ORGANIZATION_IS_ACTIVE_IN_SECRET =
  'SET_ALTERNATE_ORGANIZATION_IS_ACTIVE_IN_SECRET';
export const STANDBY_RESERVATION_FAILURE = 'STANDBY_RESERVATION_FAILURE';
export const STANDBY_RESERVATION_REQUEST = 'STANDBY_RESERVATION_REQUEST';
export const STANDBY_RESERVATION_SUCCESS = 'STANDBY_RESERVATION_SUCCESS';
export const UPDATE_TRANSLATIONS_FAILURE = 'UPDATE_TRANSLATIONS_FAILURE';
export const UPDATE_TRANSLATIONS_REQUEST = 'UPDATE_TRANSLATIONS_REQUEST';
export const UPDATE_TRANSLATIONS_SUCCESS = 'UPDATE_TRANSLATIONS_SUCCESS';
export const GET_TRANSLATIONS_FOR_TEXTS_FAILURE =
  'GET_TRANSLATIONS_FOR_TEXTS_FAILURE';
export const GET_TRANSLATIONS_FOR_TEXTS_REQUEST =
  'GET_TRANSLATIONS_FOR_TESTS_REQUEST';
export const GET_TRANSLATIONS_FOR_TEXTS_SUCCESS =
  'GET_TRANSLATIONS_FOR_TEXTS_SUCCESS';
export const BATCH_GET_TRANSLATIONS_FAILURE = 'BATCH_GET_TRANSLATIONS_FAILURE';
export const BATCH_GET_TRANSLATIONS_REQUEST = 'BATCH_GET_TRANSLATIONS_REQUEST';
export const BATCH_GET_TRANSLATIONS_SUCCESS = 'BATCH_GET_TRANSLATIONS_SUCCESS';
export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS';
export const FETCH_CONTRACTS_FAILURE = 'FETCH_CONTRACTS_FAILURE';
export const FETCH_CONTRACTS_REQUEST = 'FETCH_CONTRACTS_REQUEST';
export const CREATE_CONTRACT_FAILURE = 'CREATE_CONTRACT_FAILURE';
export const CREATE_CONTRACT_REQUEST = 'CREATE_CONTRACT_REQUEST';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';
export const DELETE_CONTRACT_FAILURE = 'DELETE_CONTRACT_FAILURE';
export const DELETE_CONTRACT_REQUEST = 'DELETE_CONTRACT_REQUEST';
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_FAILURE = 'UPDATE_CONTRACT_FAILURE';
export const UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
export const UPDATE_DISPATCH_DRIVERS = 'UPDATE_DISPATCH_DRIVERS';
export const UPDATE_DISPATCH_GUIDES = 'UPDATE_DISPATCH_GUIDES';
export const CREATE_PROMOTION_REQUEST = 'CREATE_PROMOTION_REQUEST';
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_FAILURE = 'CREATE_PROMOTION_FAILURE';
export const GET_PROMOTIONS_REQUEST = 'GET_PROMOTIONS_REQUEST';
export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS';
export const GET_PROMOTIONS_FAILURE = 'GET_PROMOTIONS_FAILURE';
export const UPDATE_PROMOTION_REQUEST = 'UPDATE_PROMOTION_REQUEST';
export const UPDATE_PROMOTION_SUCCESS = 'UPDATE_PROMOTION_SUCCESS';
export const UPDATE_PROMOTION_FAILURE = 'UPDATE_PROMOTION_FAILURE';
export const SET_WEEKLY_AVAILABILITY_START_DATE =
  'SET_WEEKLY_AVAILABILITY_START_DATE';
export const SET_MONTHLY_AVAILABILITY_SELECTED_MONTH =
  'SET_MONTHLY_AVAILABILITY_SELECTED_MONTH';
export const SET_CUSTOM_RANGE_AVAILABILITY_START_DATE =
  'SET_CUSTOM_RANGE_AVAILABILITY_START_DATE';
export const SET_CUSTOM_RANGE_AVAILABILITY_END_DATE =
  'SET_CUSTOM_RANGE_AVAILABILITY_END_DATE';
export const SET_AVAILABILITY_START_DATE = 'SET_AVAILABILITY_START_DATE';
export const SET_AVAILABILITY_END_DATE = 'SET_AVAILABILITY_END_DATE';
export const SET_AVAILABILITY_CURRENT_PAGE = 'SET_AVAILABILITY_CURRENT_PAGE';
export const SET_AVAILABILITY_PAGE_SIZE = 'SET_AVAILABILITY_PAGE_SIZE';
export const SET_AVAILABILITY_PRODUCT_FILTER =
  'SET_AVAILABILITY_PRODUCT_FILTER';
export const SET_AVAILABILITY_TAG_FILTER = 'SET_AVAILABILITY_TAG_FILTER';
export const SET_AVAILABILITY_SALES_STATUS_FILTER =
  'SET_AVAILABILITY_SALES_STATUS_FILTER';
export const SET_AVAILABILITY_DISPATCH_CREW_FILTER =
  'SET_AVAILABILITY_DISPATCH_CREW_FILTER';
export const SET_AVAILABILITY_DISPATCH_VEHICLE_FILTER =
  'SET_AVAILABILITY_DISPATCH_VEHICLE_FILTER';
export const SET_AVAILABILITY_DISPATCH_MISC_RESOURCE_FILTER =
  'SET_AVAILABILITY_DISPATCH_MISC_RESOURCE_FILTER';
export const SET_AVAILABILITY_RESERVATION_SLOT_VISIBILITY_SETTING =
  'SET_AVAILABILITY_RESERVATION_VISIBILITY_SETTING';
export const SET_AVAILABILITY_RESOURCE_VISIBILITY_SETTING =
  'SET_AVAILABILITY_RESOURCE_VISIBILITY_SETTING';
export const SET_ACTIVE_RESERVATION_TUTORIAL_POPUP =
  'SET_ACTIVE_RESERVATION_TUTORIAL_POPUP';
export const SET_SELECTED_PRIVATE_MARKETPLACE_INDEX =
  'SET_SELECTED_PRIVATE_MARKETPLACE_INDEX';
export const SEND_PAYMENT_EMAIL_FAILURE = 'SEND_PAYMENT_EMAIL_FAILURE';
export const SEND_PAYMENT_EMAIL_REQUEST = 'SEND_PAYMENT_EMAIL_REQUEST';
export const SEND_PAYMENT_EMAIL_SUCCESS = 'SEND_PAYMENT_EMAIL_SUCCESS';
export const SEND_REVIEW_REQUEST_EMAIL_FAILURE =
  'SEND_REVIEW_REQUEST_EMAIL_FAILURE';
export const SEND_REVIEW_REQUEST_EMAIL_REQUEST =
  'SEND_REVIEW_REQUEST_EMAIL_REQUEST';
export const SEND_REVIEW_REQUEST_EMAIL_SUCCESS =
  'SEND_REVIEW_REQUEST_EMAIL_SUCCESS';
export const SEND_SURVEY_REQUEST_EMAIL_FAILURE =
  'SEND_SURVEY_REQUEST_EMAIL_FAILURE';
export const SEND_SURVEY_REQUEST_EMAIL_REQUEST =
  'SEND_SURVEY_REQUEST_EMAIL_REQUEST';
export const SEND_SURVEY_REQUEST_EMAIL_SUCCESS =
  'SEND_SURVEY_REQUEST_EMAIL_SUCCESS';
export const SEND_WAIVER_REQUEST_EMAIL_FAILURE =
  'SEND_WAIVER_REQUEST_EMAIL_FAILURE';
export const SEND_WAIVER_REQUEST_EMAIL_REQUEST =
  'SEND_WAIVER_REQUEST_EMAIL_REQUEST';
export const SEND_WAIVER_REQUEST_EMAIL_SUCCESS =
  'SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_SUCCESS';
export const SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_FAILURE =
  'SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_FAILURE';
export const SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_REQUEST =
  'SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_REQUEST';
export const SEND_MEDIA_DOWNLOAD_OFFER_EMAIL_SUCCESS =
  'SEND_WAIVER_REQUEST_EMAIL_SUCCESS';
export const UPDATE_PRODUCT_BLACKLIST_WHITELIST_REQUEST =
  'UPDATE_PRODUCT_BLACKLIST_WHITELIST_REQUEST';
export const UPDATE_PRODUCT_BLACKLIST_WHITELIST_SUCCESS =
  'UPDATE_PRODUCT_BLACKLIST_WHITELIST_SUCCESS';
export const UPDATE_PRODUCT_BLACKLIST_WHITELIST_FAILURE =
  'UPDATE_PRODUCT_BLACKLIST_WHITELIST_FAILURE';
export const FETCH_REVIEWS_REQUEST = 'FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAILURE = 'FETCH_REVIEWS_FAILURE';
export const FETCH_PAGE_OF_REVIEWS_REQUEST = 'FETCH_PAGE_OF_REVIEWS_REQUEST';
export const FETCH_PAGE_OF_REVIEWS_SUCCESS = 'FETCH_PAGE_OF_REVIEWS_SUCCESS';
export const FETCH_PAGE_OF_REVIEWS_FAILURE = 'FETCH_PAGE_OF_REVIEWS_FAILURE';
export const FETCH_REVIEWS_BY_RESERVATION_IDS_REQUEST =
  'FETCH_REVIEWS_BY_RESERVATION_IDS_REQUEST';
export const FETCH_REVIEWS_BY_RESERVATION_IDS_SUCCESS =
  'FETCH_REVIEWS_BY_RESERVATION_IDS_SUCCESS';
export const FETCH_REVIEWS_BY_RESERVATION_IDS_FAILURE =
  'FETCH_REVIEWS_BY_RESERVATION_IDS_FAILURE';
export const FETCH_REVIEW_STATS_REQUEST = 'FETCH_REVIEW_STATS_REQUEST';
export const FETCH_REVIEW_STATS_SUCCESS = 'FETCH_REVIEW_STATS_SUCCESS';
export const FETCH_REVIEW_STATS_FAILURE = 'FETCH_REVIEW_STATS_FAILURE';
export const UPDATE_REVIEW_REQUEST = 'UPDATE_REVIEW_REQUEST';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_FAILURE = 'UPDATE_REVIEW_FAILURE';
export const SAVE_PAYMENT_METHOD_REQUEST = 'SAVE_PAYMENT_METHOD_REQUEST';
export const SAVE_PAYMENT_METHOD_SUCCESS = 'SAVE_PAYMENT_METHOD_SUCCESS';
export const SAVE_PAYMENT_METHOD_FAILURE = 'SAVE_PAYMENT_METHOD_FAILURE';
export const ADD_SUBSCRIPTION_REQUEST = 'ADD_SUBSCRIPTION_REQUEST';
export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS';
export const ADD_SUBSCRIPTION_FAILURE = 'ADD_SUBSCRIPTION_FAILURE';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';
export const FETCH_SUBSCRIPTION_INVOICES_REQUEST =
  'FETCH_SUBSCRIPTION_INVOICES_REQUEST';
export const FETCH_SUBSCRIPTION_INVOICES_FAILURE =
  'FETCH_SUBSCRIPTION_INVOICES_FAILURE';
export const FETCH_SUBSCRIPTION_INVOICES_SUCCESS =
  'FETCH_SUBSCRIPTION_INVOICES_SUCCESS';
export const SUBDOMAIN_IS_AVAILABLE_REQUEST = 'SUBDOMAIN_IS_AVAILABLE_REQUEST';
export const SUBDOMAIN_IS_AVAILABLE_SUCCESS = 'SUBDOMAIN_IS_AVAILABLE_SUCCESS';
export const SEND_RESERVATION_EMAIL_REQUEST = 'SEND_RESERVATION_EMAIL_REQUEST';
export const SEND_RESERVATION_EMAIL_SUCCESS = 'SEND_RESERVATION_EMAIL_SUCCESS';
export const SEND_RESERVATION_EMAIL_FAILURE = 'SEND_RESERVATION_EMAIL_FAILURE';
export const FETCH_CUSTOM_PAGES_SUCCESS = 'FETCH_CUSTOM_PAGES_SUCCESS';
export const FETCH_CUSTOM_PAGES_FAILURE = 'FETCH_CUSTOM_PAGES_FAILURE';
export const FETCH_CUSTOM_PAGES_REQUEST = 'FETCH_CUSTOM_PAGES_REQUEST';
export const CREATE_CUSTOM_PAGE_FAILURE = 'CREATE_CUSTOM_PAGE_FAILURE';
export const CREATE_CUSTOM_PAGE_REQUEST = 'CREATE_CUSTOM_PAGE_REQUEST';
export const CREATE_CUSTOM_PAGE_SUCCESS = 'CREATE_CUSTOM_PAGE_SUCCESS';
export const DELETE_CUSTOM_PAGE_FAILURE = 'DELETE_CUSTOM_PAGE_FAILURE';
export const DELETE_CUSTOM_PAGE_REQUEST = 'DELETE_CUSTOM_PAGE_REQUEST';
export const DELETE_CUSTOM_PAGE_SUCCESS = 'DELETE_CUSTOM_PAGE_SUCCESS';
export const UPDATE_CUSTOM_PAGE_FAILURE = 'UPDATE_CUSTOM_PAGE_FAILURE';
export const UPDATE_CUSTOM_PAGE_REQUEST = 'UPDATE_CUSTOM_PAGE_REQUEST';
export const UPDATE_CUSTOM_PAGE_SUCCESS = 'UPDATE_CUSTOM_PAGE_SUCCESS';
export const BATCH_UPDATE_CUSTOM_PAGE_FAILURE =
  'BATCH_UPDATE_CUSTOM_PAGE_FAILURE';
export const BATCH_UPDATE_CUSTOM_PAGE_REQUEST =
  'BATCH_UPDATE_CUSTOM_PAGE_REQUEST';
export const BATCH_UPDATE_CUSTOM_PAGE_SUCCESS =
  'BATCH_UPDATE_CUSTOM_PAGE_SUCCESS';
export const FETCH_CUSTOM_TOP_PAGE_REQUEST = 'FETCH_CUSTOM_TOP_PAGE_REQUEST';
export const FETCH_CUSTOM_TOP_PAGE_SUCCESS = 'FETCH_CUSTOM_TOP_PAGE_SUCCESS';
export const FETCH_CUSTOM_TOP_PAGE_FAILURE = 'FETCH_CUSTOM_TOP_PAGE_FAILURE';
export const PUT_CUSTOM_TOP_PAGE_REQUEST = 'PUT_CUSTOM_TOP_PAGE_REQUEST';
export const PUT_CUSTOM_TOP_PAGE_SUCCESS = 'PUT_CUSTOM_TOP_PAGE_SUCCESS';
export const PUT_CUSTOM_TOP_PAGE_FAILURE = 'PUT_CUSTOM_TOP_PAGE_FAILURE';
export const FETCH_GUIDANCE_PAGES_SUCCESS = 'FETCH_GUIDANCE_PAGES_SUCCESS';
export const FETCH_GUIDANCE_PAGES_FAILURE = 'FETCH_GUIDANCE_PAGES_FAILURE';
export const FETCH_GUIDANCE_PAGES_REQUEST = 'FETCH_GUIDANCE_PAGES_REQUEST';
export const DELETE_GUIDANCE_PAGE_FAILURE = 'DELETE_GUIDANCE_PAGE_FAILURE';
export const DELETE_GUIDANCE_PAGE_REQUEST = 'DELETE_GUIDANCE_PAGE_REQUEST';
export const DELETE_GUIDANCE_PAGE_SUCCESS = 'DELETE_GUIDANCE_PAGE_SUCCESS';
export const UPDATE_GUIDANCE_PAGE_FAILURE = 'UPDATE_GUIDANCE_PAGE_FAILURE';
export const UPDATE_GUIDANCE_PAGE_SUCCESS = 'UPDATE_GUIDANCE_PAGE_SUCCESS';
export const UPDATE_GUIDANCE_PAGE_REQUEST = 'UPDATE_GUIDANCE_PAGE_REQUEST';
export const CREATE_GUIDANCE_PAGE_REQUEST = 'CREATE_GUIDANCE_PAGE_REQUEST';
export const CREATE_GUIDANCE_PAGE_FAILURE = 'CREATE_GUIDANCE_PAGE_FAILURE';
export const CREATE_GUIDANCE_PAGE_SUCCESS = 'CREATE_GUIDANCE_PAGE_SUCCESS';
export const BATCH_UPDATE_GUIDANCE_PAGE_FAILURE =
  'BATCH_UPDATE_GUIDANCE_PAGE_FAILURE';
export const BATCH_UPDATE_GUIDANCE_PAGE_REQUEST =
  'BATCH_UPDATE_GUIDANCE_PAGE_REQUEST';
export const BATCH_UPDATE_GUIDANCE_PAGE_SUCCESS =
  'BATCH_UPDATE_GUIDANCE_PAGE_SUCCESS';
export const FETCH_GUIDANCE_COUPONS_SUCCESS = 'FETCH_GUIDANCE_COUPONS_SUCCESS';
export const FETCH_GUIDANCE_COUPONS_FAILURE = 'FETCH_GUIDANCE_COUPONS_FAILURE';
export const FETCH_GUIDANCE_COUPONS_REQUEST = 'FETCH_GUIDANCE_COUPONS_REQUEST';
export const DELETE_GUIDANCE_COUPON_FAILURE = 'DELETE_GUIDANCE_COUPON_FAILURE';
export const DELETE_GUIDANCE_COUPON_REQUEST = 'DELETE_GUIDANCE_COUPON_REQUEST';
export const DELETE_GUIDANCE_COUPON_SUCCESS = 'DELETE_GUIDANCE_COUPON_SUCCESS';
export const CREATE_GUIDANCE_COUPON_REQUEST = 'CREATE_GUIDANCE_COUPON_REQUEST';
export const UPDATE_GUIDANCE_COUPON_FAILURE = 'UPDATE_GUIDANCE_COUPON_FAILURE';
export const UPDATE_GUIDANCE_COUPON_SUCCESS = 'UPDATE_GUIDANCE_COUPON_SUCCESS';
export const UPDATE_GUIDANCE_COUPON_REQUEST = 'UPDATE_GUIDANCE_COUPON_REQUEST';
export const CREATE_GUIDANCE_COUPON_FAILURE = 'CREATE_GUIDANCE_COUPON_FAILURE';
export const CREATE_GUIDANCE_COUPON_SUCCESS = 'CREATE_GUIDANCE_COUPON_SUCCESS';
export const FETCH_GUIDANCE_LOCATIONS_SUCCESS =
  'FETCH_GUIDANCE_LOCATIONS_SUCCESS';
export const FETCH_GUIDANCE_LOCATIONS_FAILURE =
  'FETCH_GUIDANCE_LOCATIONS_FAILURE';
export const FETCH_GUIDANCE_LOCATIONS_REQUEST =
  'FETCH_GUIDANCE_LOCATIONS_REQUEST';
export const DELETE_GUIDANCE_LOCATION_FAILURE =
  'DELETE_GUIDANCE_LOCATION_FAILURE';
export const DELETE_GUIDANCE_LOCATION_REQUEST =
  'DELETE_GUIDANCE_LOCATION_REQUEST';
export const DELETE_GUIDANCE_LOCATION_SUCCESS =
  'DELETE_GUIDANCE_LOCATION_SUCCESS';
export const UPDATE_GUIDANCE_LOCATION_FAILURE =
  'UPDATE_GUIDANCE_LOCATION_FAILURE';
export const UPDATE_GUIDANCE_LOCATION_SUCCESS =
  'UPDATE_GUIDANCE_LOCATION_SUCCESS';
export const UPDATE_GUIDANCE_LOCATION_REQUEST =
  'UPDATE_GUIDANCE_LOCATION_REQUEST';
export const CREATE_GUIDANCE_LOCATION_REQUEST =
  'CREATE_GUIDANCE_LOCATION_REQUEST';
export const CREATE_GUIDANCE_LOCATION_FAILURE =
  'CREATE_GUIDANCE_LOCATION_FAILURE';
export const CREATE_GUIDANCE_LOCATION_SUCCESS =
  'CREATE_GUIDANCE_LOCATION_SUCCESS';
export const CALCULATE_RESERVATION_FARE_REQUEST =
  'CALCULATE_RESERVATION_FARE_REQUEST';
export const CALCULATE_RESERVATION_FARE_SUCCESS =
  'CALCULATE_RESERVATION_FARE_SUCCESS';
export const CALCULATE_RESERVATION_FARE_FAILURE =
  'CALCULATE_RESERVATION_FARE_FAILURE';
export const GET_REBOOK_FROM_RESERVATION_FARES_REQUEST =
  'GET_REBOOK_FROM_RESERVATION_FARES_REQUEST';
export const GET_REBOOK_FROM_RESERVATION_FARES_SUCCESS =
  'GET_REBOOK_FROM_RESERVATION_FARES_SUCCESS';
export const GET_REBOOK_FROM_RESERVATION_FARES_FAILURE =
  'GET_REBOOK_FROM_RESERVATION_FARES_FAILURE';
export const GET_REBOOK_FROM_RESERVATION_FARES_CANCELED =
  'GET_REBOOK_FROM_RESERVATION_FARES_CANCELED';
export const CREATE_WAIVER_TEMPLATE_REQUEST = 'CREATE_WAIVER_TEMPLATE_REQUEST';
export const CREATE_WAIVER_TEMPLATE_FAILURE = 'CREATE_WAIVER_TEMPLATE_FAILURE';
export const CREATE_WAIVER_TEMPLATE_SUCCESS = 'CREATE_WAIVER_TEMPLATE_SUCCESS';
export const UPDATE_WAIVER_TEMPLATE_REQUEST = 'UPDATE_WAIVER_TEMPLATE_REQUEST';
export const UPDATE_WAIVER_TEMPLATE_FAILURE = 'UPDATE_WAIVER_TEMPLATE_FAILURE';
export const UPDATE_WAIVER_TEMPLATE_SUCCESS = 'UPDATE_WAIVER_TEMPLATE_SUCCESS';
export const FETCH_WAIVER_TEMPLATES_REQUEST = 'FETCH_WAIVER_TEMPLATES_REQUEST';
export const FETCH_WAIVER_TEMPLATES_SUCCESS = 'FETCH_WAIVER_TEMPLATES_SUCCESS';
export const FETCH_WAIVER_TEMPLATES_FAILURE = 'FETCH_WAIVER_TEMPLATES_FAILURE';
export const FETCH_WAIVER_TEMPLATE_REQUEST = 'FETCH_WAIVER_TEMPLATE_REQUEST';
export const FETCH_WAIVER_TEMPLATE_SUCCESS = 'FETCH_WAIVER_TEMPLATE_SUCCESS';
export const FETCH_WAIVER_TEMPLATE_FAILURE = 'FETCH_WAIVER_TEMPLATE_FAILURE';
export const DELETE_WAIVER_TEMPLATE_REQUEST = 'DELETE_WAIVER_TEMPLATE_REQUEST';
export const DELETE_WAIVER_TEMPLATE_SUCCESS = 'DELETE_WAIVER_TEMPLATE_SUCCESS';
export const DELETE_WAIVER_TEMPLATE_FAILURE = 'DELETE_WAIVER_TEMPLATE_FAILURE';
export const FETCH_WAIVER_TEMPLATE_TRANSLATIONS_REQUEST =
  'FETCH_WAIVER_TEMPLATE_TRANSLATIONS_REQUEST';
export const FETCH_WAIVER_TEMPLATE_TRANSLATIONS_SUCCESS =
  'FETCH_WAIVER_TEMPLATE_TRANSLATIONS_SUCCESS';
export const FETCH_WAIVER_TEMPLATE_TRANSLATIONS_FAILURE =
  'FETCH_WAIVER_TEMPLATE_TRANSLATIONS_FAILURE';
export const FETCH_WAIVERS_BY_RESERVATION_IDS_REQUEST =
  'FETCH_WAIVERS_BY_RESERVATION_IDS_REQUEST';
export const FETCH_WAIVERS_BY_RESERVATION_IDS_SUCCESS =
  'FETCH_WAIVERS_BY_RESERVATION_IDS_SUCCESS';
export const FETCH_WAIVERS_BY_RESERVATION_IDS_FAILURE =
  'FETCH_WAIVERS_BY_RESERVATION_IDS_FAILURE';
export const CREATE_SURVEY_TEMPLATE_REQUEST = 'CREATE_SURVEY_TEMPLATE_REQUEST';
export const CREATE_SURVEY_TEMPLATE_FAILURE = 'CREATE_SURVEY_TEMPLATE_FAILURE';
export const CREATE_SURVEY_TEMPLATE_SUCCESS = 'CREATE_SURVEY_TEMPLATE_SUCCESS';
export const UPDATE_SURVEY_TEMPLATE_REQUEST = 'UPDATE_SURVEY_TEMPLATE_REQUEST';
export const UPDATE_SURVEY_TEMPLATE_FAILURE = 'UPDATE_SURVEY_TEMPLATE_FAILURE';
export const UPDATE_SURVEY_TEMPLATE_SUCCESS = 'UPDATE_SURVEY_TEMPLATE_SUCCESS';
export const FETCH_SURVEY_TEMPLATES_REQUEST = 'FETCH_SURVEY_TEMPLATES_REQUEST';
export const FETCH_SURVEY_TEMPLATES_SUCCESS = 'FETCH_SURVEY_TEMPLATES_SUCCESS';
export const FETCH_SURVEY_TEMPLATES_FAILURE = 'FETCH_SURVEY_TEMPLATES_FAILURE';
export const FETCH_SURVEY_TEMPLATE_REQUEST = 'FETCH_SURVEY_TEMPLATE_REQUEST';
export const FETCH_SURVEY_TEMPLATE_SUCCESS = 'FETCH_SURVEY_TEMPLATE_SUCCESS';
export const FETCH_SURVEY_TEMPLATE_FAILURE = 'FETCH_SURVEY_TEMPLATE_FAILURE';
export const DELETE_SURVEY_TEMPLATE_REQUEST = 'DELETE_SURVEY_TEMPLATE_REQUEST';
export const DELETE_SURVEY_TEMPLATE_SUCCESS = 'DELETE_SURVEY_TEMPLATE_SUCCESS';
export const DELETE_SURVEY_TEMPLATE_FAILURE = 'DELETE_SURVEY_TEMPLATE_FAILURE';
export const FETCH_SURVEY_TEMPLATE_TRANSLATIONS_REQUEST =
  'FETCH_SURVEY_TEMPLATE_TRANSLATIONS_REQUEST';
export const FETCH_SURVEY_TEMPLATE_TRANSLATIONS_SUCCESS =
  'FETCH_SURVEY_TEMPLATE_TRANSLATIONS_SUCCESS';
export const FETCH_SURVEY_TEMPLATE_TRANSLATIONS_FAILURE =
  'FETCH_SURVEY_TEMPLATE_TRANSLATIONS_FAILURE';
export const FETCH_SURVEY_SUBMISSIONS_REQUEST =
  'FETCH_SURVEY_SUBMISSIONS_REQUEST';
export const FETCH_SURVEY_SUBMISSIONS_SUCCESS =
  'FETCH_SURVEY_SUBMISSIONS_SUCCESS';
export const FETCH_SURVEY_SUBMISSIONS_FAILURE =
  'FETCH_SURVEY_SUBMISSIONS_FAILURE';
export const FETCH_SURVEY_SUBMISSION_REQUEST =
  'FETCH_SURVEY_SUBMISSION_REQUEST';
export const FETCH_SURVEY_SUBMISSION_SUCCESS =
  'FETCH_SURVEY_SUBMISSION_SUCCESS';
export const FETCH_SURVEY_SUBMISSION_FAILURE =
  'FETCH_SURVEY_SUBMISSION_FAILURE';
export const FETCH_SURVEY_SUBMISSION_RESULTS_REQUEST =
  'FETCH_SURVEY_SUBMISSION_RESULTS_REQUEST';
export const FETCH_SURVEY_SUBMISSION_RESULTS_SUCCESS =
  'FETCH_SURVEY_SUBMISSION_RESULTS_SUCCESS';
export const FETCH_SURVEY_SUBMISSION_RESULTS_FAILURE =
  'FETCH_SURVEY_SUBMISSION_RESULTS_FAILURE';
export const FETCH_SURVEY_SUBMISSIONS_CSV_REQUEST =
  'FETCH_SURVEY_SUBMISSIONS_CSV_REQUEST';
export const FETCH_SURVEY_SUBMISSIONS_CSV_FAILURE =
  'FETCH_SURVEY_SUBMISSIONS_CSV_FAILURE';
export const FETCH_SURVEY_SUBMISSIONS_CSV_SUCCESS =
  'FETCH_SURVEY_SUBMISSIONS_CSV_SUCCESS';
export const FETCH_NPS_SURVEY_REPORT_DATA_REQUEST =
  'FETCH_NPS_SURVEY_REPORT_DATA_REQUEST';
export const FETCH_NPS_SURVEY_REPORT_DATA_SUCCESS =
  'FETCH_NPS_SURVEY_REPORT_DATA_SUCCESS';
export const FETCH_NPS_SURVEY_REPORT_DATA_FAILURE =
  'FETCH_NPS_SURVEY_REPORT_DATA_FAILURE';

export const FETCH_WAIVERS_REQUEST = 'FETCH_WAIVERS_REQUEST';
export const FETCH_WAIVERS_SUCCESS = 'FETCH_WAIVERS_SUCCESS';
export const FETCH_WAIVERS_FAILURE = 'FETCH_WAIVERS_FAILURE';
export const DELETE_WAIVER_REQUEST = 'DELETE_WAIVER_REQUEST';
export const DELETE_WAIVER_SUCCESS = 'DELETE_WAIVER_SUCCESS';
export const DELETE_WAIVER_FAILURE = 'DELETE_WAIVER_FAILURE';
export const CLEAR_PARTICIPANT_WAIVER_REQUEST =
  'CLEAR_PARTICIPANT_WAIVER_REQUEST';
export const CLEAR_PARTICIPANT_WAIVER_SUCCESS =
  'CLEAR_PARTICIPANT_WAIVER_SUCCESS';
export const CLEAR_PARTICIPANT_WAIVER_FAILURE =
  'CLEAR_PARTICIPANT_WAIVER_FAILURE';
export const DOWNLOAD_WAIVER_PDF_FAILURE = 'DOWNLOAD_WAIVER_PDF_FAILURE';
export const DOWNLOAD_WAIVER_PDF_REQUEST = 'DOWNLOAD_WAIVER_PDF_REQUEST';
export const DOWNLOAD_WAIVER_PDF_SUCCESS = 'DOWNLOAD_WAIVER_PDF_SUCCESS';
export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const ENABLE_ACCOUNT_REQUEST = 'ENABLE_ACCOUNT_REQUEST';
export const ENABLE_ACCOUNT_SUCCESS = 'ENABLE_ACCOUNT_SUCCESS';
export const ENABLE_ACCOUNT_FAILURE = 'ENABLE_ACCOUNT_FAILURE';
export const DISABLE_ACCOUNT_REQUEST = 'DISABLE_ACCOUNT_REQUEST';
export const DISABLE_ACCOUNT_SUCCESS = 'DISABLE_ACCOUNT_SUCCESS';
export const DISABLE_ACCOUNT_FAILURE = 'DISABLE_ACCOUNT_FAILURE';
export const SET_LAST_EXECUTED_WAIVER_SEARCH_CONDITION =
  'SET_LAST_EXECUTED_WAIVER_SEARCH_CONDITION';
export const SET_WAIVER_VISIBLE_COLUMNS = 'SET_WAIVER_VISIBLE_COLUMNS';
export const SET_WAIVER_ROW_COUNT = 'SET_WAIVER_ROW_COUNT';
export const SET_WAIVER_CURRENT_PAGE = 'SET_WAIVER_CURRENT_PAGE';
export const DOWNLOAD_GUIDE_CSV_FAILURE = 'DOWNLOAD_GUIDE_CSV_FAILURE';
export const DOWNLOAD_GUIDE_CSV_REQUEST = 'DOWNLOAD_GUIDE_CSV_REQUEST';
export const DOWNLOAD_GUIDE_CSV_SUCCESS = 'DOWNLOAD_GUIDE_CSV_SUCCESS';
export const DOWNLOAD_GUIDE_PDF_FAILURE = 'DOWNLOAD_GUIDE_PDF_FAILURE';
export const DOWNLOAD_GUIDE_PDF_REQUEST = 'DOWNLOAD_GUIDE_PDF_REQUEST';
export const DOWNLOAD_GUIDE_PDF_SUCCESS = 'DOWNLOAD_GUIDE_PDF_SUCCESS';
export const SEND_GUIDE_EMAIL_REQUEST = 'SEND_GUIDE_EMAIL_REQUEST';
export const SEND_GUIDE_EMAIL_SUCCESS = 'SEND_GUIDE_EMAIL_SUCCESS';
export const SEND_GUIDE_EMAIL_FAILURE = 'SEND_GUIDE_EMAIL_FAILURE';
export const FETCH_GUIDE_SINGLE_DAY_SCHEDULES_FAILURE =
  'FETCH_GUIDE_SINGLE_DAY_SCHEDULES_FAILURE';
export const FETCH_GUIDE_SINGLE_DAY_SCHEDULES_REQUEST =
  'FETCH_GUIDE_SINGLE_DAY_SCHEDULES_REQUEST';
export const FETCH_GUIDE_SINGLE_DAY_SCHEDULES_SUCCESS =
  'FETCH_GUIDE_SINGLE_DAY_SCHEDULES_SUCCESS';
export const FETCH_GUIDE_SINGLE_DAY_SCHEDULES_CANCELED =
  'FETCH_GUIDE_SINGLE_DAY_SCHEDULES_CANCELED';
export const FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_FAILURE =
  'FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_FAILURE';
export const FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_REQUEST =
  'FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_REQUEST';
export const FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_SUCCESS =
  'FETCH_GUIDE_SINGLE_DAY_SCHEDULES_BY_ID_SUCCESS';
export const CREATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE =
  'CREATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE';
export const CREATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST =
  'CREATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST';
export const CREATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS =
  'CREATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS';
export const UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST =
  'UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST';
export const UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS =
  'UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS';
export const UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE =
  'UPDATE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE';
export const DELETE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE =
  'DELETE_GUIDE_SINGLE_DAY_SCHEDULE_FAILURE';
export const DELETE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST =
  'DELETE_GUIDE_SINGLE_DAY_SCHEDULE_REQUEST';
export const DELETE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS =
  'DELETE_GUIDE_SINGLE_DAY_SCHEDULE_SUCCESS';
export const FETCH_RESERVATION_REPORT_DATA_FAILURE =
  'FETCH_RESERVATION_REPORT_DATA_FAILURE';
export const FETCH_RESERVATION_REPORT_DATA_REQUEST =
  'FETCH_RESERVATION_REPORT_DATA_REQUEST';
export const FETCH_RESERVATION_REPORT_DATA_SUCCESS =
  'FETCH_RESERVATION_REPORT_DATA_SUCCESS';
export const SET_LAST_RESERVATION_REPORT_SETTINGS =
  'SET_LAST_RESERVATION_REPORT_SETTINGS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const FETCH_ACCESS_REPORT_DATA_FAILURE =
  'FETCH_ACCESS_REPORT_DATA_FAILURE';
export const FETCH_ACCESS_REPORT_DATA_REQUEST =
  'FETCH_ACCESS_REPORT_DATA_REQUEST';
export const FETCH_ACCESS_REPORT_DATA_SUCCESS =
  'FETCH_ACCESS_REPORT_DATA_SUCCESS';
export const SET_LAST_ACCESS_REPORT_SETTINGS =
  'SET_LAST_ACCESS_REPORT_SETTINGS';
export const SET_LAST_MANIFEST_DISPLAY_SETTINGS =
  'SET_LAST_MANIFEST_DISPLAY_SETTINGS';
export const FETCH_MEMOS_SUCCESS = 'FETCH_MEMOS_SUCCESS';
export const FETCH_MEMOS_FAILURE = 'FETCH_MEMOS_FAILURE';
export const FETCH_MEMOS_REQUEST = 'FETCH_MEMOS_REQUEST';
export const FETCH_MEMO_SUCCESS = 'FETCH_MEMO_SUCCESS';
export const FETCH_MEMO_FAILURE = 'FETCH_MEMO_FAILURE';
export const FETCH_MEMO_REQUEST = 'FETCH_MEMO_REQUEST';
export const CREATE_MEMO_FAILURE = 'CREATE_MEMO_FAILURE';
export const CREATE_MEMO_REQUEST = 'CREATE_MEMO_REQUEST';
export const CREATE_MEMO_SUCCESS = 'CREATE_MEMO_SUCCESS';
export const DELETE_MEMO_FAILURE = 'DELETE_MEMO_FAILURE';
export const DELETE_MEMO_REQUEST = 'DELETE_MEMO_REQUEST';
export const DELETE_MEMO_SUCCESS = 'DELETE_MEMO_SUCCESS';
export const UPDATE_MEMO_FAILURE = 'UPDATE_MEMO_FAILURE';
export const UPDATE_MEMO_REQUEST = 'UPDATE_MEMO_REQUEST';
export const UPDATE_MEMO_SUCCESS = 'UPDATE_MEMO_SUCCESS';
export const FETCH_MEDIA_DOWNLOAD_ORDERS_FAILURE =
  'FETCH_MEDIA_DOWNLOAD_ORDERS_FAILURE';
export const FETCH_MEDIA_DOWNLOAD_ORDERS_REQUEST =
  'FETCH_MEDIA_DOWNLOAD_ORDERS_REQUEST';
export const FETCH_MEDIA_DOWNLOAD_ORDERS_SUCCESS =
  'FETCH_MEDIA_DOWNLOAD_ORDERS_SUCCESS';
export const SET_LAST_EXECUTED_MEDIA_DOWNLOAD_ORDER_SEARCH_CONDITION =
  'SET_LAST_EXECUTED_MEDIA_DOWNLOAD_ORDER_SEARCH_CONDITION';
export const SET_MEDIA_DOWNLOAD_ORDER_VISIBLE_COLUMNS =
  'SET_MEDIA_DOWNLOAD_ORDER_VISIBLE_COLUMNS';
export const SET_MEDIA_DOWNLOAD_ORDER_ROW_COUNT =
  'SET_MEDIA_DOWNLOAD_ORDER_ROW_COUNT';
export const SET_MEDIA_DOWNLOAD_ORDER_CURRENT_PAGE =
  'SET_MEDIA_DOWNLOAD_ORDER_CURRENT_PAGE';

export const FETCH_MEDIA_DOWNLOAD_PRICES_SUCCESS =
  'FETCH_MEDIA_DOWNLOAD_PRICES_SUCCESS';
export const FETCH_MEDIA_DOWNLOAD_PRICES_FAILURE =
  'FETCH_MEDIA_DOWNLOAD_PRICES_FAILURE';
export const FETCH_MEDIA_DOWNLOAD_PRICES_REQUEST =
  'FETCH_MEDIA_DOWNLOAD_PRICES_REQUEST';
export const DELETE_MEDIA_DOWNLOAD_PRICE_FAILURE =
  'DELETE_MEDIA_DOWNLOAD_PRICE_FAILURE';
export const DELETE_MEDIA_DOWNLOAD_PRICE_REQUEST =
  'DELETE_MEDIA_DOWNLOAD_PRICE_REQUEST';
export const DELETE_MEDIA_DOWNLOAD_PRICE_SUCCESS =
  'DELETE_MEDIA_DOWNLOAD_PRICE_SUCCESS';
export const UPDATE_MEDIA_DOWNLOAD_PRICE_FAILURE =
  'UPDATE_MEDIA_DOWNLOAD_PRICE_FAILURE';
export const UPDATE_MEDIA_DOWNLOAD_PRICE_SUCCESS =
  'UPDATE_MEDIA_DOWNLOAD_PRICE_SUCCESS';
export const UPDATE_MEDIA_DOWNLOAD_PRICE_REQUEST =
  'UPDATE_MEDIA_DOWNLOAD_PRICE_REQUEST';
export const CREATE_MEDIA_DOWNLOAD_PRICE_REQUEST =
  'CREATE_MEDIA_DOWNLOAD_PRICE_REQUEST';
export const CREATE_MEDIA_DOWNLOAD_PRICE_FAILURE =
  'CREATE_MEDIA_DOWNLOAD_PRICE_FAILURE';
export const CREATE_MEDIA_DOWNLOAD_PRICE_SUCCESS =
  'CREATE_MEDIA_DOWNLOAD_PRICE_SUCCESS';
export const FETCH_MEDIA_DOWNLOAD_SALES_PAGES_SUCCESS =
  'FETCH_MEDIA_DOWNLOAD_SALES_PAGES_SUCCESS';
export const FETCH_MEDIA_DOWNLOAD_SALES_PAGES_FAILURE =
  'FETCH_MEDIA_DOWNLOAD_SALES_PAGES_FAILURE';
export const FETCH_MEDIA_DOWNLOAD_SALES_PAGES_REQUEST =
  'FETCH_MEDIA_DOWNLOAD_SALES_PAGES_REQUEST';
export const DELETE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE =
  'DELETE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE';
export const DELETE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST =
  'DELETE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST';
export const DELETE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS =
  'DELETE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS';
export const UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE =
  'UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE';
export const UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS =
  'UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS';
export const UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST =
  'UPDATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST';
export const CREATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST =
  'CREATE_MEDIA_DOWNLOAD_SALES_PAGE_REQUEST';
export const CREATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE =
  'CREATE_MEDIA_DOWNLOAD_SALES_PAGE_FAILURE';
export const CREATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS =
  'CREATE_MEDIA_DOWNLOAD_SALES_PAGE_SUCCESS';

export const SET_DASHBOARD_TAB = 'SET_DASHBOARD_TAB';
export const SET_DASHBOARD_ROW_COUNT = 'SET_DASHBOARD_ROW_COUNT';
export const SET_DASHBOARD_CURRENT_PAGE = 'SET_DASHBOARD_CURRENT_PAGE';
export const RESEND_EMAIL_REQUEST = 'RESEND_EMAIL_REQUEST';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILURE = 'RESEND_EMAIL_FAILURE';

export const FETCH_CHECKIN_RECORDS_REQUEST = 'FETCH_CHECKIN_RECORDS_REQUEST';
export const FETCH_CHECKIN_RECORDS_SUCCESS = 'FETCH_CHECKIN_RECORDS_SUCCESS';
export const FETCH_CHECKIN_RECORDS_FAILURE = 'FETCH_CHECKIN_RECORDS_FAILURE';
export const SET_LAST_EXECUTED_CHECKIN_RECORD_SEARCH_CONDITION =
  'SET_LAST_EXECUTED_CHECKIN_RECORD_SEARCH_CONDITION';
export const SET_CHECKIN_RECORD_CURRENT_PAGE =
  'SET_CHECKIN_RECORD_CURRENT_PAGE';
export const SET_CHECKIN_RECORD_ROW_COUNT = 'SET_CHECKIN_RECORD_ROW_COUNT';
export const DOWNLOAD_CHECKIN_RECORD_CSV_FAILURE =
  'DOWNLOAD_CHECKIN_RECORD_CSV_FAILURE';
export const DOWNLOAD_CHECKIN_RECORD_CSV_REQUEST =
  'DOWNLOAD_CHECKIN_RECORD_CSV_REQUEST';
export const DOWNLOAD_CHECKIN_RECORD_CSV_SUCCESS =
  'DOWNLOAD_CHECKIN_RECORD_CSV_SUCCESS';

export const SET_ACTIM_RESERVATION_VISIBLE_COLUMNS =
  'SET_ACTIM_RESERVATION_VISIBLE_COLUMNS';
export const SET_ACTIM_RESERVATION_ROW_COUNT =
  'SET_ACTIM_RESERVATION_ROW_COUNT';
export const SET_ACTIM_RESERVATION_CURRENT_PAGE =
  'SET_ACTIM_RESERVATION_CURRENT_PAGE';
export const FETCH_ACTIM_RESERVATION_BY_ID_FAILURE =
  'FETCH_ACTIM_RESERVATION_BY_ID_FAILURE';
export const FETCH_ACTIM_RESERVATION_BY_ID_REQUEST =
  'FETCH_ACTIM_RESERVATION_BY_ID_REQUEST';
export const FETCH_ACTIM_RESERVATION_BY_ID_SUCCESS =
  'FETCH_ACTIM_RESERVATION_BY_ID_SUCCESS';
export const FETCH_ACTIM_RESERVATIONS_CANCELED =
  'FETCH_ACTIM_RESERVATIONS_CANCELED';
export const FETCH_ACTIM_RESERVATIONS_FAILURE =
  'FETCH_ACTIM_RESERVATIONS_FAILURE';
export const FETCH_ACTIM_RESERVATIONS_REQUEST =
  'FETCH_ACTIM_RESERVATIONS_REQUEST';
export const FETCH_ACTIM_RESERVATIONS_SUCCESS =
  'FETCH_ACTIM_RESERVATIONS_SUCCESS';
export const UPDATE_ACTIM_RESERVATION_FAILURE =
  'UPDATE_ACTIM_RESERVATION_FAILURE';
export const UPDATE_ACTIM_RESERVATION_REQUEST =
  'UPDATE_ACTIM_RESERVATION_REQUEST';
export const UPDATE_ACTIM_RESERVATION_SUCCESS =
  'UPDATE_ACTIM_RESERVATION_SUCCESS';
export const DELETE_ACTIM_RESERVATION_FAILURE =
  'DELETE_ACTIM_RESERVATION_FAILURE';
export const DELETE_ACTIM_RESERVATION_REQUEST =
  'DELETE_ACTIM_RESERVATION_REQUEST';
export const DELETE_ACTIM_RESERVATION_SUCCESS =
  'DELETE_ACTIM_RESERVATION_SUCCESS';

export const CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_FAILURE =
  'CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_FAILURE';
export const CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_REQUEST =
  'CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_REQUEST';
export const CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_SUCCESS =
  'CREATE_RESERVATION_DATA_CSV_EXPORT_ORDER_SUCCESS';
export const FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_FAILURE =
  'FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_FAILURE';
export const FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_REQUEST =
  'FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_REQUEST';
export const FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_SUCCESS =
  'FETCH_RESERVATION_DATA_CSV_EXPORT_ORDERS_SUCCESS';

export const FETCH_RESOURCE_ASSIGNMENTS_SUCCESS =
  'FETCH_RESOURCE_ASSIGNMENTS_SUCCESS';
export const FETCH_RESOURCE_ASSIGNMENTS_FAILURE =
  'FETCH_RESOURCE_ASSIGNMENTS_FAILURE';
export const FETCH_RESOURCE_ASSIGNMENTS_REQUEST =
  'FETCH_RESOURCE_ASSIGNMENTS_REQUEST';

export const SET_RESOURCE_AVAILABILITY_START_DATE =
  'SET_RESOURCE_AVAILABILITY_START_DATE';
export const SET_RESOURCE_AVAILABILITY_RESOURCE_KEY =
  'SET_RESOURCE_AVAILABILITY_RESOURCE_KEY';
export const SET_RESOURCE_AVAILABILITY_RESOURCE_TYPE =
  'SET_RESOURCE_AVAILABILITY_RESOURCE_TYPE';
export const SET_RESOURCE_AVAILABILITY_VEHICLE_DEPARTURE_TIME_KEY =
  'SET_RESOURCE_AVAILABILITY_VEHICLE_DEPARTURE_TIME_KEY';

export const SET_RESERVATION_DATA_CSV_EXPORT_ORDER_VISIBLE_COLUMNS =
  'SET_RESERVATION_DATA_CSV_EXPORT_ORDER_VISIBLE_COLUMNS';

export const FETCH_RESOURCE_QUANTITIES_SUCCESS =
  'FETCH_RESOURCE_QUANTITIES_SUCCESS';
export const FETCH_RESOURCE_QUANTITIES_FAILURE =
  'FETCH_RESOURCE_QUANTITIES_FAILURE';
export const FETCH_RESOURCE_QUANTITIES_REQUEST =
  'FETCH_RESOURCE_QUANTITIES_REQUEST';
export const CREATE_RESOURCE_QUANTITY_FAILURE =
  'CREATE_RESOURCE_QUANTITY_FAILURE';
export const CREATE_RESOURCE_QUANTITY_REQUEST =
  'CREATE_RESOURCE_QUANTITY_REQUEST';
export const CREATE_RESOURCE_QUANTITY_SUCCESS =
  'CREATE_RESOURCE_QUANTITY_SUCCESS';
export const DELETE_RESOURCE_QUANTITY_FAILURE =
  'DELETE_RESOURCE_QUANTITY_FAILURE';
export const DELETE_RESOURCE_QUANTITY_REQUEST =
  'DELETE_RESOURCE_QUANTITY_REQUEST';
export const DELETE_RESOURCE_QUANTITY_SUCCESS =
  'DELETE_RESOURCE_QUANTITY_SUCCESS';
export const UPDATE_RESOURCE_QUANTITY_FAILURE =
  'UPDATE_RESOURCE_QUANTITY_FAILURE';
export const UPDATE_RESOURCE_QUANTITY_REQUEST =
  'UPDATE_RESOURCE_QUANTITY_REQUEST';
export const UPDATE_RESOURCE_QUANTITY_SUCCESS =
  'UPDATE_RESOURCE_QUANTITY_SUCCESS';

export const FETCH_REDEEMED_RECORDS_REQUEST = 'FETCH_REDEEMED_RECORDS_REQUEST';
export const FETCH_REDEEMED_RECORDS_SUCCESS = 'FETCH_REDEEMED_RECORDS_SUCCESS';
export const FETCH_REDEEMED_RECORDS_FAILURE = 'FETCH_REDEEMED_RECORDS_FAILURE';
export const SET_LAST_EXECUTED_REDEEMED_RECORD_SEARCH_CONDITION =
  'SET_LAST_EXECUTED_REDEEMED_RECORD_SEARCH_CONDITION';
export const SET_REDEEMED_RECORD_CURRENT_PAGE =
  'SET_REDEEMED_RECORD_CURRENT_PAGE';
export const SET_REDEEMED_RECORD_ROW_COUNT = 'SET_REDEEMED_RECORD_ROW_COUNT';

export const UNDO_REDEEM_RESERVATION_COUPON_REQUEST =
  'UNDO_REDEEM_RESERVATION_COUPON_REQUEST';
export const UNDO_REDEEM_RESERVATION_COUPON_SUCCESS =
  'UNDO_REDEEM_RESERVATION_COUPON_SUCCESS';
export const UNDO_REDEEM_RESERVATION_COUPON_FAILURE =
  'UNDO_REDEEM_RESERVATION_COUPON_FAILURE';

export const DOWNLOAD_REDEEMED_RECORD_CSV_FAILURE =
  'DOWNLOAD_REDEEMED_RECORD_CSV_FAILURE';
export const DOWNLOAD_REDEEMED_RECORD_CSV_REQUEST =
  'DOWNLOAD_REDEEMED_RECORD_CSV_REQUEST';
export const DOWNLOAD_REDEEMED_RECORD_CSV_SUCCESS =
  'DOWNLOAD_REDEEMED_RECORD_CSV_SUCCESS';

export const FETCH_E_TICKET_LOCATIONS_SUCCESS =
  'FETCH_E_TICKET_LOCATIONS_SUCCESS';
export const FETCH_E_TICKET_LOCATIONS_FAILURE =
  'FETCH_E_TICKET_LOCATIONS_FAILURE';
export const FETCH_E_TICKET_LOCATIONS_REQUEST =
  'FETCH_E_TICKET_LOCATIONS_REQUEST';
export const DELETE_E_TICKET_LOCATION_FAILURE =
  'DELETE_E_TICKET_LOCATION_FAILURE';
export const DELETE_E_TICKET_LOCATION_REQUEST =
  'DELETE_E_TICKET_LOCATION_REQUEST';
export const DELETE_E_TICKET_LOCATION_SUCCESS =
  'DELETE_E_TICKET_LOCATION_SUCCESS';
export const UPDATE_E_TICKET_LOCATION_FAILURE =
  'UPDATE_E_TICKET_LOCATION_FAILURE';
export const UPDATE_E_TICKET_LOCATION_SUCCESS =
  'UPDATE_E_TICKET_LOCATION_SUCCESS';
export const UPDATE_E_TICKET_LOCATION_REQUEST =
  'UPDATE_E_TICKET_LOCATION_REQUEST';
export const CREATE_E_TICKET_LOCATION_REQUEST =
  'CREATE_E_TICKET_LOCATION_REQUEST';
export const CREATE_E_TICKET_LOCATION_FAILURE =
  'CREATE_E_TICKET_LOCATION_FAILURE';
export const CREATE_E_TICKET_LOCATION_SUCCESS =
  'CREATE_E_TICKET_LOCATION_SUCCESS';

export const FETCH_E_TICKET_LOCATION_SETS_SUCCESS =
  'FETCH_E_TICKET_LOCATION_SETS_SUCCESS';
export const FETCH_E_TICKET_LOCATION_SETS_FAILURE =
  'FETCH_E_TICKET_LOCATION_SETS_FAILURE';
export const FETCH_E_TICKET_LOCATION_SETS_REQUEST =
  'FETCH_E_TICKET_LOCATION_SETS_REQUEST';
export const DELETE_E_TICKET_LOCATION_SET_FAILURE =
  'DELETE_E_TICKET_LOCATION_SET_FAILURE';
export const DELETE_E_TICKET_LOCATION_SET_REQUEST =
  'DELETE_E_TICKET_LOCATION_SET_REQUEST';
export const DELETE_E_TICKET_LOCATION_SET_SUCCESS =
  'DELETE_E_TICKET_LOCATION_SET_SUCCESS';
export const UPDATE_E_TICKET_LOCATION_SET_FAILURE =
  'UPDATE_E_TICKET_LOCATION_SET_FAILURE';
export const UPDATE_E_TICKET_LOCATION_SET_SUCCESS =
  'UPDATE_E_TICKET_LOCATION_SET_SUCCESS';
export const UPDATE_E_TICKET_LOCATION_SET_REQUEST =
  'UPDATE_E_TICKET_LOCATION_SET_REQUEST';
export const CREATE_E_TICKET_LOCATION_SET_REQUEST =
  'CREATE_E_TICKET_LOCATION_SET_REQUEST';
export const CREATE_E_TICKET_LOCATION_SET_FAILURE =
  'CREATE_E_TICKET_LOCATION_SET_FAILURE';
export const CREATE_E_TICKET_LOCATION_SET_SUCCESS =
  'CREATE_E_TICKET_LOCATION_SET_SUCCESS';

export const FETCH_E_TICKET_FOOTPRINTS_REQUEST =
  'FETCH_E_TICKET_FOOTPRINTS_REQUEST';
export const FETCH_E_TICKET_FOOTPRINTS_SUCCESS =
  'FETCH_E_TICKET_FOOTPRINTS_SUCCESS';
export const FETCH_E_TICKET_FOOTPRINTS_FAILURE =
  'FETCH_E_TICKET_FOOTPRINTS_FAILURE';
export const DOWNLOAD_E_TICKET_FOOTPRINT_CSV_FAILURE =
  'DOWNLOAD_E_TICKET_FOOTPRINT_CSV_FAILURE';
export const DOWNLOAD_E_TICKET_FOOTPRINT_CSV_REQUEST =
  'DOWNLOAD_E_TICKET_FOOTPRINT_CSV_REQUEST';
export const DOWNLOAD_E_TICKET_FOOTPRINT_CSV_SUCCESS =
  'DOWNLOAD_E_TICKET_FOOTPRINT_CSV_SUCCESS';
export const SET_LAST_EXECUTED_E_TICKET_FOOTPRINT_SEARCH_CONDITION =
  'SET_LAST_EXECUTED_E_TICKET_FOOTPRINT_SEARCH_CONDITION';
export const SET_E_TICKET_FOOTPRINT_CURRENT_PAGE =
  'SET_E_TICKET_FOOTPRINT_CURRENT_PAGE';
export const SET_E_TICKET_FOOTPRINT_ROW_COUNT =
  'SET_E_TICKET_FOOTPRINT_ROW_COUNT';

export const FETCH_GUIDANCE_STAMP_RALLIES_SUCCESS =
  'FETCH_GUIDANCE_STAMP_RALLIES_SUCCESS';
export const FETCH_GUIDANCE_STAMP_RALLIES_FAILURE =
  'FETCH_GUIDANCE_STAMP_RALLIES_FAILURE';
export const FETCH_GUIDANCE_STAMP_RALLIES_REQUEST =
  'FETCH_GUIDANCE_STAMP_RALLIES_REQUEST';
export const FETCH_GUIDANCE_STAMP_RALLY_SUCCESS =
  'FETCH_GUIDANCE_STAMP_RALLY_SUCCESS';
export const FETCH_GUIDANCE_STAMP_RALLY_FAILURE =
  'FETCH_GUIDANCE_STAMP_RALLY_FAILURE';
export const FETCH_GUIDANCE_STAMP_RALLY_REQUEST =
  'FETCH_GUIDANCE_STAMP_RALLY_REQUEST';
export const DELETE_GUIDANCE_STAMP_RALLY_FAILURE =
  'DELETE_GUIDANCE_STAMP_RALLY_FAILURE';
export const DELETE_GUIDANCE_STAMP_RALLY_REQUEST =
  'DELETE_GUIDANCE_STAMP_RALLY_REQUEST';
export const DELETE_GUIDANCE_STAMP_RALLY_SUCCESS =
  'DELETE_GUIDANCE_STAMP_RALLY_SUCCESS';
export const CREATE_GUIDANCE_STAMP_RALLY_REQUEST =
  'CREATE_GUIDANCE_STAMP_RALLY_REQUEST';
export const UPDATE_GUIDANCE_STAMP_RALLY_FAILURE =
  'UPDATE_GUIDANCE_STAMP_RALLY_FAILURE';
export const UPDATE_GUIDANCE_STAMP_RALLY_SUCCESS =
  'UPDATE_GUIDANCE_STAMP_RALLY_SUCCESS';
export const UPDATE_GUIDANCE_STAMP_RALLY_REQUEST =
  'UPDATE_GUIDANCE_STAMP_RALLY_REQUEST';
export const CREATE_GUIDANCE_STAMP_RALLY_FAILURE =
  'CREATE_GUIDANCE_STAMP_RALLY_FAILURE';
export const CREATE_GUIDANCE_STAMP_RALLY_SUCCESS =
  'CREATE_GUIDANCE_STAMP_RALLY_SUCCESS';

export const UNDO_REDEEM_RESERVATION_STAMP_RALLY_REQUEST =
  'UNDO_REDEEM_RESERVATION_STAMP_RALLY_REQUEST';
export const UNDO_REDEEM_RESERVATION_STAMP_RALLY_SUCCESS =
  'UNDO_REDEEM_RESERVATION_STAMP_RALLY_SUCCESS';
export const UNDO_REDEEM_RESERVATION_STAMP_RALLY_FAILURE =
  'UNDO_REDEEM_RESERVATION_STAMP_RALLY_FAILURE';

export const UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_REQUEST =
  'UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_REQUEST';
export const UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_SUCCESS =
  'UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_SUCCESS';
export const UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_FAILURE =
  'UNDO_REDEEM_RESERVATION_STAMP_RALLY_GIFT_FAILURE';

export const SEND_ONETIME_CODE_REQUEST = 'SEND_ONETIME_CODE_REQUEST';
export const SEND_ONETIME_CODE_SUCCESS = 'SEND_ONETIME_CODE_SUCCESS';
export const SEND_ONETIME_CODE_FAILURE = 'SEND_ONETIME_CODE_FAILURE';

export const SET_USE_NEW_UI = 'SET_USE_NEW_UI';

export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';
export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMER_TAGS_SUCCESS = 'FETCH_CUSTOMER_TAGS_SUCCESS';
export const FETCH_CUSTOMER_TAGS_FAILURE = 'FETCH_CUSTOMER_TAGS_FAILURE';
export const FETCH_CUSTOMER_TAGS_REQUEST = 'FETCH_CUSTOMER_TAGS_REQUEST';
export const FETCH_CUSTOMER_BY_ID_SUCCESS = 'FETCH_CUSTOMER_BY_ID_SUCCESS';
export const FETCH_CUSTOMER_BY_ID_FAILURE = 'FETCH_CUSTOMER_BY_ID_FAILURE';
export const FETCH_CUSTOMER_BY_ID_REQUEST = 'FETCH_CUSTOMER_BY_ID_REQUEST';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';
export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_REPORT_DATA_REQUEST =
  'FETCH_CUSTOMER_REPORT_DATA_REQUEST';
export const FETCH_CUSTOMER_REPORT_DATA_SUCCESS =
  'FETCH_CUSTOMER_REPORT_DATA_SUCCESS';
export const FETCH_CUSTOMER_REPORT_DATA_FAILURE =
  'FETCH_CUSTOMER_REPORT_DATA_FAILURE';

export const SET_CUSTOMER_LIST_ROW_COUNT = 'SET_CUSTOMER_LIST_ROW_COUNT';
export const SET_CUSTOMER_LIST_CURRENT_PAGE = 'SET_CUSTOMER_LIST_CURRENT_PAGE';
export const UPDATE_CUSTOMER_LIST_PAGE_TOKEN =
  'UPDATE_CUSTOMER_LIST_PAGE_TOKEN';
export const RESET_CUSTOMER_LIST_PAGE_TOKEN = 'RESET_CUSTOMER_LIST_PAGE_INFO';
export const UPDATE_CUSTOMER_SEARCH_SETTINGS =
  'UPDATE_CUSTOMER_SEARCH_SETTINGS';
export const SET_CUSTOMER_VISIBLE_COLUMNS = 'SET_CUSTOMER_VISIBLE_COLUMNS';

export const FETCH_E_TICKET_QR_CODES_SUCCESS =
  'FETCH_E_TICKET_QR_CODES_SUCCESS';
export const FETCH_E_TICKET_QR_CODES_FAILURE =
  'FETCH_E_TICKET_QR_CODES_FAILURE';
export const FETCH_E_TICKET_QR_CODES_REQUEST =
  'FETCH_E_TICKET_QR_CODES_REQUEST';
export const DELETE_E_TICKET_QR_CODE_FAILURE =
  'DELETE_E_TICKET_QR_CODE_FAILURE';
export const DELETE_E_TICKET_QR_CODE_REQUEST =
  'DELETE_E_TICKET_QR_CODE_REQUEST';
export const DELETE_E_TICKET_QR_CODE_SUCCESS =
  'DELETE_E_TICKET_QR_CODE_SUCCESS';
export const UPDATE_E_TICKET_QR_CODE_FAILURE =
  'UPDATE_E_TICKET_QR_CODE_FAILURE';
export const UPDATE_E_TICKET_QR_CODE_SUCCESS =
  'UPDATE_E_TICKET_QR_CODE_SUCCESS';
export const UPDATE_E_TICKET_QR_CODE_REQUEST =
  'UPDATE_E_TICKET_QR_CODE_REQUEST';
export const CREATE_E_TICKET_QR_CODE_REQUEST =
  'CREATE_E_TICKET_QR_CODE_REQUEST';
export const CREATE_E_TICKET_QR_CODE_FAILURE =
  'CREATE_E_TICKET_QR_CODE_FAILURE';
export const CREATE_E_TICKET_QR_CODE_SUCCESS =
  'CREATE_E_TICKET_QR_CODE_SUCCESS';

export const FETCH_NEWSLETTER_SEGMENTS_SUCCESS =
  'FETCH_NEWSLETTER_SEGMENTS_SUCCESS';
export const FETCH_NEWSLETTER_SEGMENTS_FAILURE =
  'FETCH_NEWSLETTER_SEGMENTS_FAILURE';
export const FETCH_NEWSLETTER_SEGMENTS_REQUEST =
  'FETCH_NEWSLETTER_SEGMENTS_REQUEST';
export const DELETE_NEWSLETTER_SEGMENT_FAILURE =
  'DELETE_NEWSLETTER_SEGMENT_FAILURE';
export const DELETE_NEWSLETTER_SEGMENT_REQUEST =
  'DELETE_NEWSLETTER_SEGMENT_REQUEST';
export const DELETE_NEWSLETTER_SEGMENT_SUCCESS =
  'DELETE_NEWSLETTER_SEGMENT_SUCCESS';
export const UPDATE_NEWSLETTER_SEGMENT_FAILURE =
  'UPDATE_NEWSLETTER_SEGMENT_FAILURE';
export const UPDATE_NEWSLETTER_SEGMENT_SUCCESS =
  'UPDATE_NEWSLETTER_SEGMENT_SUCCESS';
export const UPDATE_NEWSLETTER_SEGMENT_REQUEST =
  'UPDATE_NEWSLETTER_SEGMENT_REQUEST';
export const CREATE_NEWSLETTER_SEGMENT_REQUEST =
  'CREATE_NEWSLETTER_SEGMENT_REQUEST';
export const CREATE_NEWSLETTER_SEGMENT_FAILURE =
  'CREATE_NEWSLETTER_SEGMENT_FAILURE';
export const CREATE_NEWSLETTER_SEGMENT_SUCCESS =
  'CREATE_NEWSLETTER_SEGMENT_SUCCESS';

export const FETCH_NEWSLETTERS_SUCCESS = 'FETCH_NEWSLETTERS_SUCCESS';
export const FETCH_NEWSLETTERS_FAILURE = 'FETCH_NEWSLETTERS_FAILURE';
export const FETCH_NEWSLETTERS_REQUEST = 'FETCH_NEWSLETTERS_REQUEST';
export const FETCH_NEWSLETTER_SUCCESS = 'FETCH_NEWSLETTER_SUCCESS';
export const FETCH_NEWSLETTER_FAILURE = 'FETCH_NEWSLETTER_FAILURE';
export const FETCH_NEWSLETTER_REQUEST = 'FETCH_NEWSLETTER_REQUEST';
export const DELETE_NEWSLETTER_FAILURE = 'DELETE_NEWSLETTER_FAILURE';
export const DELETE_NEWSLETTER_REQUEST = 'DELETE_NEWSLETTER_REQUEST';
export const DELETE_NEWSLETTER_SUCCESS = 'DELETE_NEWSLETTER_SUCCESS';
export const UPDATE_NEWSLETTER_FAILURE = 'UPDATE_NEWSLETTER_FAILURE';
export const UPDATE_NEWSLETTER_SUCCESS = 'UPDATE_NEWSLETTER_SUCCESS';
export const UPDATE_NEWSLETTER_REQUEST = 'UPDATE_NEWSLETTER_REQUEST';
export const CREATE_NEWSLETTER_FAILURE = 'CREATE_NEWSLETTER_FAILURE';
export const CREATE_NEWSLETTER_REQUEST = 'CREATE_NEWSLETTER_REQUEST';
export const CREATE_NEWSLETTER_SUCCESS = 'CREATE_NEWSLETTER_SUCCESS';
export const FETCH_NEWSLETTERS_CSV_REQUEST = 'FETCH_NEWSLETTERS_CSV_REQUEST';
export const FETCH_NEWSLETTERS_CSV_SUCCESS = 'FETCH_NEWSLETTERS_CSV_SUCCESS';
export const FETCH_NEWSLETTERS_CSV_FAILURE = 'FETCH_NEWSLETTERS_CSV_FAILURE';

export const FETCH_E_TICKET_DAILY_USES_SUCCESS =
  'FETCH_E_TICKET_DAILY_USES_SUCCESS';
export const FETCH_E_TICKET_DAILY_USES_FAILURE =
  'FETCH_E_TICKET_DAILY_USES_FAILURE';
export const FETCH_E_TICKET_DAILY_USES_REQUEST =
  'FETCH_E_TICKET_DAILY_USES_REQUEST';
export const FETCH_E_TICKET_DAILY_USES_CANCELED =
  'FETCH_E_TICKET_DAILY_USES_CANCELED';
export const DOWNLOAD_E_TICKET_DAILY_USES_CSV_SUCCESS =
  'DOWNLOAD_E_TICKET_DAILY_USES_CSV_SUCCESS';
export const DOWNLOAD_E_TICKET_DAILY_USES_CSV_FAILURE =
  'DOWNLOAD_E_TICKET_DAILY_USES_CSV_FAILURE';
export const DOWNLOAD_E_TICKET_DAILY_USES_CSV_REQUEST =
  'DOWNLOAD_E_TICKET_DAILY_USES_CSV_REQUEST';
export const SET_E_TICKET_DAILY_USES_DISPLAY_SETTINGS =
  'SET_E_TICKET_DAILY_USES_DISPLAY_SETTINGS';
export const SET_E_TICKET_DAILY_USES_VISIBLE_COLUMNS =
  'SET_E_TICKET_DAILY_USES_VISIBLE_COLUMNS';

export const SET_NEWSLETTERS_SEARCH_FILTERS = 'SET_NEWSLETTERS_SEARCH_FILTERS';

export const COPY_SITE_TEMPLATE_SUCCESS = 'COPY_SITE_TEMPLATE_SUCCESS';
export const COPY_SITE_TEMPLATE_FAILURE = 'COPY_SITE_TEMPLATE_FAILURE';
export const COPY_SITE_TEMPLATE_REQUEST = 'COPY_SITE_TEMPLATE_REQUEST';
export const FETCH_TEMPLATE_SUPPLIERS_SETTINGS_SUCCESS =
  'FETCH_TEMPLATE_SUPPLIERS_SETTINGS_SUCCESS';
export const FETCH_TEMPLATE_SUPPLIERS_SETTINGS_FAILURE =
  'FETCH_TEMPLATE_SUPPLIERS_SETTINGS_FAILURE';
export const FETCH_TEMPLATE_SUPPLIERS_SETTINGS_REQUEST =
  'FETCH_TEMPLATE_SUPPLIERS_SETTINGS_REQUEST';
export const FETCH_TEMPLATE_SUPPLIERS_SETTINGS_CANCELED =
  'FETCH_TEMPLATE_SUPPLIERS_SETTINGS_CANCELED';

export const FETCH_EQUIPMENTS_SUCCESS = 'FETCH_EQUIPMENTS_SUCCESS';
export const FETCH_EQUIPMENTS_FAILURE = 'FETCH_EQUIPMENTS_FAILURE';
export const FETCH_EQUIPMENTS_REQUEST = 'FETCH_EQUIPMENTS_REQUEST';
export const CLEAR_EQUIPMENTS_SUCCESS = 'CLEAR_EQUIPMENTS_SUCCESS';
export const CLEAR_EQUIPMENTS_FAILURE = 'CLEAR_EQUIPMENTS_FAILURE';
export const CLEAR_EQUIPMENTS_REQUEST = 'CLEAR_EQUIPMENTS_REQUEST';
export const CREATE_EQUIPMENT_FAILURE = 'CREATE_EQUIPMENT_FAILURE';
export const CREATE_EQUIPMENT_REQUEST = 'CREATE_EQUIPMENT_REQUEST';
export const CREATE_EQUIPMENT_SUCCESS = 'CREATE_EQUIPMENT_SUCCESS';
export const DELETE_EQUIPMENT_FAILURE = 'DELETE_EQUIPMENT_FAILURE';
export const DELETE_EQUIPMENT_REQUEST = 'DELETE_EQUIPMENT_REQUEST';
export const DELETE_EQUIPMENT_SUCCESS = 'DELETE_EQUIPMENT_SUCCESS';
export const UPDATE_EQUIPMENT_FAILURE = 'UPDATE_EQUIPMENT_FAILURE';
export const UPDATE_EQUIPMENT_REQUEST = 'UPDATE_EQUIPMENT_REQUEST';
export const UPDATE_EQUIPMENT_SUCCESS = 'UPDATE_EQUIPMENT_SUCCESS';
export const CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_SUCCESS =
  'CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_SUCCESS';
export const CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_FAILURE =
  'CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_FAILURE';
export const CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST =
  'CALCULATE_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST';
export const CLEAR_EQUIPMENT_CELL_BLOCK_MAPPINGS_SUCCESS =
  'CLEAR_EQUIPMENT_CELL_BLOCK_MAPPINGS_SUCCESS';
export const CLEAR_EQUIPMENT_CELL_BLOCK_MAPPINGS_FAILURE =
  'CLEAR_EQUIPMENT_CELL_BLOCK_MAPPINGS_FAILURE';
export const CLEAR_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST =
  'CLEAR_EQUIPMENT_CELL_BLOCK_MAPPINGS_REQUEST';

export const FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS =
  'FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS';
export const FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE =
  'FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE';
export const FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST =
  'FETCH_EQUIPMENT_ASSIGNMENT_OCCUPATION_REQUEST';
export const CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_SUCCESS =
  'CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_SUCCESS';
export const CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_FAILURE =
  'CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_FAILURE';
export const CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_REQUEST =
  'CREATE_OR_UPDATE_EQUIPMENT_ASSIGNMENT_OCCUPATION_REQUEST';
export const DELETE_EQUIPMENT_ASSIGNMENT_SUCCESS =
  'DELETE_EQUIPMENT_ASSIGNMENT_SUCCESS';
export const DELETE_EQUIPMENT_ASSIGNMENT_FAILURE =
  'DELETE_EQUIPMENT_ASSIGNMENT_FAILURE';
export const DELETE_EQUIPMENT_ASSIGNMENT_REQUEST =
  'DELETE_EQUIPMENT_ASSIGNMENT_REQUEST';
export const BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_SUCCESS =
  'BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_SUCCESS';
export const BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_FAILURE =
  'BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_FAILURE';
export const BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_REQUEST =
  'BULK_OPERATE_EQUIPMENT_ASSIGNMENTS_REQUEST';
export const BULK_MOVE_EQUIPMENT_ASSIGNMENTS_SUCCESS =
  'BULK_MOVE_EQUIPMENT_ASSIGNMENTS_SUCCESS';
export const BULK_MOVE_EQUIPMENT_ASSIGNMENTS_FAILURE =
  'BULK_MOVE_EQUIPMENT_ASSIGNMENTS_FAILURE';
export const BULK_MOVE_EQUIPMENT_ASSIGNMENTS_REQUEST =
  'BULK_MOVE_EQUIPMENT_ASSIGNMENTS_REQUEST';
export const BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_SUCCESS =
  'BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_SUCCESS';
export const BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_FAILURE =
  'BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_FAILURE';
export const BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_REQUEST =
  'BULK_ASSIGN_EQUIPMENT_ASSIGNMENTS_REQUEST';

export const BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS =
  'BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_SUCCESS';
export const BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE =
  'BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_FAILURE';
export const BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST =
  'BATCH_FETCH_EQUIPMENT_ASSIGNMENTS_REQUEST';

export const DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_REQUEST =
  'DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_REQUEST';
export const DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_SUCCESS =
  'DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_SUCCESS';
export const DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_FAILURE =
  'DELETE_EQUIPMENT_ASSIGNMENT_IGNORING_FAILURE_FAILURE';

export const FETCH_EQUIPMENT_INSTANCES_SUCCESS =
  'FETCH_EQUIPMENT_INSTANCES_SUCCESS';
export const FETCH_EQUIPMENT_INSTANCES_FAILURE =
  'FETCH_EQUIPMENT_INSTANCES_FAILURE';
export const FETCH_EQUIPMENT_INSTANCES_REQUEST =
  'FETCH_EQUIPMENT_INSTANCES_REQUEST';

export const UPDATE_EQUIPMENT_INSTANCE_SUCCESS =
  'UPDATE_EQUIPMENT_INSTANCE_SUCCESS';
export const UPDATE_EQUIPMENT_INSTANCE_FAILURE =
  'UPDATE_EQUIPMENT_INSTANCE_FAILURE';
export const UPDATE_EQUIPMENT_INSTANCE_REQUEST =
  'UPDATE_EQUIPMENT_INSTANCE_REQUEST';
export const CLEAR_EQUIPMENT_INSTANCES_SUCCESS =
  'CLEAR_EQUIPMENT_INSTANCES_SUCCESS';
export const CLEAR_EQUIPMENT_INSTANCES_FAILURE =
  'CLEAR_EQUIPMENT_INSTANCES_FAILURE';
export const CLEAR_EQUIPMENT_INSTANCES_REQUEST =
  'CLEAR_EQUIPMENT_INSTANCES_REQUEST';

export const BATCH_CLOSE_EQUIPMENT_INSTANCES_FAILURE =
  'BATCH_CLOSE_EQUIPMENT_INSTANCES_FAILURE';
export const BATCH_CLOSE_EQUIPMENT_INSTANCES_REQUEST =
  'BATCH_CLOSE_EQUIPMENT_INSTANCES_REQUEST';
export const BATCH_CLOSE_EQUIPMENT_INSTANCES_SUCCESS =
  'BATCH_CLOSE_EQUIPMENT_INSTANCES_SUCCESS';
export const BATCH_EDIT_EQUIPMENT_INSTANCES_FAILURE =
  'BATCH_EDIT_EQUIPMENT_INSTANCES_FAILURE';
export const BATCH_EDIT_EQUIPMENT_INSTANCES_REQUEST =
  'BATCH_EDIT_EQUIPMENT_INSTANCES_REQUEST';
export const BATCH_EDIT_EQUIPMENT_INSTANCES_SUCCESS =
  'BATCH_CLOSE_EQUIPMENT_INSTANCES_SUCCESS';

export const SET_SEAT_ASSIGNMENT_DATE = 'SET_SEAT_ASSIGNMENT_DATE';
export const SET_SEAT_ASSIGNMENT_PRODUCT_ID = 'SET_SEAT_ASSIGNMENT_PRODUCT_ID';
export const SET_SEAT_ASSIGNMENT_PRODUCT_INSTANCE_ID =
  'SET_SEAT_ASSIGNMENT_PRODUCT_INSTANCE_ID';
export const SET_SEAT_ASSIGNMENT_EQUIPMENT_ID =
  'SET_SEAT_ASSIGNMENT_EQUIPMENT_ID';
export const SET_SEAT_ASSIGNMENT_START_TIME_KEY =
  'SET_SEAT_ASSIGNMENT_START_TIME_KEY';

export const FETCH_EQUIPMENT_BLOCKS_SUCCESS = 'FETCH_EQUIPMENT_BLOCKS_SUCCESS';
export const FETCH_EQUIPMENT_BLOCKS_FAILURE = 'FETCH_EQUIPMENT_BLOCKS_FAILURE';
export const FETCH_EQUIPMENT_BLOCKS_REQUEST = 'FETCH_EQUIPMENT_BLOCKS_REQUEST';
export const CREATE_EQUIPMENT_BLOCK_FAILURE = 'CREATE_EQUIPMENT_BLOCK_FAILURE';
export const CREATE_EQUIPMENT_BLOCK_REQUEST = 'CREATE_EQUIPMENT_BLOCK_REQUEST';
export const CREATE_EQUIPMENT_BLOCK_SUCCESS = 'CREATE_EQUIPMENT_BLOCK_SUCCESS';
export const DELETE_EQUIPMENT_BLOCK_FAILURE = 'DELETE_EQUIPMENT_BLOCK_FAILURE';
export const DELETE_EQUIPMENT_BLOCK_REQUEST = 'DELETE_EQUIPMENT_BLOCK_REQUEST';
export const DELETE_EQUIPMENT_BLOCK_SUCCESS = 'DELETE_EQUIPMENT_BLOCK_SUCCESS';
export const UPDATE_EQUIPMENT_BLOCK_FAILURE = 'UPDATE_EQUIPMENT_BLOCK_FAILURE';
export const UPDATE_EQUIPMENT_BLOCK_REQUEST = 'UPDATE_EQUIPMENT_BLOCK_REQUEST';
export const UPDATE_EQUIPMENT_BLOCK_SUCCESS = 'UPDATE_EQUIPMENT_BLOCK_SUCCESS';

export const FETCH_EQUIPMENT_ASSETS_SUCCESS = 'FETCH_EQUIPMENT_ASSETS_SUCCESS';
export const FETCH_EQUIPMENT_ASSETS_FAILURE = 'FETCH_EQUIPMENT_ASSETS_FAILURE';
export const FETCH_EQUIPMENT_ASSETS_REQUEST = 'FETCH_EQUIPMENT_ASSETS_REQUEST';
export const CREATE_EQUIPMENT_ASSET_FAILURE = 'CREATE_EQUIPMENT_ASSET_FAILURE';
export const CREATE_EQUIPMENT_ASSET_REQUEST = 'CREATE_EQUIPMENT_ASSET_REQUEST';
export const CREATE_EQUIPMENT_ASSET_SUCCESS = 'CREATE_EQUIPMENT_ASSET_SUCCESS';
export const DELETE_EQUIPMENT_ASSET_FAILURE = 'DELETE_EQUIPMENT_ASSET_FAILURE';
export const DELETE_EQUIPMENT_ASSET_REQUEST = 'DELETE_EQUIPMENT_ASSET_REQUEST';
export const DELETE_EQUIPMENT_ASSET_SUCCESS = 'DELETE_EQUIPMENT_ASSET_SUCCESS';
export const UPDATE_EQUIPMENT_ASSET_FAILURE = 'UPDATE_EQUIPMENT_ASSET_FAILURE';
export const UPDATE_EQUIPMENT_ASSET_REQUEST = 'UPDATE_EQUIPMENT_ASSET_REQUEST';
export const UPDATE_EQUIPMENT_ASSET_SUCCESS = 'UPDATE_EQUIPMENT_ASSET_SUCCESS';
export const CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_SUCCESS =
  'CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_SUCCESS';
export const CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_FAILURE =
  'CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_FAILURE';
export const CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST =
  'CALCULATE_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST';
export const CLEAR_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST =
  'CLEAR_EQUIPMENT_ASSET_CELL_BLOCK_MAPPINGS_REQUEST';

export const SET_WEEKLY_EQUIPMENT_AVAILABILITY_START_DATE =
  'SET_WEEKLY_EQUIPMENT_AVAILABILITY_START_DATE';
export const SET_MONTHLY_EQUIPMENT_AVAILABILITY_SELECTED_MONTH =
  'SET_MONTHLY_EQUIPMENT_AVAILABILITY_SELECTED_MONTH';
export const SET_EQUIPMENT_AVAILABILITY_START_DATE =
  'SET_EQUIPMENT_AVAILABILITY_START_DATE';
export const SET_EQUIPMENT_AVAILABILITY_END_DATE =
  'SET_EQUIPMENT_AVAILABILITY_END_DATE';
export const SET_EQUIPMENT_AVAILABILITY_CURRENT_PAGE =
  'SET_EQUIPMENT_AVAILABILITY_CURRENT_PAGE';
export const SET_EQUIPMENT_AVAILABILITY_PAGE_SIZE =
  'SET_EQUIPMENT_AVAILABILITY_PAGE_SIZE';

export const ADD_EQUIPMENT_NOTIFICATION = 'ADD_EQUIPMENT_NOTIFICATION';
export const REMOVE_EQUIPMENT_NOTIFICATION = 'REMOVE_EQUIPMENT_NOTIFICATION';

export const SET_SORT_EQUIPMENTS = 'SET_SORT_EQUIPMENTS';
export const SET_SORT_SUB_EQUIPMENTS = 'SET_SORT_SUB_EQUIPMENTS';
export const SET_CANDIDATE_EQUIPMENTS = 'SET_CANDIDATE_EQUIPMENTS';
export const SET_CANDIDATE_SUB_EQUIPMENTS = 'SET_CANDIDATE_SUB_EQUIPMENTS';

export const CREATE_BOILERPLATE_SAMPLE_FAILURE =
  'CREATE_BOILERPLATE_SAMPLE_FAILURE';
export const CREATE_BOILERPLATE_SAMPLE_REQUEST =
  'CREATE_BOILERPLATE_SAMPLE_REQUEST';
export const CREATE_BOILERPLATE_SAMPLE_SUCCESS =
  'CREATE_BOILERPLATE_SAMPLE_SUCCESS';
export const DELETE_BOILERPLATE_SAMPLE_FAILURE =
  'DELETE_BOILERPLATE_SAMPLE_FAILURE';
export const DELETE_BOILERPLATE_SAMPLE_REQUEST =
  'DELETE_BOILERPLATE_SAMPLE_REQUEST';
export const DELETE_BOILERPLATE_SAMPLE_SUCCESS =
  'DELETE_BOILERPLATE_SAMPLE_SUCCESS';
export const FETCH_BOILERPLATE_SAMPLES_FAILURE =
  'FETCH_BOILERPLATE_SAMPLES_FAILURE';
export const FETCH_BOILERPLATE_SAMPLES_REQUEST =
  'FETCH_BOILERPLATE_SAMPLES_REQUEST';
export const FETCH_BOILERPLATE_SAMPLES_SUCCESS =
  'FETCH_BOILERPLATE_SAMPLES_SUCCESS';
export const FETCH_BOILERPLATE_SAMPLE_FAILURE =
  'FETCH_BOILERPLATE_SAMPLE_FAILURE';
export const FETCH_BOILERPLATE_SAMPLE_REQUEST =
  'FETCH_BOILERPLATE_SAMPLE_REQUEST';
export const FETCH_BOILERPLATE_SAMPLE_SUCCESS =
  'FETCH_BOILERPLATE_SAMPLE_SUCCESS';
export const UPDATE_BOILERPLATE_SAMPLE_FAILURE =
  'UPDATE_BOILERPLATE_SAMPLE_FAILURE';
export const UPDATE_BOILERPLATE_SAMPLE_REQUEST =
  'UPDATE_BOILERPLATE_SAMPLE_REQUEST';
export const UPDATE_BOILERPLATE_SAMPLE_SUCCESS =
  'UPDATE_BOILERPLATE_SAMPLE_SUCCESS';

export const CREATE_SALES_OFFER_FAILURE = 'CREATE_SALES_OFFER_FAILURE';
export const CREATE_SALES_OFFER_REQUEST = 'CREATE_SALES_OFFER_REQUEST';
export const CREATE_SALES_OFFER_SUCCESS = 'CREATE_SALES_OFFER_SUCCESS';
export const DELETE_SALES_OFFER_FAILURE = 'DELETE_SALES_OFFER_FAILURE';
export const DELETE_SALES_OFFER_REQUEST = 'DELETE_SALES_OFFER_REQUEST';
export const DELETE_SALES_OFFER_SUCCESS = 'DELETE_SALES_OFFER_SUCCESS';
export const FETCH_SALES_OFFERS_FAILURE = 'FETCH_SALES_OFFERS_FAILURE';
export const FETCH_SALES_OFFERS_REQUEST = 'FETCH_SALES_OFFERS_REQUEST';
export const FETCH_SALES_OFFERS_SUCCESS = 'FETCH_SALES_OFFERS_SUCCESS';
export const FETCH_SALES_OFFER_FAILURE = 'FETCH_SALES_OFFER_FAILURE';
export const FETCH_SALES_OFFER_REQUEST = 'FETCH_SALES_OFFER_REQUEST';
export const FETCH_SALES_OFFER_SUCCESS = 'FETCH_SALES_OFFER_SUCCESS';
export const FETCH_SALES_OFFER_STATS_FAILURE =
  'FETCH_SALES_OFFER_STATS_FAILURE';
export const FETCH_SALES_OFFER_STATS_REQUEST =
  'FETCH_SALES_OFFER_STATS_REQUEST';
export const FETCH_SALES_OFFER_STATS_SUCCESS =
  'FETCH_SALES_OFFER_STATS_SUCCESS';
export const UPDATE_SALES_OFFER_FAILURE = 'UPDATE_SALES_OFFER_FAILURE';
export const UPDATE_SALES_OFFER_REQUEST = 'UPDATE_SALES_OFFER_REQUEST';
export const UPDATE_SALES_OFFER_SUCCESS = 'UPDATE_SALES_OFFER_SUCCESS';

export const CREATE_INSTANT_WIN_FAILURE = 'CREATE_INSTANT_WIN_FAILURE';
export const CREATE_INSTANT_WIN_REQUEST = 'CREATE_INSTANT_WIN_REQUEST';
export const CREATE_INSTANT_WIN_SUCCESS = 'CREATE_INSTANT_WIN_SUCCESS';
export const DELETE_INSTANT_WIN_FAILURE = 'DELETE_INSTANT_WIN_FAILURE';
export const DELETE_INSTANT_WIN_REQUEST = 'DELETE_INSTANT_WIN_REQUEST';
export const DELETE_INSTANT_WIN_SUCCESS = 'DELETE_INSTANT_WIN_SUCCESS';
export const FETCH_INSTANT_WINS_FAILURE = 'FETCH_INSTANT_WINS_FAILURE';
export const FETCH_INSTANT_WINS_REQUEST = 'FETCH_INSTANT_WINS_REQUEST';
export const FETCH_INSTANT_WINS_SUCCESS = 'FETCH_INSTANT_WINS_SUCCESS';
export const FETCH_INSTANT_WIN_FAILURE = 'FETCH_INSTANT_WIN_FAILURE';
export const FETCH_INSTANT_WIN_REQUEST = 'FETCH_INSTANT_WIN_REQUEST';
export const FETCH_INSTANT_WIN_SUCCESS = 'FETCH_INSTANT_WIN_SUCCESS';
export const UPDATE_INSTANT_WIN_FAILURE = 'UPDATE_INSTANT_WIN_FAILURE';
export const UPDATE_INSTANT_WIN_REQUEST = 'UPDATE_INSTANT_WIN_REQUEST';
export const UPDATE_INSTANT_WIN_SUCCESS = 'UPDATE_INSTANT_WIN_SUCCESS';
export const GET_INSTANT_WIN_STATS_FAILURE = 'GET_INSTANT_WIN_STATS_FAILURE';
export const GET_INSTANT_WIN_STATS_REQUEST = 'GET_INSTANT_WIN_STATS_REQUEST';
export const GET_INSTANT_WIN_STATS_SUCCESS = 'GET_INSTANT_WIN_STATS_SUCCESS';
export const DOWNLOAD_INSTANT_WIN_CSV_FAILURE =
  'DOWNLOAD_INSTANT_WIN_CSV_FAILURE';
export const DOWNLOAD_INSTANT_WIN_CSV_REQUEST =
  'DOWNLOAD_INSTANT_WIN_CSV_REQUEST';
export const DOWNLOAD_INSTANT_WIN_CSV_SUCCESS =
  'DOWNLOAD_INSTANT_WIN_CSV_SUCCESS';

export const UPLOAD_JARAN_RESERVATION_CSV_FAILURE =
  'UPLOAD_JARAN_RESERVATION_CSV_FAILURE';
export const UPLOAD_JARAN_RESERVATION_CSV_REQUEST =
  'UPLOAD_JARAN_RESERVATION_CSV_REQUEST';
export const UPLOAD_JARAN_RESERVATION_CSV_SUCCESS =
  'UPLOAD_JARAN_RESERVATION_CSV_SUCCESS';
export const FETCH_JARAN_IMPORT_RESERVATION_ORDERS_CANCELED =
  'FETCH_JARAN_IMPORT_RESERVATION_ORDERS_CANCELED';
export const FETCH_JARAN_IMPORT_RESERVATION_ORDERS_FAILURE =
  'FETCH_JARAN_IMPORT_RESERVATION_ORDERS_FAILURE';
export const FETCH_JARAN_IMPORT_RESERVATION_ORDERS_REQUEST =
  'FETCH_JARAN_IMPORT_RESERVATION_ORDERS_REQUEST';
export const FETCH_JARAN_IMPORT_RESERVATION_ORDERS_SUCCESS =
  'FETCH_JARAN_IMPORT_RESERVATION_ORDERS_SUCCESS';

export const MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_FAILURE =
  'MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_FAILURE';
export const MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_REQUEST =
  'MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_REQUEST';
export const MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_SUCCESS =
  'MARK_RESERVATION_EQUIPMENT_TICKET_PRINTED_SUCCESS';

export const CREATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE =
  'CREATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE';
export const CREATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST =
  'CREATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST';
export const CREATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS =
  'CREATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS';
export const DELETE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE =
  'DELETE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE';
export const DELETE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST =
  'DELETE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST';
export const DELETE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS =
  'DELETE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS';
export const FETCH_REALTIME_NOTIFICATION_TEMPLATES_FAILURE =
  'FETCH_REALTIME_NOTIFICATION_TEMPLATES_FAILURE';
export const FETCH_REALTIME_NOTIFICATION_TEMPLATES_REQUEST =
  'FETCH_REALTIME_NOTIFICATION_TEMPLATES_REQUEST';
export const FETCH_REALTIME_NOTIFICATION_TEMPLATES_SUCCESS =
  'FETCH_REALTIME_NOTIFICATION_TEMPLATES_SUCCESS';
export const FETCH_REALTIME_NOTIFICATION_TEMPLATE_FAILURE =
  'FETCH_REALTIME_NOTIFICATION_TEMPLATE_FAILURE';
export const FETCH_REALTIME_NOTIFICATION_TEMPLATE_REQUEST =
  'FETCH_REALTIME_NOTIFICATION_TEMPLATE_REQUEST';
export const FETCH_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS =
  'FETCH_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS';
export const UPDATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE =
  'UPDATE_REALTIME_NOTIFICATION_TEMPLATE_FAILURE';
export const UPDATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST =
  'UPDATE_REALTIME_NOTIFICATION_TEMPLATE_REQUEST';
export const UPDATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS =
  'UPDATE_REALTIME_NOTIFICATION_TEMPLATE_SUCCESS';

export const CLEAR_CREATE_RESERVATION_ERROR = 'CLEAR_CREATE_RESERVATION_ERROR';

export const CLEAR_LAST_CREATED_RESERVATION_ID =
  'CLEAR_LAST_CREATED_RESERVATION_ID';

export const CREATE_GROUP_BOOKING_TEMPLATE_FAILURE =
  'CREATE_GROUP_BOOKING_TEMPLATE_FAILURE';
export const CREATE_GROUP_BOOKING_TEMPLATE_REQUEST =
  'CREATE_GROUP_BOOKING_TEMPLATE_REQUEST';
export const CREATE_GROUP_BOOKING_TEMPLATE_SUCCESS =
  'CREATE_GROUP_BOOKING_TEMPLATE_SUCCESS';
export const DELETE_GROUP_BOOKING_TEMPLATE_FAILURE =
  'DELETE_GROUP_BOOKING_TEMPLATE_FAILURE';
export const DELETE_GROUP_BOOKING_TEMPLATE_REQUEST =
  'DELETE_GROUP_BOOKING_TEMPLATE_REQUEST';
export const DELETE_GROUP_BOOKING_TEMPLATE_SUCCESS =
  'DELETE_GROUP_BOOKING_TEMPLATE_SUCCESS';
export const FETCH_GROUP_BOOKING_TEMPLATES_FAILURE =
  'FETCH_GROUP_BOOKING_TEMPLATES_FAILURE';
export const FETCH_GROUP_BOOKING_TEMPLATES_REQUEST =
  'FETCH_GROUP_BOOKING_TEMPLATES_REQUEST';
export const FETCH_GROUP_BOOKING_TEMPLATES_SUCCESS =
  'FETCH_GROUP_BOOKING_TEMPLATES_SUCCESS';
export const FETCH_GROUP_BOOKING_TEMPLATE_FAILURE =
  'FETCH_GROUP_BOOKING_TEMPLATE_FAILURE';
export const FETCH_GROUP_BOOKING_TEMPLATE_REQUEST =
  'FETCH_GROUP_BOOKING_TEMPLATE_REQUEST';
export const FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS =
  'FETCH_GROUP_BOOKING_TEMPLATE_SUCCESS';
export const UPDATE_GROUP_BOOKING_TEMPLATE_FAILURE =
  'UPDATE_GROUP_BOOKING_TEMPLATE_FAILURE';
export const UPDATE_GROUP_BOOKING_TEMPLATE_REQUEST =
  'UPDATE_GROUP_BOOKING_TEMPLATE_REQUEST';
export const UPDATE_GROUP_BOOKING_TEMPLATE_SUCCESS =
  'UPDATE_GROUP_BOOKING_TEMPLATE_SUCCESS';

export const SET_ANNUAL_PASS_LAST_EXECUTED_SEARCH_CONDITION =
  'SET_ANNUAL_PASS_LAST_EXECUTED_SEARCH_CONDITION';
export const SET_ANNUAL_PASS_RESERVATION_VISIBLE_COLUMNS =
  'SET_ANNUAL_PASS_RESERVATION_VISIBLE_COLUMNS';
export const SET_ANNUAL_PASS_RESERVATION_ROW_COUNT =
  'SET_ANNUAL_PASS_RESERVATION_ROW_COUNT';
export const SET_ANNUAL_PASS_RESERVATION_CURRENT_PAGE =
  'SET_ANNUAL_PASS_RESERVATION_CURRENT_PAGE';
export const SET_ANNUAL_PASS_RESERVATION_DEFAULT_VISIBLE_COLUMNS =
  'SET_ANNUAL_PASS_RESERVATION_DEFAULT_VISIBLE_COLUMNS';

export const CREATE_DIGITAL_MAP_FAILURE = 'CREATE_DIGITAL_MAP_FAILURE';
export const CREATE_DIGITAL_MAP_REQUEST = 'CREATE_DIGITAL_MAP_REQUEST';
export const CREATE_DIGITAL_MAP_SUCCESS = 'CREATE_DIGITAL_MAP_SUCCESS';
export const DELETE_DIGITAL_MAP_FAILURE = 'DELETE_DIGITAL_MAP_FAILURE';
export const DELETE_DIGITAL_MAP_REQUEST = 'DELETE_DIGITAL_MAP_REQUEST';
export const DELETE_DIGITAL_MAP_SUCCESS = 'DELETE_DIGITAL_MAP_SUCCESS';
export const FETCH_DIGITAL_MAPS_FAILURE = 'FETCH_DIGITAL_MAPS_FAILURE';
export const FETCH_DIGITAL_MAPS_REQUEST = 'FETCH_DIGITAL_MAPS_REQUEST';
export const FETCH_DIGITAL_MAPS_SUCCESS = 'FETCH_DIGITAL_MAPS_SUCCESS';
export const FETCH_DIGITAL_MAP_FAILURE = 'FETCH_DIGITAL_MAP_FAILURE';
export const FETCH_DIGITAL_MAP_REQUEST = 'FETCH_DIGITAL_MAP_REQUEST';
export const FETCH_DIGITAL_MAP_SUCCESS = 'FETCH_DIGITAL_MAP_SUCCESS';
export const UPDATE_DIGITAL_MAP_FAILURE = 'UPDATE_DIGITAL_MAP_FAILURE';
export const UPDATE_DIGITAL_MAP_REQUEST = 'UPDATE_DIGITAL_MAP_REQUEST';
export const UPDATE_DIGITAL_MAP_SUCCESS = 'UPDATE_DIGITAL_MAP_SUCCESS';

export const ISSUE_RESERVATION_RECEIPT_REQUEST =
  'ISSUE_RESERVATION_RECEIPT_REQUEST';
export const ISSUE_RESERVATION_RECEIPT_SUCCESS =
  'ISSUE_RESERVATION_RECEIPT_SUCCESS';
export const ISSUE_RESERVATION_RECEIPT_FAILURE =
  'ISSUE_RESERVATION_RECEIPT_FAILURE';
export const ISSUE_RESERVATION_RECEIPT_RESET =
  'ISSUE_RESERVATION_RECEIPT_RESET';
export const FETCH_RESERVATION_RECEIPT_REQUEST =
  'FETCH_RESERVATION_RECEIPT_REQUEST';
export const FETCH_RESERVATION_RECEIPT_SUCCESS =
  'FETCH_RESERVATION_RECEIPT_SUCCESS';
export const FETCH_RESERVATION_RECEIPT_FAILURE =
  'FETCH_RESERVATION_RECEIPT_FAILURE';
export const DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST =
  'DOWNLOAD_RESERVATION_RECEIPT_PDF_REQUEST';
export const DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS =
  'DOWNLOAD_RESERVATION_RECEIPT_PDF_SUCCESS';
export const DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE =
  'DOWNLOAD_RESERVATION_RECEIPT_PDF_FAILURE';
export const SEND_RESERVATION_RECEIPT_EMAIL_REQUEST =
  'SEND_RESERVATION_RECEIPT_EMAIL_REQUEST';
export const SEND_RESERVATION_RECEIPT_EMAIL_SUCCESS =
  'SEND_RESERVATION_RECEIPT_EMAIL_SUCCESS';
export const SEND_RESERVATION_RECEIPT_EMAIL_FAILURE =
  'SEND_RESERVATION_RECEIPT_EMAIL_FAILURE';

export const CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE =
  'CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE';
export const CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST =
  'CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST';
export const CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS =
  'CREATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS';
export const DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE =
  'DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE';
export const DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST =
  'DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST';
export const DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS =
  'DELETE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS';
export const FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_FAILURE =
  'FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_FAILURE';
export const FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_REQUEST =
  'FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_REQUEST';
export const FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_SUCCESS =
  'FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITIONS_SUCCESS';
export const FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE =
  'FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE';
export const FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST =
  'FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST';
export const FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS =
  'FETCH_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS';
export const UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE =
  'UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_FAILURE';
export const UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST =
  'UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_REQUEST';
export const UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS =
  'UPDATE_MARKETING_AUTOMATION_TRIGGER_CONDITION_SUCCESS';

export const CREATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE =
  'CREATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE';
export const CREATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST =
  'CREATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST';
export const CREATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS =
  'CREATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS';
export const DELETE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE =
  'DELETE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE';
export const DELETE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST =
  'DELETE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST';
export const DELETE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS =
  'DELETE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS';
export const FETCH_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE =
  'FETCH_MARKETING_AUTOMATION_CAMPAIGNS_FAILURE';
export const FETCH_MARKETING_AUTOMATION_CAMPAIGNS_REQUEST =
  'FETCH_MARKETING_AUTOMATION_CAMPAIGNS_REQUEST';
export const FETCH_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS =
  'FETCH_MARKETING_AUTOMATION_CAMPAIGNS_SUCCESS';
export const FETCH_MARKETING_AUTOMATION_CAMPAIGN_FAILURE =
  'FETCH_MARKETING_AUTOMATION_CAMPAIGN_FAILURE';
export const FETCH_MARKETING_AUTOMATION_CAMPAIGN_REQUEST =
  'FETCH_MARKETING_AUTOMATION_CAMPAIGN_REQUEST';
export const FETCH_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS =
  'FETCH_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS';
export const UPDATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE =
  'UPDATE_MARKETING_AUTOMATION_CAMPAIGN_FAILURE';
export const UPDATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST =
  'UPDATE_MARKETING_AUTOMATION_CAMPAIGN_REQUEST';
export const UPDATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS =
  'UPDATE_MARKETING_AUTOMATION_CAMPAIGN_SUCCESS';
export const FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_REQUEST =
  'FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_REQUEST';
export const FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_SUCCESS =
  'FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_SUCCESS';
export const FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_FAILURE =
  'FETCH_MARKETING_AUTOMATION_CAMPAIGN_REPORT_DATA_FAILURE';

export const CREATE_INSTANT_WIN_EVENT_FAILURE =
  'CREATE_INSTANT_WIN_EVENT_FAILURE';
export const CREATE_INSTANT_WIN_EVENT_REQUEST =
  'CREATE_INSTANT_WIN_EVENT_REQUEST';
export const CREATE_INSTANT_WIN_EVENT_SUCCESS =
  'CREATE_INSTANT_WIN_EVENT_SUCCESS';
export const DELETE_INSTANT_WIN_EVENT_FAILURE =
  'DELETE_INSTANT_WIN_EVENT_FAILURE';
export const DELETE_INSTANT_WIN_EVENT_REQUEST =
  'DELETE_INSTANT_WIN_EVENT_REQUEST';
export const DELETE_INSTANT_WIN_EVENT_SUCCESS =
  'DELETE_INSTANT_WIN_EVENT_SUCCESS';
export const FETCH_INSTANT_WIN_EVENTS_FAILURE =
  'FETCH_INSTANT_WIN_EVENTS_FAILURE';
export const FETCH_INSTANT_WIN_EVENTS_REQUEST =
  'FETCH_INSTANT_WIN_EVENTS_REQUEST';
export const FETCH_INSTANT_WIN_EVENTS_SUCCESS =
  'FETCH_INSTANT_WIN_EVENTS_SUCCESS';
export const FETCH_INSTANT_WIN_EVENT_FAILURE =
  'FETCH_INSTANT_WIN_EVENT_FAILURE';
export const FETCH_INSTANT_WIN_EVENT_REQUEST =
  'FETCH_INSTANT_WIN_EVENT_REQUEST';
export const FETCH_INSTANT_WIN_EVENT_SUCCESS =
  'FETCH_INSTANT_WIN_EVENT_SUCCESS';
export const UPDATE_INSTANT_WIN_EVENT_FAILURE =
  'UPDATE_INSTANT_WIN_EVENT_FAILURE';
export const UPDATE_INSTANT_WIN_EVENT_REQUEST =
  'UPDATE_INSTANT_WIN_EVENT_REQUEST';
export const UPDATE_INSTANT_WIN_EVENT_SUCCESS =
  'UPDATE_INSTANT_WIN_EVENT_SUCCESS';
export const SET_LAST_EXECUTED_INSTANT_WIN_EVENT_SEARCH_CONDITION =
  'SET_LAST_EXECUTED_INSTANT_WIN_EVENT_SEARCH_CONDITION';
export const SET_INSTANT_WIN_EVENT_VISIBLE_COLUMNS =
  'SET_INSTANT_WIN_EVENT_VISIBLE_COLUMNS';
export const SET_INSTANT_WIN_EVENT_ROW_COUNT =
  'SET_INSTANT_WIN_EVENT_ROW_COUNT';
export const SET_INSTANT_WIN_EVENT_CURRENT_PAGE =
  'SET_INSTANT_WIN_EVENT_CURRENT_PAGE';
export const UPDATE_INSTANT_WIN_EVENT_LIST_PAGE_TOKEN =
  'UPDATE_INSTANT_WIN_EVENT_PAGE_TOKEN';
export const RESET_INSTANT_WIN_EVENT_LIST_PAGE_TOKEN =
  'RESET_INSTANT_WIN_EVENT_PAGE_TOKEN';
export const CREATE_MARKETING_AUTOMATION_CONTENT_FAILURE =
  'CREATE_MARKETING_AUTOMATION_CONTENT_FAILURE';
export const CREATE_MARKETING_AUTOMATION_CONTENT_REQUEST =
  'CREATE_MARKETING_AUTOMATION_CONTENT_REQUEST';
export const CREATE_MARKETING_AUTOMATION_CONTENT_SUCCESS =
  'CREATE_MARKETING_AUTOMATION_CONTENT_SUCCESS';
export const DELETE_MARKETING_AUTOMATION_CONTENT_FAILURE =
  'DELETE_MARKETING_AUTOMATION_CONTENT_FAILURE';
export const DELETE_MARKETING_AUTOMATION_CONTENT_REQUEST =
  'DELETE_MARKETING_AUTOMATION_CONTENT_REQUEST';
export const DELETE_MARKETING_AUTOMATION_CONTENT_SUCCESS =
  'DELETE_MARKETING_AUTOMATION_CONTENT_SUCCESS';
export const FETCH_MARKETING_AUTOMATION_CONTENTS_FAILURE =
  'FETCH_MARKETING_AUTOMATION_CONTENTS_FAILURE';
export const FETCH_MARKETING_AUTOMATION_CONTENTS_REQUEST =
  'FETCH_MARKETING_AUTOMATION_CONTENTS_REQUEST';
export const FETCH_MARKETING_AUTOMATION_CONTENTS_SUCCESS =
  'FETCH_MARKETING_AUTOMATION_CONTENTS_SUCCESS';
export const FETCH_MARKETING_AUTOMATION_CONTENT_FAILURE =
  'FETCH_MARKETING_AUTOMATION_CONTENT_FAILURE';
export const FETCH_MARKETING_AUTOMATION_CONTENT_REQUEST =
  'FETCH_MARKETING_AUTOMATION_CONTENT_REQUEST';
export const FETCH_MARKETING_AUTOMATION_CONTENT_SUCCESS =
  'FETCH_MARKETING_AUTOMATION_CONTENT_SUCCESS';
export const UPDATE_MARKETING_AUTOMATION_CONTENT_FAILURE =
  'UPDATE_MARKETING_AUTOMATION_CONTENT_FAILURE';
export const UPDATE_MARKETING_AUTOMATION_CONTENT_REQUEST =
  'UPDATE_MARKETING_AUTOMATION_CONTENT_REQUEST';
export const UPDATE_MARKETING_AUTOMATION_CONTENT_SUCCESS =
  'UPDATE_MARKETING_AUTOMATION_CONTENT_SUCCESS';
export const FETCH_ANALYTICS_CUSTOMERS_SUCCESS =
  'FETCH_ANALYTICS_CUSTOMERS_SUCCESS';
export const FETCH_ANALYTICS_CUSTOMERS_FAILURE =
  'FETCH_ANALYTICS_CUSTOMERS_FAILURE';
export const FETCH_ANALYTICS_CUSTOMERS_REQUEST =
  'FETCH_ANALYTICS_CUSTOMERS_REQUEST';
export const FETCH_ANALYTICS_CUSTOMER_SUCCESS =
  'FETCH_ANALYTICS_CUSTOMER_SUCCESS';
export const FETCH_ANALYTICS_CUSTOMER_FAILURE =
  'FETCH_ANALYTICS_CUSTOMER_FAILURE';
export const FETCH_ANALYTICS_CUSTOMER_REQUEST =
  'FETCH_ANALYTICS_CUSTOMER_REQUEST';
export const FETCH_ON_SITE_ANALYTICS_CUSTOMERS_SUCCESS =
  'FETCH_ON_SITE_ANALYTICS_CUSTOMERS_SUCCESS';
export const FETCH_ON_SITE_ANALYTICS_CUSTOMERS_FAILURE =
  'FETCH_ON_SITE_ANALYTICS_CUSTOMERS_FAILURE';
export const FETCH_ON_SITE_ANALYTICS_CUSTOMERS_REQUEST =
  'FETCH_ON_SITE_ANALYTICS_CUSTOMERS_REQUEST';
export const FETCH_ON_SITE_ANALYTICS_CUSTOMER_SUCCESS =
  'FETCH_ON_SITE_ANALYTICS_CUSTOMER_SUCCESS';
export const FETCH_ON_SITE_ANALYTICS_CUSTOMER_FAILURE =
  'FETCH_ON_SITE_ANALYTICS_CUSTOMER_FAILURE';
export const FETCH_ON_SITE_ANALYTICS_CUSTOMER_REQUEST =
  'FETCH_ON_SITE_ANALYTICS_CUSTOMER_REQUEST';

export const FETCH_DASHBOARD_SETTINGS_FAILURE =
  'FETCH_DASHBOARD_SETTINGS_FAILURE';
export const FETCH_DASHBOARD_SETTINGS_REQUEST =
  'FETCH_DASHBOARD_SETTINGS_REQUEST';
export const FETCH_DASHBOARD_SETTINGS_SUCCESS =
  'FETCH_DASHBOARD_SETTINGS_SUCCESS';
export const PUT_DASHBOARD_SETTINGS_FAILURE = 'PUT_DASHBOARD_SETTINGS_FAILURE';
export const PUT_DASHBOARD_SETTINGS_REQUEST = 'PUT_DASHBOARD_SETTINGS_REQUEST';
export const PUT_DASHBOARD_SETTINGS_SUCCESS = 'PUT_DASHBOARD_SETTINGS_SUCCESS';
export const FETCH_ACCOUNT_SETTINGS_REQUEST = 'FETCH_ACCOUNT_SETTINGS_REQUEST';
export const FETCH_ACCOUNT_SETTINGS_SUCCESS = 'FETCH_ACCOUNT_SETTINGS_SUCCESS';
export const FETCH_ACCOUNT_SETTINGS_FAILURE = 'FETCH_ACCOUNT_SETTINGS_FAILURE';
export const PUT_ACCOUNT_SETTINGS_REQUEST = 'PUT_ACCOUNT_SETTINGS_REQUEST';
export const PUT_ACCOUNT_SETTINGS_SUCCESS = 'PUT_ACCOUNT_SETTINGS_SUCCESS';
export const PUT_ACCOUNT_SETTINGS_FAILURE = 'PUT_ACCOUNT_SETTINGS_FAILURE';
export const FETCH_AGENT_SYSTEM_FEE_INVOICES_REQUEST =
  'FETCH_AGENT_SYSTEM_FEE_INVOICES_REQUEST';
export const FETCH_AGENT_SYSTEM_FEE_INVOICES_FAILURE =
  'FETCH_AGENT_SYSTEM_FEE_INVOICES_FAILURE';
export const FETCH_AGENT_SYSTEM_FEE_INVOICES_SUCCESS =
  'FETCH_AGENT_SYSTEM_FEE_INVOICES_SUCCESS';
export const FETCH_CUSTOMER_EVENT_REPORT_DATA_FAILURE =
  'FETCH_CUSTOMER_EVENT_REPORT_DATA_FAILURE';
export const FETCH_CUSTOMER_EVENT_REPORT_DATA_REQUEST =
  'FETCH_CUSTOMER_EVENT_REPORT_DATA_REQUEST';
export const FETCH_CUSTOMER_EVENT_REPORT_DATA_SUCCESS =
  'FETCH_CUSTOMER_EVENT_REPORT_DATA_SUCCESS';
export const FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_FAILURE =
  'FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_FAILURE';
export const FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_REQUEST =
  'FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_REQUEST';
export const FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_SUCCESS =
  'FETCH_CUSTOMER_EVENT_ON_SITE_REPORT_DATA_SUCCESS';

export const SET_CUSTOMER_EVENT_FOCUSED_SITE =
  'SET_CUSTOMER_EVENT_FOCUSED_SITE';

export const UNDO_EQUIPMENT_TICKET_PRINT_REQUEST =
  'UNDO_EQUIPMENT_TICKET_PRINT_REQUEST';
export const UNDO_EQUIPMENT_TICKET_PRINT_FAILURE =
  'UNDO_EQUIPMENT_TICKET_PRINT_FAILURE';
export const UNDO_EQUIPMENT_TICKET_PRINT_SUCCESS =
  'UNDO_EQUIPMENT_TICKET_PRINT_SUCCESS';

export const FETCH_SUPPLIER_SETTINGS_REQUEST =
  'FETCH_SUPPLIER_SETTINGS_REQUEST';
export const FETCH_SUPPLIER_SETTINGS_SUCCESS =
  'FETCH_SUPPLIER_SETTINGS_SUCCESS';
export const FETCH_SUPPLIER_SETTINGS_FAILURE =
  'FETCH_SUPPLIER_SETTINGS_FAILURE';
export const PUT_SUPPLIER_SETTINGS_REQUEST = 'PUT_SUPPLIER_SETTINGS_REQUEST';
export const PUT_SUPPLIER_SETTINGS_SUCCESS = 'PUT_SUPPLIER_SETTINGS_SUCCESS';
export const PUT_SUPPLIER_SETTINGS_FAILURE = 'PUT_SUPPLIER_SETTINGS_FAILURE';

export const FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_FAILURE =
  'FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_FAILURE';
export const FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_REQUEST =
  'FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_REQUEST';
export const FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_SUCCESS =
  'FETCH_DIGITAL_MAP_ACCESS_REPORT_DATA_SUCCESS';
export const SET_LAST_DIGITAL_MAP_ACCESS_REPORT_SETTINGS =
  'SET_LAST_DIGITAL_MAP_ACCESS_REPORT_SETTINGS';
export const CREATE_RESTAURANT_FAILURE = 'CREATE_RESTAURANT_FAILURE';
export const CREATE_RESTAURANT_REQUEST = 'CREATE_RESTAURANT_REQUEST';
export const CREATE_RESTAURANT_SUCCESS = 'CREATE_RESTAURANT_SUCCESS';
export const DELETE_RESTAURANT_FAILURE = 'DELETE_RESTAURANT_FAILURE';
export const DELETE_RESTAURANT_REQUEST = 'DELETE_RESTAURANT_REQUEST';
export const DELETE_RESTAURANT_SUCCESS = 'DELETE_RESTAURANT_SUCCESS';
export const FETCH_RESTAURANTS_FAILURE = 'FETCH_RESTAURANTS_FAILURE';
export const FETCH_RESTAURANTS_REQUEST = 'FETCH_RESTAURANTS_REQUEST';
export const FETCH_RESTAURANTS_SUCCESS = 'FETCH_RESTAURANTS_SUCCESS';
export const FETCH_RESTAURANT_FAILURE = 'FETCH_RESTAURANT_FAILURE';
export const FETCH_RESTAURANT_REQUEST = 'FETCH_RESTAURANT_REQUEST';
export const FETCH_RESTAURANT_SUCCESS = 'FETCH_RESTAURANT_SUCCESS';
export const UPDATE_RESTAURANT_FAILURE = 'UPDATE_RESTAURANT_FAILURE';
export const UPDATE_RESTAURANT_REQUEST = 'UPDATE_RESTAURANT_REQUEST';
export const UPDATE_RESTAURANT_SUCCESS = 'UPDATE_RESTAURANT_SUCCESS';

export const CREATE_RESTAURANT_MENU_FAILURE = 'CREATE_RESTAURANT_MENU_FAILURE';
export const CREATE_RESTAURANT_MENU_REQUEST = 'CREATE_RESTAURANT_MENU_REQUEST';
export const CREATE_RESTAURANT_MENU_SUCCESS = 'CREATE_RESTAURANT_MENU_SUCCESS';
export const DELETE_RESTAURANT_MENU_FAILURE = 'DELETE_RESTAURANT_MENU_FAILURE';
export const DELETE_RESTAURANT_MENU_REQUEST = 'DELETE_RESTAURANT_MENU_REQUEST';
export const DELETE_RESTAURANT_MENU_SUCCESS = 'DELETE_RESTAURANT_MENU_SUCCESS';
export const FETCH_RESTAURANT_MENUS_FAILURE = 'FETCH_RESTAURANT_MENUS_FAILURE';
export const FETCH_RESTAURANT_MENUS_REQUEST = 'FETCH_RESTAURANT_MENUS_REQUEST';
export const FETCH_RESTAURANT_MENUS_SUCCESS = 'FETCH_RESTAURANT_MENUS_SUCCESS';
export const FETCH_RESTAURANT_MENU_FAILURE = 'FETCH_RESTAURANT_MENU_FAILURE';
export const FETCH_RESTAURANT_MENU_REQUEST = 'FETCH_RESTAURANT_MENU_REQUEST';
export const FETCH_RESTAURANT_MENU_SUCCESS = 'FETCH_RESTAURANT_MENU_SUCCESS';
export const UPDATE_RESTAURANT_MENU_FAILURE = 'UPDATE_RESTAURANT_MENU_FAILURE';
export const UPDATE_RESTAURANT_MENU_REQUEST = 'UPDATE_RESTAURANT_MENU_REQUEST';
export const UPDATE_RESTAURANT_MENU_SUCCESS = 'UPDATE_RESTAURANT_MENU_SUCCESS';

export const CREATE_RESTAURANT_ORDER_FAILURE =
  'CREATE_RESTAURANT_ORDER_FAILURE';
export const CREATE_RESTAURANT_ORDER_REQUEST =
  'CREATE_RESTAURANT_ORDER_REQUEST';
export const CREATE_RESTAURANT_ORDER_SUCCESS =
  'CREATE_RESTAURANT_ORDER_SUCCESS';
export const DELETE_RESTAURANT_ORDER_FAILURE =
  'DELETE_RESTAURANT_ORDER_FAILURE';
export const DELETE_RESTAURANT_ORDER_REQUEST =
  'DELETE_RESTAURANT_ORDER_REQUEST';
export const DELETE_RESTAURANT_ORDER_SUCCESS =
  'DELETE_RESTAURANT_ORDER_SUCCESS';
export const FETCH_RESTAURANT_ORDERS_FAILURE =
  'FETCH_RESTAURANT_ORDERS_FAILURE';
export const FETCH_RESTAURANT_ORDERS_REQUEST =
  'FETCH_RESTAURANT_ORDERS_REQUEST';
export const FETCH_RESTAURANT_ORDERS_SUCCESS =
  'FETCH_RESTAURANT_ORDERS_SUCCESS';
export const FETCH_RESTAURANT_ORDER_FAILURE = 'FETCH_RESTAURANT_ORDER_FAILURE';
export const FETCH_RESTAURANT_ORDER_REQUEST = 'FETCH_RESTAURANT_ORDER_REQUEST';
export const FETCH_RESTAURANT_ORDER_SUCCESS = 'FETCH_RESTAURANT_ORDER_SUCCESS';
export const UPDATE_RESTAURANT_ORDER_FAILURE =
  'UPDATE_RESTAURANT_ORDER_FAILURE';
export const UPDATE_RESTAURANT_ORDER_REQUEST =
  'UPDATE_RESTAURANT_ORDER_REQUEST';
export const UPDATE_RESTAURANT_ORDER_SUCCESS =
  'UPDATE_RESTAURANT_ORDER_SUCCESS';
export const FETCH_RESTAURANT_ORDER_REPORT_DATA_SUCCESS =
  'FETCH_RESTAURANT_ORDER_REPORT_DATA_SUCCESS';
export const FETCH_RESTAURANT_ORDER_REPORT_DATA_FAILURE =
  'FETCH_RESTAURANT_ORDER_REPORT_DATA_FAILURE';
export const FETCH_RESTAURANT_ORDER_REPORT_DATA_REQUEST =
  'FETCH_RESTAURANT_ORDER_REPORT_DATA_REQUEST';

export const CREATE_SITE_CONTROL_WIDGET_FAILURE =
  'CREATE_SITE_CONTROL_WIDGET_FAILURE';
export const CREATE_SITE_CONTROL_WIDGET_REQUEST =
  'CREATE_SITE_CONTROL_WIDGET_REQUEST';
export const CREATE_SITE_CONTROL_WIDGET_SUCCESS =
  'CREATE_SITE_CONTROL_WIDGET_SUCCESS';
export const FETCH_SITE_CONTROL_WIDGETS_SUCCESS =
  'FETCH_SITE_CONTROL_WIDGETS_SUCCESS';
export const FETCH_SITE_CONTROL_WIDGETS_FAILURE =
  'FETCH_SITE_CONTROL_WIDGETS_FAILURE';
export const FETCH_SITE_CONTROL_WIDGETS_REQUEST =
  'FETCH_SITE_CONTROL_WIDGETS_REQUEST';
export const FETCH_SITE_CONTROL_WIDGET_SUCCESS =
  'FETCH_SITE_CONTROL_WIDGET_SUCCESS';
export const FETCH_SITE_CONTROL_WIDGET_FAILURE =
  'FETCH_SITE_CONTROL_WIDGET_FAILURE';
export const FETCH_SITE_CONTROL_WIDGET_REQUEST =
  'FETCH_SITE_CONTROL_WIDGET_REQUEST';
export const UPDATE_SITE_CONTROL_WIDGET_FAILURE =
  'UPDATE_SITE_CONTROL_WIDGET_FAILURE';
export const UPDATE_SITE_CONTROL_WIDGET_SUCCESS =
  'UPDATE_SITE_CONTROL_WIDGET_SUCCESS';
export const UPDATE_SITE_CONTROL_WIDGET_REQUEST =
  'UPDATE_SITE_CONTROL_WIDGET_REQUEST';
export const DELETE_SITE_CONTROL_WIDGET_FAILURE =
  'DELETE_SITE_CONTROL_WIDGET_FAILURE';
export const DELETE_SITE_CONTROL_WIDGET_REQUEST =
  'DELETE_SITE_CONTROL_WIDGET_REQUEST';
export const DELETE_SITE_CONTROL_WIDGET_SUCCESS =
  'DELETE_SITE_CONTROL_WIDGET_SUCCESS';
export const FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_REQUEST =
  'FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_REQUEST';
export const FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_SUCCESS =
  'FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_SUCCESS';
export const FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_FAILURE =
  'FETCH_SITE_CONTROL_WIDGET_REPORT_DATA_FAILURE';

export const SET_WEB_JOURNEY_CUSTOMER_LIST_VISIBLE_COLUMNS =
  'SET_WEB_JOURNEY_CUSTOMER_LIST_VISIBLE_COLUMNS';
export const SET_WEB_JOURNEY_CUSTOMER_LIST_PAGE_SIZE =
  'SET_WEB_JOURNEY_CUSTOMER_LIST_PAGE_SIZE';
export const SET_WEB_JOURNEY_CUSTOMER_LIST_CURRENT_PAGE =
  'SET_WEB_JOURNEY_CUSTOMER_LIST_CURRENT_PAGE';
export const SET_WEB_JOURNEY_CUSTOMER_LIST_SELECTED_PRESET_NAME =
  'SET_WEB_JOURNEY_CUSTOMER_LIST_SELECTED_PRESET_NAME';
export const SET_WEB_JOURNEY_CUSTOMER_LIST_ACTIVE_TAB =
  'SET_WEB_JOURNEY_CUSTOMER_LIST_ACTIVE_TAB';

export const FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_REQUEST =
  'FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_REQUEST';
export const FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_FAILURE =
  'FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_FAILURE';
export const FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_SUCCESS =
  'FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICES_SUCCESS';
export const FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_REQUEST =
  'FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_REQUEST';
export const FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_FAILURE =
  'FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_FAILURE';
export const FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_SUCCESS =
  'FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_SUCCESS';
export const FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_REQUEST =
  'FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_REQUEST';
export const FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_FAILURE =
  'FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_FAILURE';
export const FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_SUCCESS =
  'FETCH_PARTNERSHIP_SYSTEM_FEE_INVOICE_CSV_SUCCESS';
export const FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_REQUEST =
  'FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_REQUEST';
export const FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_FAILURE =
  'FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_FAILURE';
export const FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_SUCCESS =
  'FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICES_SUCCESS';
export const FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_REQUEST =
  'FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_REQUEST';
export const FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_FAILURE =
  'FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_FAILURE';
export const FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_SUCCESS =
  'FETCH_PARTNERSHIP_AGENT_SYSTEM_FEE_INVOICE_CSV_SUCCESS';

export const CREATE_WAIT_TIME_FAILURE = 'CREATE_WAIT_TIME_FAILURE';
export const CREATE_WAIT_TIME_REQUEST = 'CREATE_WAIT_TIME_REQUEST';
export const CREATE_WAIT_TIME_SUCCESS = 'CREATE_WAIT_TIME_SUCCESS';
export const DELETE_WAIT_TIME_FAILURE = 'DELETE_WAIT_TIME_FAILURE';
export const DELETE_WAIT_TIME_REQUEST = 'DELETE_WAIT_TIME_REQUEST';
export const DELETE_WAIT_TIME_SUCCESS = 'DELETE_WAIT_TIME_SUCCESS';
export const FETCH_WAIT_TIMES_FAILURE = 'FETCH_WAIT_TIMES_FAILURE';
export const FETCH_WAIT_TIMES_REQUEST = 'FETCH_WAIT_TIMES_REQUEST';
export const FETCH_WAIT_TIMES_SUCCESS = 'FETCH_WAIT_TIMES_SUCCESS';
export const FETCH_WAIT_TIME_FAILURE = 'FETCH_WAIT_TIME_FAILURE';
export const FETCH_WAIT_TIME_REQUEST = 'FETCH_WAIT_TIME_REQUEST';
export const FETCH_WAIT_TIME_SUCCESS = 'FETCH_WAIT_TIME_SUCCESS';
export const UPDATE_WAIT_TIME_FAILURE = 'UPDATE_WAIT_TIME_FAILURE';
export const UPDATE_WAIT_TIME_REQUEST = 'UPDATE_WAIT_TIME_REQUEST';
export const UPDATE_WAIT_TIME_SUCCESS = 'UPDATE_WAIT_TIME_SUCCESS';

export const SET_MAP_DASHBOARD_SELECTED_MAP_ID =
  'SET_MAP_DASHBOARD_SELECTED_MAP_ID';
export const SET_MAP_DASHBOARD_BASE_DATE_RANGE =
  'SET_MAP_DASHBOARD_BASE_DATE_RANGE';
export const SET_MAP_DASHBOARD_COMPARISON_DATE_RANGE =
  'SET_MAP_DASHBOARD_COMPARISON_DATE_RANGE';
export const SET_UI_THEME = 'SET_UI_THEME';
