import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { currency } from 'shared/libraries/currency';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { ComparisonDateInput } from 'client/components/v3/ComparisonDateInput/ComparisonDateInput';
import { QuantityGadget } from 'client/components/v3/DashboardGadgets/QuantityGadget';
import { ReduxState } from 'client/reducers';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { fetchDigitalMaps } from 'client/actions/digitalMaps';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import {
  setMapDashboardBaseDateRange,
  setMapDashboardComparisonDateRange,
  setMapDashboardSelectedMapId,
} from 'client/actions/mapDashboardControls';

import styles from './DigitalMapDashboard.module.css';
import { MapDashboardContext } from './MapDashboardContext';
import { PieChartGadget } from './PieChartGadget';
import { HeatmapGadget } from './HeatmapGadget/HeatmapGadget';
import { PopularPinsGadget } from './PopularPinsGadget/PopularPinsGadget';
import { PopularTimesGadget } from './PopularTimesGadget/PopularTimesGadget';
import { useMapVisitorReportData } from './useMapVisitorReportData';

export const DigitalMapDashboard = () => {
  const [activePinKey, setActivePinKey] = React.useState<string | null>(null);

  const { selectedMapId, baseDateRange, comparisonDateRange } = useSelector(
    (state: ReduxState) => state.mapDashboardControls
  );
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const org = useSelector(activeUserOrganizationSelector);
  const currencyCode = org?.default_currency ?? 'USD';
  const formattedCurrencySymbol =
    currencyCode === 'USD'
      ? '$'
      : currencyCode === 'JPY' && locale === 'ja'
      ? '円'
      : currencyCode;

  const { data, isLoading } = useMapVisitorReportData(
    baseDateRange,
    selectedMapId ?? '',
    comparisonDateRange
  );

  // 0 = 8am, 14 = 10pm
  const timezone = org?.default_timezone;

  const now = moment().tz(timezone ?? 'UTC');

  const hour = now.hour();
  const [time, setTime] = React.useState(Math.min(Math.max(hour - 8, 0), 14));

  const baseDataSet = data?.[0];

  const baseUserCount = baseDataSet?.totals?.unique_user_count ?? 0;
  const baseSessionCount = baseDataSet?.totals?.session_count ?? 0;
  const baseTotalVisitDuration =
    baseDataSet?.totals?.total_visit_duration_minutes ?? 0;
  const baseGrossSales = baseDataSet?.totals?.gross_sales ?? 0;
  const baseUniqueUserDaysCount =
    baseDataSet?.totals?.unique_user_days_count ?? 0;
  const baseAvgSessionsPerDay = baseUniqueUserDaysCount
    ? baseSessionCount / baseUniqueUserDaysCount
    : 0;
  const baseAvgVisitDuration = baseUniqueUserDaysCount
    ? baseTotalVisitDuration / baseUniqueUserDaysCount
    : 0;

  const baseAgeGroupData = baseDataSet?.totals?.totals_by_age_group;
  const baseSpendingData = baseDataSet?.totals?.totals_by_spending_amount;

  const comparisonDataSet = data && data.length > 1 ? data[1] : null;

  const comparisonUserCount = comparisonDataSet?.totals?.unique_user_count ?? 0;
  const comparisonSessionCount = comparisonDataSet?.totals?.session_count ?? 0;
  const comparisonTotalVisitDuration =
    comparisonDataSet?.totals?.total_visit_duration_minutes ?? 0;
  const comparisonGrossSales = comparisonDataSet?.totals?.gross_sales ?? 0;
  const comparisonUniqueUserDaysCount =
    comparisonDataSet?.totals?.unique_user_days_count ?? 0;
  const comparisonAvgSessionsPerDay = comparisonUniqueUserDaysCount
    ? comparisonSessionCount / comparisonUniqueUserDaysCount
    : 0;
  const comparisonAvgVisitDuration = comparisonUniqueUserDaysCount
    ? comparisonTotalVisitDuration / comparisonUniqueUserDaysCount
    : 0;

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchDigitalMaps());
  }, [dispatch]);

  const digitalMaps = useSelector((state: ReduxState) => state.digitalMaps.all);

  React.useEffect(() => {
    if (digitalMaps.length > 0 && !selectedMapId) {
      dispatch(setMapDashboardSelectedMapId(digitalMaps[0].id ?? ''));
    }
  }, [digitalMaps, dispatch, selectedMapId]);

  const selectedMap = digitalMaps.find((map) => map.id === selectedMapId);

  return (
    <V3Page>
      <PageHeader
        title={t('Map Dashboard')}
        description={t('Explore Guest Insights from your Smart Map')}
      />

      <PageContent className={styles['page-content-custom']}>
        <MapDashboardContext.Provider
          value={{ time, setTime, baseDateRange, comparisonDateRange }}
        >
          <div className={styles['container']}>
            <div className={styles['controls']}>
              <div className={styles['map-selector']}>
                <SingleDropdown
                  label={t('Select Map')}
                  options={digitalMaps.map((map) => ({
                    text: map.name ?? '',
                    value: map.id ?? '',
                  }))}
                  onChange={(newOption) =>
                    dispatch(setMapDashboardSelectedMapId(newOption))
                  }
                  selectedOption={selectedMapId ?? ''}
                />
              </div>
              <div className={styles['date-range-container']}>
                <ComparisonDateInput
                  baseDateRange={baseDateRange}
                  setBaseDateRange={(newDateRange) =>
                    dispatch(setMapDashboardBaseDateRange(newDateRange))
                  }
                  comparisonDateRange={comparisonDateRange}
                  setComparisonDateRange={(newDateRange) =>
                    dispatch(setMapDashboardComparisonDateRange(newDateRange))
                  }
                  menuStyle={{ right: 0, left: 'auto' }}
                />
              </div>
            </div>
            <div className={styles['gadget-row']}>
              <QuantityGadget
                header={t('Users')}
                unit={locale === 'ja' ? '人' : ''}
                amount={baseUserCount}
                changeAmount={
                  comparisonDataSet ? comparisonUserCount - baseUserCount : null
                }
                loading={isLoading}
              />
              <QuantityGadget
                header={t('Avg Usage')}
                unit={t('Times Per Day')}
                amount={baseAvgSessionsPerDay}
                changeAmount={
                  comparisonDataSet
                    ? comparisonAvgSessionsPerDay - baseAvgSessionsPerDay
                    : null
                }
                numberFormatter={(amount) => amount.toFixed(1)}
                loading={isLoading}
              />
              <QuantityGadget
                header={t('Avg Visit Duration')}
                unit={t('Min')}
                amount={baseAvgVisitDuration}
                changeAmount={
                  comparisonDataSet
                    ? comparisonAvgVisitDuration - baseAvgVisitDuration
                    : null
                }
                loading={isLoading}
                numberFormatter={(amount) => amount.toFixed(0)}
              />
              <QuantityGadget
                header={t('Sales')}
                unit={formattedCurrencySymbol}
                isPrefixUnit={formattedCurrencySymbol === '$'}
                amount={currency(baseGrossSales)?.value}
                changeAmount={
                  comparisonDataSet
                    ? currency(comparisonGrossSales)?.value -
                      currency(baseGrossSales)?.value
                    : null
                }
                loading={isLoading}
              />
            </div>
            <div className={styles['gadget-row']}>
              <HeatmapGadget
                data={data || []}
                title={t('Guest Heatmap')}
                map={selectedMap || null}
                activePinKey={activePinKey}
                setActivePinKey={setActivePinKey}
              />
              <div className={styles['stack']}>
                <PopularTimesGadget
                  title={t('Popular Times')}
                  data={data}
                  isLoading={isLoading}
                />
                <PopularPinsGadget
                  title={t('Most Popular Pins')}
                  map={selectedMap || null}
                  activePinKey={activePinKey}
                  setActivePinKey={setActivePinKey}
                  data={data}
                  isLoading={isLoading}
                />
              </div>
            </div>
            <div className={styles['gadget-row']}>
              <PieChartGadget
                title={t('User Ages')}
                data={[
                  {
                    name: '10-19 years',
                    value:
                      baseAgeGroupData?.find(
                        (ageGroupTotal) => ageGroupTotal?.age_group === '10-19'
                      )?.user_count ?? 0,
                    color: '#D9D9D9',
                  },
                  {
                    name: '20-29 years',
                    value:
                      baseAgeGroupData?.find(
                        (ageGroupTotal) => ageGroupTotal?.age_group === '20-29'
                      )?.user_count ?? 0,
                    color: '#FF8360',
                  },
                  {
                    name: '30-39 years',
                    value:
                      baseAgeGroupData?.find(
                        (ageGroupTotal) => ageGroupTotal?.age_group === '30-39'
                      )?.user_count ?? 0,
                    color: '#5FD189',
                  },
                  {
                    name: '40-49 years',
                    value:
                      baseAgeGroupData?.find(
                        (ageGroupTotal) => ageGroupTotal?.age_group === '40-49'
                      )?.user_count ?? 0,
                    color: '#7CD9FD',
                  },
                  {
                    name: '50+ years',
                    value:
                      baseAgeGroupData?.find(
                        (ageGroupTotal) => ageGroupTotal?.age_group === '50+'
                      )?.user_count ?? 0,
                    color: '#B9E9FE',
                  },
                ]}
              />

              <PieChartGadget
                title={t('User Profiles')}
                data={[
                  { name: 'Family', value: 13, color: '#D9D9D9' },
                  { name: 'Couple', value: 29, color: '#FF8360' },
                  { name: 'Single', value: 3, color: '#5FD189' },
                  { name: 'Others', value: 10, color: '#7CD9FD' },
                ]}
              />

              <PieChartGadget
                title={t('User Spending')}
                data={[
                  {
                    name: '$150 or more',
                    value:
                      baseSpendingData?.find(
                        (spendingTotal) =>
                          spendingTotal?.spending_amount === '150+'
                      )?.user_count ?? 0,
                    color: '#D9D9D9',
                  },
                  {
                    name: '$100-$150',
                    value:
                      baseSpendingData?.find(
                        (spendingTotal) =>
                          spendingTotal?.spending_amount === '100-150'
                      )?.user_count ?? 0,
                    color: '#FF8360',
                  },
                  {
                    name: '$50-$100',
                    value:
                      baseSpendingData?.find(
                        (spendingTotal) =>
                          spendingTotal?.spending_amount === '50-100'
                      )?.user_count ?? 0,
                    color: '#5FD189',
                  },
                  {
                    name: '$0-$50',
                    value:
                      baseSpendingData?.find(
                        (spendingTotal) =>
                          spendingTotal?.spending_amount === '0-50'
                      )?.user_count ?? 0,
                    color: '#7CD9FD',
                  },
                ]}
              />
            </div>
          </div>
        </MapDashboardContext.Provider>
      </PageContent>
    </V3Page>
  );
};
