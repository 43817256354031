import * as React from 'react';

import styles from './WaitTimeManager.module.css';

interface Props {
  text: string;
  isSelected: boolean;
  onClick: () => void;
}

export const WaitTimeNonNumericOption: React.FC<Props> = ({
  text,
  isSelected,
  onClick,
}) => {
  const cardClassName = isSelected
    ? styles.optionCardSelected
    : styles.optionCard;

  return (
    <button className={cardClassName} onClick={onClick}>
      <span className={styles.optionText}>{text}</span>
    </button>
  );
};
