import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import {
  activeUserSelector,
  agentOptionsSelector,
  activeUserOrganizationSelector,
  bookingWidgetPMPSupportedLanguagesSelector,
} from 'client/reducers/user';
import {
  operationAllowed,
  presetSearchConditionsEnabledForAccount,
} from 'shared/models/access';
import {
  getDateFilterPresetOptions,
  getExpirationFilterPresetOptions,
  DateFilterPreset,
} from 'client/libraries/util/searchReservations';
import { Modal } from 'client/components/Modal/Modal';
import { SearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import {
  Checkbox,
  Input,
  DateRangeInput,
  Button,
  MultiSelect,
  Select,
  FieldWrapper,
  Radio,
} from 'client/components/Form';
import {
  supplierOptionsSelector,
  summariesWithBookmarksSelector,
} from 'client/reducers/products';
import { ReduxState } from 'client/reducers';
import { getBookingSourceOptions } from 'client/libraries/util/getBookingSourceOptions';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import { getSourceLanguageOptions } from 'client/libraries/util/getSourceLanguageOptions';
import { useOrderByOptions } from 'client/hooks/useOrderByOptions';
import { ReservationSearchConditionPresetSelector } from 'client/components/ReservationSearchConditionPresetSelector/ReservationSearchConditionPresetSelector';
import {
  getProductSalesStatus,
  SalesStatus,
} from 'client/libraries/util/getProductSalesStatus';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { getPresetSearchReservationsRequest } from 'client/libraries/util/getPresetSearchReservationsRequest';
import {
  BookingSourceType,
  ReservationStatus,
  SourceLanguage,
} from 'shared/models/swagger';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';

type Props = {
  title?: string;
  searchButtonText?: string;
  onReset: () => void;
  onSearch: () => void;
  onClick?: () => void;
  searchCondition: SearchReservationsRequest;
  setSearchCondition: (arg0: SearchReservationsRequest) => void;
  trigger: React.ReactElement<any>;
  note?: string;
  annualPassOnly?: boolean;
};
export const ReservationSearchQueryModal = ({
  title,
  searchButtonText,
  onReset,
  onSearch,
  onClick,
  searchCondition,
  setSearchCondition,
  trigger,
  note,
  annualPassOnly,
}: Props) => {
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);

  const [productStatus, setProductStatus] = React.useState<SalesStatus | ''>(
    'ON_SALE'
  );

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const allProducts = useSelector(summariesWithBookmarksSelector);
  const groupBookingTemplates = useSelector(
    (state: ReduxState) => state.groupBookingTemplates.all
  );
  const groupOptions = groupBookingTemplates.map((groupBookingTemplate) => ({
    value: groupBookingTemplate.id ?? '',
    text: groupBookingTemplate.name ?? '',
  }));

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );

  const languageOptions = React.useMemo(() => {
    const options = getSourceLanguageOptions(t);

    return options.filter((option) => {
      return supportedLanguages.includes(option.value as SourceLanguage);
    });
  }, [supportedLanguages, t]);

  const productOptions = React.useMemo(() => {
    let salesStatusProducts;
    if (productStatus && presetSearchConditionsEnabledForAccount(activeUser)) {
      salesStatusProducts = allProducts.filter((product) => {
        return (
          getProductSalesStatus(product) === productStatus ||
          (searchCondition.productIds &&
            searchCondition.productIds.includes(product.id))
        );
      });
    } else {
      salesStatusProducts = allProducts;
    }

    return salesStatusProducts
      .filter((product) => {
        return (
          (annualPassOnly ?? false) ===
          (product?.annual_pass_mode_settings?.is_enabled ?? false)
        );
      })
      .map((product) => {
        return {
          value: product.id,
          key: product.id,
          text: presetSearchConditionsEnabledForAccount(activeUser)
            ? `${
                product.internal_product_name || product.product_name || ''
              }  (${product.supplier_reference})`
            : getVerboseDisplayProductName(product),
        };
      });
  }, [allProducts, productStatus]);

  const statusOptions = getBookingStatusOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const bookingSourceOptions = getBookingSourceOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const agentOptions = useSelector(agentOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const supplierOptions = useSelector(supplierOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const orderByOptions = useOrderByOptions();

  const dateFilterPresetOptions = [
    {
      value: 'NONE',
      text: t('None'),
    },
    ...getDateFilterPresetOptions(t),
  ];

  const expirationFilterPresetOptions = [
    {
      value: 'NONE',
      text: t('None'),
    },
    ...getExpirationFilterPresetOptions(t),
  ];

  const waiverStatusOptions = [
    {
      value: 'NOT_APPLICABLE',
      text: t('Waiver Not Required'),
    },
    {
      value: 'UNSIGNED',
      text: t('Not Started'),
    },
    {
      value: 'PARTIALLY_SIGNED',
      text: t('Partially Complete'),
    },
    {
      value: 'COMPLETED',
      text: t('Completed'),
    },
  ];

  const checkinStatusOptions = [
    { value: 'NOT_CHECKED_IN', text: t('Checkin Not Started') },
    { value: 'IN_PROGRESS', text: t('Partially Checked-in') },
    { value: 'CHECKED_IN', text: t('Checkin Complete') },
  ];

  const changeProductId = ({ value }: { value: string[] }) => {
    setSearchCondition({ ...searchCondition, productIds: value });
  };

  const changeStatus = ({ value }: { value: string[] }) => {
    setSearchCondition({
      ...searchCondition,
      statuses: value as ReservationStatus[],
    });
  };

  const changeBookingSource = ({ value }: { value: string[] }) => {
    setSearchCondition({
      ...searchCondition,
      bookingSourceTypes: value as BookingSourceType[],
    });
  };

  const changeAgentId = ({ value }: { value: string[] }) => {
    setSearchCondition({ ...searchCondition, agentIds: value });
  };

  const changeGroupIds = ({ value }: { value: string[] }) => {
    setSearchCondition({ ...searchCondition, groupIds: value });
  };

  const changeSupplierId = ({ value }: { value: string[] }) => {
    setSearchCondition({ ...searchCondition, supplierIds: value });
  };

  const participationDateDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      participationDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const participationDateDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      participationDateTo: date,
      dateFilterPreset: null,
    });
  };

  const bookedDateDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      bookedDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const bookedDateDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      bookedDateTo: date,
      dateFilterPreset: null,
    });
  };

  const lastUpdatedDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      lastUpdatedDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const lastUpdatedDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      lastUpdatedDateTo: date ? moment(date).format('YYYY-MM-DD') : '',
      dateFilterPreset: null,
    });
  };

  const expirationDateFromChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      expirationDateFrom: date,
      dateFilterPreset: null,
    });
  };

  const expirationDateToChangeHandler = (date: string) => {
    setSearchCondition({
      ...searchCondition,
      expirationDateTo: date ? moment(date).format('YYYY-MM-DD') : '',
      dateFilterPreset: null,
    });
  };

  const presetChangeHandler = (preset: string) => {
    const presetSearchCondition = getPresetSearchReservationsRequest(
      activeUserOrganization,
      preset,
      moment()
    );
    if (presetSearchCondition) {
      setSearchCondition(presetSearchCondition);
    }
  };

  const reservationLanguagesChangeHandler = ({
    value,
  }: {
    value: string[];
  }) => {
    setSearchCondition({
      ...searchCondition,
      reservationLanguages: value as SourceLanguage[],
    });
  };

  const toggleMostRecentEmailBounced = () => {
    setSearchCondition({
      ...searchCondition,
      mostRecentEmailBounced: !searchCondition.mostRecentEmailBounced,
    });
  };

  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <Modal
      title={title || t('Search Reservation')}
      trigger={trigger}
      open={showModal}
      onOpen={() => {
        setShowModal(true);
        onClick?.();
      }}
      onClose={() => setShowModal(false)}
    >
      <Modal.Content>
        {!annualPassOnly && (
          <>
            {presetSearchConditionsEnabledForAccount(activeUser) &&
              (
                activeUserOrganization?.account_reservation_search_settings
                  ?.presets ?? []
              ).length > 0 && (
                <Modal.Box>
                  <FieldWrapper label={t('Preset Condition')}>
                    <ReservationSearchConditionPresetSelector
                      value={searchCondition.presetKey}
                      onChange={presetChangeHandler}
                    />
                  </FieldWrapper>
                </Modal.Box>
              )}
          </>
        )}

        <Modal.Box>
          <Select
            label={t('Order By')}
            options={orderByOptions}
            value={searchCondition.orderBy}
            onChange={(_, { value }: { value: string }) => {
              setSearchCondition({ ...searchCondition, orderBy: value });
            }}
          />
        </Modal.Box>

        {presetSearchConditionsEnabledForAccount(activeUser) ? (
          <>
            <Modal.Box>
              <FieldWrapper label={t('Product')}>
                <Modal.Box column="three">
                  <Radio
                    label={t('On Sale')}
                    checked={productStatus === 'ON_SALE'}
                    onChange={() => {
                      setProductStatus('ON_SALE');
                    }}
                  />
                  <Radio
                    label={t('Not On Sale')}
                    checked={productStatus === 'LIST_ONLY'}
                    onChange={() => {
                      setProductStatus('LIST_ONLY');
                    }}
                  />
                  <Radio
                    label={t('Not Listed')}
                    checked={productStatus === 'NOT_LISTED'}
                    onChange={() => {
                      setProductStatus('NOT_LISTED');
                    }}
                  />
                </Modal.Box>
                <MultiSelect
                  style={{ marginTop: '4px' }}
                  search
                  options={productOptions}
                  selectedValues={searchCondition.productIds}
                  onChange={changeProductId}
                />
              </FieldWrapper>
            </Modal.Box>
          </>
        ) : (
          <Modal.Box>
            <MultiSelect
              search
              label={t('Product')}
              options={productOptions}
              selectedValues={searchCondition.productIds}
              onChange={changeProductId}
            />
          </Modal.Box>
        )}

        <Modal.Box>
          <MultiSelect
            label={t('Booking Status')}
            options={statusOptions}
            selectedValues={searchCondition.statuses}
            onChange={changeStatus}
          />
        </Modal.Box>

        {presetSearchConditionsEnabledForAccount(activeUser) ? (
          <Modal.Box>
            <Input
              label={t('Confirmation/Application Number')}
              value={searchCondition.supplierOrAgentReference}
              onChange={(event) => {
                setSearchCondition({
                  ...searchCondition,
                  supplierOrAgentReference: event.currentTarget.value,
                });
              }}
            />
          </Modal.Box>
        ) : (
          <Modal.Box column="three">
            <Input
              label={t('Application Number')}
              value={searchCondition.agentReference}
              onChange={(event) => {
                setSearchCondition({
                  ...searchCondition,
                  agentReference: event.currentTarget.value,
                });
              }}
            />

            <Input
              label={t('Confirmation Number')}
              value={searchCondition.supplierReference}
              onChange={(event) => {
                setSearchCondition({
                  ...searchCondition,
                  supplierReference: event.currentTarget.value,
                });
              }}
            />

            <Input
              label="#"
              value={searchCondition.id}
              onChange={(event) => {
                setSearchCondition({
                  ...searchCondition,
                  id: event.currentTarget.value,
                });
              }}
            />
          </Modal.Box>
        )}

        <Modal.Box>
          {annualPassOnly ? (
            <Select
              label={t('Date Preset')}
              options={expirationFilterPresetOptions}
              value={searchCondition.expirationDateFilterPreset ?? 'NONE'}
              onChange={(event, { value }) => {
                setSearchCondition({
                  ...searchCondition,
                  expirationDateFilterPreset:
                    value === 'NONE' ? null : (value as DateFilterPreset),
                  expirationDateFrom: '',
                  expirationDateTo: '',
                });
              }}
            />
          ) : (
            <Select
              label={t('Date Preset')}
              options={dateFilterPresetOptions}
              value={searchCondition.dateFilterPreset ?? 'NONE'}
              onChange={(event, { value }) => {
                setSearchCondition({
                  ...searchCondition,
                  dateFilterPreset:
                    value === 'NONE' ? null : (value as DateFilterPreset),
                  lastUpdatedDateFrom: '',
                  lastUpdatedDateTo: '',
                  bookedDateFrom: '',
                  bookedDateTo: '',
                  participationDateFrom: '',
                  participationDateTo: '',
                });
              }}
            />
          )}
        </Modal.Box>

        {annualPassOnly ? (
          <>
            <Modal.Box column="two">
              <DateRangeInput
                label={t('Expiration Date')}
                fromDate={searchCondition.expirationDateFrom}
                onChangeFromDate={expirationDateFromChangeHandler}
                toDate={searchCondition.expirationDateTo}
                onChangeToDate={expirationDateToChangeHandler}
              />
            </Modal.Box>
          </>
        ) : (
          <>
            <Modal.Box column="two">
              <DateRangeInput
                label={t('Participation Date')}
                fromDate={searchCondition.participationDateFrom}
                onChangeFromDate={participationDateDateFromChangeHandler}
                toDate={searchCondition.participationDateTo}
                onChangeToDate={participationDateDateToChangeHandler}
              />

              <DateRangeInput
                label={t('Booked Date')}
                fromDate={searchCondition.bookedDateFrom}
                onChangeFromDate={bookedDateDateFromChangeHandler}
                toDate={searchCondition.bookedDateTo}
                onChangeToDate={bookedDateDateToChangeHandler}
              />
            </Modal.Box>

            <Modal.Box column="two">
              <DateRangeInput
                label={t('Last Updated Date')}
                fromDate={searchCondition.lastUpdatedDateFrom}
                onChangeFromDate={lastUpdatedDateFromChangeHandler}
                toDate={searchCondition.lastUpdatedDateTo}
                onChangeToDate={lastUpdatedDateToChangeHandler}
              />
            </Modal.Box>
          </>
        )}

        {locale === 'ja' ? (
          <Modal.Box column="two">
            <Input
              label={t('Family Name')}
              value={searchCondition.customerFamilyName}
              onChange={(event) => {
                setSearchCondition({
                  ...searchCondition,
                  customerFamilyName: event.currentTarget.value,
                });
              }}
            />
            <Input
              label={t('Given Name')}
              value={searchCondition.customerGivenName}
              onChange={(event) => {
                setSearchCondition({
                  ...searchCondition,
                  customerGivenName: event.currentTarget.value,
                });
              }}
            />
          </Modal.Box>
        ) : (
          <Modal.Box column="two">
            <Input
              label={t('Given Name')}
              value={searchCondition.customerGivenName}
              onChange={(event) => {
                setSearchCondition({
                  ...searchCondition,
                  customerGivenName: event.currentTarget.value,
                });
              }}
            />
            <Input
              label={t('Family Name')}
              value={searchCondition.customerFamilyName}
              onChange={(event) => {
                setSearchCondition({
                  ...searchCondition,
                  customerFamilyName: event.currentTarget.value,
                });
              }}
            />
          </Modal.Box>
        )}

        <Modal.Box column="two">
          <Input
            label={t('Phone')}
            value={searchCondition.customerPhone}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                customerPhone: event.currentTarget.value,
              });
            }}
          />

          <Input
            label={t('Email')}
            value={searchCondition.customerEmail}
            onChange={(event) => {
              setSearchCondition({
                ...searchCondition,
                customerEmail: event.currentTarget.value,
              });
            }}
          />
        </Modal.Box>

        {operationAllowed(activeUser, 'write', 'reservationConfirmation') ? (
          <>
            <Modal.Box>
              <MultiSelect
                label={t('Booking Source')}
                options={bookingSourceOptions}
                selectedValues={searchCondition.bookingSourceTypes}
                onChange={changeBookingSource}
              />
            </Modal.Box>

            <Modal.Box>
              <MultiSelect
                search={true}
                label={t('Agent')}
                options={agentOptions}
                selectedValues={searchCondition.agentIds}
                onChange={changeAgentId}
              />
            </Modal.Box>

            <Modal.Box>
              <MultiSelect
                search={true}
                label={t('Group')}
                options={groupOptions}
                selectedValues={searchCondition.groupIds}
                onChange={changeGroupIds}
              />
            </Modal.Box>
          </>
        ) : (
          <>
            <Modal.Box>
              <MultiSelect
                label={t('Supplier')}
                options={supplierOptions}
                selectedValues={searchCondition.supplierIds}
                onChange={changeSupplierId}
              />
            </Modal.Box>
          </>
        )}

        {presetSearchConditionsEnabledForAccount(activeUser) && (
          <>
            <Modal.Box>
              <MultiSelect
                label={t('Languages')}
                options={languageOptions}
                selectedValues={searchCondition.reservationLanguages}
                onChange={reservationLanguagesChangeHandler}
              />
            </Modal.Box>
            <Modal.Box>
              <Checkbox
                label={t('Error when sending emails')}
                checked={searchCondition.mostRecentEmailBounced}
                onChange={toggleMostRecentEmailBounced}
              />
            </Modal.Box>
          </>
        )}

        {annualPassOnly && (
          <Modal.Box>
            <FieldWrapper label={t('Automatic Continuing Status')}>
              <Modal.Box column="three">
                <Radio
                  label={t('None')}
                  checked={!searchCondition.automaticContinuingStatus}
                  onChange={() => {
                    setSearchCondition({
                      ...searchCondition,
                      automaticContinuingStatus: null,
                    });
                  }}
                />
                <Radio
                  label={t('ON')}
                  checked={searchCondition.automaticContinuingStatus == 'ON'}
                  onChange={() => {
                    setSearchCondition({
                      ...searchCondition,
                      automaticContinuingStatus: 'ON',
                    });
                  }}
                />
                <Radio
                  label={t('OFF')}
                  checked={searchCondition.automaticContinuingStatus == 'OFF'}
                  onChange={() => {
                    setSearchCondition({
                      ...searchCondition,
                      automaticContinuingStatus: 'OFF',
                    });
                  }}
                />
              </Modal.Box>
            </FieldWrapper>
          </Modal.Box>
        )}

        {!annualPassOnly && (
          <Modal.Box>
            <Input
              label={t('Pickup/Checkin Location')}
              value={searchCondition.pickupCheckinLocationName}
              onChange={(event) => {
                setSearchCondition({
                  ...searchCondition,
                  pickupCheckinLocationName: event.currentTarget.value,
                });
              }}
            />
          </Modal.Box>
        )}

        {hasSubscription(activeUserOrganization, 'feature-digital-waiver') &&
          !isSubscriptionCancelled(
            activeUserOrganization,
            'feature-digital-waiver'
          ) && (
            <Modal.Box>
              <MultiSelect
                label={t('Waiver Status')}
                options={waiverStatusOptions}
                selectedValues={searchCondition.waiverCompletionStatuses}
                onChange={({ value }) => {
                  setSearchCondition({
                    ...searchCondition,
                    waiverCompletionStatuses:
                      value as SearchReservationsRequest['waiverCompletionStatuses'],
                  });
                }}
              />
            </Modal.Box>
          )}

        {(hasSubscription(activeUserOrganization, 'feature-qr-checkin') ||
          hasSubscription(activeUserOrganization, 'feature-e-ticket')) && (
          <Modal.Box>
            <MultiSelect
              label={t('Checkin Status')}
              options={checkinStatusOptions}
              selectedValues={searchCondition.checkinStatuses}
              onChange={({ value }) => {
                setSearchCondition({
                  ...searchCondition,
                  checkinStatuses:
                    value as SearchReservationsRequest['checkinStatuses'],
                });
              }}
            />
          </Modal.Box>
        )}

        {note && <Modal.Box>{note}</Modal.Box>}
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onReset();
          }}
        >
          {t('Clear')}
        </Button.Cancel>
        <Button.Submit
          onClick={() => {
            onSearch();
            setShowModal(false);
          }}
        >
          {searchButtonText || t('Search')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
