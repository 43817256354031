import * as React from 'react';

import { getDateRangeForPreset } from 'client/libraries/util/dateRangePresets';

import { DateRange } from '../MobileOrder/MobileOrderDashboard/util';

export const MapDashboardContext = React.createContext<{
  time: number;
  setTime: (time: number) => void;

  baseDateRange: DateRange;
  comparisonDateRange: DateRange | null;
}>({
  time: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTime: () => {},
  baseDateRange: getDateRangeForPreset('PREV_7_DAYS'),
  comparisonDateRange: null,
});
