import { Dispatch, SetStateAction, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import { getBookingSourceOptions } from 'client/libraries/util/getBookingSourceOptions';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import {
  operationAllowed,
  presetSearchConditionsEnabledForAccount,
} from 'shared/models/access';
import {
  SalesStatus,
  getProductSalesStatus,
} from 'client/libraries/util/getProductSalesStatus';
import {
  summariesWithBookmarksSelector,
  supplierOptionsSelector,
} from 'client/reducers/products';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { Radio } from 'client/components/v3/Form/Radio';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { ReduxState } from 'client/reducers';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';
import {
  DateFilterPreset,
  getDateFilterPresetOptions,
} from 'client/libraries/util/searchReservations';
import { getSourceLanguageOptions } from 'client/libraries/util/getSourceLanguageOptions';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
  agentOptionsSelector,
} from 'client/reducers/user';
import { useOrderByOptions } from 'client/hooks/useOrderByOptions';
import { fetchProducts } from 'client/actions/products';
import { setLastReservationSearchQuery } from 'client/actions/reservationSearch';
import { SavedSearchConditionsList } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownloadInfo/SavedSearchConditionsList';
import { getPresetSearchReservationsRequest } from 'client/libraries/util/getPresetSearchReservationsRequest';
import {
  SearchReservationsRequest,
  SearchReservationsRequestKey,
} from 'client/pages/v3/Reservation/ReservationDataDownload/utils';
import { Select } from 'client/pages/v3/Reservation/ReservationDataDownload/Select/Select';
import styles from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownload.module.css';

import { Modal } from './Modal';

export type BULK_OPERATION = 'CANCEL' | 'DECLINE' | undefined;

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const SearchConditionsModal = ({ setShowModal }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const appliedSearchCondition = useSelector(
    (state: ReduxState) => (state as any).reservationSearch.lastQuery
  );
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const [searchCondition, setSearchCondition] =
    useState<SearchReservationsRequest>(appliedSearchCondition);

  useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUser]);

  // Initialize value for form input fields
  const orderByOptions = useOrderByOptions();
  const [productStatus, setProductStatus] = useState<SalesStatus | ''>(
    'ON_SALE'
  );
  const sourceLanguages = getSourceLanguageOptions(t);
  const dateFilterPresetOptions = [
    {
      value: 'NONE',
      text: t('None'),
    },
    ...getDateFilterPresetOptions(t),
  ];
  const allProducts = useSelector(summariesWithBookmarksSelector);
  const productOptions = useMemo(() => {
    let salesStatusProducts;
    if (productStatus && presetSearchConditionsEnabledForAccount(activeUser)) {
      salesStatusProducts = allProducts.filter((product) => {
        return (
          getProductSalesStatus(product) === productStatus ||
          (searchCondition.productIds &&
            searchCondition.productIds.includes(product.id))
        );
      });
    } else {
      salesStatusProducts = allProducts;
    }

    return salesStatusProducts
      .filter((product) => {
        return (
          false === (product?.annual_pass_mode_settings?.is_enabled ?? false)
        );
      })
      .map((product) => {
        return {
          value: product.id,
          key: product.id,
          text: presetSearchConditionsEnabledForAccount(activeUser)
            ? `${
                product.internal_product_name || product.product_name || ''
              }  (${product.supplier_reference})`
            : getVerboseDisplayProductName(product),
        };
      });
  }, [allProducts, productStatus]);

  const bookingStatusOptions = getBookingStatusOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const bookingSourceOptions = getBookingSourceOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const agentOptions = useSelector(agentOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const groupBookingTemplates = useSelector(
    (state: ReduxState) => state.groupBookingTemplates.all
  );
  const groupOptions = groupBookingTemplates.map((groupBookingTemplate) => ({
    value: groupBookingTemplate.id ?? '',
    text: groupBookingTemplate.name ?? '',
  }));

  const supplierOptions = useSelector(supplierOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const waiverStatusOptions = [
    {
      value: 'NOT_APPLICABLE',
      text: t('Waiver Not Required'),
    },
    {
      value: 'UNSIGNED',
      text: t('Not Started'),
    },
    {
      value: 'PARTIALLY_SIGNED',
      text: t('Partially Complete'),
    },
    {
      value: 'COMPLETED',
      text: t('Completed'),
    },
  ];

  const checkinStatusOptions = [
    { value: 'NOT_CHECKED_IN', text: t('Checkin Not Started') },
    { value: 'IN_PROGRESS', text: t('Partially Checked-in') },
    { value: 'CHECKED_IN', text: t('Checkin Complete') },
  ];

  const createDropdownChangeHandler =
    (field: SearchReservationsRequestKey) => (selectedOption: string) =>
      setSearchCondition({ ...searchCondition, [field]: selectedOption });
  const createMultiDropdownChangeHandler =
    (field: SearchReservationsRequestKey) => (selectedOptions: string[]) =>
      setSearchCondition({ ...searchCondition, [field]: [...selectedOptions] });
  const createFieldChangeHandler =
    (field: SearchReservationsRequestKey) => (value: string) =>
      setSearchCondition({ ...searchCondition, [field]: value });
  const createDateRangeInputChangeHandler =
    (
      dateFromField: SearchReservationsRequestKey,
      dateToField: SearchReservationsRequestKey
    ) =>
    (dates: [string | null, string | null]) => {
      const [startDate, endDate] = dates;
      setSearchCondition({
        ...searchCondition,
        [dateFromField]: startDate,
        [dateToField]: endDate,
        dateFilterPreset: null,
      });
    };

  const changeOrderBy = createDropdownChangeHandler('orderBy');
  const changeProductId = createMultiDropdownChangeHandler('productIds');
  const changeParticipationDate = createDateRangeInputChangeHandler(
    'participationDateFrom',
    'participationDateTo'
  );
  const changeBookedDate = createDateRangeInputChangeHandler(
    'bookedDateFrom',
    'bookedDateTo'
  );
  const changeLastUpdatedDate = createDateRangeInputChangeHandler(
    'lastUpdatedDateFrom',
    'lastUpdatedDateTo'
  );
  const changeReservationStatus = createMultiDropdownChangeHandler('statuses');
  const changeSupplierOrAgentReference = createFieldChangeHandler(
    'supplierOrAgentReference'
  );
  const changeAgentReference = createFieldChangeHandler('agentReference');
  const changeSupplierReference = createFieldChangeHandler('supplierReference');
  const changeCustomerGivenName = createFieldChangeHandler('customerGivenName');
  const changeCustomerFamilyName =
    createFieldChangeHandler('customerFamilyName');
  const changeCustomerEmail = createFieldChangeHandler('customerEmail');
  const changeCustomerPhone = createFieldChangeHandler('customerPhone');
  const changeBookingSource =
    createMultiDropdownChangeHandler('bookingSourceTypes');
  const changeAgentIds = createMultiDropdownChangeHandler('agentIds');
  const changeGroupIds = createMultiDropdownChangeHandler('groupIds');
  const changeSupplierIds = createMultiDropdownChangeHandler('supplierIds');
  const changeReservationLanguage = createMultiDropdownChangeHandler(
    'reservationLanguages'
  );
  const toggleMostRecentEmailBounced = () => {
    setSearchCondition({
      ...searchCondition,
      mostRecentEmailBounced: !searchCondition.mostRecentEmailBounced,
    });
  };
  const changePickupCheckinLocationName = createFieldChangeHandler(
    'pickupCheckinLocationName'
  );
  const changeWaiverCompletionStatuses = createMultiDropdownChangeHandler(
    'waiverCompletionStatuses'
  );
  const changeCheckinStatuses =
    createMultiDropdownChangeHandler('checkinStatuses');

  const onApply = () => {
    dispatch(setLastReservationSearchQuery(searchCondition));
  };
  const onReset = () => {
    setSearchCondition({
      presetKey: '',
      orderBy: 'last_updated_desc',
      agentReference: '',
      agentIds: [],
      supplierReference: '',
      id: '',
      statuses: [],
      customerGivenName: '',
      customerFamilyName: '',
      customerPhone: '',
      customerEmail: '',
      bookingSourceTypes: [],
      groupIds: [],
      supplierIds: [],
      productIds: [],
      bookedDateFrom: '',
      bookedDateTo: '',
      participationDateFrom: '',
      participationDateTo: '',
      lastUpdatedDateFrom: '',
      lastUpdatedDateTo: '',
      dateFilterPreset: null,
      supplierOrAgentReference: '',
      reservationLanguages: [],
      mostRecentEmailBounced: false,
      pickupCheckinLocationName: '',
      waiverCompletionStatuses: [],
      checkinStatuses: [],
      annualPassOnly: false,
      expirationPresetKey: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      expirationDateFilterPreset: null,
      automaticContinuingStatus: null,
      partnershipReservationOnly: false,
    });
  };
  const changePreset = (preset: string) => {
    const presetSearchCondition = getPresetSearchReservationsRequest(
      activeUserOrganization,
      preset,
      moment()
    );
    if (presetSearchCondition) {
      setSearchCondition(presetSearchCondition);
      // When preset is selected, make it as if search button is clicked
      onApply();
    }
  };

  return (
    <>
      <Modal
        title={t('Search Conditions')}
        subtitle={t(
          '* Search conditions are also applied on the the reservation list.'
        )}
        open={true}
        onClose={() => {
          setShowModal(false);
        }}
        headerActionChildren={
          <>
            {presetSearchConditionsEnabledForAccount(activeUser) &&
              (
                activeUserOrganization?.account_reservation_search_settings
                  ?.presets ?? []
              ).length > 0 && (
                <div className={styles['p-search__header__actions']}>
                  <SavedSearchConditionsList
                    selectedOption={searchCondition.presetKey}
                    onChange={changePreset}
                  />
                </div>
              )}
          </>
        }
        rightActionChildren={
          <>
            <Button
              text={t('Cancel')}
              uiType="bg"
              size="md"
              color="white"
              onClick={() => {
                onReset();
                setShowModal(false);
              }}
            />
            <Button
              type="submit"
              text={t('Save')}
              uiType="bg"
              size="md"
              color="primary"
              onClick={() => {
                onApply();
                setShowModal(false);
              }}
            />
          </>
        }
        style={{ width: '600px', height: 'fit-content', maxHeight: '85%' }}
      >
        <div className={styles['p-downloadModal']}>
          <div className={styles['p-downloadModal__item']}>
            <p className={styles['p-downloadModal__item__ttl']}>
              {t('Order By')}
            </p>
            <div className={styles['p-downloadModal__item__body']}>
              <Select
                options={orderByOptions}
                selectedOption={searchCondition.orderBy}
                onChange={changeOrderBy}
              />
            </div>
          </div>

          <div className={styles['p-downloadModal__item']}>
            <p className={styles['p-downloadModal__item__ttl']}>
              {t('Product Name')}
            </p>
            <div className={styles['p-downloadModal__item__body']}>
              <div className={styles['p-downloadModal__item__body__check']}>
                <p>{t('Product Status')}</p>
                {presetSearchConditionsEnabledForAccount(activeUser) && (
                  <div>
                    <Radio
                      label={t('On Sale')}
                      checked={productStatus === 'ON_SALE'}
                      onChange={() => {
                        setProductStatus('ON_SALE');
                      }}
                      size="sm"
                    />
                    <Radio
                      label={t('Not On Sale')}
                      checked={productStatus === 'LIST_ONLY'}
                      onChange={() => {
                        setProductStatus('LIST_ONLY');
                      }}
                      size="sm"
                    />
                    <Radio
                      label={t('Not Listed')}
                      checked={productStatus === 'NOT_LISTED'}
                      onChange={() => {
                        setProductStatus('NOT_LISTED');
                      }}
                      size="sm"
                    />
                  </div>
                )}
              </div>
              <MultiDropdown
                options={productOptions}
                onChange={changeProductId}
                selectedOptions={searchCondition.productIds}
                searchable={true}
              />
            </div>
          </div>

          <div className={styles['p-downloadModal__item']}>
            <p className={styles['p-downloadModal__item__ttl']}>
              {t('Booking Status')}
            </p>
            <div className={styles['p-downloadModal__item__body']}>
              <MultiDropdown
                options={bookingStatusOptions}
                onChange={changeReservationStatus}
                selectedOptions={searchCondition.statuses}
              />
            </div>
          </div>

          {presetSearchConditionsEnabledForAccount(activeUser) ? (
            <div className={styles['p-downloadModal__item']}>
              <p className={styles['p-downloadModal__item__ttl']}>
                {t('Confirmation/Application Number')}
              </p>
              <div className={styles['p-downloadModal__item__body']}>
                <TextField
                  onChange={changeSupplierOrAgentReference}
                  value={searchCondition.supplierOrAgentReference}
                />
              </div>
            </div>
          ) : (
            <div className={styles['p-downloadModal__item']}>
              <p className={styles['p-downloadModal__item__ttl']}>
                {t('Confirmation/Application Number')}
              </p>
              <div className={styles['p-downloadModal__item__body']}>
                <TextField
                  label={t('Application Number')}
                  onChange={changeAgentReference}
                  value={searchCondition.agentReference}
                />
                <TextField
                  label={t('Confirmation Number')}
                  onChange={changeSupplierReference}
                  value={searchCondition.supplierReference}
                />
                <TextField
                  label="#"
                  onChange={changeSupplierOrAgentReference}
                  value={searchCondition.id}
                />
              </div>
            </div>
          )}

          <div className={styles['p-downloadModal__item']}>
            <p className={styles['p-downloadModal__item__ttl']}>
              {t('Date Preset')}
            </p>
            <div className={styles['p-downloadModal__item__body']}>
              <SingleDropdown
                options={dateFilterPresetOptions}
                selectedOption={searchCondition.dateFilterPreset ?? 'NONE'}
                onChange={(value) => {
                  setSearchCondition({
                    ...searchCondition,
                    dateFilterPreset:
                      value === 'NONE' ? null : (value as DateFilterPreset),
                    lastUpdatedDateFrom: '',
                    lastUpdatedDateTo: '',
                    bookedDateFrom: '',
                    bookedDateTo: '',
                    participationDateFrom: '',
                    participationDateTo: '',
                  });
                }}
              />
            </div>
          </div>

          <div className={styles['p-downloadModal__item']}>
            <p className={styles['p-downloadModal__item__ttl']}>
              {t('Participation Date')}
            </p>
            <div className={styles['p-downloadModal__item__body']}>
              <DateRangeInput
                onChange={changeParticipationDate}
                dateFrom={searchCondition.participationDateFrom}
                dateTo={searchCondition.participationDateTo}
              />
            </div>
          </div>
          <div className={styles['p-downloadModal__item']}>
            <p className={styles['p-downloadModal__item__ttl']}>
              {t('Booking Date')}
            </p>
            <div className={styles['p-downloadModal__item__body']}>
              <DateRangeInput
                onChange={changeBookedDate}
                dateFrom={searchCondition.bookedDateFrom}
                dateTo={searchCondition.bookedDateTo}
              />
            </div>
          </div>
          <div className={styles['p-downloadModal__item']}>
            <p className={styles['p-downloadModal__item__ttl']}>
              {t('Last Updated Date')}
            </p>
            <div className={styles['p-downloadModal__item__body']}>
              <DateRangeInput
                onChange={changeLastUpdatedDate}
                dateFrom={searchCondition.lastUpdatedDateFrom}
                dateTo={searchCondition.lastUpdatedDateTo}
              />
            </div>
          </div>

          {locale === 'ja' ? (
            <div className={styles['p-downloadModal__item']}>
              <div className={styles['p-downloadModal__item__flex']}>
                <div>
                  <p className={styles['p-downloadModal__item__ttl']}>
                    {t('Family Name')}
                  </p>
                  <div className={styles['p-downloadModal__item__body']}>
                    <TextField
                      onChange={changeCustomerFamilyName}
                      value={searchCondition.customerFamilyName}
                    />
                  </div>
                </div>
                <div>
                  <p className={styles['p-downloadModal__item__ttl']}>
                    {t('Given Name')}
                  </p>
                  <div className={styles['p-downloadModal__item__body']}>
                    <TextField
                      onChange={changeCustomerGivenName}
                      value={searchCondition.customerGivenName}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles['p-downloadModal__item']}>
              <div className={styles['p-downloadModal__item__flex']}>
                <div>
                  <p className={styles['p-downloadModal__item__ttl']}>
                    {t('Given Name')}
                  </p>
                  <div className={styles['p-downloadModal__item__body']}>
                    <TextField
                      onChange={changeCustomerGivenName}
                      value={searchCondition.customerGivenName}
                    />
                  </div>
                </div>
                <div>
                  <p className={styles['p-downloadModal__item__ttl']}>
                    {t('Family Name')}
                  </p>
                  <div className={styles['p-downloadModal__item__body']}>
                    <TextField
                      onChange={changeCustomerFamilyName}
                      value={searchCondition.customerFamilyName}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={styles['p-downloadModal__item']}>
            <div className={styles['p-downloadModal__item__flex']}>
              <div>
                <p className={styles['p-downloadModal__item__ttl']}>
                  {t('Phone')}
                </p>
                <div className={styles['p-downloadModal__item__body']}>
                  <TextField
                    onChange={changeCustomerPhone}
                    value={searchCondition.customerPhone}
                  />
                </div>
              </div>
              <div>
                <p className={styles['p-downloadModal__item__ttl']}>
                  {t('Email')}
                </p>
                <div className={styles['p-downloadModal__item__body']}>
                  <TextField
                    type="email"
                    label={t('Email')}
                    onChange={changeCustomerEmail}
                    value={searchCondition.customerEmail}
                  />
                </div>
              </div>
            </div>
          </div>

          {operationAllowed(activeUser, 'write', 'reservationConfirmation') ? (
            <>
              <div className={styles['p-downloadModal__item']}>
                <p className={styles['p-downloadModal__item__ttl']}>
                  {t('Booking Source')}
                </p>
                <div className={styles['p-downloadModal__item__body']}>
                  <MultiDropdown
                    options={bookingSourceOptions}
                    onChange={changeBookingSource}
                    selectedOptions={searchCondition.bookingSourceTypes}
                  />
                </div>
              </div>
              <div className={styles['p-downloadModal__item']}>
                <p className={styles['p-downloadModal__item__ttl']}>
                  {t('Agent')}
                </p>
                <div className={styles['p-downloadModal__item__body']}>
                  <MultiDropdown
                    options={agentOptions}
                    onChange={changeAgentIds}
                    selectedOptions={searchCondition.agentIds}
                    searchable={true}
                  />
                </div>
              </div>
              <div className={styles['p-downloadModal__item']}>
                <p className={styles['p-downloadModal__item__ttl']}>
                  {t('Group')}
                </p>
                <div className={styles['p-downloadModal__item__body']}>
                  <MultiDropdown
                    options={groupOptions}
                    onChange={changeGroupIds}
                    selectedOptions={searchCondition.groupIds}
                    searchable={true}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={styles['p-downloadModal__item']}>
              <p className={styles['p-downloadModal__item__ttl']}>
                {t('Supplier')}
              </p>
              <div className={styles['p-downloadModal__item__body']}>
                <MultiDropdown
                  label={t('Supplier')}
                  options={supplierOptions}
                  onChange={changeSupplierIds}
                  selectedOptions={searchCondition.supplierIds}
                  searchable={true}
                />
              </div>
            </div>
          )}

          {presetSearchConditionsEnabledForAccount(activeUser) && (
            <>
              <div className={styles['p-downloadModal__item']}>
                <p className={styles['p-downloadModal__item__ttl']}>
                  {t('Languages')}
                </p>
                <div className={styles['p-downloadModal__item__body']}>
                  <MultiDropdown
                    options={sourceLanguages}
                    onChange={changeReservationLanguage}
                    selectedOptions={searchCondition.reservationLanguages}
                  />
                </div>
              </div>
              <div className={styles['p-downloadModal__item']}>
                <p className={styles['p-downloadModal__item__ttl']}>
                  {t('Error when sending emails')}
                </p>
                <div className={styles['p-downloadModal__item__body']}>
                  <Checkbox
                    label={t('Error when sending emails')}
                    checked={searchCondition.mostRecentEmailBounced}
                    onChange={toggleMostRecentEmailBounced}
                    size="sm"
                  />
                </div>
              </div>
            </>
          )}

          <>
            <div className={styles['p-downloadModal__item']}>
              <p className={styles['p-downloadModal__item__ttl']}>
                {t('Automatic Continuing Status')}
              </p>
              <div className={styles['p-downloadModal__item__body']}>
                <div className={styles['p-downloadModal__item__body__check']}>
                  <div>
                    <Radio
                      label={t('None')}
                      name="productStatus"
                      checked={!searchCondition.automaticContinuingStatus}
                      onChange={() => {
                        setSearchCondition({
                          ...searchCondition,
                          automaticContinuingStatus: null,
                        });
                      }}
                      size="sm"
                    />
                    <Radio
                      label={t('ON')}
                      name="productStatus"
                      checked={
                        searchCondition.automaticContinuingStatus === 'ON'
                      }
                      onChange={() => {
                        setSearchCondition({
                          ...searchCondition,
                          automaticContinuingStatus: 'ON',
                        });
                      }}
                      size="sm"
                    />
                    <Radio
                      label={t('OFF')}
                      name="productStatus"
                      checked={
                        searchCondition.automaticContinuingStatus === 'OFF'
                      }
                      onChange={() => {
                        setSearchCondition({
                          ...searchCondition,
                          automaticContinuingStatus: 'OFF',
                        });
                      }}
                      size="sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
          <div className={styles['p-downloadModal__item']}>
            <p className={styles['p-downloadModal__item__ttl']}>
              {t('Pickup/Checkin Location')}
            </p>
            <div className={styles['p-downloadModal__item__body']}>
              <TextField
                label={t('Pickup/Checkin Location')}
                onChange={changePickupCheckinLocationName}
                value={searchCondition.pickupCheckinLocationName}
              />
            </div>
          </div>

          {hasSubscription(activeUserOrganization, 'feature-digital-waiver') &&
            !isSubscriptionCancelled(
              activeUserOrganization,
              'feature-digital-waiver'
            ) && (
              <div className={styles['p-downloadModal__item']}>
                <p className={styles['p-downloadModal__item__ttl']}>
                  {t('Waiver Status')}
                </p>
                <div className={styles['p-downloadModal__item__body']}>
                  <MultiDropdown
                    label={t('Waiver Status')}
                    options={waiverStatusOptions}
                    onChange={changeWaiverCompletionStatuses}
                    selectedOptions={searchCondition.waiverCompletionStatuses}
                  />
                </div>
              </div>
            )}

          {(hasSubscription(activeUserOrganization, 'feature-qr-checkin') ||
            hasSubscription(activeUserOrganization, 'feature-e-ticket')) && (
            <div className={styles['p-downloadModal__item']}>
              <p className={styles['p-downloadModal__item__ttl']}>
                {t('Checkin Status')}
              </p>
              <div className={styles['p-downloadModal__item__body']}>
                <MultiDropdown
                  label={t('Checkin Status')}
                  options={checkinStatusOptions}
                  onChange={changeCheckinStatuses}
                  selectedOptions={searchCondition.checkinStatuses}
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
