import clsx from 'clsx';
import React from 'react';

import styles from './PopularPinsGadget.module.css';

interface LocationItemProps {
  number: string;
  name: string;
  users: number;
  isActive?: boolean;
  onClick?: () => void;
}

export const LocationItem: React.FC<LocationItemProps> = ({
  number,
  name,
  users,
  isActive = false,
  onClick,
}) => {
  return (
    <tr
      className={`${styles.locationItem} ${
        isActive ? styles.activeLocation : ''
      }`}
      onClick={onClick}
    >
      <td className={clsx(styles.tableCell, styles.locationNumber)}>
        {number}
      </td>
      <td className={clsx(styles.tableCell, styles.locationName)}>{name}</td>
      <td className={clsx(styles.tableCell, styles.userCount)}>
        {users}
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/28eb06cd1f8a43205ea77ebc6c2e73a861df7e688616fee5a2a3494fb4aa0bcd?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
          className={styles.icon}
          alt=""
        />
      </td>
      <td className={styles.tableCell}>{'-'}</td>
    </tr>
  );
};
