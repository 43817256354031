import { combineReducers } from 'redux';

import {
  SEARCH_RESERVATIONS_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  LOGOUT_SUCCESS,
  SEARCH_RESERVATIONS_FAILURE,
  SEARCH_RESERVATIONS_REQUEST,
  FETCH_RESERVATION_SEARCH_CSV_REQUEST,
  FETCH_RESERVATION_SEARCH_CSV_FAILURE,
  FETCH_RESERVATION_SEARCH_CSV_SUCCESS,
  SET_LAST_RESERVATION_SEARCH_QUERY,
  SET_LAST_ANNUAL_PASS_RESERVATION_SEARCH_QUERY,
  BULK_CANCEL_RESERVATION_SUCCESS,
  BULK_CANCEL_RESERVATION_REQUEST,
  BULK_CANCEL_RESERVATION_FAILURE,
  SET_SHOULD_SHOW_DETAILED_CONDITIONS,
} from 'client/constants/ActionTypes';
import { SearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import * as Swagger from 'shared/models/swagger';

const defaultQuery: SearchReservationsRequest = {
  presetKey: '',
  agentReference: '',
  agentIds: [],
  supplierReference: '',
  id: '',
  statuses: [],
  customerGivenName: '',
  customerFamilyName: '',
  customerPhone: '',
  customerEmail: '',
  bookingSourceTypes: [],
  groupIds: [],
  supplierIds: [],
  productIds: [],
  bookedDateFrom: '',
  bookedDateTo: '',
  participationDateFrom: '',
  participationDateTo: '',
  lastUpdatedDateFrom: '',
  lastUpdatedDateTo: '',
  dateFilterPreset: 'LAST_UPDATED_7_DAYS',
  orderBy: 'last_updated_desc',
  supplierOrAgentReference: '',
  reservationLanguages: [],
  mostRecentEmailBounced: false,
  pickupCheckinLocationName: '',
  waiverCompletionStatuses: [],
  checkinStatuses: [],
  annualPassOnly: false,
  expirationPresetKey: '',
  expirationDateFrom: '',
  expirationDateTo: '',
  expirationDateFilterPreset: null,
  automaticContinuingStatus: null,
  partnershipReservationOnly: false,
};

const lastQuery = (state = defaultQuery, action: any) => {
  switch (action.type) {
    case SET_LAST_RESERVATION_SEARCH_QUERY:
      return action.searchFilters;

    default:
      return state;
  }
};

const annualPassLastQuery = (
  state = { ...defaultQuery, annualPassOnly: true },
  action: any
) => {
  switch (action.type) {
    case SET_LAST_ANNUAL_PASS_RESERVATION_SEARCH_QUERY:
      return action.searchFilters;

    default:
      return state;
  }
};

const all = (state: Swagger.Reservation[] = [], action: any) => {
  switch (action.type) {
    case SEARCH_RESERVATIONS_SUCCESS: {
      const resp = action.response as Swagger.SearchReservationsResponse;
      return resp.reservations || [];
    }
    case BULK_CANCEL_RESERVATION_SUCCESS: {
      const resp = action.response as Swagger.BulkCancelReservationResponse;
      const newState: Swagger.Reservation[] = [];
      state.forEach((stateReservation) => {
        const reservation = resp?.reservations?.find(
          (cancelReservation) => cancelReservation.id === stateReservation.id
        );
        if (reservation) {
          newState.push(reservation);
        } else {
          newState.push(stateReservation);
        }
      });
      return newState;
    }
    default:
      return state;
  }
};

const totalHits = (state = 0, action: any) => {
  switch (action.type) {
    case SEARCH_RESERVATIONS_SUCCESS: {
      const resp = action.response as Swagger.SearchReservationsResponse;
      return resp.total ?? 0;
    }
    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case SEARCH_RESERVATIONS_SUCCESS:
    case SEARCH_RESERVATIONS_REQUEST:
      return '';
    case SEARCH_RESERVATIONS_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const loading = (state = false, action: any) => {
  switch (action.type) {
    case SEARCH_RESERVATIONS_REQUEST:
    case BULK_CANCEL_RESERVATION_REQUEST:
      return true;
    case SEARCH_RESERVATIONS_SUCCESS:
    case SEARCH_RESERVATIONS_FAILURE:
    case BULK_CANCEL_RESERVATION_SUCCESS:
    case BULK_CANCEL_RESERVATION_FAILURE:
      return false;
    default:
      return state;
  }
};

const csvLoading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_RESERVATION_SEARCH_CSV_REQUEST:
      return true;

    case FETCH_RESERVATION_SEARCH_CSV_SUCCESS:
    case FETCH_RESERVATION_SEARCH_CSV_FAILURE:
      return false;

    default:
      return state;
  }
};

const shouldShowDetailedConditions = (state = false, action: any) => {
  switch (action.type) {
    case SET_SHOULD_SHOW_DETAILED_CONDITIONS:
      return action.flag;

    default:
      return state;
  }
};

type State = {
  all: Swagger.Reservation[];
  csvLoading: boolean;
  loading: boolean;
  totalHits: number;
  error: string;
  lastQuery: SearchReservationsRequest;
  annualPassLastQuery: SearchReservationsRequest;
  shouldShowDetailedConditions: boolean;
};

const reducer = combineReducers<State>({
  all,
  csvLoading,
  loading,
  totalHits,
  error,
  lastQuery,
  annualPassLastQuery,
  shouldShowDetailedConditions,
});

export const reservationSearch = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
