import { WaitTime } from 'shared/models/swagger';

export interface WaitTimeFormValues {
  locationType: 'RESTAURANT' | 'ATTRACTION';
  restaurantId: string;
  mapId: string;
  pinKey: string;
  dataUpdateType: 'MANUAL' | 'AUTO';
  automaticDataUpdateConfig: string;
  waitTimeInMinutes: string;
  isClosed: boolean;
  enabled: boolean;
}

export const getInitialWaitTimeFormValues = (): WaitTimeFormValues => {
  return {
    locationType: 'ATTRACTION',
    restaurantId: '',
    mapId: '',
    pinKey: '',
    dataUpdateType: 'MANUAL',
    automaticDataUpdateConfig: '',
    waitTimeInMinutes: '0',
    isClosed: false,
    enabled: true,
  };
};

export const convertSwaggerWaitTimeToFormValues = (
  waitTime: WaitTime
): WaitTimeFormValues => {
  return {
    locationType: waitTime.restaurant_id ? 'RESTAURANT' : 'ATTRACTION',
    restaurantId: waitTime.restaurant_id ?? '',
    mapId: waitTime.map_id ?? '',
    pinKey: waitTime.pin_key ?? '',
    dataUpdateType: waitTime.update_automatically ? 'AUTO' : 'MANUAL',
    automaticDataUpdateConfig: waitTime.data_source_json ?? '',
    waitTimeInMinutes: `${waitTime.wait_time_minutes ?? 0}`,
    isClosed: waitTime.is_closed || false,
    enabled: waitTime.enabled || false,
  };
};

export const convertFormValuesToSwaggerWaitTime = (
  waitTime: WaitTimeFormValues
): WaitTime => {
  return {
    restaurant_id:
      waitTime.locationType === 'RESTAURANT' ? waitTime.restaurantId : '',
    map_id: waitTime.locationType === 'ATTRACTION' ? waitTime.mapId : '',
    pin_key: waitTime.locationType === 'ATTRACTION' ? waitTime.pinKey : '',
    data_source_json: waitTime.automaticDataUpdateConfig,
    update_automatically: waitTime.dataUpdateType === 'AUTO',
    wait_time_minutes: Number(waitTime.waitTimeInMinutes),
    enabled: waitTime.enabled,
    is_closed: waitTime.isClosed,
  };
};
