import * as React from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import * as Swagger from 'shared/models/swagger';
import { httpRequestHeadersSelector } from 'client/reducers/accounts';
import { createCancellableFetcher } from 'client/libraries/util/createCancellableFetcher';

interface DateRange {
  start: string;
  end: string;
}

export const useMapGuestJourneys = (
  dateRange: DateRange,
  selectedMapId: string
): {
  data: Swagger.DigitalMapGuestJourneySummary[] | null;
  isLoading: boolean;
  error: string;
} => {
  const fetcherRef = React.useRef(createCancellableFetcher());
  const headers = useSelector(httpRequestHeadersSelector);

  const queryParams = convertParamsToMapGuestJourneysQueryParams(dateRange);

  const { data: swrData, error } =
    useSWR<Swagger.ListDigitalMapGuestJourneysResponse>(
      [
        selectedMapId ? `/api/digitalmaps/${selectedMapId}/guestjourneys` : '',
        JSON.stringify(queryParams),
        JSON.stringify(headers),
      ],
      fetcherRef.current
    );

  React.useEffect(() => {
    // Cleanup function to abort any pending requests when the component unmounts
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      fetcherRef.current('', '', '').catch(() => {});
    };
  }, []);

  return {
    data: swrData?.guest_journeys ?? null,
    isLoading: Boolean(!error && !swrData),
    error: error,
  };
};

const convertParamsToMapGuestJourneysQueryParams = (
  dateRange: DateRange
): {
  start_date: string;
  end_date: string;
} => {
  return {
    start_date: dateRange.start,
    end_date: dateRange.end,
  };
};
