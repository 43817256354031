import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import mobileOrderIcon from 'client/images/map/ic_fork_spoon_white.svg';
import ticketIcon from 'client/images/map/ic_ticket_white.svg';
import pinBlackIcon from 'client/images/map/ic_map_pin_black.svg';
import pinBlueIcon from 'client/images/map/ic_map_pin_blue.svg';
import { DigitalMap, DigitalMapGuestJourney } from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';

import styles from './SessionComponent.module.css';
import { SessionTimeline } from './SessionTimeline';

type Session = Exclude<DigitalMapGuestJourney['sessions'], undefined>[number];
type DigitalMapPin = Exclude<DigitalMap['pins'], undefined>[number];

interface SessionComponentProps {
  sessionName: string;
  highlighted: boolean;
  session: Session;
  expanded: boolean;
  onClick: () => void;
}

export const SessionComponent: React.FC<SessionComponentProps> = ({
  sessionName,
  expanded,
  onClick,
  highlighted,
  session,
}) => {
  const { mapId } = useParams<{
    mapId: string;
  }>();

  const { t } = useTranslation();
  const digitalMap = useSelector((state: ReduxState) => state.digitalMaps.all);
  const selectedMap = digitalMap.find((map) => map.id === mapId);

  // Find the closest pin to the session:
  // - Find average latitude and longitude of the session
  // - Find the pin with the closest latitude and longitude
  const geoEvents = session.events?.filter((e) => e.latitude && e.longitude);
  const avgLatitude = geoEvents?.length
    ? (geoEvents?.reduce((acc, curr) => acc + (curr.latitude ?? 0), 0) ?? 0) /
      geoEvents?.length
    : 0;
  const avgLongitude = geoEvents?.length
    ? (geoEvents?.reduce((acc, curr) => acc + (curr.longitude ?? 0), 0) ?? 0) /
      geoEvents?.length
    : 0;

  let closestPin: DigitalMapPin | null = null;
  let minDistance = Number.MAX_VALUE;

  if (avgLatitude && avgLongitude) {
    for (const pin of selectedMap?.pins ?? []) {
      const distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(avgLatitude, avgLongitude),
          new window.google.maps.LatLng(
            pin.location?.latitude,
            pin.location?.longitude
          )
        );
      if (distance < minDistance && distance < 500) {
        closestPin = pin;
        minDistance = distance;
      }
    }
  }

  const hasTicket = session?.events?.some(
    (event) => event.event_type === 'reservation-thank-you'
  );
  const hasMobileOrder = session?.events?.some(
    (event) => event.event_type === 'mobile-order-purchase'
  );

  return (
    <section
      className={clsx(
        styles.sessionContainer,
        highlighted && styles.highlighted
      )}
      onClick={() => onClick()}
    >
      <div className={clsx(styles.sessionContent, expanded && styles.active)}>
        <div className={styles.sessionDetails}>
          <div className={styles.timeContainer}>
            <time className={styles.sessionTime}>
              {moment(session.time_of_day_start, 'HH:mm')
                .locale('en')
                .format('h:mma')}
            </time>
            <div className={styles.sessionInfo}>
              <div className={styles.sessionNameDuration}>
                <h3 className={styles.sessionName}>{sessionName}</h3>
                <span className={styles.sessionDuration}>
                  {t('{{durationInMinutes}} minutes', {
                    durationInMinutes: Math.floor(
                      (session.duration_seconds ?? 0) / 60
                    ),
                  })}
                </span>
              </div>
            </div>
            <div className={styles.chips}>
              {hasTicket && (
                <div className={clsx(styles.chip, styles.ticket)}>
                  <img
                    src={ticketIcon}
                    alt="Completed"
                    className={styles.chipIcon}
                  />
                </div>
              )}
              {hasMobileOrder && (
                <div
                  className={clsx(styles.chip, styles.mobileOrder)}
                  style={{
                    marginLeft: hasTicket ? '-16px' : '0px',
                  }}
                >
                  <img
                    src={mobileOrderIcon}
                    alt="Completed"
                    className={styles.chipIcon}
                  />
                </div>
              )}
            </div>
          </div>
          {closestPin && (
            <div className={styles.iconContainer}>
              {highlighted ? (
                <>
                  <img src={pinBlueIcon} alt="" className={styles.icon} />
                  <div className={styles.iconLabel}>
                    <p>{closestPin.title}</p>
                  </div>
                </>
              ) : (
                <img src={pinBlackIcon} alt="" className={styles.icon} />
              )}
            </div>
          )}
        </div>
        {expanded ? (
          <i className="c-icon-solid-arrows-chevron-up"></i>
        ) : (
          <i className="c-icon-solid-arrows-chevron-down"></i>
        )}
      </div>
      {expanded && <SessionTimeline session={session} />}
    </section>
  );
};
