import * as React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-final-form';
import clsx from 'clsx';
import _ from 'lodash';

import { Box } from 'client/components/Box/Box';
import {
  searchReservations,
  setLastReservationSearchQuery,
} from 'client/actions/reservationSearch';
import { fetchProducts } from 'client/actions/products';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { reservationVisibleColumnsSelector } from 'client/reducers/reservationTableControls';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
} from 'client/reducers/user';
import {
  ReservationSummaryShape,
  toReservationSummaryShape,
} from 'client/libraries/util/reservationSummaryShape';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import type { ReduxState } from 'client/reducers';
import type { ColumnType } from 'client/libraries/util/getReservationTableColumns';
import { Button, Checkbox } from 'client/components/Form';
import { operationAllowed } from 'shared/models/access';
import { ReservationSearchCustomTable } from 'client/pages/ReservationSearch/ReservationSearchTable/ReservationSearchCustomTable';
import { ReservationSearchQueryModal } from 'client/pages/ReservationSearch/ReservationSearchQueryModal/ReservationSearchQueryModal';
import { ReservationSearchQueryDisplayBox } from 'client/pages/ReservationSearch/ReservationSearchQueryDisplayBox/ReservationSearchQueryDisplayBox';
import {
  buildSearchReservationsRequest,
  SearchReservationsRequest,
} from 'client/pages/ReservationSearch/util';
import { setReservationCurrentPage } from 'client/actions/reservationTableControls';
import searchIcon from 'client/images/ic_search.svg';
import baseStyles from 'client/base.module.css';

import styles from './ReservationSelect.module.css';
import { FormValues } from './formValues';

export const ReservationSelect = () => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.reservationSearch.loading
  );
  const form = useForm<FormValues>();
  const selectedReservations = form.getState().values?.reservations ?? [];
  const dispatch = useDispatch();
  const allReservations = useSelector(
    (state: ReduxState) => state.reservationSearch.all
  );
  const totalSearchHits = useSelector(
    (state: ReduxState) => state.reservationSearch.totalHits
  );

  const visibleColumns = useSelector(reservationVisibleColumnsSelector);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const reservationSummaries = React.useMemo(
    () => allReservations.map((r) => toReservationSummaryShape(r, locale, t)),
    [allReservations, locale, t]
  );

  const allColumns = React.useMemo(() => {
    return [
      {
        Header: '',
        id: 'select',
        width: 'short',
        th: true,
        Cell: (cellInfo: { original: ReservationSummaryShape }) =>
          cellInfo.original.email ? (
            <Checkbox
              checked={selectedReservations.some(
                (r) => r.id === cellInfo.original.id
              )}
              onChange={() => {
                const prevValue = form.getState().values?.reservations ?? [];
                form.change(
                  'reservations',
                  prevValue.some((r) => r.id === cellInfo.original.id)
                    ? prevValue.filter((r) => r.id !== cellInfo.original.id)
                    : [...prevValue, cellInfo.original]
                );
              }}
            />
          ) : (
            t('No Email')
          ),
      },
      ...getReservationTableColumns(t, locale),
    ];
  }, [locale, selectedReservations]);

  const showColumns = React.useMemo(() => {
    const getColumns = (columnMask: string[]): ColumnType[] => {
      return [
        'select',
        ...(columnMask.filter((c) => c !== 'select') as any),
      ].map((c) => allColumns.find((col) => col.id === c) as any);
    };

    return getColumns(visibleColumns);
  }, [visibleColumns, allColumns]);

  const rowCount = useSelector(
    (state: ReduxState) => state.reservationTableControls.rowCount
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.reservationTableControls.currentPage
  );

  const lastQuery = useSelector(
    (state: ReduxState) => state.reservationSearch.lastQuery
  );
  const [searchCondition, setSearchCondition] =
    React.useState<SearchReservationsRequest>(lastQuery);
  React.useEffect(() => {
    search();
  }, [activeUser, rowCount, currentPage]);
  // Fetch products for search modal and search display
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUser]);
  React.useEffect(() => {
    // If user is a supplier, fetch contracted agents for search modal and search display
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  const search = () => {
    if (!isNutmegAdmin) {
      let pageToFetch = currentPage;
      if (!_.isEqual(searchCondition, lastQuery)) {
        dispatch(setLastReservationSearchQuery(searchCondition as any));
        pageToFetch = 1;
        dispatch(setReservationCurrentPage(pageToFetch));
      }

      dispatch(
        searchReservations(
          buildSearchReservationsRequest(searchCondition),
          rowCount,
          rowCount * (pageToFetch - 1)
        )
      );
    }
  };

  const reset = () => {
    setSearchCondition({
      presetKey: '',
      agentReference: '',
      agentIds: [],
      supplierReference: '',
      id: '',
      statuses: [],
      customerGivenName: '',
      customerFamilyName: '',
      customerPhone: '',
      customerEmail: '',
      bookingSourceTypes: [],
      groupIds: [],
      supplierIds: [],
      productIds: [],
      bookedDateFrom: '',
      bookedDateTo: '',
      participationDateFrom: '',
      participationDateTo: '',
      lastUpdatedDateFrom: '',
      lastUpdatedDateTo: '',
      dateFilterPreset: null,
      orderBy: 'last_updated_desc',
      supplierOrAgentReference: '',
      reservationLanguages: [],
      mostRecentEmailBounced: false,
      pickupCheckinLocationName: '',
      waiverCompletionStatuses: [],
      checkinStatuses: [],
      annualPassOnly: false,
      expirationPresetKey: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      expirationDateFilterPreset: null,
      automaticContinuingStatus: null,
      partnershipReservationOnly: false,
    });
  };

  return (
    <>
      {loading ? (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      ) : (
        <>
          <div className={styles['step-button-row']}>
            <div className={baseStyles['base-subttl']}>
              {t('{{count}} reservations selected', {
                count: selectedReservations.length,
              })}
            </div>
            <Box ml="auto">
              <Button
                disabled={selectedReservations.length === 0}
                type="submit"
                style="green"
                size="middle"
              >
                {t('Continue to Step 2')}
              </Button>
            </Box>
          </div>

          <div className={clsx(baseStyles['base-main__body__header'])}>
            <div
              className={clsx(
                baseStyles['base-main__body__header__left'],
                baseStyles['spOrder-1'],
                styles['action-buttons-row']
              )}
            >
              <ReservationSearchQueryModal
                onReset={reset}
                onSearch={() => {
                  dispatch(setReservationCurrentPage(1));
                  search();
                }}
                searchCondition={searchCondition}
                setSearchCondition={(condition) =>
                  setSearchCondition(condition)
                }
                trigger={
                  <Button.Transition
                    content={
                      <>
                        <img src={searchIcon} />
                        {t('Search')}
                      </>
                    }
                  />
                }
              />
              <Button
                onClick={() => {
                  form.change('reservations', [
                    ...new Set([
                      ...selectedReservations,
                      ...reservationSummaries.filter((r) => r.email),
                    ]),
                  ]);
                }}
                style="yellow"
                size="middle"
              >
                {t('Select All')}
              </Button>
              <div className={styles['clear-button-container']}>
                <Button
                  onClick={() => form.change('reservations', [])}
                  style="gray"
                  size="middle"
                >
                  {t('Clear Selection')}
                </Button>
              </div>
            </div>
          </div>

          <ReservationSearchQueryDisplayBox searchFilters={lastQuery} />

          <ReservationSearchCustomTable
            reservations={reservationSummaries}
            totalHits={totalSearchHits}
            visibleColumns={showColumns}
          />
        </>
      )}
    </>
  );
};
