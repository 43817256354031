import { Moment } from 'moment';

import { SearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import { ReservationStatus, Organization } from 'shared/models/swagger';

export const getPresetSearchReservationsRequest = (
  organization: Organization | null,
  presetKey: string,
  today: Moment
): SearchReservationsRequest | null => {
  const selectedPreset =
    organization?.account_reservation_search_settings?.presets?.find(
      (preset) => preset.key === presetKey
    );

  if (selectedPreset) {
    return {
      presetKey: presetKey,
      agentReference: '',
      agentIds: selectedPreset.agent_ids || [],
      supplierReference: '',
      id: '',
      statuses: (selectedPreset.statuses || []) as ReservationStatus[],
      customerGivenName: '',
      customerFamilyName: '',
      customerPhone: '',
      customerEmail: '',
      bookingSourceTypes: selectedPreset.booking_sources || [],
      groupIds: [],
      supplierIds: selectedPreset.supplier_ids || [],
      productIds: [],
      bookedDateFrom: '',
      bookedDateTo: '',
      participationDateFrom: '',
      participationDateTo: '',
      lastUpdatedDateFrom: '',
      lastUpdatedDateTo: '',
      dateFilterPreset: null,
      orderBy: selectedPreset.order_by || 'last_updated_date_time_utc',
      supplierOrAgentReference: '',
      reservationLanguages: selectedPreset.reservation_languages || [],
      ...convertDateRange(selectedPreset, today),
      mostRecentEmailBounced: false,
      pickupCheckinLocationName: '',
      waiverCompletionStatuses: [],
      checkinStatuses: [],
      annualPassOnly: false,
      expirationPresetKey: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      expirationDateFilterPreset: null,
      automaticContinuingStatus: null,
      partnershipReservationOnly: false,
    };
  }

  return null;
};

export type FlexibleDashboardTargetDateType =
  | 'CURRENT_DATE'
  | 'PREVIOUS_DATE'
  | 'NEXT_DATE'
  | 'PREVIOUS_DATE_RANGE'
  | 'NEXT_DATE_RANGE'
  | 'TO_CURRENT_DATE' // from indefinite past dates to today
  | 'FROM_CURRENT_DATE'; // from today to indefinite future dates

type FlexibleDashboardSearchPreset = {
  target_date_type?: FlexibleDashboardTargetDateType;

  previous_date_range?: number;
  next_date_range?: number;
  date_type?: 'PARTICIPATION_DATE' | 'BOOKED_DATE' | 'LAST_UPDATED_DATE';
};

export const convertDateRange = (
  preset: FlexibleDashboardSearchPreset,
  today: Moment
): Partial<SearchReservationsRequest> | undefined => {
  let from = '';
  let to = '';

  switch (preset.target_date_type) {
    case 'CURRENT_DATE':
      from = today.format('YYYY-MM-DD');
      to = today.format('YYYY-MM-DD');
      break;
    case 'PREVIOUS_DATE':
      from = today.subtract(1, 'day').format('YYYY-MM-DD');
      to = today.format('YYYY-MM-DD');
      break;
    case 'NEXT_DATE':
      from = today.add(1, 'day').format('YYYY-MM-DD');
      to = today.format('YYYY-MM-DD');
      break;
    case 'PREVIOUS_DATE_RANGE':
      from = today
        .clone()
        .subtract(preset.previous_date_range ?? 0, 'day')
        .format('YYYY-MM-DD');
      to = today.clone().format('YYYY-MM-DD');
      break;
    case 'NEXT_DATE_RANGE':
      from = today.clone().format('YYYY-MM-DD');
      to = today
        .clone()
        .add(preset.next_date_range ?? 0 + 1, 'day')
        .format('YYYY-MM-DD');
      break;
    case 'TO_CURRENT_DATE':
      to = today.format('YYYY-MM-DD');
      break;
    case 'FROM_CURRENT_DATE':
      from = today.format('YYYY-MM-DD');
      break;
  }

  switch (preset.date_type) {
    case 'PARTICIPATION_DATE':
      return {
        participationDateFrom: from,
        participationDateTo: to,
      };
    case 'BOOKED_DATE':
      return {
        bookedDateFrom: from,
        bookedDateTo: to,
      };
    case 'LAST_UPDATED_DATE':
      return {
        lastUpdatedDateFrom: from,
        lastUpdatedDateTo: to,
      };
  }
};
