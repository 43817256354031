import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import type { Reservation } from 'shared/models/swagger';
import v3BaseStyles from 'client/v3-base.module.css';
import reservationStyles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';

import { DynamicPackageComponentCheckinTab } from './DynamicPackageComponentCheckinTab';

type Props = {
  reservation?: Reservation;
};

export const DynamicPackageCheckin = ({ reservation }: Props) => {
  const { t } = useTranslation();

  return (
    <section
      id="ticketRedemptions"
      className={clsx(reservationStyles['g-section'], v3BaseStyles['u-mt-6'])}
    >
      <div className={reservationStyles['p-reservationsDetail']}>
        <div className={reservationStyles['p-reservationsDetail__header']}>
          <p className={reservationStyles['p-reservationsDetail__ttl']}>
            {t('Ticket Redemptions')}
          </p>
        </div>
        <div className={reservationStyles['p-reservationsDetail__body']}>
          <DynamicPackageComponentCheckinTab reservation={reservation} />
        </div>
      </div>
    </section>
  );
};
