import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import {
  fetchSupplierSettings,
  putSupplierSettings,
} from 'client/actions/supplierSettings';
import { journeyAnalyticsSearchPresetsSelector } from 'client/reducers/supplierSettings';
import { ReduxState } from 'client/reducers';

import styles from './SearchForm.module.css';
import { EditPresetModal } from './EditPresetModal';
import { SearchPresetDisplay } from './SearchPresetDisplay';

interface Props {
  selectedPresetName: string;
  setSelectedPresetName: (newPresetName: string) => void;
}

export const SearchForm = ({
  selectedPresetName,
  setSelectedPresetName,
}: Props) => {
  const { t } = useTranslation();
  const [editingPresetIdx, setEditingPresetIdx] = React.useState<number | null>(
    null
  );

  const store = useStore<ReduxState>();
  const presets = useSelector(journeyAnalyticsSearchPresetsSelector);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const existingJourneyAnalyticsSettings = useSelector(
    (reduxState: ReduxState) => reduxState.supplierSettings.journeyAnalytics
  );

  React.useEffect(() => {
    dispatch(fetchSupplierSettings('JOURNEY_ANALYTICS'));
  }, []);

  const selectedPreset = presets.find(
    (preset) => preset.preset_name === selectedPresetName
  );

  return (
    <section className={styles['g-section']}>
      <div className={styles['p-search']}>
        <div className={styles['p-search__header']}>
          <h2 className={styles['p-search__header__title']}>
            {t('Search Conditions')}
          </h2>
        </div>
        <div className={styles['p-search__body']}>
          <div className={styles['p-search__body__display']}>
            <div className={styles['p-search__header__actions']}>
              <SingleDropdown
                label={t('Preset')}
                selectedOption={selectedPresetName || 'NONE'}
                options={[
                  {
                    text: t('None'),
                    value: 'NONE',
                  },
                  ...presets.map((preset) => ({
                    text: preset.preset_name ?? '',
                    value: preset.preset_name ?? '',
                  })),
                  {
                    text: t('Add New Preset'),
                    value: 'ADD_NEW',
                  },
                ]}
                onChange={(newOption) => {
                  if (newOption === 'NONE') {
                    setSelectedPresetName('');
                  } else if (newOption === 'ADD_NEW') {
                    setEditingPresetIdx(presets.length);
                  } else {
                    setSelectedPresetName(newOption);
                  }
                }}
                renderOption={(option) => {
                  if (option.value === 'ADD_NEW') {
                    return (
                      <div className={styles['add-new']}>
                        <i className="c-icon-outline-general-plus-circle"></i>
                        <p>{option.text}</p>
                      </div>
                    );
                  } else {
                    return option.text;
                  }
                }}
              />
              {selectedPresetName && selectedPresetName !== 'NONE' && (
                <>
                  <Button
                    uiType="bg"
                    size="sm"
                    color="white"
                    iconBeforeText={
                      <i className="c-icon-outline-general-edit-05"></i>
                    }
                    onClick={() => {
                      if (selectedPresetName) {
                        setEditingPresetIdx(
                          presets.findIndex(
                            (preset) =>
                              preset.preset_name === selectedPresetName
                          )
                        );
                      }
                    }}
                  />
                  <Button
                    size="icon"
                    color="white"
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                    iconBeforeText={
                      <i className="c-icon-outline-general-trash-03"></i>
                    }
                  />
                </>
              )}
              {showDeleteModal && (
                <Modal
                  onClose={() => setShowDeleteModal(false)}
                  title={t('Delete Preset')}
                  open={true}
                  rightActionChildren={
                    <>
                      <Button
                        text={t('Cancel')}
                        size="md"
                        color="white"
                        onClick={() => setShowDeleteModal(false)}
                      />
                      <Button
                        text={t('Delete')}
                        onClick={async () => {
                          await dispatch(
                            putSupplierSettings('JOURNEY_ANALYTICS', {
                              journey_analytics_settings: {
                                ...existingJourneyAnalyticsSettings,
                                presets: presets.filter(
                                  (preset) =>
                                    preset.preset_name !== selectedPresetName
                                ),
                              },
                            })
                          );
                          const updatedPresets =
                            store.getState().supplierSettings.journeyAnalytics
                              ?.presets ?? [];
                          setSelectedPresetName(
                            updatedPresets.length > 0
                              ? updatedPresets[0].preset_name ?? ''
                              : ''
                          );
                          setShowDeleteModal(false);
                        }}
                      />
                    </>
                  }
                >
                  <div>
                    {t('Are you sure you want to delete "{{presetName}}"?', {
                      presetName: selectedPresetName,
                    })}
                  </div>
                </Modal>
              )}
            </div>
            {selectedPreset && <SearchPresetDisplay preset={selectedPreset} />}
            {editingPresetIdx != null && (
              <EditPresetModal
                onClose={() => setEditingPresetIdx(null)}
                onSave={(presetName) => {
                  setSelectedPresetName(presetName);
                }}
                presetIdx={editingPresetIdx}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
