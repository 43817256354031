import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';

import { config } from 'client/config';
import { reservationFormInEmailForAccount } from 'shared/models/access';
import { TextField } from 'client/components/v3/Form/TextField';
import { PcSidebarMenu } from 'client/pages/v3/BookingWidget/BookingWidgetSettings/Menu/PcSidebarMenu';
import { Section } from 'client/pages/v3/BookingWidget/BookingWidgetSettings/Menu/menuType';
import { CollapsibleSection } from 'client/pages/v3/BookingWidget/BookingWidgetSettings/CollapsibleSection/CollapsibleSection';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Button } from 'client/components/v3/Common/Button';
import { SingleImageInput } from 'client/components/v3/SingleImageInput/SingleImageInput';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Radio } from 'client/components/v3/Form/Radio';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { Loading } from 'client/pages/Loading';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { WeekdaysInput } from 'client/components/v3/WeekdaysInput/WeekdaysInput';
import type { ReduxState } from 'client/reducers';
import {
  getLanguageName,
  uppercaseIsoToLowercaseIso,
  contentLanguageOptions,
} from 'client/libraries/i18n';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { getBookingWidgetUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { getValidators } from 'shared/libraries/validate/validator';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';
import { SiteSettingsTutorialPopUp } from 'client/pages/Home/Tutorial/TutorialPopUp/SiteSettingsTutorialPopUp';
import {
  defaultProductCurrencySelector,
  defaultProductLanguageSelector,
} from 'client/reducers/organizations';
import { getArrayMutators } from 'client/libraries/util/form';
import { getPOBPaymentText } from 'client/libraries/util/getPOBPaymentText';
import { lowercaseIsoToUppercaseIso } from 'shared/libraries/i18n';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetSettings/BookingWidgetSettings.module.css';
import type { SiteSettingsFormValues } from 'client/pages/v3/BookingWidget/BookingWidgetSettings/utils';
import { timezoneOptions } from 'client/libraries/util/timezoneOptions';
import {
  getInitialValues,
  getSupplierGuestPaymentSettings,
  isFloat,
  getSupplierEmailSubjectCustomizationItemName,
  isDefaultSupplierEmailSubject,
  getDefaultSupplierEmailSubjectCustomizationSettings,
} from 'client/pages/v3/BookingWidget/BookingWidgetSettings/utils';
import type { Translation } from 'shared/models/swagger';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import {
  batchGetTranslations,
  updateTranslations,
} from 'client/actions/translations';

import { EditOrganizationNameModal } from './EditOrganizationNameModal';
import { GuestMyPageSettings } from './GuestMyPageSettings';

const focusOnError: any = createDecorator();
const emptyTranslation = {
  en_us: '',
};

export const BookingWidgetSettingsBody = () => {
  const { t } = useTranslation();
  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);
  const [showPopUp, setShowPopUp] = React.useState<boolean>(true);
  const [showOrganizationNameModal, setShowOrganizationNameModal] =
    React.useState<boolean>(false);
  const scrollTo = React.useContext(ScrollToContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const languageOptions = contentLanguageOptions.map((option) => ({
    value: lowercaseIsoToUppercaseIso[option.iso],
    text: getLanguageName(option.iso, t),
  }));
  const originalTranslations = useSelector(
    (state: ReduxState) => state.translations.all
  );
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const defaultCurrency = useSelector(defaultProductCurrencySelector);
  const defaultLanguage = useSelector(defaultProductLanguageSelector);
  const bookingWidgetUrl = getBookingWidgetUrl(
    activeUserOrganization?.booking_widget_subdomain || ''
  );
  const tutorialActive = Boolean(useRouteMatch('/home/tutorial'));
  const validators = getValidators(t);

  const sourceLanguage = activeUserOrganization?.source_language || 'EN_US';
  const translationLanguages = (
    activeUserOrganization?.supported_languages || []
  ).filter((lang) => lang !== sourceLanguage);
  const shouldShowMultilingualSettings =
    [...new Set([sourceLanguage, ...translationLanguages])].length > 1;

  const companyLanguage = getLanguageName(
    activeUserOrganization?.source_language ?? 'JA_JP',
    t
  );
  const companyTimezone =
    timezoneOptions.find(
      (opt) => opt.value === activeUserOrganization?.default_timezone
    )?.text ?? activeUserOrganization?.default_timezone;

  const [currentSectionId, setCurrentSectionId] = React.useState('info');
  const [showTranslateMode, setShowTranslateMode] =
    React.useState<boolean>(false);
  const [pobMessageTranslation, setPobMessageTranslation] =
    React.useState<Translation>(emptyTranslation);

  React.useEffect(() => {
    dispatch(
      batchGetTranslations([
        activeUserOrganization?.booking_widget_design_params
          ?.pob_payment_custom_message ?? '',
      ])
    );
  }, [dispatch, activeUserOrganization]);
  const reset = React.useCallback(() => {
    if (originalTranslations.length > 0) {
      setPobMessageTranslation(originalTranslations[0]);
    } else {
      setPobMessageTranslation(emptyTranslation);
    }
  }, [originalTranslations]);
  React.useEffect(() => {
    reset();
  }, [originalTranslations, reset]);
  const sections: Section[] = [
    {
      title: t('Site Info'),
      id: 'info',
      hasPermission: true,
    },
    {
      title: t('Company Info'),
      id: 'company',
      hasPermission: true,
    },
    {
      title: t('Logo・Icon'),
      id: 'logo',
      hasPermission: true,
    },
    {
      title: t('Payment Types'),
      id: 'payment',
      hasPermission: true,
    },
    {
      title: t('SNS Accounts'),
      id: 'sns',
      hasPermission: true,
    },
    {
      title: t('Notification Settings'),
      id: 'notification',
      hasPermission: true,
    },
    {
      title: t('Subscriptions'),
      id: 'subscription',
      hasPermission:
        hasSubscription(activeUserOrganization, 'feature-guest-my-page') ||
        hasSubscription(activeUserOrganization, 'feature-digital-waiver') ||
        hasSubscription(activeUserOrganization, 'feature-review') ||
        hasSubscription(activeUserOrganization, 'feature-image-download'),
    },
  ];

  if (!activeUserOrganization) {
    return <Loading />;
  }

  return (
    <div className={baseStyles['l-main__body']}>
      {tutorialActive && (
        <div>
          <div>{t('Register Site Info')}</div>
        </div>
      )}
      <div className={baseStyles['l-main__body__flex']}>
        <div className={baseStyles['l-main__body__flex__left']}>
          <Form
            onSubmit={async (values: SiteSettingsFormValues) => {
              const orgId = activeUserOrganization.id;
              const supplierGuestPaymentSettings =
                getSupplierGuestPaymentSettings(
                  values,
                  defaultCurrency,
                  activeUserOrganization
                );
              const snsIds = Object.entries(values.snsIds || [])
                .map(([key, id]) => {
                  if (typeof id !== 'string') {
                    return {};
                  }

                  return {
                    key,
                    id,
                  };
                })
                .filter((e) => e.key !== '');

              try {
                await dispatch(
                  updateOrganization(orgId, 'SUPPLIER', {
                    booking_widget_settings: {
                      google_analytics_tag: values.googleAnalyticsTag,
                      ua_google_analytics_tag: values.uaGoogleAnalyticsTag,
                      ga4_google_analytics_tag: values.ga4GoogleAnalyticsTag,
                      google_tag_manager_id: values.googleTagManagerId,
                      enable_no_index: values.enableNoIndex,
                    },
                    supported_languages: values.supportedLanguages,
                    logo_url: values.logoUrl,
                    office_mailing_address: values.officeAddress,
                    office_phone: values.officePhone,
                    office_email: values.officeEmail,
                    emergency_contact: values.emergencyContact,
                    operating_hours_rules: [
                      {
                        days_of_week: values.operatingDaysOfWeek,
                        start_time_local: values.operatingTimeOfDayStart,
                        end_time_local: values.operatingTimeOfDayEnd,
                      },
                    ],
                    supplier_guest_payment_settings:
                      supplierGuestPaymentSettings,
                    tutorial_stage: tutorialActive ? 'COMPLETED' : undefined,
                    sns_ids: snsIds,
                    guest_my_page_settings: {
                      allow_viewing_reservation_details:
                        values.allowViewingReservationDetails,
                      allow_updating_reservation_info:
                        values.allowUpdatingReservationInfo,
                      allow_changing_reservation_parameters:
                        values.allowChangingReservationParameters,
                      allow_cancelling_reservation:
                        values.allowCancellingReservation,
                      allow_issuing_downloading_receipt_pdf:
                        values.allowIssuingDownloadingReceiptPDF,
                    },
                    common_subscription_settings: {
                      show_email_offers_option_for_agent_bookings:
                        values.showEmailOffersOptionForAgentBookings,
                    },
                    booking_widget_design_params: {
                      ...activeUserOrganization?.booking_widget_design_params,
                      favicon_url: values.faviconUrl,
                      pob_payment_custom_message: values.pobPaymentMessage,
                    },
                    supplier_guest_receipt_settings: {
                      ...activeUserOrganization?.supplier_guest_receipt_settings,
                      receipt_business_name: values.receiptBusinessName,
                      receipt_proviso: values.receiptProviso,
                      business_registration_number:
                        values.businessRegistrationNumber,
                      consumption_tax_calculation:
                        values.consumptionTaxCalculation,
                    },
                    supplier_email_settings: {
                      should_show_reservation_form_info:
                        values.shouldShowReservationFormInfo,
                    },
                    contact_emails: !values.needReservationEmail
                      ? []
                      : values.contactEmails,
                    extranet_notification_settings: {
                      disable_reservation_notifications:
                        !values.needReservationNotifications,
                      disable_qr_checkin_notifications:
                        !values.needQrCheckinNotifications,
                    },
                    supplier_email_subject_customization_settings: (
                      values.supplierEmailSubjectCustomizationSettings || []
                    )
                      .map((s) => ({
                        email_type: s.email_type,
                        subject: isDefaultSupplierEmailSubject(
                          sourceLanguage,
                          s.email_type as any,
                          s.subject as string
                        )
                          ? ''
                          : s.subject,
                      }))
                      .filter((s) => Boolean(s.subject)),
                  })
                );
                await dispatch(
                  updateTranslations([
                    {
                      ...pobMessageTranslation,
                      [sourceLanguage.toLowerCase()]: values.pobPaymentMessage,
                      source_language: sourceLanguage,
                    },
                  ])
                );

                if (tutorialActive) {
                  history.push('/home');
                }

                scrollTo(0, 0);
                setSaveSucceeded(true);
              } catch (err) {
                return {
                  [FORM_ERROR]: t('Save Failed'),
                };
              }
            }}
            decorators={[focusOnError]}
            initialValues={
              tutorialActive
                ? {
                    supportedLanguages: [defaultLanguage],
                    officeEmail: activeUserOrganization?.office_email,
                    operatingDaysOfWeek: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
                    operatingTimeOfDayStart: '9:00',
                    operatingTimeOfDayEnd: '17:00',
                    acceptedPaymentTypes: ['PAY_ON_BOARD', 'PAID_IN_FULL'],
                    pobAcceptedPaymentMethods: ['CASH', 'CREDIT_CARD'],
                    pobCreditCardFeePercent: '0',
                    markupPercent: '0',
                    creditCardFeeType: 'PERCENT',
                    acceptedPIFPaymentCurrencies: ['JPY', 'USD'],
                    supplierEmailSubjectCustomizationSettings:
                      getDefaultSupplierEmailSubjectCustomizationSettings(t),
                  }
                : {
                    ...getInitialValues(activeUserOrganization),
                    translations: [],
                  }
            }
            mutators={getArrayMutators()}
            keepDirtyOnReinitialize={true}
          >
            {({ handleSubmit, values, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {saveSucceeded && (
                    <Snackbar
                      text={t('Save Successful')}
                      color="success"
                      shouldShow={saveSucceeded}
                      onClose={() => setSaveSucceeded(false)}
                    />
                  )}
                  <section className={styles['g-section']}>
                    <CollapsibleSection
                      id="info"
                      title={t('Site Info')}
                      setCurrentSectionId={setCurrentSectionId}
                      subtitle={t(
                        'Set up basic information for the booking site.'
                      )}
                    >
                      <ul className={styles['p-general-list']}>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>URL</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <a
                                className="c-button-link-sm-color"
                                href={bookingWidgetUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <p
                                  style={{ fontWeight: 'var(--text-semibold)' }}
                                >
                                  {bookingWidgetUrl}
                                </p>
                                <i className="c-icon-outline-general-link-external-02"></i>
                              </a>
                            </div>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <p
                                className={
                                  styles[
                                    'p-general-products__section__body__item__txt'
                                  ]
                                }
                              >
                                {t(
                                  '* Switch to your own domain later if desired'
                                )}
                              </p>
                            </div>
                          </div>
                        </li>

                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Google Analytics')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <dl
                              className={
                                styles['p-general-products__section__list']
                              }
                            >
                              <dt
                                className={
                                  styles[
                                    'p-general-products__section__list__term'
                                  ]
                                }
                              >
                                <span>
                                  {t('GA4 Analytics Tag (Recommended)')}
                                </span>
                              </dt>
                              <dd
                                className={
                                  styles[
                                    'p-general-products__section__list__desc'
                                  ]
                                }
                              >
                                <Field name="ga4GoogleAnalyticsTag">
                                  {({ input }) => (
                                    <TextField
                                      placeholder="G-xxxxxx"
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </dd>
                            </dl>
                            <dl
                              className={
                                styles['p-general-products__section__list']
                              }
                            >
                              <dt
                                className={
                                  styles[
                                    'p-general-products__section__list__term'
                                  ]
                                }
                              >
                                <span>{t('UA Analytics Tag')}</span>
                              </dt>
                              <dd
                                className={
                                  styles[
                                    'p-general-products__section__list__desc'
                                  ]
                                }
                              >
                                <Field name="uaGoogleAnalyticsTag">
                                  {({ input }) => (
                                    <TextField
                                      placeholder="UA-xxxxxx"
                                      {...input}
                                    />
                                  )}
                                </Field>
                              </dd>
                            </dl>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <a
                                className="c-button-link-sm-color"
                                href="/helps/settings/google-analytics"
                                target="_blank"
                              >
                                <p
                                  style={{ fontWeight: 'var(--text-semibold)' }}
                                >
                                  {t('How to set up Google Analytics')}
                                </p>
                                <i className="c-icon-outline-general-link-external-02"></i>
                              </a>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Google Tag Manager Id')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="googleTagManagerId">
                                {({ input }) => <TextField {...input} />}
                              </Field>
                            </div>
                          </div>
                        </li>

                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Language')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={clsx(
                                styles[
                                  'p-general-products__section__body__item'
                                ],
                                styles['flex']
                              )}
                            >
                              <Field
                                name="supportedLanguages"
                                validate={validators.atLeastOneLanguageRequred}
                              >
                                {({ input }) => (
                                  <>
                                    {languageOptions.map((langOpt) => (
                                      <div key={langOpt.value}>
                                        <Checkbox
                                          label={langOpt.text}
                                          checked={(input.value || []).includes(
                                            langOpt.value
                                          )}
                                          onChange={() => {
                                            if (
                                              (input.value || []).includes(
                                                langOpt.value
                                              )
                                            ) {
                                              input.onChange(
                                                input.value?.filter(
                                                  (lang) =>
                                                    langOpt.value !== lang
                                                )
                                              );
                                            } else {
                                              input.onChange([
                                                ...(input.value as any),
                                                langOpt.value,
                                              ]);
                                            }
                                          }}
                                          size="sm"
                                        />
                                      </div>
                                    ))}
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Search Engine')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="enableNoIndex">
                                {({ input }) => (
                                  <Toggle
                                    label={t(
                                      'Do not show booking site on Google and other search result'
                                    )}
                                    checked={input.value}
                                    onChange={() =>
                                      input.onChange(!input.value)
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <ul
                              className={
                                styles['p-general-list__item__body__dot']
                              }
                            >
                              <li>
                                <i></i>
                                {t(
                                  'noindex tag will be added to each page on booking website to avoid being indexed by search engines.'
                                )}
                              </li>
                              <li>
                                <i></i>
                                {t(
                                  'Website can be displayed when the URL is accessed directly'
                                )}
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </CollapsibleSection>

                    <CollapsibleSection
                      id="company"
                      title={t('Company Info')}
                      setCurrentSectionId={setCurrentSectionId}
                      subtitle={t(
                        'Enter company information such as contact email and phone number. These information will be displayed on the booking site and emails.'
                      )}
                    >
                      <ul className={styles['p-general-list']}>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Organization Name')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={clsx(
                                styles[
                                  'p-general-products__section__body__item'
                                ],
                                styles['flex']
                              )}
                            >
                              {activeUserOrganization ? (
                                <div>{activeUserOrganization.name}</div>
                              ) : (
                                <div>{t('No organization found')}</div>
                              )}
                              <Button
                                color="white"
                                size="sm"
                                iconAfterText={
                                  <i className="c-icon-outline-general-edit-05"></i>
                                }
                                onClick={() =>
                                  setShowOrganizationNameModal(true)
                                }
                              />
                              {showOrganizationNameModal && (
                                <EditOrganizationNameModal
                                  setShowModal={setShowOrganizationNameModal}
                                />
                              )}
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Address')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="officeAddress">
                                {({ input }) => <TextField {...input} />}
                              </Field>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Company Phone Number')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="officePhone">
                                {({ input }) => <TextField {...input} />}
                              </Field>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Email')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="officeEmail">
                                {({ input }) => <TextField {...input} />}
                              </Field>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Emergency Contact Info')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="emergencyContact">
                                {({ input }) => <TextField {...input} />}
                              </Field>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Operating Days of the Week')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="operatingDaysOfWeek">
                                {({ input }) => (
                                  <WeekdaysInput
                                    value={input.value}
                                    onChange={input.onChange}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Operating Hours')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={clsx(
                                styles[
                                  'p-general-products__section__body__item'
                                ],
                                styles['flex']
                              )}
                            >
                              <div style={{ width: 'auto' }}>
                                <Field name="operatingTimeOfDayStart">
                                  {({ input }) => (
                                    <TimePicker
                                      allowEmpty={false}
                                      value={moment(input.value, 'HH:mm')}
                                      onChange={(newMoment) => {
                                        if (!newMoment) {
                                          return;
                                        }
                                        input.onChange(
                                          newMoment.format('HH:mm')
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>
                              <span>〜</span>
                              <div style={{ width: 'auto' }}>
                                <Field name="operatingTimeOfDayEnd">
                                  {({ input }) => (
                                    <TimePicker
                                      allowEmpty={false}
                                      value={moment(input.value, 'HH:mm')}
                                      onChange={(newMoment) => {
                                        if (!newMoment) {
                                          return;
                                        }

                                        input.onChange(
                                          newMoment.format('HH:mm')
                                        );
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </li>
                        <>
                          <li className={styles['p-general-list__item']}>
                            <div
                              className={styles['p-general-list__item__ttl']}
                            >
                              <div
                                className={
                                  styles['p-general-list__item__ttl__txt']
                                }
                              >
                                <div>{t('Receipt Business Name')}</div>
                              </div>
                            </div>
                            <div
                              className={styles['p-general-list__item__body']}
                            >
                              <div
                                className={
                                  styles[
                                    'p-general-products__section__body__item'
                                  ]
                                }
                              >
                                <Field name="receiptBusinessName">
                                  {({ input }) => <TextField {...input} />}
                                </Field>
                              </div>
                            </div>
                          </li>
                          {activeUserOrganization.default_timezone ===
                            'Asia/Tokyo' && (
                            <>
                              <li className={styles['p-general-list__item']}>
                                <div
                                  className={
                                    styles['p-general-list__item__ttl']
                                  }
                                >
                                  <div
                                    className={
                                      styles['p-general-list__item__ttl__txt']
                                    }
                                  >
                                    <div>{t('Receipt Proviso Text')}</div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    styles['p-general-list__item__body']
                                  }
                                >
                                  <div
                                    className={
                                      styles[
                                        'p-general-products__section__body__item'
                                      ]
                                    }
                                  >
                                    <Field name="receiptProviso">
                                      {({ input }) => (
                                        <TextField
                                          {...input}
                                          placeholder={t(
                                            'Default receipt proviso'
                                          )}
                                        />
                                      )}
                                    </Field>
                                  </div>
                                </div>
                              </li>
                              <li className={styles['p-general-list__item']}>
                                <div
                                  className={
                                    styles['p-general-list__item__ttl']
                                  }
                                >
                                  <div
                                    className={
                                      styles['p-general-list__item__ttl__txt']
                                    }
                                  >
                                    <div>
                                      {t('Business Registration Number')}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    styles['p-general-list__item__body']
                                  }
                                >
                                  <div
                                    className={
                                      styles[
                                        'p-general-products__section__body__item'
                                      ]
                                    }
                                  >
                                    <Field name="businessRegistrationNumber">
                                      {({ input }) => <TextField {...input} />}
                                    </Field>
                                  </div>
                                </div>
                              </li>
                              <li className={styles['p-general-list__item']}>
                                <div
                                  className={
                                    styles['p-general-list__item__ttl']
                                  }
                                >
                                  <div
                                    className={
                                      styles['p-general-list__item__ttl__txt']
                                    }
                                  >
                                    <div>
                                      {t('Consumption Tax Calculation')}{' '}
                                    </div>
                                  </div>
                                  <span>
                                    {t(
                                      'Applies to rounding when calculating the consumption tax amount included in the total gross price.'
                                    )}
                                  </span>
                                </div>
                                <div
                                  className={
                                    styles['p-general-list__item__body']
                                  }
                                >
                                  <div
                                    className={
                                      styles[
                                        'p-general-products__section__body__item'
                                      ]
                                    }
                                  >
                                    <EnumRadioButtonGroup
                                      name="consumptionTaxCalculation"
                                      options={[
                                        {
                                          value: 'ROUND_OFF',
                                          label: t('Tax Round Off'),
                                        },
                                        {
                                          value: 'ROUND_UP',
                                          label: t('Tax Round Up'),
                                        },
                                        {
                                          value: 'ROUND_DOWN',
                                          label: t('Tax Round Down'),
                                        },
                                      ]}
                                    />
                                  </div>
                                </div>
                              </li>
                            </>
                          )}
                        </>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Language')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              {companyLanguage}
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Timezone')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              {companyTimezone}
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Currency')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              {activeUserOrganization?.default_currency ?? ''}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </CollapsibleSection>
                    <CollapsibleSection
                      id="logo"
                      title={t('Logo, Icon')}
                      setCurrentSectionId={setCurrentSectionId}
                      subtitle={t(
                        'Set up the logo image and icon to be displayed on the booking site and email.'
                      )}
                    >
                      <ul className={styles['p-general-list']}>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Logo (jpg,png)')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="logoUrl">
                                {({ input }) => (
                                  <SingleImageInput
                                    onUploadFinished={(filename) =>
                                      input.onChange(filename)
                                    }
                                    initialValue={
                                      input.value
                                        ? `${config.s3Config.mediaPathRoot}/logos/supplier/${activeUserOrganization?.id}/${input.value}`
                                        : ''
                                    }
                                    saveFilePath={`logos/supplier/${activeUserOrganization?.id}`}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>

                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Icon (ico)')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="faviconUrl">
                                {({ input }) => (
                                  <SingleImageInput
                                    fileTypes={['image/x-icon']}
                                    onUploadFinished={(_, fileUploadUrl) =>
                                      input.onChange(fileUploadUrl)
                                    }
                                    initialValue={
                                      input.value ? input.value : ''
                                    }
                                    saveFilePath={`logos/supplier/${activeUserOrganization?.id}`}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </CollapsibleSection>
                    <CollapsibleSection
                      id="payment"
                      title={t('Payment Types')}
                      setCurrentSectionId={setCurrentSectionId}
                      subtitle={t(
                        'Set up payment methods available on the booking site.'
                      )}
                    >
                      <ul className={styles['p-general-list']}>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Payment Types')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={clsx(
                                styles[
                                  'p-general-products__section__body__item'
                                ],
                                styles['flex']
                              )}
                            >
                              <Field
                                name="acceptedPaymentTypes"
                                validate={
                                  validators.atLeastOnePaymentTypeRequred
                                }
                              >
                                {({ input, meta: { error, touched } }) => (
                                  <>
                                    <Checkbox
                                      label={t('Online Payment')}
                                      name="acceptedPaymentTypes"
                                      checked={(input.value || []).includes(
                                        'PAID_IN_FULL'
                                      )}
                                      value="PAID_IN_FULL"
                                      onChange={() => {
                                        if (
                                          !(input.value || []).includes(
                                            'PAID_IN_FULL'
                                          )
                                        ) {
                                          input.onChange([
                                            ...(input.value as any),
                                            'PAID_IN_FULL',
                                          ]);
                                        } else {
                                          input.onChange(
                                            (input.value || []).filter(
                                              (v) => v !== 'PAID_IN_FULL'
                                            )
                                          );
                                        }
                                      }}
                                      size="sm"
                                    />
                                    <Checkbox
                                      label={t('Local Payment')}
                                      name="acceptedPaymentTypes"
                                      checked={(input.value || []).includes(
                                        'PAY_ON_BOARD'
                                      )}
                                      value="PAY_ON_BOARD"
                                      onChange={() => {
                                        if (
                                          !(input.value || []).includes(
                                            'PAY_ON_BOARD'
                                          )
                                        ) {
                                          input.onChange([
                                            ...(input.value as any),
                                            'PAY_ON_BOARD',
                                          ]);
                                        } else {
                                          input.onChange(
                                            (input.value || []).filter(
                                              (v) => v !== 'PAY_ON_BOARD'
                                            )
                                          );
                                        }
                                      }}
                                      size="sm"
                                    />
                                    {error && touched && (
                                      <p className={baseStyles['u-error-msg']}>
                                        {error}
                                      </p>
                                    )}
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>
                        {(values?.acceptedPaymentTypes || []).includes(
                          'PAID_IN_FULL'
                        ) && (
                          <li className={styles['p-general-list__item']}>
                            <div
                              className={styles['p-general-list__item__ttl']}
                            >
                              <div
                                className={
                                  styles['p-general-list__item__ttl__txt']
                                }
                              >
                                <div>
                                  {t('Accepted Currencies for Online Payment')}
                                </div>
                              </div>
                            </div>
                            <div
                              className={styles['p-general-list__item__body']}
                            >
                              <div
                                className={clsx(
                                  styles[
                                    'p-general-products__section__body__item'
                                  ],
                                  styles['flex']
                                )}
                              >
                                <Field
                                  name="acceptedPIFPaymentCurrencies"
                                  validate={
                                    validators.atLeastOneCurrencyRequred
                                  }
                                >
                                  {({ input, meta: { error, touched } }) => (
                                    <>
                                      {['JPY', 'USD'].map((currencyOpt) => (
                                        <div key={currencyOpt}>
                                          <Checkbox
                                            label={currencyOpt}
                                            onChange={() => {
                                              if (
                                                input.value?.includes(
                                                  currencyOpt
                                                )
                                              ) {
                                                input.onChange(
                                                  input.value.filter(
                                                    (curr) =>
                                                      curr !== currencyOpt
                                                  )
                                                );
                                              } else {
                                                input.onChange([
                                                  ...(input.value as any),
                                                  currencyOpt,
                                                ]);
                                              }
                                            }}
                                            checked={Boolean(
                                              input.value?.includes(currencyOpt)
                                            )}
                                            size="sm"
                                          />
                                        </div>
                                      ))}
                                      {error && touched && (
                                        <p
                                          className={baseStyles['u-error-msg']}
                                        >
                                          {error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </li>
                        )}
                        {(values?.acceptedPaymentTypes || []).includes(
                          'PAY_ON_BOARD'
                        ) && (
                          <li className={styles['p-general-list__item']}>
                            <div
                              className={styles['p-general-list__item__ttl']}
                            >
                              <div
                                className={
                                  styles['p-general-list__item__ttl__txt']
                                }
                              >
                                <div>
                                  {t('Accepted forms of local payment')}
                                </div>
                              </div>
                            </div>
                            <div
                              className={styles['p-general-list__item__body']}
                            >
                              <div
                                className={clsx(
                                  styles[
                                    'p-general-products__section__body__item'
                                  ],
                                  styles['flex']
                                )}
                              >
                                <Field
                                  name="pobAcceptedPaymentMethods"
                                  validate={
                                    validators.atLeastOnePayOnBoardPaymentMethodRequred
                                  }
                                >
                                  {({ input, meta: { touched, error } }) => (
                                    <>
                                      <Checkbox
                                        label={t('Cash')}
                                        name="pobAcceptedPaymentMethods"
                                        value="CASH"
                                        onChange={() => {
                                          if (
                                            !(input.value || []).includes(
                                              'CASH'
                                            )
                                          ) {
                                            input.onChange([
                                              ...(input.value as any),
                                              'CASH',
                                            ]);
                                          } else {
                                            input.onChange(
                                              (input.value || []).filter(
                                                (v) => v !== 'CASH'
                                              )
                                            );
                                          }
                                        }}
                                        checked={(input.value || []).includes(
                                          'CASH'
                                        )}
                                        size="sm"
                                      />
                                      <Checkbox
                                        label={t('Credit Card')}
                                        value="CREDIT_CARD"
                                        name="pobAcceptedPaymentMethods"
                                        onChange={() => {
                                          if (
                                            !(input.value || []).includes(
                                              'CREDIT_CARD'
                                            )
                                          ) {
                                            input.onChange([
                                              ...(input.value as any),
                                              'CREDIT_CARD',
                                            ]);
                                          } else {
                                            input.onChange(
                                              (input.value || []).filter(
                                                (v) => v !== 'CREDIT_CARD'
                                              )
                                            );
                                          }
                                        }}
                                        checked={(input.value || []).includes(
                                          'CREDIT_CARD'
                                        )}
                                        size="sm"
                                      />
                                      <Checkbox
                                        label={t('Bank Transfer')}
                                        value="BANK"
                                        name="pobAcceptedPaymentMethods"
                                        onChange={() => {
                                          if (
                                            !(input.value || []).includes(
                                              'BANK'
                                            )
                                          ) {
                                            input.onChange([
                                              ...(input.value as any),
                                              'BANK',
                                            ]);
                                          } else {
                                            input.onChange(
                                              (input.value || []).filter(
                                                (v) => v !== 'BANK'
                                              )
                                            );
                                          }
                                        }}
                                        checked={(input.value || []).includes(
                                          'BANK'
                                        )}
                                        size="sm"
                                      />
                                      {error && touched && (
                                        <p
                                          className={baseStyles['u-error-msg']}
                                        >
                                          {error}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </li>
                        )}
                        {(values?.acceptedPaymentTypes || []).includes(
                          'PAY_ON_BOARD'
                        ) &&
                          (values?.pobAcceptedPaymentMethods || []).includes(
                            'CREDIT_CARD'
                          ) && (
                            <li className={styles['p-general-list__item']}>
                              <div
                                className={styles['p-general-list__item__ttl']}
                              >
                                <div
                                  className={
                                    styles['p-general-list__item__ttl__txt']
                                  }
                                >
                                  <div>
                                    {t('Local Payment Credit Card Fee')}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={styles['p-general-list__item__body']}
                              >
                                <div
                                  className={clsx(
                                    styles[
                                      'p-general-products__section__body__item'
                                    ],
                                    styles['flex']
                                  )}
                                >
                                  <Field name="creditCardFeeType">
                                    {({ input }) => (
                                      <>
                                        <Radio
                                          label={t('Set to percent of total')}
                                          value="PERCENT"
                                          name="creditCardFeeType"
                                          onChange={() => {
                                            input.onChange('PERCENT');
                                          }}
                                          checked={input.value === 'PERCENT'}
                                          size="sm"
                                        />
                                        <Radio
                                          label={t('Set to fixed amount')}
                                          value="FIXED"
                                          name="creditCardFeeType"
                                          onChange={() => {
                                            input.onChange('FIXED');
                                          }}
                                          checked={input.value === 'FIXED'}
                                          size="sm"
                                        />
                                      </>
                                    )}
                                  </Field>
                                </div>
                                <div
                                  className={clsx(
                                    styles[
                                      'p-general-products__section__body__item'
                                    ],
                                    styles['flex']
                                  )}
                                >
                                  {values?.creditCardFeeType === 'PERCENT' && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Field name="pobCreditCardFeePercent">
                                        {({ input }) => (
                                          <TextField
                                            value={input.value}
                                            onChange={(value) => {
                                              if (!isFloat(value)) {
                                                return;
                                              }
                                              input.onChange(value);
                                            }}
                                          />
                                        )}
                                      </Field>
                                      <div>%</div>
                                    </div>
                                  )}
                                  {values?.creditCardFeeType === 'FIXED' && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <div>{defaultCurrency}</div>
                                      <Field name="pobCreditCardFeeFixed">
                                        {({ input }) => (
                                          <TextField
                                            value={input.value}
                                            style={{ width: 'auto' }}
                                            onChange={(value) => {
                                              if (
                                                !currencyInputAllowed(
                                                  defaultCurrency,
                                                  value
                                                )
                                              ) {
                                                return;
                                              }

                                              input.onChange(value);
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                          )}
                        {config.enablePOBCustomMessage &&
                          (values?.acceptedPaymentTypes || []).includes(
                            'PAY_ON_BOARD'
                          ) && (
                            <li className={styles['p-general-list__item']}>
                              <div
                                className={styles['p-general-list__item__ttl']}
                              >
                                <div
                                  className={
                                    styles['p-general-list__item__ttl__txt']
                                  }
                                >
                                  <div>{t('Message for local payment')}</div>
                                </div>
                              </div>
                              <div
                                className={styles['p-general-list__item__body']}
                              >
                                <div
                                  className={clsx(
                                    styles[
                                      'p-general-products__section__body__item'
                                    ]
                                  )}
                                >
                                  <dl
                                    className={
                                      styles[
                                        'p-general-products__section__list'
                                      ]
                                    }
                                  >
                                    <dt
                                      className={
                                        styles[
                                          'p-general-products__section__list__term'
                                        ]
                                      }
                                    >
                                      <span>{t('Initial Message')}</span>
                                    </dt>
                                    <dd
                                      className={
                                        styles[
                                          'p-general-products__section__list__desc'
                                        ]
                                      }
                                    >
                                      <div className={styles['p-general-box']}>
                                        <span>
                                          {getPOBPaymentText(
                                            getSupplierGuestPaymentSettings(
                                              values,
                                              defaultCurrency,
                                              activeUserOrganization
                                            ),
                                            t
                                          )}
                                        </span>
                                      </div>
                                    </dd>
                                  </dl>
                                  <div
                                    className={clsx(
                                      styles[
                                        'p-general-products__section__body__item'
                                      ]
                                    )}
                                  >
                                    <dl
                                      className={
                                        styles[
                                          'p-general-products__section__list'
                                        ]
                                      }
                                    >
                                      <dt
                                        className={
                                          styles[
                                            'p-general-products__section__list__term'
                                          ]
                                        }
                                      >
                                        <span>{t('Custom Message')}</span>
                                      </dt>
                                      <dd
                                        className={
                                          styles[
                                            'p-general-products__section__list__desc'
                                          ]
                                        }
                                      >
                                        <Field name="pobPaymentMessage">
                                          {({ input }) => (
                                            <TextField {...input} />
                                          )}
                                        </Field>
                                      </dd>
                                    </dl>
                                  </div>
                                  {shouldShowMultilingualSettings && (
                                    <>
                                      <div
                                        className={clsx(
                                          styles[
                                            'p-general-products__section__body__item'
                                          ]
                                        )}
                                      >
                                        <Toggle
                                          label={t('Translation')}
                                          checked={showTranslateMode}
                                          onChange={() =>
                                            setShowTranslateMode(
                                              !showTranslateMode
                                            )
                                          }
                                        />
                                      </div>
                                      {showTranslateMode && (
                                        <div
                                          className={clsx(
                                            styles[
                                              'p-general-products__section__body__item'
                                            ]
                                          )}
                                          style={{
                                            backgroundColor: 'var(--gray100)',
                                            borderRadius: 'var(--radius-xl)',
                                            padding: '10px 10px 1px 10px',
                                          }}
                                        >
                                          {translationLanguages.map(
                                            (target) => (
                                              <>
                                                <div
                                                  style={{
                                                    marginBottom: '10px',
                                                  }}
                                                >
                                                  <TextField
                                                    label={getLanguageName(
                                                      uppercaseIsoToLowercaseIso[
                                                        target
                                                      ],
                                                      t
                                                    )}
                                                    value={
                                                      pobMessageTranslation[
                                                        target.toLowerCase() as keyof Translation
                                                      ] || ''
                                                    }
                                                    onChange={(value) => {
                                                      setPobMessageTranslation({
                                                        ...pobMessageTranslation,
                                                        [target.toLowerCase()]:
                                                          value,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </>
                                            )
                                          )}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </li>
                          )}
                      </ul>
                    </CollapsibleSection>
                    <CollapsibleSection
                      id="sns"
                      title={t('SNS Accounts')}
                      setCurrentSectionId={setCurrentSectionId}
                      subtitle={t(
                        'Enter ID/URL of your SNS account, icons will be displayed on the booking site footer.'
                      )}
                    >
                      <ul className={styles['p-general-list']}>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Facebook')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="snsIds.facebook">
                                {({ input }) => (
                                  <TextField
                                    placeholder={t('username (e.g. ntmg.jp)')}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Twitter')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="snsIds.twitter">
                                {({ input }) => (
                                  <TextField
                                    placeholder={t(
                                      'username without @ (e.g. ntmgjp)'
                                    )}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Instagram')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="snsIds.instagram">
                                {({ input }) => (
                                  <TextField
                                    placeholder={t(
                                      'username (e.g. nutmeglabsjapan)'
                                    )}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('YouTube')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="snsIds.youtube">
                                {({ input }) => (
                                  <TextField
                                    placeholder={t(
                                      'channel ID (e.g. UCE4moRPwnsR9mkpnCr9xK2g)'
                                    )}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Line')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="snsIds.line">
                                {({ input }) => (
                                  <TextField
                                    placeholder={t(
                                      'Add friend/Timeline URL (e.g. https://lin.ee/JmMjCIx )'
                                    )}
                                    {...input}
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </CollapsibleSection>

                    <CollapsibleSection
                      id="notification"
                      title={t('Notification Settings')}
                      setCurrentSectionId={setCurrentSectionId}
                      subtitle={t(
                        'Set up for the reservation notification emails and notifications on the management console.'
                      )}
                    >
                      <ul className={styles['p-general-list']}>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Send notification emails')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="needReservationEmail">
                                {({ input }) => (
                                  <Toggle
                                    label={t('Send notification emails')}
                                    checked={input.value}
                                    onChange={() =>
                                      input.onChange(!input.value)
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <FieldArray name="contactEmails">
                                {({ fields }) => {
                                  const count = fields.length ?? 0;
                                  return (
                                    <>
                                      {fields.map((name, idx) => (
                                        <Field key={name} name={name}>
                                          {({ input }) => (
                                            <div
                                              className={
                                                styles['company__item']
                                              }
                                              style={{ display: 'flex' }}
                                              key={idx}
                                            >
                                              <TextField {...input} />
                                              <div
                                                className={
                                                  baseStyles['base-flex']
                                                }
                                              >
                                                <Button
                                                  size="icon"
                                                  color="tertiarygray"
                                                  iconBeforeText={
                                                    <i className="c-icon-outline-general-trash-03"></i>
                                                  }
                                                  onClick={() =>
                                                    fields.remove(idx)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </Field>
                                      ))}
                                      <a
                                        className={styles['add__button']}
                                        onClick={() => {
                                          if (count === 0) {
                                            (fields as any).insertAt(0, '');
                                          } else {
                                            (fields as any).insertAt(
                                              count + 1,
                                              ''
                                            );
                                          }
                                        }}
                                      >
                                        <i className="c-icon-outline-general-plus-circle"></i>
                                        {t('Add')}
                                      </a>
                                    </>
                                  );
                                }}
                              </FieldArray>
                            </div>
                            {reservationFormInEmailForAccount(activeUser) && (
                              <div
                                className={
                                  styles[
                                    'p-general-products__section__body__item'
                                  ]
                                }
                              >
                                <Field name="shouldShowReservationFormInfo">
                                  {({ input }) => (
                                    <Toggle
                                      label={t(
                                        'Show reservation form question texts and answers in emails'
                                      )}
                                      checked={input.value}
                                      onChange={() =>
                                        input.onChange(!input.value)
                                      }
                                    />
                                  )}
                                </Field>
                                <ul
                                  className={
                                    styles['p-general-list__item__body__dot']
                                  }
                                >
                                  <li>
                                    <i></i>
                                    {t(
                                      'Reservation form answers may include personal information. By turning this setting ON, you are agreeing to our policy of handling personal information.'
                                    )}
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div style={{ marginRight: '2px' }}>
                                {t('Notification Email Subject')}
                              </div>
                              <Tooltip
                                text={t(
                                  'You can edit the subject line of the reservation notification email.'
                                )}
                                width={200}
                              >
                                <i className="c-icon-outline-general-info-circle"></i>
                              </Tooltip>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <FieldArray name="supplierEmailSubjectCustomizationSettings">
                                {({ fields }) => {
                                  return (
                                    <>
                                      {fields.map((name, idx) => (
                                        <>
                                          <Field
                                            key={`${name}.email_type`}
                                            name={`${name}.email_type`}
                                          >
                                            {({ input }) => (
                                              <div
                                                className={
                                                  styles['company__item']
                                                }
                                                style={{ display: 'flex' }}
                                                key={idx}
                                              >
                                                {getSupplierEmailSubjectCustomizationItemName(
                                                  t,
                                                  input.value
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                          <Field
                                            key={`${name}.subject`}
                                            name={`${name}.subject`}
                                          >
                                            {({ input }) => (
                                              <div
                                                className={
                                                  styles['company__item']
                                                }
                                                style={{ display: 'flex' }}
                                                key={idx}
                                              >
                                                <TextField {...input} />
                                              </div>
                                            )}
                                          </Field>
                                        </>
                                      ))}
                                    </>
                                  );
                                }}
                              </FieldArray>
                            </div>
                          </div>
                        </li>
                        <li className={styles['p-general-list__item']}>
                          <div className={styles['p-general-list__item__ttl']}>
                            <div
                              className={
                                styles['p-general-list__item__ttl__txt']
                              }
                            >
                              <div>{t('Management Console Notifications')}</div>
                            </div>
                          </div>
                          <div className={styles['p-general-list__item__body']}>
                            <div
                              className={
                                styles[
                                  'p-general-products__section__body__item'
                                ]
                              }
                            >
                              <Field name="needReservationNotifications">
                                {({ input }) => (
                                  <Toggle
                                    label={t(
                                      'Show notifications for new & updated reservations'
                                    )}
                                    checked={input.value}
                                    onChange={() =>
                                      input.onChange(!input.value)
                                    }
                                  />
                                )}
                              </Field>
                            </div>
                            {hasSubscription(
                              activeUserOrganization,
                              'feature-qr-checkin'
                            ) &&
                              !isSubscriptionCancelled(
                                activeUserOrganization,
                                'feature-qr-checkin'
                              ) && (
                                <div
                                  className={
                                    styles[
                                      'p-general-products__section__body__item'
                                    ]
                                  }
                                >
                                  <Field name="needQrCheckinNotifications">
                                    {({ input }) => (
                                      <Toggle
                                        label={t(
                                          'Show notifications when guests check in with QR code'
                                        )}
                                        checked={input.value}
                                        onChange={() =>
                                          input.onChange(!input.value)
                                        }
                                      />
                                    )}
                                  </Field>
                                </div>
                              )}
                          </div>
                        </li>
                      </ul>
                    </CollapsibleSection>

                    {(hasSubscription(
                      activeUserOrganization,
                      'feature-guest-my-page'
                    ) ||
                      hasSubscription(
                        activeUserOrganization,
                        'feature-digital-waiver'
                      ) ||
                      hasSubscription(
                        activeUserOrganization,
                        'feature-review'
                      ) ||
                      hasSubscription(
                        activeUserOrganization,
                        'feature-image-download'
                      )) && (
                      <CollapsibleSection
                        id="subscription"
                        title={t('Subscriptions')}
                        setCurrentSectionId={setCurrentSectionId}
                      >
                        <ul className={styles['p-general-list']}>
                          {hasSubscription(
                            activeUserOrganization,
                            'feature-guest-my-page'
                          ) && (
                            <li className={styles['p-general-list__item']}>
                              <div
                                className={styles['p-general-list__item__ttl']}
                              >
                                <div
                                  className={
                                    styles['p-general-list__item__ttl__txt']
                                  }
                                >
                                  <div>{t('My Page for Guest')}</div>
                                </div>
                              </div>
                              <div
                                className={styles['p-general-list__item__body']}
                              >
                                <div
                                  className={
                                    styles[
                                      'p-general-products__section__body__item'
                                    ]
                                  }
                                >
                                  {hasSubscription(
                                    activeUserOrganization,
                                    'feature-customer-ledger'
                                  ) ? (
                                    <>
                                      {t(
                                        'Please set the allowed operations on My Page for Guest on "Members > Settings" page'
                                      )}
                                    </>
                                  ) : (
                                    <GuestMyPageSettings />
                                  )}
                                </div>
                              </div>
                            </li>
                          )}
                          {(hasSubscription(
                            activeUserOrganization,
                            'feature-digital-waiver'
                          ) ||
                            hasSubscription(
                              activeUserOrganization,
                              'feature-review'
                            ) ||
                            hasSubscription(
                              activeUserOrganization,
                              'feature-image-download'
                            )) && (
                            <li className={styles['p-general-list__item']}>
                              <div
                                className={styles['p-general-list__item__ttl']}
                              >
                                <div
                                  className={
                                    styles['p-general-list__item__ttl__txt']
                                  }
                                >
                                  <div>{t('Agent Reservations')}</div>
                                </div>
                              </div>
                              <div
                                className={styles['p-general-list__item__body']}
                              >
                                <div
                                  className={
                                    styles[
                                      'p-general-products__section__body__item'
                                    ]
                                  }
                                >
                                  <Field name="showEmailOffersOptionForAgentBookings">
                                    {({ input }) => (
                                      <Toggle
                                        label={t(
                                          'Allow agent reservations to opt-in to email offers when:'
                                        )}
                                        checked={input.value}
                                        onChange={() =>
                                          input.onChange(!input.value)
                                        }
                                      />
                                    )}
                                  </Field>
                                  <ul
                                    className={
                                      styles['p-general-list__item__body__dot']
                                    }
                                  >
                                    <li>
                                      <i></i>
                                      {t('Submitting Waivers')}
                                    </li>
                                    <li>
                                      <i></i>
                                      {t('Downloading Images')}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          )}
                        </ul>
                      </CollapsibleSection>
                    )}
                  </section>
                  <div
                    className={clsx(
                      styles['p-general-products__fixed'],
                      styles['is-active']
                    )}
                  >
                    <div className={styles['p-general-products__fixed__main']}>
                      <Button
                        text={t('Save')}
                        type="submit"
                        color="primary"
                        size="md"
                        loading={submitting}
                        style={{
                          width: '60px',
                          fontWeight: 'var(--text-semibold)',
                        }}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
          {tutorialActive && showPopUp && (
            <SiteSettingsTutorialPopUp
              popUpDisable={() => setShowPopUp(false)}
            />
          )}
        </div>
        <div
          className={clsx(
            baseStyles['l-main__body__flex__right'],
            baseStyles['sticky']
          )}
        >
          <PcSidebarMenu
            sections={sections}
            currentSectionId={currentSectionId}
          />
        </div>
      </div>
    </div>
  );
};
