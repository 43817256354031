import { combineReducers } from 'redux';

import {
  SET_MAP_DASHBOARD_SELECTED_MAP_ID,
  SET_MAP_DASHBOARD_BASE_DATE_RANGE,
  SET_MAP_DASHBOARD_COMPARISON_DATE_RANGE,
} from 'client/constants/ActionTypes';
import { getDateRangeForPreset } from 'client/libraries/util/dateRangePresets';
import type { MapDashboardControlsAction } from 'client/actions/mapDashboardControls';

export interface DateRange {
  start: string;
  end: string;
}

const selectedMapId = (
  state: string | null = null,
  action: MapDashboardControlsAction
) => {
  switch (action.type) {
    case SET_MAP_DASHBOARD_SELECTED_MAP_ID:
      return action.selectedMapId;

    default:
      return state;
  }
};

const baseDateRange = (
  state: DateRange = getDateRangeForPreset('PREV_7_DAYS'),
  action: MapDashboardControlsAction
) => {
  switch (action.type) {
    case SET_MAP_DASHBOARD_BASE_DATE_RANGE:
      return action.baseDateRange;

    default:
      return state;
  }
};

const comparisonDateRange = (
  state: DateRange | null = null,
  action: MapDashboardControlsAction
) => {
  switch (action.type) {
    case SET_MAP_DASHBOARD_COMPARISON_DATE_RANGE:
      return action.comparisonDateRange;

    default:
      return state;
  }
};

interface State {
  selectedMapId: string | null;
  baseDateRange: DateRange;
  comparisonDateRange: DateRange | null;
}

export const mapDashboardControls = combineReducers<State>({
  selectedMapId,
  baseDateRange,
  comparisonDateRange,
});
