import * as React from 'react';
import clsx from 'clsx';

import styles from 'client/base.module.css';

type Props = {
  size: 'tiny' | 'small' | 'middle';
  style: 'blue' | 'green' | 'yellow' | 'red' | 'gray' | 'more' | 'link' | 'usa';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  content?: any;
  flex?: boolean;
  width?: number | string;
  loading?: boolean;
};

export const Button = ({
  children,
  loading,
  onClick,
  size,
  style,
  type,
  disabled,
  flex,
  width,
}: Props) => {
  return (
    <button
      type={type || 'button'}
      className={clsx(
        styles['base-btn'],
        styles[size],
        flex ? styles['flex'] : '',
        disabled ? styles['is-disabled'] : styles[style],
        loading && styles['loading']
      )}
      onClick={onClick}
      style={{
        width,
      }}
      disabled={disabled || loading}
    >
      {!loading && children}
    </button>
  );
};

const ButtonTemplate = ({
  children,
  onClick,
  disabled,
  content,
  style,
  size,
  type,
  loading,
}: Props) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      style={style}
      size={size}
      type={type}
      loading={loading}
    >
      {content ? <>{content}</> : children ? children : null}
    </Button>
  );
};

const ButtonGreen = ({
  children,
  onClick,
  disabled,
  content,
  size = 'middle',
  type,
}: Partial<Props>) => {
  return (
    <ButtonTemplate
      onClick={onClick}
      disabled={disabled}
      content={content}
      size={size}
      style="green"
      type={type}
    >
      {children}
    </ButtonTemplate>
  );
};

const ButtonGray = ({
  children,
  onClick,
  disabled,
  content,
  loading,
  size = 'middle',
  type,
}: Partial<Props>) => {
  return (
    <ButtonTemplate
      onClick={onClick}
      disabled={disabled}
      content={content}
      size={size}
      style="gray"
      type={type}
      loading={loading}
    >
      {children}
    </ButtonTemplate>
  );
};

const ButtonRed = ({
  children,
  onClick,
  disabled,
  content,
  size = 'middle',
  type,
  loading,
}: Partial<Props>) => {
  return (
    <ButtonTemplate
      onClick={onClick}
      disabled={disabled}
      content={content}
      size={size}
      style="red"
      type={type}
      loading={loading}
    >
      {children}
    </ButtonTemplate>
  );
};

const ButtonYellow = ({
  children,
  onClick,
  disabled,
  content,
  size = 'middle',
  type,
  loading,
}: Partial<Props>) => {
  return (
    <ButtonTemplate
      onClick={onClick}
      disabled={disabled}
      content={content}
      size={size}
      style="yellow"
      type={type}
      loading={loading}
    >
      {children}
    </ButtonTemplate>
  );
};

const ButtonBlue = ({
  children,
  onClick,
  disabled,
  content,
  size = 'middle',
  type,
  loading,
}: Partial<Props>) => {
  return (
    <ButtonTemplate
      onClick={onClick}
      disabled={disabled}
      content={content}
      size={size}
      style="blue"
      type={type}
      loading={loading}
    >
      {children}
    </ButtonTemplate>
  );
};

Button.Cancel = ButtonGray;
Button.Submit = ButtonBlue;
Button.Caution = ButtonRed;
Button.Sub = ButtonGray;
Button.Delete = ButtonRed;
Button.Create = ButtonGreen;
Button.Update = ButtonYellow;
Button.Transition = ButtonBlue;
