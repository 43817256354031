import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import styles from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ReservationCreate.module.css';
import { config } from 'client/config';
import { ModalLoader } from 'client/components/ModalLoader';
import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import type {
  Product,
  ProductInstance,
  ProductSummary,
} from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import stopIcon from 'client/images/ic_stop.svg';
import rqIcon from 'client/images/ic_rq.svg';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { Badge } from 'client/components/v3/Common/Badge';
import { Button } from 'client/components/v3/Common/Button';
import {
  SalesStatus,
  getProductSalesStatus,
} from 'client/libraries/util/getProductSalesStatus';
import { Radio } from 'client/components/v3/Form/Radio';
import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';

export type CustomCalendarEvent = {
  id: string;
  time: string;
  title: string;
  resource: ProductInstance;
  style: Record<string, any>;
};

type Props = {
  defaultDate?: Moment;
  productSelectorIsSearchable?: boolean;
  productSelectorWithDisabled?: boolean;
  title?: string;
  events: null | CustomCalendarEvent[];
  timezone: string;
  locale: string;
  product: null | Product;
  onRangeChange: (arg0: Record<string, any>) => void;
  onEventClick?: (arg0: ProductInstance) => void;
  loading?: boolean;
  showProductSelector?: boolean;
  products?: ProductSummary[];
  onChangeProduct?: (arg0: string) => void;
  productStatus: SalesStatus | 'FREE_FORMAT';
  onChangeProductStatus: (arg0: SalesStatus | 'FREE_FORMAT') => void;
  initialProductId?: string;
};

type ButtonProps = {
  isClicked: boolean;
  onClick: (newState: boolean) => void;
  setOpenDate: (value: React.SetStateAction<number>) => void;
  eventOfTheDay: CustomCalendarEvent[];
  widx: number;
  didx: number;
  numOfList: number;
};

const SpShowMoreButton = ({
  isClicked,
  onClick,
  setOpenDate,
  eventOfTheDay,
  widx,
  didx,
  numOfList,
}: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        styles['p-calendarCell__bottom__cell__moreSp'],
        baseStyles['u-pcHidden']
      )}
    >
      <Button
        text={
          isClicked
            ? t('Close')
            : t('More {{count}}', {
                count: String(eventOfTheDay.length - numOfList),
              })
        }
        iconAfterText={
          isClicked ? (
            <i className="c-icon-outline-arrows-chevron-up"></i>
          ) : (
            <i className="c-icon-outline-arrows-chevron-down"></i>
          )
        }
        size="xs"
        color="secondarycolor"
        useFullWidth={true}
        onClick={() => {
          setOpenDate(widx * 7 + didx);
          onClick(!isClicked);
        }}
      />
    </div>
  );
};

type LocationState = {
  isFromProductDetailsPage?: boolean;
  isChangeReservation?: boolean;
  productInstanceId?: string;
};

export const CustomCalendar = ({
  defaultDate,
  productSelectorIsSearchable,
  productSelectorWithDisabled,
  events,
  timezone,
  locale,
  product,
  onRangeChange,
  onEventClick,
  loading,
  showProductSelector,
  products,
  onChangeProduct,
  productStatus,
  onChangeProductStatus,
  initialProductId,
}: Props) => {
  const location = useLocation();
  const {
    isFromProductDetailsPage = false,
    isChangeReservation = false,
    productInstanceId,
  }: LocationState = location.state || {};
  const [productId, setProductId] = useState<string | null>(
    product ? product.id : ''
  );
  const [disableProductInput, setDisableProductInput] = useState<boolean>(true);
  const [movedMonth, setMovedMonth] = useState<number>(() => {
    // If defaultDate props is provided, display calendar range based on the value.
    // If defaultDate props is not provided, display calendar range based on product's today date.
    let val: number;

    if (!defaultDate) {
      val = 0;
    } else {
      const today = moment.tz(timezone).locale(locale);
      const target = moment(defaultDate.format());
      const yToday = today.get('year');
      const yTarget = target.get('year');
      // At Moment.js, get('month') start from 0 (Jan) ~ 11 (Dec).
      const mToday = today.get('month');
      const mTarget = target.get('month');

      if (yTarget < yToday) {
        // Target date has passed.
        val = 0;
      } else {
        // Target year is greater or equal to current year.
        val = 12 * (yTarget - yToday) + mTarget - mToday;
      }
    }

    return val;
  });

  const [openDate, setOpenDate] = useState<number>(-1);
  const [spShowMoreButtonClickedStates, setSpShowMoreButtonClickedStates] =
    useState<{
      [key: string]: boolean;
    }>({});
  const [selectedCell, setSelectedCell] = useState<string | null>(null);
  const numOfList = 3;
  const { t } = useTranslation();

  const [freeFormatProductName, setFreeFormatProductName] =
    useState<string>('');
  const [freeFormatStartDate, setFreeFormatStartDate] = useState<string>('');
  const [freeFormatStartTime, setFreeFormatStartTime] =
    useState<string>('00:00');

  const getDaysOfTheMonth = (
    month: number,
    year: number,
    timezone: string
  ): Moment[] => {
    const date = moment.tz(
      {
        year: year,
        month: month,
        day: 1,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      },
      timezone
    );
    let days = [];
    const before = moment(date);

    for (let i = before.day(); i > 0; i--) {
      days.push(moment(before.add('-1', 'day')));
    }

    days = days.reverse();

    while (date.month() === month) {
      days.push(moment(date));
      date.add('1', 'day');
    }

    while (date.day() !== 0) {
      days.push(moment(date));
      date.add('1', 'day');
    }

    return days;
  };

  useEffect(() => {
    const today = moment.tz(timezone).locale(locale);
    const target = today.add(`${movedMonth}`, 'month');
    onRangeChange({
      start: moment(target).startOf('month').add(-1, 'weeks'),
      end: moment(target).endOf('month').add(1, 'weeks'),
    });
  }, [movedMonth]);

  // Enable Product input when `products` is ready, if it has disabled.
  useEffect(() => {
    if (products && products.length > 0) {
      setDisableProductInput(false);
    }
  }, [products]);
  const today = moment.tz(timezone).locale(locale);
  const target = moment(today).add(`${movedMonth}`, 'month');
  const days = getDaysOfTheMonth(target.month(), target.year(), timezone);

  const getProductOptions = () => {
    let options: { text: string; value: string; key: string }[] = [];

    if (products) {
      options = [
        ...products
          .filter((p) => getProductSalesStatus(p) === productStatus)
          .filter((p) => p.is_dynamic_package_product?.value !== true)
          .map((p) => ({
            value: p.id ?? '',
            key: p.id ?? '',
            text: getVerboseDisplayProductName(p),
          })),
      ];
    }
    return options;
  };

  // Reset selected product & product instance panel when product status changes
  useEffect(() => {
    if (!isFromProductDetailsPage) {
      setProductId(null);
    }
    if (onChangeProduct) {
      onChangeProduct('');
    }
  }, [productStatus]);

  // If coming from product details page, set selected product according to product in details
  useEffect(() => {
    if (isFromProductDetailsPage && product && onChangeProduct) {
      setDisableProductInput(true);
      onChangeProduct(product.id);
      setProductId(product.id);
      onChangeProductStatus(getProductSalesStatus(product));
    }
  }, [isFromProductDetailsPage, product]);

  // If coming from change reservation, set selected product according to product in reservation
  // Also select the initial product instance according to reservation
  useEffect(() => {
    if (
      isChangeReservation &&
      product &&
      onChangeProduct &&
      productInstanceId &&
      !loading // Wait until loading finishes
    ) {
      onChangeProduct(product.id);
      setProductId(product.id);
      onChangeProductStatus(getProductSalesStatus(product));

      const productInstance = events?.find(
        (e) => e.id === productInstanceId
      )?.resource;
      if (productInstance) {
        onEventClick && onEventClick(productInstance);
        handleCellClick(productInstance.id);
      }
    }
  }, [isChangeReservation, product, productInstanceId, loading]);

  // Update product id if initialProductId is passed
  useEffect(() => {
    if (initialProductId) {
      setProductId(initialProductId);
    }
  }, [initialProductId]);

  const handleCellClick = (cell: string) => {
    setSelectedCell(cell);
  };

  const handleSpShowMoreButtonClick = (
    week: number,
    day: number,
    newState: boolean
  ) => {
    setSpShowMoreButtonClickedStates({
      ...spShowMoreButtonClickedStates,
      [`${week}-${day}`]: newState,
    });
  };

  return (
    <>
      {showProductSelector && (
        <section className={styles['g-section']}>
          <div className={styles['p-reservationsCreate__search']}>
            <div className={styles['p-reservationsCreate__search__header']}>
              <div
                className={
                  styles['p-reservationsCreate__search__header__title']
                }
              >
                {t('Select Product')}
              </div>
            </div>
            <div className={styles['p-reservationsCreate__search__body']}>
              <div
                className={styles['p-reservationsCreate__search__body__item']}
              >
                <div
                  className={clsx(
                    styles['p-reservationsCreate__search__body__item__flex'],
                    styles['p-reservationsCreate__search__body__item__radio']
                  )}
                >
                  <p
                    className={
                      styles[
                        'p-reservationsCreate__search__body__item__radiotitle'
                      ]
                    }
                  >
                    {t('Product Status')}
                  </p>
                  <Radio
                    label={t('On Sale')}
                    checked={productStatus === 'ON_SALE'}
                    onChange={() => {
                      onChangeProductStatus('ON_SALE');
                    }}
                    size="sm"
                    disabled={disableProductInput}
                  />
                  <Radio
                    label={t('Not On Sale')}
                    checked={productStatus === 'LIST_ONLY'}
                    onChange={() => {
                      onChangeProductStatus('LIST_ONLY');
                    }}
                    size="sm"
                    disabled={disableProductInput}
                  />
                  <Radio
                    label={t('Not Listed')}
                    checked={productStatus === 'NOT_LISTED'}
                    onChange={() => {
                      onChangeProductStatus('NOT_LISTED');
                    }}
                    size="sm"
                    disabled={disableProductInput}
                  />
                  {config.enableFreeFormatReservation && (
                    <Radio
                      label={t('Free Format Reservation')}
                      checked={productStatus === 'FREE_FORMAT'}
                      onChange={() => {
                        onChangeProductStatus('FREE_FORMAT');
                      }}
                      size="sm"
                      disabled={disableProductInput}
                    />
                  )}
                </div>
              </div>
              {productStatus !== 'FREE_FORMAT' && (
                <div
                  className={styles['p-reservationsCreate__search__body__item']}
                >
                  <SingleDropdown
                    label={t('Product Name')}
                    options={getProductOptions()}
                    selectedOption={productId ?? ''}
                    onChange={(value) => {
                      setProductId(value);

                      if (onChangeProduct) {
                        // We should not update product unless value matches the possible product in options
                        // This is to avoid excessive product update during text search which affects API calls
                        const possibleProduct = getProductOptions().find(
                          (option) => option.key === value
                        );
                        if (possibleProduct) {
                          onChangeProduct(value);
                        }
                      }
                    }}
                    placeholder={
                      productSelectorIsSearchable
                        ? t('Select a product')
                        : undefined
                    }
                    disabled={
                      productSelectorWithDisabled ? disableProductInput : false
                    }
                    searchable={productSelectorIsSearchable ?? false}
                    resetSearchText={productId === null}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      )}

      {/* Calendar range selection start */}
      {productStatus !== 'FREE_FORMAT' && (
        <section className={clsx(styles['g-section'], baseStyles['u-mt-6'])}>
          <div className={styles['p-reservationsCreate__calendar']}>
            <div className={styles['p-reservationsCreate__calendar__header']}>
              <div className={styles['p-reservationsCreate__calendar__ttl']}>
                {t('Select reservation slot')}
              </div>
            </div>
            <div className={styles['p-reservationsCreate__calendar__body']}>
              <div className={styles['p-calendar']}>
                <div className={styles['p-calendar__header']}>
                  <div className={styles['p-calendar__header__date']}>
                    <div className={styles['p-calendar__header__date__select']}>
                      <a
                        onClick={() => {
                          setMovedMonth(movedMonth - 1);
                        }}
                      >
                        <i className="c-icon-outline-arrows-chevron-left"></i>
                      </a>
                      <p style={{ margin: 0 }}>{target.format('YYYY/MM')}</p>
                      <a
                        onClick={() => {
                          setMovedMonth(movedMonth + 1);
                        }}
                      >
                        <i className="c-icon-outline-arrows-chevron-right"></i>
                      </a>
                    </div>
                    <a
                      className={styles['p-calendar__header__date__today']}
                      onClick={() => {
                        setMovedMonth(0);
                      }}
                    >
                      {t('Today')}
                    </a>
                  </div>
                  <p className={styles['p-calendar__header__timezone']}>
                    {t('Timezone: {{tz}}', {
                      tz: timezone,
                    })}
                  </p>
                </div>
                <div className={styles['p-calendar__body']}>
                  <>
                    <div
                      className={clsx(
                        styles['p-calendar__body__header'],
                        baseStyles['u-spHidden']
                      )}
                    >
                      <p className={styles['red']}>{t('Sun')}</p>
                      <p>{t('Mon')}</p>
                      <p>{t('Tue')}</p>
                      <p>{t('Wed')}</p>
                      <p>{t('Thu')}</p>
                      <p>{t('Fri')}</p>
                      <p className={styles['blue']}>{t('Sat')}</p>
                    </div>
                    <ul className={styles['p-calendar__body__body']}>
                      {_.chunk(days, 7).map((week, widx) => {
                        return (
                          <React.Fragment key={widx}>
                            {week.map((day, didx) => {
                              // If loading or no events, show loading indicator
                              if (loading || events === null) {
                                return (
                                  <li
                                    key={didx}
                                    className={clsx(
                                      styles['p-calendar__body__body__item'],
                                      styles[
                                        'p-calendar__body__body__item__loading'
                                      ]
                                    )}
                                  >
                                    <ModalLoader />
                                  </li>
                                );
                              }

                              const eventOfTheDay = events.filter((e) => {
                                const startTime = moment(
                                  e.resource.start_date_time_utc
                                ).tz(timezone);

                                if (
                                  day.year() === startTime.year() &&
                                  day.month() === startTime.month() &&
                                  day.date() === startTime.date()
                                ) {
                                  return true;
                                }

                                return false;
                              });

                              return (
                                <li
                                  key={didx}
                                  className={
                                    styles['p-calendar__body__body__item']
                                  }
                                >
                                  <div
                                    className={clsx(
                                      styles['p-calendarCell'],
                                      target.month() === day.month()
                                        ? ''
                                        : styles['disabled']
                                    )}
                                  >
                                    <div
                                      className={styles['p-calendarCell__top']}
                                    >
                                      <p
                                        className={clsx(
                                          styles['p-calendarCell__top__week'],
                                          day.format('d') === '0' &&
                                            styles['red'],
                                          day.format('d') === '6' &&
                                            styles['blue'],
                                          baseStyles['u-pcHidden']
                                        )}
                                      >
                                        {day.locale(locale).format('ddd')}
                                      </p>
                                      <p
                                        className={clsx(
                                          styles['p-calendarCell__top__day'],
                                          day.year() === today.year() &&
                                            day.month() === today.month() &&
                                            day.date() === today.date()
                                            ? styles['today']
                                            : ''
                                        )}
                                      >
                                        {day.date()}
                                      </p>
                                    </div>
                                    <div
                                      className={
                                        styles['p-calendarCell__bottom']
                                      }
                                    >
                                      <>
                                        {/* Show availabilities for PC */}
                                        {eventOfTheDay
                                          .filter((_, sid) => {
                                            return sid < numOfList;
                                          })
                                          .map((e, sidx) => {
                                            return (
                                              <div
                                                className={clsx(
                                                  styles[
                                                    'p-calendarCell__bottom__cell'
                                                  ],
                                                  styles[
                                                    e.style['backgroundColor']
                                                  ],
                                                  selectedCell &&
                                                    selectedCell ===
                                                      e.resource.id &&
                                                    styles['selected'],
                                                  baseStyles['u-spHidden']
                                                )}
                                                key={sidx}
                                                onClick={() => {
                                                  onEventClick &&
                                                    onEventClick(e.resource);
                                                  handleCellClick(
                                                    e.resource.id
                                                  );
                                                }}
                                              >
                                                <div
                                                  className={
                                                    styles[
                                                      'p-calendarCell__bottom__cell__event'
                                                    ]
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles[
                                                        'p-calendarCell__bottom__cell__event__time'
                                                      ]
                                                    }
                                                  >
                                                    <p>{e.time}</p>
                                                    <span>{e.title}</span>
                                                  </div>
                                                  {e.style?.iconType &&
                                                    e.style.iconType !==
                                                      'NO_ICON' &&
                                                    (e.style?.iconType ===
                                                    'STOP' ? (
                                                      <img src={stopIcon} />
                                                    ) : (
                                                      <img src={rqIcon} />
                                                    ))}
                                                </div>
                                              </div>
                                            );
                                          })}

                                        {/* Show availaibilities for mobile */}
                                        {eventOfTheDay
                                          .filter((_, sid) => {
                                            // Show all if show more button is clicked for the particular cell
                                            return spShowMoreButtonClickedStates[
                                              `${widx}-${didx}`
                                            ]
                                              ? true
                                              : sid < numOfList;
                                          })
                                          .map((e, sidx) => {
                                            return (
                                              <div
                                                className={clsx(
                                                  styles[
                                                    'p-calendarCell__bottom__cell'
                                                  ],
                                                  styles[
                                                    e.style['backgroundColor']
                                                  ],
                                                  selectedCell &&
                                                    selectedCell ===
                                                      e.resource.id &&
                                                    styles['selected'],
                                                  baseStyles['u-pcHidden']
                                                )}
                                                key={sidx}
                                                onClick={() => {
                                                  onEventClick &&
                                                    onEventClick(e.resource);
                                                  handleCellClick(
                                                    e.resource.id
                                                  );
                                                }}
                                              >
                                                <div
                                                  className={
                                                    styles[
                                                      'p-calendarCell__bottom__cell__event'
                                                    ]
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles[
                                                        'p-calendarCell__bottom__cell__event__time'
                                                      ]
                                                    }
                                                  >
                                                    <p>{e.time}</p>
                                                    <span>{e.title}</span>
                                                  </div>
                                                  {e.style?.iconType &&
                                                    e.style.iconType !==
                                                      'NO_ICON' &&
                                                    (e.style?.iconType ===
                                                    'STOP' ? (
                                                      <img src={stopIcon} />
                                                    ) : (
                                                      <img src={rqIcon} />
                                                    ))}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        {eventOfTheDay.length > numOfList && (
                                          <div
                                            className={clsx(
                                              styles[
                                                'p-calendarCell__bottom__cell__morePC'
                                              ],
                                              baseStyles['u-spHidden']
                                            )}
                                            onClick={() => {
                                              setOpenDate(widx * 7 + didx);
                                            }}
                                          >
                                            <a>
                                              {t('More {{count}}', {
                                                count: String(
                                                  eventOfTheDay.length -
                                                    numOfList
                                                ),
                                              })}
                                            </a>
                                          </div>
                                        )}
                                        {eventOfTheDay.length > numOfList && (
                                          <SpShowMoreButton
                                            isClicked={
                                              spShowMoreButtonClickedStates[
                                                `${widx}-${didx}`
                                              ]
                                            }
                                            onClick={(newState: boolean) =>
                                              handleSpShowMoreButtonClick(
                                                widx,
                                                didx,
                                                newState
                                              )
                                            }
                                            setOpenDate={setOpenDate}
                                            eventOfTheDay={eventOfTheDay}
                                            widx={widx}
                                            didx={didx}
                                            numOfList={numOfList}
                                          />
                                        )}
                                      </>
                                      {eventOfTheDay.length >= numOfList && (
                                        <div
                                          className={clsx(
                                            styles[
                                              'p-calendarCell__bottom__cell__menu'
                                            ],
                                            openDate === widx * 7 + didx
                                              ? styles['is-active']
                                              : '',
                                            baseStyles['u-spHidden']
                                          )}
                                        >
                                          <div
                                            className={clsx(
                                              styles[
                                                'p-calendarCell__bottom__cell__menu__header'
                                              ]
                                            )}
                                          >
                                            <p>{day.format('l')}</p>
                                            <a
                                              onClick={() => {
                                                setOpenDate(-1);
                                              }}
                                            >
                                              <i className="c-icon-outline-general-x-close"></i>
                                            </a>
                                          </div>
                                          {/* Show availaibilities when show more popup is clicked */}
                                          <ul
                                            className={
                                              styles[
                                                'p-calendarCell__bottom__cell__menu__body'
                                              ]
                                            }
                                          >
                                            {eventOfTheDay.map((e, sidx) => {
                                              return (
                                                <div
                                                  className={clsx(
                                                    styles[
                                                      'p-calendarCell__bottom__cell'
                                                    ],
                                                    styles[
                                                      e.style['backgroundColor']
                                                    ],
                                                    selectedCell &&
                                                      selectedCell ===
                                                        e.resource.id &&
                                                      styles['selected']
                                                  )}
                                                  key={sidx}
                                                  onClick={() => {
                                                    onEventClick &&
                                                      onEventClick(e.resource);
                                                    handleCellClick(
                                                      e.resource.id
                                                    );
                                                  }}
                                                >
                                                  <div
                                                    className={
                                                      styles[
                                                        'p-calendarCell__bottom__cell__event'
                                                      ]
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles[
                                                          'p-calendarCell__bottom__cell__event__time'
                                                        ]
                                                      }
                                                    >
                                                      <p>{e.time}</p>
                                                      <span>{e.title}</span>
                                                    </div>
                                                    {e.style?.iconType &&
                                                      e.style.iconType !==
                                                        'NO_ICON' &&
                                                      (e.style?.iconType ===
                                                      'STOP' ? (
                                                        <img src={stopIcon} />
                                                      ) : (
                                                        <img src={rqIcon} />
                                                      ))}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  </>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* Calendar range selection end */}

      {productStatus === 'FREE_FORMAT' && (
        <section className={clsx(styles['g-section'], baseStyles['u-mt-6'])}>
          <div
            className={styles['p-reservationsCreate__freeFormatReservation']}
          >
            <div
              className={
                styles['p-reservationsCreate__freeFormatReservation__header']
              }
            >
              <div
                className={
                  styles[
                    'p-reservationsCreate__freeFormatReservation__header__title'
                  ]
                }
              >
                {t('Free Format Reservation')}
              </div>
            </div>
            <div
              className={
                styles['p-reservationsCreate__freeFormatReservation__body']
              }
            >
              <div
                className={
                  styles[
                    'p-reservationsCreate__freeFormatReservation__body__item'
                  ]
                }
              >
                <div
                  className={
                    styles[
                      'p-reservationsCreate__freeFormatReservation__body__item__ttl'
                    ]
                  }
                >
                  <div
                    className={
                      styles[
                        'p-reservationsCreate__freeFormatReservation__body__item__ttl__txt'
                      ]
                    }
                  >
                    <p style={{ marginRight: '4px' }}>
                      {t('Product Name for Free Format Reservation')}
                    </p>
                    <Badge
                      size="sm"
                      label={t('Required')}
                      color="warning-contained"
                    />
                  </div>
                </div>
                <div
                  className={
                    styles[
                      'p-reservationsCreate__freeFormatReservation__body__item__body'
                    ]
                  }
                >
                  <TextField
                    value={freeFormatProductName}
                    onChange={(value) => setFreeFormatProductName(value)}
                  />
                </div>
              </div>
              <div
                className={
                  styles[
                    'p-reservationsCreate__freeFormatReservation__body__item'
                  ]
                }
              >
                <div
                  className={
                    styles[
                      'p-reservationsCreate__freeFormatReservation__body__item__ttl'
                    ]
                  }
                >
                  <div
                    className={
                      styles[
                        'p-reservationsCreate__freeFormatReservation__body__item__ttl__txt'
                      ]
                    }
                  >
                    <p style={{ marginRight: '4px' }}>
                      {t('Participation Date')}
                    </p>
                    <Badge
                      size="sm"
                      label={t('Required')}
                      color="warning-contained"
                    />
                  </div>
                </div>
                <div
                  className={
                    styles[
                      'p-reservationsCreate__freeFormatReservation__body__item__body'
                    ]
                  }
                >
                  <SimpleDateInput
                    onChange={(value) => {
                      setFreeFormatStartDate(value ?? '');
                    }}
                    dateFrom={freeFormatStartDate}
                  />
                </div>
              </div>
              <div
                className={
                  styles[
                    'p-reservationsCreate__freeFormatReservation__body__item'
                  ]
                }
              >
                <div
                  className={
                    styles[
                      'p-reservationsCreate__freeFormatReservation__body__item__ttl'
                    ]
                  }
                >
                  <div
                    className={
                      styles[
                        'p-reservationsCreate__freeFormatReservation__body__item__ttl__txt'
                      ]
                    }
                  >
                    <p>{t('Start Time')}</p>
                  </div>
                </div>
                <div
                  className={
                    styles[
                      'p-reservationsCreate__freeFormatReservation__body__item__body'
                    ]
                  }
                >
                  <TimePicker
                    allowEmpty={false}
                    value={moment(freeFormatStartTime, 'HH:mm')}
                    onChange={(newMoment) => {
                      if (!newMoment) {
                        return;
                      }

                      setFreeFormatStartTime(newMoment.format('HH:mm'));
                    }}
                  />
                </div>
              </div>
              <div
                className={
                  styles[
                    'p-reservationsCreate__freeFormatReservation__body__item'
                  ]
                }
              >
                <div
                  className={
                    styles[
                      'p-reservationsCreate__freeFormatReservation__body__item__ttl'
                    ]
                  }
                >
                  {(freeFormatProductName === '' ||
                    freeFormatStartDate === '') && (
                    <Button
                      text={t('Create new reservation')}
                      size="md"
                      color="primary"
                      disabled={true}
                    />
                  )}
                  {freeFormatProductName !== '' &&
                    freeFormatStartDate !== '' && (
                      <Link
                        to={{
                          pathname: `/freeformat/book`,
                          state: {
                            productName: freeFormatProductName,
                            participationDate: freeFormatStartDate,
                            startTime: freeFormatStartTime,
                          },
                        }}
                      >
                        <Button
                          text={t('Create new reservation')}
                          size="md"
                          color="primary"
                        />
                      </Link>
                    )}
                </div>
                <div
                  className={
                    styles[
                      'p-reservationsCreate__freeFormatReservation__body__item__body'
                    ]
                  }
                ></div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
