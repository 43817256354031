import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as Swagger from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { ListEditor } from 'client/components/v3/Form/ListEditor/ListEditor';
import { Modal } from 'client/components/v3/Form/Modal';
import { TextField } from 'client/components/v3/Form/TextField';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateOrganization } from 'client/actions/organizations';

import styles from './AreaGroupSettings.module.css';
import {
  AreaGroup,
  convertFormValuesToOrganizationPatch,
  convertSwaggerAreaGroupToFormValues,
  getInitialValues,
} from './FormValues';
import { ProvinceCityNameEditor } from './ProvinceCityNameEditor';

const getDefaultProvinceCityName = (): Swagger.CustomerProvinceCityName => {
  return {
    province: '',
    cities: [],
  };
};

type Props = {
  areaGroupIndex: number;
  onClose: () => void;
  onSubmitSuccess: (arg0: boolean) => void;
};

export const EditAreaGroupModal = ({
  areaGroupIndex,
  onClose,
  onSubmitSuccess,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const areaGroups = (activeUserOrganization?.customer_ledger_settings
    ?.customer_area_groups ?? []) as Swagger.CustomerAreaGroup[];

  const headerText = areaGroupIndex
    ? t('Edit Area Group')
    : t('Create New Area Group');

  const initialValues = useMemo((): AreaGroup => {
    if (areaGroupIndex >= areaGroups.length) {
      return getInitialValues();
    } else {
      return {
        ...convertSwaggerAreaGroupToFormValues(areaGroups[areaGroupIndex]),
      };
    }
  }, [areaGroupIndex, areaGroups]);

  return (
    <Form<AreaGroup>
      initialValues={initialValues}
      onSubmit={async (values: AreaGroup) => {
        const orgId = activeUserOrganization?.id || '';
        const patch = convertFormValuesToOrganizationPatch(
          activeUserOrganization,
          areaGroupIndex,
          values
        );

        try {
          await dispatch(updateOrganization(orgId, 'SUPPLIER', patch));

          await onSubmitSuccess(true);
          await onClose();
        } catch (e) {
          console.error(e);
        }
      }}
      mutators={getArrayMutators()}
      keepDirtyOnReinitialize={true}
    >
      {({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit} id="editTagModalForm">
          <Modal
            title={headerText}
            open={true}
            onClose={onClose}
            rightActionChildren={
              <>
                <Button text={t('Cancel')} color="white" onClick={onClose} />
                <Button
                  text={t('Save')}
                  loading={submitting}
                  type="submit"
                  form="editTagModalForm"
                />
              </>
            }
            style={{ width: '600px', height: '800px' }}
          >
            <div className={styles['p-areaGroupSettingsModal']}>
              <div className={styles['p-areaGroupSettingsModal__item']}>
                <p className={styles['p-areaGroupSettingsModal__item__ttl']}>
                  {t('Type')}
                </p>
                <div className={styles['p-areaGroupSettingsModal__item__body']}>
                  <Field name="type">
                    {({ input }) => {
                      return (
                        <EnumRadioButtonGroup
                          name="type"
                          options={[
                            {
                              label: t('By String'),
                              value: 'PROVINCE_CITY_NAME',
                            },
                            {
                              label: t('By ZIP / Postal Code'),
                              value: 'POSTAL_CODE',
                            },
                          ]}
                          onChange={(newValue: string) => {
                            input.onChange(newValue);
                          }}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
              <div className={styles['p-areaGroupSettingsModal__item']}>
                <p className={styles['p-areaGroupSettingsModal__item__ttl']}>
                  {t('Name')}
                </p>
                <div className={styles['p-areaGroupSettingsModal__item__body']}>
                  <Field name="name">
                    {({ input }) => {
                      return (
                        <TextField
                          value={input.value}
                          onChange={(value) => {
                            input.onChange(value);
                          }}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
              <div className={styles['p-areaGroupSettingsModal__item']}>
                {values.type === 'PROVINCE_CITY_NAME' && (
                  <>
                    <p
                      className={styles['p-areaGroupSettingsModal__item__ttl']}
                      style={{ marginBottom: '8px' }}
                    >
                      {t('State / Province / Region')} &gt;{' '}
                      {t('City / Ward / Town')}
                    </p>
                    <div
                      className={styles['p-areaGroupSettingsModal__item__body']}
                    >
                      <FieldArray name={'provinceCityNames'}>
                        {({ fields }) => {
                          return (
                            <>
                              <div
                                className={styles['p-tagSettingsModal__item']}
                              >
                                <div
                                  className={
                                    styles['p-tagSettingsModal__condition']
                                  }
                                >
                                  <ul
                                    className={
                                      styles[
                                        'p-tagSettingsModal__condition__list'
                                      ]
                                    }
                                  >
                                    {fields.map((name, index) => {
                                      return (
                                        <ProvinceCityNameEditor
                                          key={index}
                                          name={name}
                                          onDeleteButtonClick={() => {
                                            fields.remove(index);
                                          }}
                                        />
                                      );
                                    })}
                                  </ul>
                                  <a
                                    className={
                                      styles[
                                        'p-tagSettingsModal__condition__add'
                                      ]
                                    }
                                    onClick={() => {
                                      if (fields.length && fields.length > 0) {
                                        (fields as any).insertAt(
                                          fields.length + 1,
                                          getDefaultProvinceCityName()
                                        );
                                      } else {
                                        (fields as any).insertAt(
                                          0,
                                          getDefaultProvinceCityName()
                                        );
                                      }
                                    }}
                                  >
                                    <i className="c-icon-outline-general-plus-circle"></i>
                                    {t('Add Condition')}
                                  </a>
                                </div>
                              </div>
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </>
                )}
                {values.type === 'POSTAL_CODE' && (
                  <>
                    <p
                      className={styles['p-areaGroupSettingsModal__item__ttl']}
                    >
                      {t('ZIP / Postal Code')}
                    </p>
                    <div
                      className={styles['p-areaGroupSettingsModal__item__body']}
                    >
                      <ListEditor name={`postalCodes`} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal>
        </form>
      )}
    </Form>
  );
};
