import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_ANALYTICS_CUSTOMERS_SUCCESS,
  FETCH_ANALYTICS_CUSTOMERS_FAILURE,
  FETCH_ANALYTICS_CUSTOMERS_REQUEST,
  FETCH_ANALYTICS_CUSTOMER_SUCCESS,
  FETCH_ANALYTICS_CUSTOMER_FAILURE,
  FETCH_ANALYTICS_CUSTOMER_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';
import * as Swagger from 'shared/models/swagger';

import { getHTTPRequestHeaders } from '.';

export type SearchConditionGroup = Exclude<
  Exclude<
    Swagger.JourneyAnalyticsSettings['presets'],
    undefined
  >[0]['condition_groups'],
  undefined
>[0];

type Dispatch = ThunkDispatch<any, any, any>;

const fetchAnalyticsCustomersRequest = () => ({
  type: FETCH_ANALYTICS_CUSTOMERS_REQUEST,
});

const fetchAnalyticsCustomersSuccess = (response: any) => ({
  type: FETCH_ANALYTICS_CUSTOMERS_SUCCESS,
  response,
});

const fetchAnalyticsCustomersFailure = (error: any) => ({
  type: FETCH_ANALYTICS_CUSTOMERS_FAILURE,
  error,
});

let fetchAnalyticsCustomersCancel: () => void | undefined;
export const fetchAnalyticsCustomers =
  (conditionGroups: SearchConditionGroup[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchAnalyticsCustomersCancel?.();
    dispatch(fetchAnalyticsCustomersRequest());
    axios
      .post(
        '/api/analyticscustomers/search',
        {
          condition_groups: conditionGroups,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
          cancelToken: new axios.CancelToken(function executor(c) {
            fetchAnalyticsCustomersCancel = c;
          }),
        }
      )
      .then((result) => {
        dispatch(fetchAnalyticsCustomersSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchAnalyticsCustomersFailure('canceled'));
        dispatch(fetchAnalyticsCustomersFailure(error));
      });
  };

const fetchAnalyticsCustomerRequest = () => ({
  type: FETCH_ANALYTICS_CUSTOMER_REQUEST,
});

const fetchAnalyticsCustomerSuccess = (response: any) => ({
  type: FETCH_ANALYTICS_CUSTOMER_SUCCESS,
  response,
});

const fetchAnalyticsCustomerFailure = (error: any) => ({
  type: FETCH_ANALYTICS_CUSTOMER_FAILURE,
  error,
});

export const fetchAnalyticsCustomer =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchAnalyticsCustomerRequest());
    axios
      .get(`/api/analyticscustomers/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchAnalyticsCustomerSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchAnalyticsCustomerFailure('canceled'));
        else dispatch(fetchAnalyticsCustomerFailure(error));
      });
  };
