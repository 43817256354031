import * as Swagger from 'shared/models/swagger';

import { ParkBusyChart } from './ParkBusyChart';
import styles from './PopularTimesGadget.module.css';

interface PopularTimesGadgetProps {
  title: string;
  data: Swagger.DigitalMapAccessReportDataSet[] | null;
  isLoading: boolean;
}

export const PopularTimesGadget = ({
  title,
  data,
}: PopularTimesGadgetProps) => {
  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{title}</div>
          </div>
        </div>
        <div className={styles['body']}>
          <ParkBusyChart data={data} />
        </div>
      </div>
    </div>
  );
};
