import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/Form';
import { Message } from 'client/components/Message/Message';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { privateMarketplaceSelector } from 'client/reducers/privateMarketplace';
import { EditProductCollectionPageModal } from 'client/pages/PrivateMarketplace/PrivateMarketplaceProductCollectionPages/EditProductCollectionPageModal';
import { DraggableProductCollectionPage } from 'client/pages/PrivateMarketplace/PrivateMarketplaceProductCollectionPages/DraggableProductCollectionPage';
import { PrivateMarketplaceSelect } from 'client/pages/PrivateMarketplace/PrivateMarketplaceSelect/PrivateMarketplaceSelect';
import type { ReduxState } from 'client/reducers';
import {
  fetchProducts,
  fetchPassthroughCandidateProducts,
} from 'client/actions/products';
import {
  updateCategoryPages,
  updateFeaturePages,
} from 'client/actions/privateMarketplace';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/PrivateMarketplace/PrivateMarketplaceProductCollectionPages/PrivateMarketplaceProductCollectionPages.module.css';
import type { ProductCollectionPage } from 'shared/models/swagger';
import { isPartnershipAgent } from 'client/libraries/util/partnership';
import { activeUserOrganizationSelector } from 'client/reducers/user';

type Props = {
  collectionType: 'CATEGORY' | 'FEATURE';
};
export const PrivateMarketplaceProductCollectionPages = ({
  collectionType,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const privateMarketplace = useSelector(privateMarketplaceSelector);
  const updateError = useSelector(
    (state: ReduxState) => state.organizations.error
  );
  const lastUpdatedOrganization = useSelector(
    (state: ReduxState) => state.organizations.lastUpdated
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  React.useEffect(() => {
    dispatch(fetchProducts());
    if (isPartnershipAgent(activeUserOrganization)) {
      dispatch(
        fetchPassthroughCandidateProducts({
          agent_id: activeUserOrganization?.corresponding_organization_id ?? '',
        })
      );
    }
  }, []);
  const [initialError] = React.useState<string>(updateError);
  const [initialLastUpdatedOrganization] = React.useState<any>(
    lastUpdatedOrganization
  );
  const existingProductCollectionPages = React.useMemo(
    () =>
      collectionType === 'CATEGORY'
        ? privateMarketplace?.category_pages || []
        : privateMarketplace?.feature_pages || [],
    [privateMarketplace, collectionType]
  );
  const [productCollectionPages, setProductCollectionPages] = React.useState<
    ProductCollectionPage[]
  >(existingProductCollectionPages);
  React.useEffect(() => {
    setProductCollectionPages(existingProductCollectionPages);
  }, [existingProductCollectionPages]);
  const newCollectionText =
    collectionType === 'CATEGORY'
      ? t('Add New Category Page')
      : t('Add New Featured Page');
  const pristine = existingProductCollectionPages === productCollectionPages;

  const updateProductCollectionPages = (
    newProductCollectionPages: ProductCollectionPage[]
  ) =>
    collectionType === 'CATEGORY'
      ? dispatch(updateCategoryPages(newProductCollectionPages))
      : dispatch(updateFeaturePages(newProductCollectionPages));

  const error = updateError && updateError !== initialError ? updateError : '';
  const success =
    !updateError && lastUpdatedOrganization !== initialLastUpdatedOrganization
      ? true
      : false;
  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <PrivateMarketplaceSelect />
        <FormTableBox>
          <table>
            <tbody>
              <tr>
                <th>
                  {collectionType === 'CATEGORY'
                    ? t('Categories')
                    : t('Featured')}
                </th>
                <td>
                  <Button
                    width={200}
                    style="green"
                    size="middle"
                    onClick={() => setShowEditModal(true)}
                  >
                    {newCollectionText}
                  </Button>
                  <EditProductCollectionPageModal
                    onSave={(newProductCollectionPage) =>
                      updateProductCollectionPages([
                        newProductCollectionPage,
                        ...productCollectionPages,
                      ])
                    }
                    collectionType={collectionType}
                    open={showEditModal}
                    onClose={() => setShowEditModal(false)}
                  />
                  <div className={styles['collection-element-box']}>
                    {productCollectionPages.map((collectionPage, idx) => (
                      <DraggableProductCollectionPage
                        key={collectionPage.name}
                        collectionType={collectionType}
                        value={collectionPage}
                        index={idx}
                        onSave={(newProductCollectionPage) => {
                          const newProductCollectionPages = [
                            ...productCollectionPages,
                          ];
                          newProductCollectionPages[idx] =
                            newProductCollectionPage;
                          updateProductCollectionPages(
                            newProductCollectionPages
                          );
                        }}
                        moveItem={(dragIndex: number, hoverIndex: number) => {
                          const draggingProductCollectionPage =
                            productCollectionPages[dragIndex];
                          const newProductCollectionPages = [
                            ...productCollectionPages,
                          ];
                          newProductCollectionPages.splice(dragIndex, 1);
                          newProductCollectionPages.splice(
                            hoverIndex,
                            0,
                            draggingProductCollectionPage
                          );
                          setProductCollectionPages(newProductCollectionPages);
                        }}
                        deleteItem={() => {
                          const newPages = [...productCollectionPages];
                          newPages.splice(idx, 1);
                          updateProductCollectionPages(newPages);
                        }}
                      />
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </FormTableBox>
        {error && <Message error header={t('Update failed')} content={error} />}
        {success && <Message success header={t('Update succeeded')} />}
        <div className={baseStyles['base-main__box__body__bottomBtns']}>
          <Button
            disabled={pristine}
            style="gray"
            size="middle"
            onClick={() =>
              setProductCollectionPages(existingProductCollectionPages)
            }
          >
            {t('Discard')}
          </Button>
          <Button
            disabled={pristine}
            style="blue"
            size="middle"
            onClick={() => {
              updateProductCollectionPages(productCollectionPages);
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
