import React from 'react';
import moment from 'moment-timezone';

import { getTime } from '../util';

import styles from './TimeSlider.module.css';

interface SliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  timeStart: string;
}

export const TimeSlider: React.FC<SliderProps> = ({
  min,
  max,
  value,
  onChange,
  timeStart,
}) => {
  const progressWidth = `calc(${(value / max) * 100}%)`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value));
  };

  return (
    <div className={styles.container}>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderLabel}>
          {moment(timeStart, 'HH:mm').locale('en').format('h:mma')}
        </div>
        <div className={styles.controlContainer}>
          <div className={styles.sliderBackground}></div>
          <div
            className={styles.progress}
            style={{ width: progressWidth }}
          ></div>
          <input
            type="range"
            min={min}
            max={max}
            value={value}
            onChange={handleChange}
            className={styles.slider}
          />

          <div
            className={styles.slidingLabel}
            style={{
              left: `calc(${progressWidth} - 50px)`,
            }}
          >
            {moment(getTime(value, timeStart), 'HH:mm')
              .locale('en')
              .format('h:mma')}
          </div>
        </div>
        <div className={styles.sliderLabel}>
          {moment(getTime(max, timeStart), 'HH:mm')
            .locale('en')
            .format('h:mma')}
        </div>
      </div>
    </div>
  );
};
