import * as React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import * as Swagger from 'shared/models/swagger';

import { MapDashboardContext } from '../MapDashboardContext';

import styles from './ParkBusyChart.module.css';
import BarChart from './BarChart';
import { TimeSlider } from './TimeSlider';

function stddev(array: number[]) {
  const avg = _.sum(array) / array.length;
  return Math.sqrt(
    _.sum(_.map(array, (i) => Math.pow(i - avg, 2))) / array.length
  );
}

const getBusyLevelText = (barData: number[], time: number) => {
  // Get mean and standard deviation of the bar data
  const mean = _.mean(barData);
  const stdDev = stddev(barData);

  // Return busy level text based on current value compared to mean and stddev
  const currentValue = barData[time];

  if (currentValue === 0) {
    return 'Empty';
  }
  if (currentValue < mean - stdDev) {
    return 'Not Busy';
  } else if (currentValue < mean) {
    return 'Slightly Busy';
  } else if (currentValue < mean + stdDev) {
    return 'Moderately Busy';
  } else if (currentValue < mean + stdDev * 2) {
    return 'Quite Busy';
  } else {
    return 'Very Busy';
  }
};

export const ParkBusyChart: React.FC<{
  data: Swagger.DigitalMapVisitorReportDataSet[] | null;
}> = ({ data }) => {
  const { time, setTime } = React.useContext(MapDashboardContext);
  const barData = _.times(15, (i) => {
    const hour = i + 8;
    const date = moment().hour(hour).minute(0);
    const total =
      data && data.length > 0
        ? data?.[0]?.totals?.totals_by_time_of_day?.find(
            (total) => total.time_of_day === date.format('HH:mm')
          )?.user_count
        : 0;
    return total ?? 0;
  });

  return (
    <section className={styles.container}>
      <div className={styles.chartContainer}>
        <TimeSlider min={0} max={14} value={time} onChange={setTime} />
      </div>
      <div className={styles.chartWrapper}>
        <div className={styles.chartInner}>
          <div className={styles.chartContent}>
            <BarChart
              data={barData}
              highlightedIndex={time}
              onClickBar={(index) => {
                setTime(index);
              }}
            />
          </div>
          <div className={styles.busyInfoContainer}>
            <div className={styles.busyInfo}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ae23f66168141a3fc03f36c957078791f590b0e3f92aa7208db1ba70ce1446aa?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
                className={styles.busyInfoIcon}
                alt=""
              />
              <div className={styles.busyInfoText}>
                <span className={styles.boldBlue}>
                  {moment()
                    .locale('en')
                    .hour(time + 8)
                    .minute(0)
                    .format('h:mm a')}
                  :{' '}
                </span>
                Park is usually{' '}
                <span className={styles.bold}>
                  {getBusyLevelText(barData, time)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
