import clsx from 'clsx';
import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { GoogleMap, Polyline } from '@react-google-maps/api';

import { DigitalMap, DigitalMapGuestJourney } from 'shared/models/swagger';
import { useGoogleMapsApi } from 'client/hooks/useGoogleMapsApi';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { GoogleMapsAdvancedMarker } from 'client/components/GoogleMapsAdvancedMarker';

import styles from './OnsiteJourneyGadget.module.css';
import { TimeSlider } from './TimeSlider';

interface Props {
  map: DigitalMap | null;
  time: number;
  setTime: (time: number) => void;
  journeyData: DigitalMapGuestJourney | null;
}

export const OnsiteJourneyGadget = ({
  map,
  time,
  setTime,
  journeyData,
}: Props) => {
  const { t } = useTranslation();
  const [showPins, setShowPins] = React.useState(false);
  const [googleMap, setGoogleMap] = React.useState<google.maps.Map | null>(
    null
  );
  const { isLoaded } = useGoogleMapsApi();
  const timeOfDayStart = journeyData?.sessions?.length
    ? journeyData.sessions[0].time_of_day_start ?? '00:00'
    : '00:00';

  let currentPosition = {
    lat: map?.default_map_center?.latitude ?? 0,
    lng: map?.default_map_center?.longitude ?? 0,
  };

  const eventsWithLocations =
    journeyData?.sessions
      ?.map((session) =>
        session.events?.filter((event) => event.latitude && event.longitude)
      )
      .flat() ?? [];

  // Find the position of the pin that is closest to the current time
  if (journeyData?.sessions?.length) {
    const closestEvent = eventsWithLocations.length
      ? eventsWithLocations.reduce((prev, curr) => {
          return curr?.time_of_day_start && prev?.time_of_day_start
            ? Math.abs(
                moment(curr.time_of_day_start, 'HH:mm').diff(
                  moment(timeOfDayStart, 'HH:mm'),
                  'minutes'
                ) - time
              ) <
              Math.abs(
                moment(prev?.time_of_day_start, 'HH:mm').diff(
                  moment(timeOfDayStart, 'HH:mm'),
                  'minutes'
                ) - time
              )
              ? curr
              : prev
            : prev;
        })
      : null;

    if (closestEvent) {
      currentPosition = {
        lat: closestEvent.latitude ?? 0,
        lng: closestEvent.longitude ?? 0,
      };
    }
  }

  const onLoad = React.useCallback((map) => {
    setGoogleMap(map);
  }, []);

  const path = React.useMemo(() => {
    return journeyData?.sessions
      ?.map((session) => {
        return (
          session.events
            ?.filter((event) => event.latitude && event.longitude)
            .map((event) => ({
              lat: event.latitude ?? 0,
              lng: event.longitude ?? 0,
            })) ?? []
        );
      })
      .flat();
  }, [journeyData]);

  const mapOptions = React.useMemo(() => {
    const center = {
      lat: map?.default_map_center?.latitude ?? 0,
      lng: map?.default_map_center?.longitude ?? 0,
    };

    // Center the map on the center of the path
    const pathSum = path?.reduce(
      (acc, curr) => ({ lat: acc.lat + curr.lat, lng: acc.lng + curr.lng }),
      { lat: 0, lng: 0 }
    );

    if (pathSum && path?.length) {
      center.lat = pathSum.lat / path.length;
      center.lng = pathSum.lng / path.length;
    }

    return {
      mapId: '998d8780ce687665',
      disableDoubleClickZoom: true,
      center,
      zoom: map?.default_map_zoom ? map?.default_map_zoom - 1 : 0,
      heading: map?.map_rotation ?? 0,
      streetViewControl: false,
      mapTypeControl: false,
    };
  }, [map, path]);

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('Onsite Journey')}</div>
            <div className={styles['controls']}>
              <div className={styles['pin-toggle']}>
                <Toggle
                  label={t('Show Nearby Pins')}
                  checked={showPins}
                  onChange={() => {
                    setShowPins(!showPins);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles['body']}>
          {eventsWithLocations.length > 0 ? (
            <TimeSlider
              timeStart={timeOfDayStart}
              min={0}
              max={(journeyData?.visit_duration_minutes ?? 0) + 1}
              value={time}
              onChange={setTime}
            />
          ) : (
            <div className={styles['no-events']}>
              {t('No location data collected for this journey')}
            </div>
          )}
          {map && isLoaded && (
            <GoogleMap
              mapContainerClassName={styles['map-container']}
              options={mapOptions}
              onLoad={onLoad}
            >
              {showPins &&
                map?.pins?.map((pin) => (
                  <GoogleMapsAdvancedMarker
                    key={pin.key}
                    position={{
                      lat: pin.location?.latitude ?? 0,
                      lng: pin.location?.longitude ?? 0,
                    }}
                    map={googleMap}
                    zIndex={10}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <svg
                      className={clsx(styles['pin-icon'])}
                      xmlns="http://www.w3.org/2000/svg"
                      width="69"
                      height="84"
                      viewBox="0 0 69 84"
                      fill="none"
                    >
                      <path
                        d="M68.916 36.2285C68.916 56.0093 34.541 83.6358 34.541 83.6358C34.541 83.6358 0.166016 56.0093 0.166016 36.2285C0.166016 16.4477 15.5562 0.412109 34.541 0.412109C53.5258 0.412109 68.916 16.4477 68.916 36.2285Z"
                        fill="#3AA0FF"
                      />
                      <circle
                        cx="34.8403"
                        cy="33.8842"
                        r="16.5844"
                        fill="white"
                      />
                    </svg>
                  </GoogleMapsAdvancedMarker>
                ))}
              {(map?.pins?.length ?? 0) > 0 &&
                eventsWithLocations.length > 0 && (
                  <GoogleMapsAdvancedMarker
                    position={currentPosition}
                    map={googleMap}
                    zIndex={10}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div className={styles.currentPositionMarker}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="61"
                        height="61"
                        viewBox="0 0 61 61"
                        fill="none"
                      >
                        <g filter="url(#filter0_d_430_16274)">
                          <circle
                            cx="30.5"
                            cy="30.5"
                            r="30.5"
                            fill="#3AA0FF"
                            fillOpacity="0.3"
                          />
                          <circle
                            cx="30.5"
                            cy="30.5"
                            r="17"
                            fill="#3AA0FF"
                            stroke="#3AA0FF"
                            strokeWidth="3"
                          />
                        </g>
                        <g transform="translate(-11.5, -6.5)">
                          <path
                            d="M41.7104 36.0589C43.6596 36.0589 45.2398 34.4787 45.2398 32.5295C45.2398 30.5802 43.6596 29 41.7104 29C39.7611 29 38.1809 30.5802 38.1809 32.5295C38.1809 34.4787 39.7611 36.0589 41.7104 36.0589Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M48.4228 43.9994C47.9668 42.5775 47.0712 41.3374 45.865 40.4575C44.6589 39.5776 43.2044 39.1035 41.7114 39.1035C40.2184 39.1035 38.7639 39.5776 37.5577 40.4575C36.3515 41.3374 35.4559 42.5775 35 43.9994H48.4228Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_430_16274"
                            x="0"
                            y="0"
                            width="61"
                            height="61"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="1.5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_430_16274"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_430_16274"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                  </GoogleMapsAdvancedMarker>
                )}
              <Polyline
                path={path}
                options={{
                  strokeColor: '#E76E4C',
                  strokeWeight: 18,
                }}
              />
              <Polyline
                path={path}
                options={{
                  strokeColor: '#FF8360',
                  strokeWeight: 11,
                }}
              />
            </GoogleMap>
          )}
        </div>
      </div>
    </div>
  );
};
