import * as React from 'react';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from 'recharts';

import styles from './PieChartGadget.module.css';

interface Props {
  title: string;
  data: { name: string; value: number; color: string }[];
}

export const PieChartGadget = ({ title, data }: Props) => {
  const hasData = data.some((entry) => entry.value > 0);

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{title}</div>
          </div>
        </div>
        <div className={styles['body']}>
          {!hasData && (
            <div className={styles['no-data']}>No data available</div>
          )}
          {hasData && (
            <div className={styles['chart-comparison']}>
              <div className={styles['chart-container']}>
                <div className={styles['chart']}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        dataKey="value"
                        data={data}
                        cx="50%"
                        cy="50%"
                        outerRadius="90%"
                        innerRadius="70%"
                        paddingAngle={0}
                      >
                        {data.map((entry, index) => (
                          <Cell key={index} fill={entry.color} />
                        ))}
                      </Pie>
                      <RechartsTooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className={styles['legend']}>
                {data.map((entry, index) => (
                  <div key={index} className={styles['legend-item']}>
                    <div
                      className={styles['legend-dot']}
                      style={{ backgroundColor: entry.color }}
                    />
                    <div>{entry.name}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
