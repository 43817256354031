import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import styles from './ChangeLabel.module.css';

interface Props {
  amountChange: number;
  unit?: string;
  isPrefixUnit?: boolean;
}

export const ChangeLabel = ({ amountChange, unit, isPrefixUnit }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        styles['change'],
        amountChange > 0 && styles['inc'],
        amountChange < 0 && styles['dec']
      )}
    >
      <div className={styles['change-inner']}>
        {amountChange > 0 && (
          <>
            <div className={styles['change-text']}>
              +{isPrefixUnit && unit}
              {amountChange.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              })}
              {!isPrefixUnit && unit}
            </div>
            <i className="c-icon-solid-arrows-arrow-up"></i>
          </>
        )}
        {amountChange === 0 && (
          <div className={styles['change-text']}>{t('No change')}</div>
        )}
        {amountChange < 0 && (
          <>
            <div className={styles['change-text']}>
              {isPrefixUnit && unit}
              {amountChange.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              })}
              {!isPrefixUnit && unit}
            </div>
            <i className="c-icon-solid-arrows-arrow-down"></i>
          </>
        )}
      </div>
    </div>
  );
};
