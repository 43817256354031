import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type {
  Product,
  Reservation,
  ProductSummary,
} from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';

import { ReservationGuestTypeRedemptionTableContent } from './ReservationGuestTypeRedemptionTableContent';
import styles from './ReservationGuestTypeRedemptionTable.module.css';

interface Props {
  reservation: ManifestReservationShape | Reservation | undefined;
  product: Product | ProductSummary | undefined;
}

export const ReservationGuestTypeRedemptionTable = ({
  reservation,
  product,
}: Props) => {
  const { t } = useTranslation();

  return (
    <section
      id="ticketRedemptions"
      className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
    >
      <div className={styles['p-reservationsDetail']}>
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>
            {t('Ticket Redemptions')}
          </p>
        </div>
        <div className={styles['p-reservationsDetail__body']}>
          <ReservationGuestTypeRedemptionTableContent
            reservation={reservation}
            product={product}
          />
        </div>
      </div>
    </section>
  );
};
