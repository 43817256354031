import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import { getETicketRedemptionSummaryByPlace } from 'client/pages/ETicket/ETicketRedemptionCountDailyUses/util';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import type { ETicketDailyUse } from 'shared/models/swagger';

import styles from './ETicketRedemptionCountDailyUsesSummary.module.css';

type Props = {
  eTicketDailyUsesList: ETicketDailyUse[];
};

export const ETicketRedemptionCountDailyUsesSummary = ({
  eTicketDailyUsesList,
}: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const summaries = useSelector(summariesSortedByBookmarkedSelector);

  const eticketRedemptionSummaryByPlace = getETicketRedemptionSummaryByPlace(
    eTicketDailyUsesList,
    summaries
  );

  const totalRedemptionCountByProduct =
    eticketRedemptionSummaryByPlace.find((place) => place.text === 'Total')
      ?.counts ?? [];

  const currencyCode = activeUserOrganization?.default_currency ?? '';

  return (
    <div className={clsx(styles['c-table-nowrap'], styles['accordion-table'])}>
      <table>
        <tbody>
          <tr>
            <th>
              {t('Redemption Place')}/{t('Ticket Usage')}
            </th>
            {totalRedemptionCountByProduct.map((count, idx) => (
              <th key={idx}>
                {count.productName === 'Total' ? t('Total') : count.productName}
              </th>
            ))}
          </tr>
          {eticketRedemptionSummaryByPlace.map((place, idx) => {
            return (
              <tr key={idx}>
                <td>{place.text === 'Total' ? t('Total') : place.text}</td>
                {totalRedemptionCountByProduct.map((totalCount, idx) => (
                  <td key={idx} className={clsx(styles['right-align'])}>
                    {place.counts.find(
                      (count) => count.productName === totalCount.productName
                    )?.count ?? 0}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={clsx(styles['footer-note'])}>
        {t('Currency Code') + ' : ' + currencyCode}
      </div>
    </div>
  );
};
