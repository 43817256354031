import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/v3/Form/Modal';
import { WaitTime } from 'shared/models/swagger';

import PopularTimes from './PopularTimes/PopularTimes';

export const GraphModal = ({
  waitTime,
  onClose,
}: {
  waitTime: WaitTime;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={true}
      onClose={onClose}
      title={t("{{attractionName}}: Today's Popular Times", {
        attractionName: waitTime.attraction_name,
        date: moment().format('M/D/YYYY'),
      })}
      style={{
        borderRadius: '20px',
      }}
      insertAtRoot
    >
      <PopularTimes waitTime={waitTime} />
    </Modal>
  );
};
