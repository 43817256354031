import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { currency } from 'shared/libraries/currency';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import arrowUp from 'client/images/arrow-narrow-up.svg';
import arrowDown from 'client/images/arrow-narrow-down.svg';

import styles from './SalesOverview.module.css';
import { getMonthlyDateRange, getWeeklyDateRange } from './util';

interface SalesCardProps {
  title: string;
  amount: number | null;
  change: number | null;
  currencyCode: string | null;
  dateDescription?: string;
}

const SalesCard: React.FC<SalesCardProps> = ({
  title,
  dateDescription,
  amount,
  change,
  currencyCode,
}) => {
  const isPositive = change != null && change > 0;
  const isNegative = change != null && change < 0;

  return (
    <div className={styles.salesCard}>
      <h3 className={styles.cardTitle}>{title}</h3>
      {dateDescription && <p className={styles.date}>{dateDescription}</p>}
      <div className={styles.cardContent}>
        <p className={styles.amount}>
          {amount != null
            ? formattedCurrencyAmount(`${currencyCode}${amount}`)
            : '-'}
        </p>
        {isPositive ||
          (isNegative && (
            <div
              className={`${styles.change} ${
                isPositive ? styles.positive : styles.negative
              }`}
            >
              <span>
                {change != null
                  ? `${isPositive ? '+' : '-'}${formattedCurrencyAmount(
                      `${currencyCode}${Math.abs(change)}`
                    )}`
                  : '-'}
              </span>
              {isPositive && (
                <img src={arrowUp} alt="" className={styles.changeIcon} />
              )}
              {isNegative && (
                <img src={arrowDown} alt="" className={styles.changeIcon} />
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export const SalesOverview: React.FC = () => {
  const { t } = useTranslation();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const currencyCode =
    useSelector(activeUserOrganizationSelector)?.default_currency ?? 'USD';

  const monthlyDateRange = getMonthlyDateRange(moment());
  const weeklyDateRange = getWeeklyDateRange(moment());
  const priorWeekDateRange = getWeeklyDateRange(moment().subtract(1, 'week'));
  const priorMonthDateRange = getMonthlyDateRange(
    moment().subtract(1, 'month')
  );

  const reportData = useSelector(
    (state: ReduxState) => state.restaurantOrderReportData.reportData
  );

  const monthlyData = reportData?.sets?.find(
    (set) =>
      set?.range?.start === monthlyDateRange.start &&
      set?.range?.end === monthlyDateRange.end
  );
  const priorMonthData = reportData?.sets?.find(
    (set) =>
      set?.range?.start === priorMonthDateRange.start &&
      set?.range?.end === priorMonthDateRange.end
  );
  const weeklyData = reportData?.sets?.find(
    (set) =>
      set?.range?.start === weeklyDateRange.start &&
      set?.range?.end === weeklyDateRange.end
  );
  const priorWeekData = reportData?.sets?.find(
    (set) =>
      set?.range?.start === priorWeekDateRange.start &&
      set?.range?.end === priorWeekDateRange.end
  );

  const monthlyTotal = monthlyData?.totals?.gross_sales
    ? currency(monthlyData?.totals?.gross_sales)
    : currency(0, currencyCode);

  const priorMonthTotal = priorMonthData?.totals?.gross_sales
    ? currency(priorMonthData?.totals?.gross_sales)
    : currency(0, currencyCode);
  const monthlyChange = monthlyTotal.subtract(priorMonthTotal);

  const weeklyTotal = weeklyData?.totals?.gross_sales
    ? currency(weeklyData?.totals?.gross_sales)
    : currency(0, currencyCode);

  const priorWeekTotal = priorWeekData?.totals?.gross_sales
    ? currency(priorWeekData?.totals?.gross_sales)
    : currency(0, currencyCode);
  const weeklyChange = weeklyTotal.subtract(priorWeekTotal);

  const todayItem = weeklyData?.items?.find(
    (item) => item.date === moment().format('YYYY-MM-DD')
  );
  const todayTotal = todayItem?.gross_sales
    ? currency(todayItem?.gross_sales)
    : currency(0, currencyCode);
  const yesterdayItem = weeklyData?.items?.find(
    (item) => item.date === moment().subtract(1, 'day').format('YYYY-MM-DD')
  );
  const yesterdayTotal = yesterdayItem?.gross_sales
    ? currency(yesterdayItem?.gross_sales)
    : currency(0, currencyCode);
  const dailyChange = todayTotal.subtract(yesterdayTotal);

  return (
    <section className={styles.salesOverview}>
      <SalesCard
        title={t('Daily Sales')}
        dateDescription={moment().locale(locale).format('MMMM D, YYYY')}
        currencyCode={currencyCode}
        amount={todayTotal?.value ?? null}
        change={dailyChange?.value ?? null}
      />
      <SalesCard
        title={t('Weekly Sales')}
        dateDescription={`${moment(weeklyDateRange.start)
          .locale(locale)
          .format('MMMM D')} - ${moment(weeklyDateRange.end)
          .locale(locale)
          .format('MMMM D')}`}
        currencyCode={currencyCode}
        amount={weeklyTotal?.value ?? null}
        change={weeklyChange?.value ?? null}
      />
      <SalesCard
        title={t('Monthly Sales')}
        dateDescription={moment(monthlyDateRange.start)
          .locale(locale)
          .format('MMMM YYYY')}
        currencyCode={currencyCode}
        amount={monthlyTotal?.value ?? null}
        change={monthlyChange?.value ?? null}
      />
    </section>
  );
};
