import { Link } from 'react-router-dom';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import type { ReduxState } from 'client/reducers';
import type { TranslateFuncType } from 'client/components/Translate';
import type {
  Guest,
  ETicketDailyUse,
  CheckinGuestTypeCount,
  CheckinInfo,
  ProductSummary,
} from 'shared/models/swagger';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';

export type ETicketDailyUsesItem = {
  productName: string;
  reservationId: string;
  dateTimeUtc: string;
  reservationStartTimeZone: string;
  reservationSupplierReference: string;
  reservationStartDateTimeUtc: string;
  reservationAgentReference: string;
  reservationDisplayName: string;
  stubKey: string;
  stubOptionText: string;
};

export const getCheckinRecordColumns = (
  additionColumn: CustomTableColumn[]
): CustomTableColumn[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  return [
    {
      Header: '',
      width: 'short',
      id: 'EDIT',
      Cell: (cellInfo: any) => (
        <Link
          to={`${getReservationDetailsPath(cellInfo.original)}`}
          className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
          data-text={t('Detail')}
        >
          <img src={reservationDetailIcon} />
        </Link>
      ),
    },
    {
      Header: t('Redemption Date'),
      width: 'middle',
      id: 'REDEMPTION_DATE',
      accessor: (row) =>
        moment
          .tz(row.dateTimeUtc, row.reservationStartTimeZone ?? 'UTC')
          .locale(locale)
          .format('lll'),
    },
    {
      Header: t('Product'),
      id: 'PRODUCT_NAME',
      accessor: (row) => row.productName,
    },
    {
      Header: t('Ticket Usage'),
      width: 'middle',
      id: 'TICKET_USAGE',
      accessor: (row) => {
        let text = '';
        if (row.reservation_is_free_start_date_time) {
          text = t('Free Pass');
          if (row.stub_text) {
            text += `(${row.stub_text})`;
          }
        } else {
          if (row.stubKey) {
            text = row.stubKey;
          } else {
            text = t('Redeem Ticket');
          }
        }
        return text;
      },
    },
    {
      Header: t('Ticket Usage Option'),
      width: 'middle',
      id: 'TICKET_USAGE_OPTION',
      accessor: (row) => {
        return row.stubOptionText;
      },
    },
    {
      Header: t('Application Number'),
      accessor: 'reservation_agent_reference',
      id: 'AGENT_REFERENCE',
      Cell: (cellInfo) => (
        <Link
          to={`${getReservationDetailsPath(cellInfo.original)}`}
          target="_blank"
        >
          {cellInfo.original.reservationAgentReference}
        </Link>
      ),
    },
    {
      Header: t('Confirmation Number'),
      id: 'SUPPLIER_REFERENCE',
      accessor: (row) => row.reservationSupplierReference,
    },
    {
      Header: t('Customer'),
      id: 'GUEST_DISPLAY_NAME',
      accessor: (row) => row.reservationDisplayName,
    },
    ...additionColumn,
  ];
};

export const getGuestCounts = (guests: Guest[]): Record<string, number> => {
  const grouped = _.groupBy(
    guests,
    (g) => g.guest_type_title || g.guest_type_key
  );

  const total: Record<string, number> = {};
  Object.keys(grouped).forEach((key) => {
    total[key] = grouped[key].length;
  });

  return total;
};

export const getGuestCountByGuestTypeCounts = (
  guestTypeCounts: CheckinGuestTypeCount[] | undefined
): Record<string, number> => {
  const total: Record<string, number> = {};
  (guestTypeCounts ?? []).forEach((g) => {
    total[g.guest_type_key ?? ''] = g.count ?? 0;
  });
  return total;
};

export const getGuestCountByCheckinInfo = (
  checkinInfo: CheckinInfo | undefined
): Record<string, number> => {
  const total: Record<string, number> = {};
  checkinInfo?.checkin_records?.forEach((record) => {
    if (record.guest_type_counts) {
      (record.guest_type_counts ?? []).forEach((g) => {
        total[g.guest_type_key ?? ''] =
          (total[g.guest_type_key ?? ''] ?? 0) + (g.count ?? 0);
      });
    }
  });
  return total;
};

export const defaultDesiredUnitSort = (t: TranslateFuncType) => {
  return [t('Adult'), t('Child'), t('Infant'), t('Adult/Child')];
};

export const getAllUnits = (
  t: TranslateFuncType,
  eTicketDailyUses: ETicketDailyUse[]
): Record<string, number> => {
  const allUnits: Record<string, number> = {};
  eTicketDailyUses
    .map((p) => {
      const guestSet = getGuestCounts(p.guests ?? []);
      return guestSet;
    })
    .forEach((g) => {
      Object.keys(g).forEach((key) => {
        if (!allUnits[key]) {
          allUnits[key] = 0;
        }
        allUnits[key] += g[key];
      });
    });

  const desiredUnitSort = defaultDesiredUnitSort(t);
  const sortingUnits = Object.entries(allUnits);
  sortingUnits.sort((a, b) => {
    const u1 = a[0];
    const u2 = b[0];
    if (desiredUnitSort?.indexOf(u1) == -1) {
      return 1;
    }
    if (desiredUnitSort?.indexOf(u2) == -1) {
      return -1;
    }
    if (desiredUnitSort?.indexOf(u1) < desiredUnitSort?.indexOf(u2)) {
      return -1;
    }
    if (desiredUnitSort?.indexOf(u1) < desiredUnitSort?.indexOf(u2)) {
      return 1;
    }
    return 0;
  });

  return Object.fromEntries(sortingUnits);
};

export const sortAllUnitColumn = (
  t: TranslateFuncType,
  unitColumn: CustomTableColumn[]
) => {
  const desiredUnitSort = defaultDesiredUnitSort(t);
  unitColumn.sort((a, b) => {
    if (desiredUnitSort?.indexOf(a.id ?? '') == -1) {
      return 1;
    }
    if (desiredUnitSort?.indexOf(b.id ?? '') == -1) {
      return -1;
    }
    if (
      desiredUnitSort?.indexOf(a.id ?? '') <
      desiredUnitSort?.indexOf(b.id ?? '')
    ) {
      return -1;
    }
    if (
      desiredUnitSort?.indexOf(a.id ?? '') <
      desiredUnitSort?.indexOf(b.id ?? '')
    ) {
      return 1;
    }
    return 0;
  });
};

export const compareFunc = (
  desiredStubKeySort: (string | undefined)[] | undefined,
  desiredStubOptionSort: Record<string, string[]>
) => {
  return (a: any, b: any) => {
    if (a.stubKey && b.stubKey && desiredStubKeySort) {
      if (
        desiredStubKeySort?.indexOf(a?.stubKey) <
        desiredStubKeySort?.indexOf(b?.stubKey)
      ) {
        return -1;
      }
      if (
        desiredStubKeySort?.indexOf(b?.stubKey) <
        desiredStubKeySort?.indexOf(a?.stubKey)
      ) {
        return 1;
      }
    }
    if (desiredStubOptionSort) {
      if (a.stubKey == b.stubKey) {
        const key = a.stubKey;
        if (
          desiredStubOptionSort[key]?.indexOf(a?.stubOptionText) <
          desiredStubOptionSort[key]?.indexOf(b?.stubOptionText)
        ) {
          return -1;
        }
        if (
          desiredStubOptionSort[key]?.indexOf(b?.stubOptionText) <
          desiredStubOptionSort[key]?.indexOf(a?.stubOptionText)
        ) {
          return 1;
        }
      }
    }

    return 0;
  };
};

export const sortETicketDailyUsesByProductSummaries = (
  eTicketDailyUsesList: ETicketDailyUse[],
  summaries: ProductSummary[]
) => {
  eTicketDailyUsesList.sort((a, b) => {
    const tmp_a = summaries.find((p) => p?.id == a.product_id);
    const tmp_b = summaries.find((p) => p?.id == b.product_id);

    if (
      summaries.indexOf(tmp_a ?? ({} as ProductSummary)) <
      summaries.indexOf(tmp_b ?? ({} as ProductSummary))
    ) {
      return -1;
    } else if (
      summaries.indexOf(tmp_a ?? ({} as ProductSummary)) >
      summaries.indexOf(tmp_b ?? ({} as ProductSummary))
    ) {
      return 1;
    }

    return 0;
  });
};

export const summarizeSameProductETicketDailyUse = (
  eTicketDailyUsesList: ETicketDailyUse[]
) => {
  const products: { [key: string]: ETicketDailyUse[] } = {};
  eTicketDailyUsesList.forEach((item) => {
    if (!products[item.product_id ?? '']) {
      products[item.product_id ?? ''] = [];
    }
    products[item.product_id ?? ''].push(item);
  });

  return products;
};

const getReservationDetailsPath = (eTicketDailyUse: any): string => {
  if (
    !eTicketDailyUse.isPartnershipReservation &&
    eTicketDailyUse.isDynamicPackageComponentReservation
  ) {
    return `/reservations/${eTicketDailyUse.packageReservationId}`;
  }
  return `/reservations/${eTicketDailyUse.reservationId}`;
};
