// @flow

import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { useSelector, useDispatch } from 'react-redux';

import type { Reservation, Account } from 'shared/models/swagger';
import { calculateResellNet } from 'client/libraries/util/calculateResellNet';
import { currency } from 'shared/libraries/currency';
import { fetchSystemFeeInvoices } from 'client/actions/systemFeeInvoices';
import { ModalLoader } from 'client/components/ModalLoader';
import { ReservationFareAdjustmentModal } from 'client/pages/ReservationDetails/ReservationFareAdjustmentModal';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { config } from 'client/config';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import { Message } from 'client/components/Message/Message';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { allOrganizationsSelector } from 'client/reducers/organizations';
import type { ReduxState } from 'client/reducers';
import { Button } from 'client/components/Form';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';

import { ReservationCardPaymentLog } from './ReservationCardPaymentLog';

type Props = {
  reservation: Reservation,
  activeUser: Account | null,
  active: boolean,
};

export const ReservationBillingTab = ({
  reservation,
  activeUser,
  active,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const organization = useSelector(activeUserOrganizationSelector);
  const allOrganizations = useSelector(allOrganizationsSelector);
  const organizationsLoading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );
  const systemFeeInvoices = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.summaries
  );

  const [showModal, setShowModal] = React.useState(false);

  const startTime = moment.tz(
    reservation.start_date_time_utc,
    organization?.default_timezone || 'UTC'
  );
  let targetInvoiceYearMonth = startTime.format('YYYY-MM');
  if (
    reservation?.free_start_date_time_from &&
    reservation?.free_start_date_time_to
  ) {
    if (reservation?.checkin_info?.checkin_records) {
      const startTimeUtc =
        reservation?.checkin_info?.checkin_records?.[0]?.date_time_utc || '';
      if (startTimeUtc) {
        const freeStartTime = moment.tz(
          startTimeUtc,
          organization?.default_timezone || 'UTC'
        );
        targetInvoiceYearMonth = freeStartTime.format('YYYY-MM');
      }
    }
  }
  const targetInvoice = (systemFeeInvoices || []).find(
    (s) => s?.month == targetInvoiceYearMonth
  );
  const showAdjustmentButton =
    Boolean(!targetInvoice) ||
    (targetInvoice && targetInvoice?.status != 'SYSTEM_FEE_CONFIRMED');

  React.useEffect(() => {
    if (organization) {
      dispatch(fetchSystemFeeInvoices());
    }
  }, [organization]);

  const isResellAgent =
    reservation.contract_type === 'RESELL' &&
    activeUser?.organization_type === 'AGENT';
  let netTotal = t('Unknown');

  if (reservation.billing_info?.amount_net) {
    netTotal = reservation.billing_info.amount_net;
    if (isResellAgent && reservation.billing_info.amount_gross) {
      netTotal = calculateResellNet(
        reservation.billing_info.amount_gross,
        reservation.billing_info.amount_net
      );
    }
  }

  let grossTotal = reservation.billing_info?.amount_gross;
  if (
    reservation.status === 'CANCELED_BY_AGENT' ||
    reservation.status === 'CANCELED_BY_GUEST' ||
    reservation.status === 'CANCELED_BY_SUPPLIER'
  ) {
    grossTotal = reservation.billing_info?.amount_cancellation_fee_gross;
    netTotal = reservation.billing_info?.amount_cancellation_fee_net;
  }

  const isDirectChargable =
    reservation &&
    reservation.booking_source &&
    ((reservation.booking_source.source_type === 'DIRECT_WEB' &&
      reservation.payment_type !== 'PAY_ON_BOARD') ||
      (reservation.booking_source.source_type === 'AGENT' &&
        reservation.payment_method === 'CREDIT_CARD') ||
      reservation.payment_deferred);

  const isPIFAvailable =
    reservation && reservation.payment_type !== 'PAY_ON_BOARD';

  const paymentSupplier =
    activeUser?.role === 'nutmeg_admin'
      ? allOrganizations.find((org) => org.id === reservation.supplier_id)
      : organization;

  const stripeAccount = paymentSupplier?.stripe_accounts?.find(
    (acct) =>
      acct.currency === reservation.billing_info?.settlement_currency_code
  );

  const stripeAccountId =
    stripeAccount && stripeAccount.account_type !== 'JAPAN_EXPRESS'
      ? stripeAccount.stripe_account_id
      : '';

  const apiKey =
    reservation.billing_info &&
    reservation.billing_info.settlement_currency_code === 'JPY'
      ? stripeAccount?.account_type === 'JAPAN_EXPRESS' ||
        stripeAccount?.account_type === 'JAPAN_NTMGJP_NO_CONNECT'
        ? config.stripeConfig.publicJPYExpressApiKey
        : stripeAccount?.account_type === 'HIS_3DS'
        ? config.stripeConfig.publicHIS3DSApiKey
        : config.stripeConfig.publicJPYApiKey
      : config.stripeConfig.publicUSDApiKey;

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        componentStyles['c-tab-box__box'],
        active ? componentStyles['is-active'] : ''
      )}
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Billing Info')}
        </div>
        <div className={clsx(baseStyles['base-main__body__box__header__btn'])}>
          {(activeUser?.role === 'nutmeg_admin' ||
            activeUser?.organization_type === 'SUPPLIER') &&
            hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
            reservation.billing_info &&
            (reservation.status === 'CONFIRMED' ||
              reservation.status === 'CANCELED_BY_SUPPLIER' ||
              reservation.status === 'CANCELED_BY_AGENT' ||
              reservation.status === 'CANCELED_BY_GUEST') &&
            (organizationsLoading ? (
              <ModalLoader />
            ) : (
              <>
                {showAdjustmentButton && (
                  <Button
                    style="yellow"
                    size="small"
                    width={140}
                    disabled={[
                      'INVOICE_CHARGE_PENDING',
                      'INVOICE_IN_PROGRESS',
                    ].includes(reservation.billing_info?.invoice_status)}
                    onClick={() => setShowModal(true)}
                  >
                    {t('Fare Adjustment')}
                  </Button>
                )}
                {showModal && (
                  <StripeProvider
                    key={apiKey}
                    apiKey={apiKey}
                    stripeAccount={stripeAccountId}
                  >
                    <Elements>
                      {
                        <ReservationFareAdjustmentModal
                          header={t('Fare Adjustment')}
                          reservation={reservation}
                          currency={
                            reservation.billing_info &&
                            reservation.billing_info.amount_gross
                              ? reservation.billing_info.amount_gross
                                  .replace(/-/, '')
                                  .slice(0, 3)
                              : 'ERR'
                          }
                          onClose={() => setShowModal(false)}
                          isDirectChargable={isDirectChargable}
                          id={reservation.id}
                          savedPaymentInfoAvailable={
                            reservation.billing_info
                              ?.saved_payment_info_available ?? false
                          }
                          isPIFAvailable={isPIFAvailable}
                        />
                      }
                    </Elements>
                  </StripeProvider>
                )}
              </>
            ))}
        </div>
      </div>

      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        {!reservation.billing_info || !reservation.billing_info.amount_gross ? (
          <Message warning>
            {t(
              'Details are displayed on confirmed reservations and cancelled reservations with cancel fees. It may take up to 1 minute, please reload your browser if details are not displayed.'
            )}
          </Message>
        ) : (
          <div className={clsx(componentStyles['c-table-nowrap'])}>
            <table>
              <tbody>
                <tr>
                  <th className={clsx(baseStyles['base-t-128'])}>
                    {t('Title')}
                  </th>
                  <th className={clsx(baseStyles['base-t-128'])}>
                    {t('Gross')}
                  </th>
                  <th className={clsx(baseStyles['base-t-128'])}>{t('Net')}</th>
                  <th className={clsx(baseStyles['base-t-128'])}>
                    {t('Count')}
                  </th>
                  <th className={clsx(componentStyles['c-table-nowrap__cell'])}>
                    {t('Notes')}
                  </th>
                  <th className={clsx(baseStyles['base-t-128'])}>
                    {t('Invoice')}
                  </th>
                </tr>
                {reservation.billing_info?.line_items?.map((item, idx) => {
                  let net = item.amount_net;
                  let calNet = '';
                  let calGross = '';
                  let count = item.count ? item.count : 0;

                  if (isResellAgent && item.amount_gross && item.amount_net) {
                    net = calculateResellNet(
                      item.amount_gross,
                      item.amount_net
                    );
                  }

                  if (net) {
                    calNet = currency(net).multiply(count).format();
                  }
                  if (item.amount_gross) {
                    calGross = currency(item.amount_gross)
                      .multiply(count)
                      .format();
                  }

                  return (
                    <tr key={idx}>
                      <td>
                        {item.title === 'fare adjustment'
                          ? t('Adjust')
                          : item.title}
                      </td>
                      <td>
                        <p
                          className={clsx(
                            componentStyles['c-table-nowrap__bold']
                          )}
                        >
                          {calGross}
                        </p>
                        <p
                          className={clsx(
                            componentStyles['c-table-nowrap_small']
                          )}
                        >
                          {currency(item.amount_gross || '').format()} x{' '}
                          {item.count}
                        </p>
                      </td>
                      <td>
                        <p
                          className={clsx(
                            componentStyles['c-table-nowrap__bold']
                          )}
                        >
                          {calNet}
                        </p>
                        <p
                          className={clsx(
                            componentStyles['c-table-nowrap__small']
                          )}
                        >
                          {currency(net || '').format()} x {item.count}
                        </p>
                      </td>
                      <td>
                        {item.count !== undefined ? `x${item.count}` : 'x0'}
                      </td>
                      <td>{item.notes}</td>
                      <td>
                        {reservation.booking_source?.source_type !==
                          'DIRECT_WEB' && item.invoice_id !== undefined ? (
                          <Link to={`/invoices/${item.invoice_id}`}>
                            {item.invoice_id.slice(0, 8)}
                            ...
                          </Link>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  );
                })}
                {reservation.billing_info?.variable_markup_amount &&
                  currency(reservation.billing_info?.variable_markup_amount)
                    .intValue !== 0 && (
                    <tr>
                      <td>{t('Taxes and Fees')}</td>
                      <td>
                        <p
                          className={clsx(
                            componentStyles['c-table-nowrap__bold']
                          )}
                        >
                          {currency(
                            reservation.billing_info?.variable_markup_amount ??
                              ''
                          ).format()}
                        </p>
                      </td>
                      <td>-</td>
                      <td>x1</td>
                      <td />
                      <td />
                    </tr>
                  )}

                <tr>
                  <td>{t('Total')}</td>
                  <td>
                    <p
                      className={clsx(componentStyles['c-table-nowrap__bold'])}
                    >
                      {formattedCurrencyAmount(grossTotal ?? '')}
                    </p>
                  </td>
                  <td>
                    <p
                      className={clsx(componentStyles['c-table-nowrap__bold'])}
                    >
                      {formattedCurrencyAmount(netTotal ?? '')}
                    </p>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      {reservation.billing_info?.amount_gross &&
        reservation?.initial_settlement_amount_gross &&
        reservation?.rebooked_from_reservation_amount_gross &&
        reservation?.rebooked_from_reservation_amount_gross !==
          reservation?.initial_settlement_amount_gross && (
          <div>
            <div className={clsx(baseStyles['base-main__body__box__header'])}>
              <div
                className={clsx(
                  baseStyles['base-main__body__box__header__ttl']
                )}
              >
                {t('Adjustment for change reservation')}
              </div>
            </div>
            <div className={clsx(baseStyles['base-main__body__box__body'])}>
              <table className={clsx(baseStyles['base-table'])}>
                <tbody>
                  <tr>
                    <th>{t('Total gross before change')}</th>
                    <td>
                      {reservation?.rebooked_from_reservation_amount_gross}
                    </td>
                  </tr>
                  {reservation?.billing_info?.amount_gross && (
                    <tr>
                      <th>{t('Total gross after change')}</th>
                      <td>{reservation?.initial_settlement_amount_gross}</td>
                    </tr>
                  )}
                  {reservation?.rebooked_from_reservation_amount_gross && (
                    <tr>
                      <th>{t('Adjustment')}</th>
                      <td>
                        {currency(
                          reservation?.initial_settlement_amount_gross || ''
                        )
                          .subtract(
                            reservation?.rebooked_from_reservation_amount_gross ||
                              ''
                          )
                          .format()}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      {reservation.billing_info?.card_payment_log && (
        <ReservationCardPaymentLog
          paymentLog={reservation.billing_info?.card_payment_log}
        />
      )}
    </div>
  );
};
