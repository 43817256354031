import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { useState } from 'react';

import {
  checkinReservation,
  undoCheckinReservation,
} from 'client/actions/reservations';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import type { Reservation, Product } from 'shared/models/swagger';
import v3BaseStyles from 'client/v3-base.module.css';
import reservationStyles from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/ReservationDetailsSection.module.css';
import tableSmallStyles from 'client/components/v3/Table/TableSmall.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { config } from 'client/config';
import { EditFixedExpirationDateTimeModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/TicketRedemption/Checkin/EditFixedExpirationDateTimeModal';

import styles from './Checkin.module.css';

type Props = {
  reservation?: Reservation;
  product?: Product;
};

export const CheckinContent = ({ reservation, product }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeUser = useSelector(activeUserSelector);
  const [
    openFixedExpirationDateTimeModal,
    setOpenFixedExpirationDateTimeModal,
  ] = useState(false);

  const [undoCheckinLoadingStubKey, setUndoCheckinLoadingStubKey] = useState<
    string | null
  >(null);
  const [checkinLoadingStubKey, setCheckinLoadingStubKey] = useState<
    string | null
  >(null);

  const checkin = async (stubKey: string) => {
    try {
      setCheckinLoadingStubKey(stubKey);
      await dispatch(
        checkinReservation(reservation?.id ?? '', {
          email: '',
          guest_count: (reservation?.guests ?? []).length,
          package_component_reservation_id: '',
          stub_key: stubKey,
        })
      );
    } finally {
      setCheckinLoadingStubKey(null);
    }
  };

  const undoCheckin = async (stubKey: string) => {
    try {
      setUndoCheckinLoadingStubKey(stubKey);
      await dispatch(
        undoCheckinReservation(reservation?.id ?? '', {
          stub_key: stubKey,
        })
      );
    } finally {
      setUndoCheckinLoadingStubKey(null);
    }
  };

  let records: {
    key: string;
    text: string;
    dateTime: Moment | null;
    stubOptionText: string;
  }[] = [];
  if ((product?.qr_checkin_settings?.stubs ?? []).length > 0) {
    records = (product?.qr_checkin_settings?.stubs || [])
      .map((stub) => {
        const dateTime = getCheckinTime(reservation ?? null, stub.key ?? '');
        return {
          key: stub.key ?? '',
          text:
            reservation?.free_start_date_time_from &&
            reservation?.free_start_date_time_to
              ? `${t('Free Pass')}(${stub.text ?? ''})`
              : stub.text ?? '',
          dateTime: dateTime,
          stubOptionText: getCheckinStubOptionText(
            reservation ?? null,
            product ?? null,
            stub.key ?? ''
          ),
        };
      })
      .sort((a, b) => {
        if (!a.dateTime) {
          return 1;
        }

        if (!b.dateTime) {
          return -1;
        }

        if (a.dateTime.isBefore(b.dateTime)) {
          return -1;
        } else {
          return 1;
        }
      });
  } else {
    const checkinRecords = reservation?.checkin_info?.checkin_records ?? [];
    const isFreeStartDateTime =
      reservation?.free_start_date_time_from &&
      reservation?.free_start_date_time_to;

    records = [
      {
        key: '',
        text: isFreeStartDateTime ? t('Free Pass') : t('Redeem Ticket'),
        dateTime:
          checkinRecords.length === 0
            ? null
            : moment.tz(
                checkinRecords[0].date_time_utc,
                reservation?.start_timezone || 'UTC'
              ),
        stubOptionText: '',
      },
    ];
  }

  const expirationDateTime =
    reservation?.checkin_info?.fixed_expiration_date_time_utc ||
    reservation?.checkin_info?.expiration_date_time_utc;

  return (
    <>
      {config.enableFixedExpirationDateTime && expirationDateTime && (
        <div
          className={clsx(
            reservationStyles['p-relations'],
            v3BaseStyles['u-mb-8']
          )}
        >
          <table className={tableSmallStyles['c-tableSmall']}>
            <tbody>
              <tr>
                <th className={clsx(v3BaseStyles['u-width-160'])}>
                  {t('Expiration Date')}
                </th>
                <td
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    {moment
                      .tz(
                        expirationDateTime,
                        reservation?.start_timezone || 'UTC'
                      )
                      .format('lll')}
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <Button
                      text={t('Edit')}
                      color="white"
                      onClick={() => setOpenFixedExpirationDateTimeModal(true)}
                      iconBeforeText={
                        <i className="c-icon-outline-general-edit-05"></i>
                      }
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div className={reservationStyles['p-relations']}>
        <table
          className={clsx(
            tableSmallStyles['c-tableSmall'],
            tableSmallStyles['row']
          )}
        >
          <thead>
            <tr>
              {hasCustomUserRoleWritePermissions(
                activeUser,
                'RESERVATION.LIST'
              ) && (
                <>
                  <th className={clsx(v3BaseStyles['u-width-256'])}></th>
                </>
              )}
              <th className={clsx(v3BaseStyles['u-width-192'])}>
                {t('Date/Time')}
              </th>
              <th>{t('Ticket Usage')}</th>
              <th>{t('Ticket Usage Option')}</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record, idx) => {
              return (
                <tr key={idx}>
                  {hasCustomUserRoleWritePermissions(
                    activeUser,
                    'RESERVATION.LIST'
                  ) && (
                    <>
                      <td
                        className={clsx(
                          styles['center-align'],
                          styles['flex'],
                          styles['row']
                        )}
                      >
                        <Button
                          text={t('Redeem Ticket')}
                          disabled={!!record.dateTime}
                          onClick={() => {
                            checkin(record.key ?? '');
                          }}
                          loading={checkinLoadingStubKey === record.key}
                          style={{ width: '120px' }}
                        />
                        <Button
                          text={t('Delete record')}
                          color="white"
                          disabled={!record.dateTime}
                          onClick={() => {
                            undoCheckin(record.key ?? '');
                          }}
                          loading={undoCheckinLoadingStubKey === record.key}
                          style={{ width: '120px' }}
                        />
                      </td>
                    </>
                  )}
                  <td>{record.dateTime?.format('lll') ?? ''}</td>
                  <td>{record.text}</td>
                  <td>{record.stubOptionText}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {reservation && openFixedExpirationDateTimeModal && (
        <EditFixedExpirationDateTimeModal
          onClose={() => setOpenFixedExpirationDateTimeModal(false)}
          reservation={reservation}
        />
      )}
    </>
  );
};

const getCheckinTime = (
  reservation: Reservation | null,
  stub_key: string
): Moment | null => {
  if (!reservation || !stub_key) {
    return null;
  }

  const record = (reservation?.checkin_info?.checkin_records || []).find(
    (record) => {
      return record.stub_key === stub_key;
    }
  );

  if (record) {
    return moment.tz(record.date_time_utc, reservation.start_timezone || 'UTC');
  }

  return null;
};

const getCheckinStubOptionText = (
  reservation: Reservation | null,
  product: Product | null,
  stubKey: string
): string => {
  if (!reservation || !product || !stubKey) {
    return '';
  }

  const record = (reservation?.checkin_info?.checkin_records || []).find(
    (record) => {
      return record.stub_key === stubKey;
    }
  );

  if (!record) {
    return '';
  }

  if (!record.stub_option_key) {
    return '';
  }

  const stub = (product?.qr_checkin_settings?.stubs || []).find((stub) => {
    return stub.key === stubKey;
  });

  if (!stub) {
    return '';
  }

  const option = (stub?.options || []).find((option) => {
    return option.key === record.stub_option_key;
  });

  if (!option) {
    return '';
  }

  return option.text ?? '';
};
