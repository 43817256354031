import * as React from 'react';

import { DigitalMapGuestJourney } from 'shared/models/swagger';

import { SessionEvent } from './SessionEvent';
import styles from './SessionTimeline.module.css';

type Session = Exclude<DigitalMapGuestJourney['sessions'], undefined>[number];

export const SessionTimeline: React.FC<{ session: Session }> = ({
  session,
}) => {
  const [trackHeight, setTrackHeight] = React.useState(0);
  const timelineItemsRef = React.useRef<(HTMLDivElement | null)[]>([]);

  React.useEffect(() => {
    const calculateTrackHeight = () => {
      let totalHeight = 0;
      timelineItemsRef.current.forEach((item, index) => {
        if (item && index < timelineItemsRef.current.length - 1) {
          const rect = item.getBoundingClientRect();

          console.log('rect', rect);
          totalHeight += rect.height;
        }
      });
      setTrackHeight(totalHeight);
    };

    calculateTrackHeight();

    // Recalculate on window resize
    window.addEventListener('resize', calculateTrackHeight);
    return () => window.removeEventListener('resize', calculateTrackHeight);
  }, []);

  const filteredEvents = session?.events?.filter(
    (event, idx) =>
      idx === 0 ||
      !['return-page', 'leave-page', 'page-view'].includes(
        event.event_type ?? ''
      )
  );

  return (
    <div className={styles.timelineContent}>
      <div className={styles.timelineLine}>
        <div
          className={styles.timelineTrack}
          style={{ height: `${trackHeight}px` }}
        />
      </div>
      <div className={styles.timelineItems}>
        {filteredEvents?.map((item, index) => (
          <SessionEvent
            key={index}
            sessionEvent={item}
            ref={(el) => (timelineItemsRef.current[index] = el)}
          />
        ))}
      </div>
    </div>
  );
};
