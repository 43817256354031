import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Field, FieldRenderProps } from 'react-final-form';

import { config } from 'client/config';
import {
  Button,
  FieldWrapper,
  Input,
  Select,
  ToggleButton,
} from 'client/components/Form';
import { ReduxState } from 'client/reducers';
import { Box } from 'client/components/Box/Box';
import baseStyles from 'client/base.module.css';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { fetchProducts } from 'client/actions/products';
import { fetchGuidanceCoupons } from 'client/actions/guidanceCoupon';
import { fetchSurveyTemplates } from 'client/actions/surveyTemplates';
import { summariesWithBookmarksSelector } from 'client/reducers/products';
import { getValidators } from 'shared/libraries/validate/validator';
import { Message } from 'client/components/Message/Message';
import { DraggableProductList } from 'client/pages/ProductDetails/ProductContentsHeader/DraggableProductList/DraggableProductList';

import styles from './ComposeMessage.module.css';
import { MessagePopupEditor } from './MessagePopupEditor';

interface Props {
  onBackClick: () => void;
}

export const WebsitePopupEditor = ({ onBackClick }: Props) => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.marketingAutomationContents.loading
  );
  const allProducts = useSelector(summariesWithBookmarksSelector);
  const coupons = useSelector((state: ReduxState) => state.guidanceCoupons.all);
  const surveyTemplates = useSelector((state: ReduxState) =>
    config.enableNpsSurvey
      ? state.surveyTemplates.all.filter(
          (survey) =>
            survey.is_nps_survey &&
            survey.status === 'SURVEY_TEMPLATE_PUBLISHED'
        )
      : state.surveyTemplates.all
  );

  const productOptions = (allProducts || []).map((product) => ({
    key: product.id,
    value: product.id ?? '',
    text: product.internal_product_name ?? '',
  }));

  const couponOptions = coupons.map((coupon) => ({
    value: coupon.id ?? '',
    text: coupon.title ?? '',
  }));

  const surveyOptions = surveyTemplates.map((survey) => ({
    value: survey.id ?? '',
    text: config.enableNpsSurvey
      ? survey.template_name ?? ''
      : survey.title ?? '',
  }));

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchGuidanceCoupons());
    dispatch(fetchSurveyTemplates());
  }, []);

  const [enableShowInitially, setEnableShowInitially] = React.useState(true);
  const { required } = getValidators(t);
  const form = useForm();

  const { submitSucceeded, submitError } = form.getState();

  const { contentType, displayType } = form.getState().values;

  React.useEffect(() => {
    if (contentType === 'PRODUCT_LIST') {
      form.change('shouldShowInitially', false);
      form.change('displayType', 'FLOATING_BUTTON');
      setEnableShowInitially(false);
      return;
    }

    if (
      contentType === 'POPULAR_PRODUCT_LIST' &&
      displayType === 'FLOATING_BUTTON'
    ) {
      form.change('shouldShowInitially', false);
      setEnableShowInitially(false);
      return;
    }

    if (displayType === 'INLINE') {
      form.change('shouldShowInitially', false);
      setEnableShowInitially(false);
      return;
    }

    setEnableShowInitially(true);
  }, [contentType, displayType]);

  const offerTypeOptions = [
    {
      value: 'ANNUAL_PASS',
      label: t('Annual Pass Offer'),
    },
    {
      value: 'PRODUCT',
      label: t('Separate Product Offer'),
    },
    {
      value: 'PRODUCT_UPGRADE',
      label: t('Product Upgrade Offer'),
    },
    {
      value: 'COUPON',
      label: t('Coupon Offer'),
    },
    {
      value: 'MESSAGE',
      label: t('Message'),
    },
    { value: 'SURVEY', label: t('Survey') },
    ...(config.enableMaRevamp
      ? [
          { value: 'PRODUCT_LIST', label: t('Product List') },
          { value: 'POPULAR_PRODUCT_LIST', label: t('Popular Product List') },
        ]
      : []),
  ];

  const displayTypeOptions = [
    {
      value: 'INLINE',
      label: t('Inline'),
    },
    {
      value: 'POPUP',
      label: t('Popup'),
    },
    {
      value: 'FLOATING_BUTTON',
      label: t('Floating Button'),
    },
  ];

  const availableDispalyTypes: { [index: string]: string[] } = {
    ANNUAL_PASS: ['INLINE', 'POPUP'],
    PRODUCT: ['INLINE', 'POPUP'],
    PRODUCT_UPGRADE: ['INLINE', 'POPUP'],
    COUPON: ['INLINE', 'POPUP'],
    MESSAGE: ['INLINE', 'POPUP'],
    PRODUCT_LIST: ['FLOATING_BUTTON'],
    SURVEY: ['POPUP'],
    POPULAR_PRODUCT_LIST: ['INLINE', 'POPUP', 'FLOATING_BUTTON'],
  };

  const availableShowInitially: { [index: string]: boolean } = {
    ANNUAL_PASS: true,
    PRODUCT: true,
    PRODUCT_UPGRADE: true,
    COUPON: true,
    MESSAGE: true,
    PRODUCT_LIST: false,
    SURVEY: false,
    POPULAR_PRODUCT_LIST: true,
  };

  return (
    <div>
      <div className={styles['buttons-row']}>
        <Button onClick={onBackClick} style="gray" size="middle">
          {t('Back')}
        </Button>
        <Box ml={2}>
          <Button loading={loading} type="submit" style="blue" size="middle">
            {t('Save')}
          </Button>
        </Box>
      </div>

      {submitSucceeded && <Message success header={t('Save Successful')} />}
      {submitError && <Message error header={t('Save Failed')} />}
      <div className={baseStyles['base-main__body__box']}>
        <div className={baseStyles['base-main__body__box__header']}>
          <div className={baseStyles['base-main__body__box__header__ttl']}>
            {t('Website Popup Content')}
          </div>
        </div>
        <div className={baseStyles['base-main__body__box__body']}>
          <Box mb={2}>
            <FieldWrapper label={t('Website Popup Name')}>
              <Field name="title" validate={required}>
                {({ input, meta: { touched, error } }) => (
                  <Input
                    value={input.value}
                    onChange={input.onChange}
                    error={touched && error}
                  />
                )}
              </Field>
            </FieldWrapper>
          </Box>
          <FieldWrapper label={t('Popup type')}>
            <EnumRadioButtonGroup
              name="contentType"
              options={offerTypeOptions}
            />
          </FieldWrapper>

          {['PRODUCT', 'ANNUAL_PASS', 'PRODUCT_UPGRADE'].includes(
            contentType
          ) && (
            <>
              <Field name="productIds">
                {({ input }) => (
                  <Select
                    label={t('Product')}
                    search
                    options={productOptions}
                    value={input.value?.length ? input.value[0] : ''}
                    onChange={(e, { value }) => input.onChange([value])}
                  />
                )}
              </Field>
              <Box mt={2}>
                <Field name="promoCode">
                  {({ input }) => (
                    <Input label={t('Promo Code (optional)')} {...input} />
                  )}
                </Field>
              </Box>
            </>
          )}
          {contentType === 'COUPON' && (
            <>
              <Field name="couponId">
                {({ input }) => (
                  <Select
                    label={t('Coupon')}
                    search
                    options={couponOptions}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
              <Field name="thumbnailUrl">
                {({ input }: FieldRenderProps<string>) => (
                  <FieldWrapper label={t('Thumbnail image (jpg, png)')}>
                    <ImageVideoAudioInput
                      fileUrls={input.value ? [input.value] : []}
                      onChange={(newValue) =>
                        newValue?.length
                          ? input.onChange(newValue[0])
                          : input.onChange('')
                      }
                      maxFileCount={1}
                      disableYoutubeVideos
                    />
                  </FieldWrapper>
                )}
              </Field>
            </>
          )}
          {contentType === 'SURVEY' && (
            <>
              <Field name="surveyTemplateId">
                {({ input }) => (
                  <Select
                    label={t('Survey')}
                    search
                    options={surveyOptions}
                    value={input.value}
                    onChange={(_, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
            </>
          )}
          {contentType === 'MESSAGE' && <MessagePopupEditor />}
          {contentType === 'PRODUCT_LIST' && (
            <>
              <Field name="buttonImageUrl">
                {({ input }: FieldRenderProps<string>) => (
                  <FieldWrapper label={t('Button image (jpg, png)')}>
                    <ImageVideoAudioInput
                      fileUrls={input.value ? [input.value] : []}
                      onChange={(newValue) =>
                        newValue?.length
                          ? input.onChange(newValue[0])
                          : input.onChange('')
                      }
                      maxFileCount={1}
                      disableYoutubeVideos
                    />
                  </FieldWrapper>
                )}
              </Field>
              <Field name="productIds">
                {({ input }) => (
                  <DraggableProductList
                    productIds={input.value}
                    setProductIds={input.onChange}
                    options={productOptions}
                    label={t('Product List')}
                  />
                )}
              </Field>
            </>
          )}
          {availableDispalyTypes[contentType]?.length > 1 && (
            <Box mt={2}>
              <FieldWrapper label={t('Display type')}>
                <EnumRadioButtonGroup
                  name="displayType"
                  options={displayTypeOptions.filter((option) =>
                    availableDispalyTypes[contentType].includes(option.value)
                  )}
                />
              </FieldWrapper>
            </Box>
          )}

          {availableShowInitially[contentType] && (
            <Field name="shouldShowInitially" type="checkbox">
              {({ input }) => (
                <Box mb={2} mt={2}>
                  <ToggleButton
                    disabled={!enableShowInitially}
                    label={t('Directly show the popup')}
                    checked={input.checked}
                    onChange={() => {
                      input.onChange(!input.checked);
                    }}
                  />
                </Box>
              )}
            </Field>
          )}
        </div>
      </div>
    </div>
  );
};
