import clsx from 'clsx';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { GoogleMap, HeatmapLayer } from '@react-google-maps/api';

import {
  DigitalMap,
  DigitalMapVisitorReportDataSet,
} from 'shared/models/swagger';
import { useGoogleMapsApi } from 'client/hooks/useGoogleMapsApi';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { GoogleMapsAdvancedMarker } from 'client/components/GoogleMapsAdvancedMarker';
import { Loading } from 'client/components/v3/Common/Loading';

import { MapDashboardContext } from '../MapDashboardContext';
import { useMapLocationReportData } from '../useMapLocationReportData';

import styles from './HeatmapGadget.module.css';

interface Props {
  title: string;
  map: DigitalMap | null;
  activePinKey: string | null;
  setActivePinKey: (key: string | null) => void;
  data: DigitalMapVisitorReportDataSet[];
}

export const HeatmapGadget = ({
  title,
  map,
  activePinKey,
  setActivePinKey,
  data,
}: Props) => {
  const { t } = useTranslation();
  const [showPins, setShowPins] = React.useState(false);
  const [googleMap, setGoogleMap] = React.useState<google.maps.Map | null>(
    null
  );
  const { isLoaded } = useGoogleMapsApi();

  const { time, baseDateRange } = React.useContext(MapDashboardContext);

  const { data: locationData, isLoading: locationDataIsLoading } =
    useMapLocationReportData(
      baseDateRange,
      {
        start: moment()
          .hour(time + 8)
          .minute(0)
          .format('HH:mm'),
        end: moment()
          .hour(time + 9)
          .minute(0)
          .format('HH:mm'),
      },
      map?.id ?? ''
    );

  const onLoad = React.useCallback((map) => {
    setGoogleMap(map);
  }, []);

  const mapOptions = React.useMemo(() => {
    return {
      mapId: '998d8780ce687665',
      disableDoubleClickZoom: true,
      center: {
        lat: map?.default_map_center?.latitude ?? 0,
        lng: map?.default_map_center?.longitude ?? 0,
      },
      zoom: map?.default_map_zoom ? map?.default_map_zoom - 1 : 0,
      heading: map?.map_rotation ?? 0,
      streetViewControl: false,
      mapTypeControl: false,
    };
  }, [map]);

  const heatmapData = React.useMemo((): {
    location: google.maps.LatLng;
    weight: number;
  }[] => {
    if (!isLoaded) {
      return [];
    }

    return (
      locationData?.locations?.map((location) => ({
        location: new google.maps.LatLng(
          location?.latitude ?? 0,
          location?.longitude ?? 0
        ),
        weight: location.sample_count ?? 0,
      })) ?? []
    );
  }, [map, isLoaded, locationData]);

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{title}</div>
            <div className={styles['controls']}>
              {/*
              <div className={styles['legend']}>
                <div className={styles['legend-circle']}></div>
                <span className={styles['legend-label']}>{t('Users')}</span>
              </div>*/}
              <div className={styles['pin-toggle']}>
                <Toggle
                  label={t('Show Popular Pins')}
                  checked={showPins}
                  onChange={() => {
                    if (showPins) {
                      setActivePinKey(null);
                    }
                    setShowPins(!showPins);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles['body']}>
          {map && isLoaded && (
            <GoogleMap
              mapContainerClassName={styles['map-container']}
              options={mapOptions}
              onLoad={onLoad}
              onClick={() => {
                setActivePinKey(null);
              }}
            >
              {showPins &&
                map?.pins
                  ?.filter(
                    (pin) =>
                      data &&
                      data.length > 0 &&
                      data[0].totals?.totals_by_pin
                        ?.slice(0, 10)
                        ?.some((totalByPin) => totalByPin.pin_key === pin.key)
                  )
                  .map((pin) => (
                    <GoogleMapsAdvancedMarker
                      key={pin.key}
                      position={{
                        lat: pin.location?.latitude ?? 0,
                        lng: pin.location?.longitude ?? 0,
                      }}
                      map={googleMap}
                      zIndex={10}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onClick={(e) => {
                        e.stopPropagation();
                        setActivePinKey(pin.key ?? null);
                      }}
                    >
                      <svg
                        className={clsx(
                          styles['pin-icon'],
                          activePinKey === pin.key && styles['active']
                        )}
                        xmlns="http://www.w3.org/2000/svg"
                        width="69"
                        height="84"
                        viewBox="0 0 69 84"
                        fill="none"
                      >
                        <path
                          d="M68.916 36.2285C68.916 56.0093 34.541 83.6358 34.541 83.6358C34.541 83.6358 0.166016 56.0093 0.166016 36.2285C0.166016 16.4477 15.5562 0.412109 34.541 0.412109C53.5258 0.412109 68.916 16.4477 68.916 36.2285Z"
                          fill="#3AA0FF"
                        />
                        <circle
                          cx="34.8403"
                          cy="33.8842"
                          r="16.5844"
                          fill="white"
                        />
                      </svg>
                    </GoogleMapsAdvancedMarker>
                  ))}
              <HeatmapLayer
                data={heatmapData}
                options={{
                  gradient: [
                    'rgba(83, 189, 255, 0)',
                    '#53BDFF',
                    '#95FFBD',
                    '#FF754E',
                  ],
                  opacity: 0.9,
                  radius: 35,
                }}
              />
              {locationDataIsLoading && (
                <div className={styles['loading-container']}>
                  <Loading size="lg" />
                </div>
              )}
            </GoogleMap>
          )}
        </div>
      </div>
    </div>
  );
};
