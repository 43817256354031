import { TranslateFuncType } from 'client/components/Translate';

export type BookingSourceType =
  | 'DIRECT_WALK_IN'
  | 'DIRECT_TELEPHONE'
  | 'DIRECT_EMAIL'
  | 'DIRECT_WEB'
  | 'AGENT'
  | 'GROUP'
  | 'OTHER';

interface Option {
  key: BookingSourceType;
  text: string;
  value: BookingSourceType;
}

export const getBookingSourceOptions = (t: TranslateFuncType): Option[] => {
  return [
    {
      key: 'AGENT',
      text: t('agent'),
      value: 'AGENT',
    },
    {
      key: 'DIRECT_WALK_IN',
      text: t('walk-in (direct)'),
      value: 'DIRECT_WALK_IN',
    },
    {
      key: 'DIRECT_TELEPHONE',
      text: t('phone (direct)'),
      value: 'DIRECT_TELEPHONE',
    },
    {
      key: 'DIRECT_EMAIL',
      text: t('email (direct)'),
      value: 'DIRECT_EMAIL',
    },
    {
      key: 'DIRECT_WEB',
      text: t('Online (direct)'),
      value: 'DIRECT_WEB',
    },
    {
      key: 'GROUP',
      text: t('group'),
      value: 'GROUP',
    },
    {
      key: 'OTHER',
      text: t('other'),
      value: 'OTHER',
    },
  ];
};
