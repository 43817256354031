import { forwardRef, useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Account, Reservation } from 'shared/models/swagger';
import { fetchSystemFeeInvoices } from 'client/actions/systemFeeInvoices';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { fetchPartnershipSystemFeeInvoices } from 'client/actions/partnershipSystemFeeInvoices';
import { ReservationCardPaymentLog } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/BillingInformation/ReservationCardPaymentLog';
import { Message } from 'client/components/Message/Message';
import { ModalLoader } from 'client/components/ModalLoader';
import { ReservationFareAdjustmentModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/BillingInformation/ReservationFareAdjustmentModal';
import { calculateResellNet } from 'client/libraries/util/calculateResellNet';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { allOrganizationsSelector } from 'client/reducers/organizations';
import { config } from 'client/config';
import { currency } from 'shared/libraries/currency';
import { Button } from 'client/components/v3/Common/Button';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import baseStyles from 'client/v3-base.module.css';

import styles from './ReservationDetailsSection.module.css';

type Props = {
  reservation: Reservation;
  activeUser: Account | null;
};

export const BillingInformation = forwardRef<any, Props>((props: Props) => {
  const { reservation, activeUser } = props;
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const organization = useSelector(activeUserOrganizationSelector);
  const allOrganizations = useSelector(allOrganizationsSelector);
  const organizationsLoading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  const { partnershipMode } = useContext(PartnershipModeContext);

  const systemFeeInvoices = useSelector((state: ReduxState) =>
    partnershipMode
      ? state.partnershipSystemFeeInvoices.summaries
      : state.systemFeeInvoices.summaries
  );

  const startTime = moment.tz(
    reservation.start_date_time_utc,
    organization?.default_timezone || 'UTC'
  );
  let targetInvoiceYearMonth = startTime.format('YYYY-MM');
  if (
    reservation?.free_start_date_time_from &&
    reservation?.free_start_date_time_to
  ) {
    if (reservation?.checkin_info?.checkin_records) {
      const startTimeUtc =
        reservation?.checkin_info?.checkin_records?.[0]?.date_time_utc || '';
      if (startTimeUtc) {
        const freeStartTime = moment.tz(
          startTimeUtc,
          organization?.default_timezone || 'UTC'
        );
        targetInvoiceYearMonth = freeStartTime.format('YYYY-MM');
      }
    }
  }
  const targetInvoice = (systemFeeInvoices || []).find(
    (s) => s?.month == targetInvoiceYearMonth
  );
  const showAdjustmentButton =
    Boolean(!targetInvoice) ||
    (targetInvoice && targetInvoice?.status != 'SYSTEM_FEE_CONFIRMED');

  useEffect(() => {
    if (organization) {
      if (partnershipMode) {
        dispatch(fetchPartnershipSystemFeeInvoices());
      } else {
        dispatch(fetchSystemFeeInvoices());
      }
    }
  }, [organization]);

  const isResellAgent =
    reservation.contract_type === 'RESELL' &&
    activeUser?.organization_type === 'AGENT';
  let netTotal = t('Unknown');

  if (reservation.billing_info?.amount_net) {
    netTotal = reservation.billing_info.amount_net;
    if (isResellAgent && reservation.billing_info.amount_gross) {
      netTotal = calculateResellNet(
        reservation.billing_info.amount_gross,
        reservation.billing_info.amount_net
      );
    }
  }

  let grossTotal = reservation.billing_info?.amount_gross;
  if (
    reservation.status === 'CANCELED_BY_AGENT' ||
    reservation.status === 'CANCELED_BY_GUEST' ||
    reservation.status === 'CANCELED_BY_SUPPLIER'
  ) {
    grossTotal = reservation.billing_info?.amount_cancellation_fee_gross;
    netTotal = reservation.billing_info?.amount_cancellation_fee_net ?? '';
  }

  const isDirectChargable =
    reservation &&
    reservation.booking_source &&
    ((reservation.booking_source.source_type === 'DIRECT_WEB' &&
      reservation.payment_type !== 'PAY_ON_BOARD') ||
      (reservation.booking_source.source_type === 'AGENT' &&
        reservation.payment_method === 'CREDIT_CARD') ||
      reservation.payment_deferred);

  const isPIFAvailable =
    reservation && reservation.payment_type !== 'PAY_ON_BOARD';

  const paymentSupplier =
    activeUser?.role === 'nutmeg_admin'
      ? allOrganizations.find((org) => org.id === reservation.supplier_id)
      : organization;

  const stripeAccount = paymentSupplier?.stripe_accounts?.find(
    (acct) =>
      acct.currency === reservation.billing_info?.settlement_currency_code
  );

  const stripeAccountId =
    stripeAccount && stripeAccount.account_type !== 'JAPAN_EXPRESS'
      ? stripeAccount.stripe_account_id
      : '';

  const apiKey =
    reservation.billing_info &&
    reservation.billing_info.settlement_currency_code === 'JPY'
      ? stripeAccount?.account_type === 'JAPAN_EXPRESS' ||
        stripeAccount?.account_type === 'JAPAN_NTMGJP_NO_CONNECT'
        ? config.stripeConfig.publicJPYExpressApiKey
        : stripeAccount?.account_type === 'HIS_3DS'
        ? config.stripeConfig.publicHIS3DSApiKey
        : config.stripeConfig.publicJPYApiKey
      : config.stripeConfig.publicUSDApiKey;

  return (
    <section
      id="billing"
      className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
    >
      <div className={styles['p-reservationsDetail']}>
        {/* Header */}
        <div className={styles['p-reservationsDetail__header']}>
          <p className={styles['p-reservationsDetail__ttl']}>
            {t('Billing Info')}
          </p>
          <div className={styles['p-reservationsDetail__actions']}>
            {(activeUser?.role === 'nutmeg_admin' ||
              activeUser?.organization_type === 'SUPPLIER') &&
              hasCustomUserRoleWritePermissions(
                activeUser,
                'RESERVATION.LIST'
              ) &&
              reservation.billing_info &&
              (reservation.status === 'CONFIRMED' ||
                reservation.status === 'CANCELED_BY_SUPPLIER' ||
                reservation.status === 'CANCELED_BY_AGENT' ||
                reservation.status === 'CANCELED_BY_GUEST') &&
              (organizationsLoading ? (
                <ModalLoader />
              ) : (
                <>
                  {showAdjustmentButton && (
                    <Button
                      text={t('Fare Adjustment')}
                      uiType="bg"
                      size="sm"
                      color="white"
                      iconBeforeText={
                        <i className="c-icon-outline-general-edit-05"></i>
                      }
                      disabled={[
                        'INVOICE_CHARGE_PENDING',
                        'INVOICE_IN_PROGRESS',
                      ].includes(
                        reservation.billing_info?.invoice_status ?? ''
                      )}
                      onClick={() => setShowModal(true)}
                    />
                  )}
                  {showModal && (
                    <StripeProvider
                      key={apiKey}
                      apiKey={apiKey}
                      stripeAccount={stripeAccountId}
                    >
                      <Elements>
                        {
                          <ReservationFareAdjustmentModal
                            header={t('Fare Adjustment')}
                            reservation={reservation}
                            currency={
                              reservation.billing_info &&
                              reservation.billing_info.amount_gross
                                ? reservation.billing_info.amount_gross
                                    .replace(/-/, '')
                                    .slice(0, 3)
                                : 'ERR'
                            }
                            onClose={() => setShowModal(false)}
                            isDirectChargable={isDirectChargable}
                            id={reservation.id}
                            savedPaymentInfoAvailable={
                              reservation.billing_info
                                ?.saved_payment_info_available ?? false
                            }
                            isPIFAvailable={isPIFAvailable}
                          />
                        }
                      </Elements>
                    </StripeProvider>
                  )}
                </>
              ))}
          </div>
        </div>

        {/* Content */}
        <div className={styles['p-reservationsDetail__body']}>
          <div className={styles['p-claim']}>
            {!reservation.billing_info ||
            !reservation.billing_info.amount_gross ? (
              <Message warning>
                {t(
                  'Details are displayed on confirmed reservations and cancelled reservations with cancel fees. It may take up to 1 minute, please reload your browser if details are not displayed.'
                )}
              </Message>
            ) : (
              <table
                className={clsx(
                  tableStyles['c-tableSmall'],
                  tableStyles['row']
                )}
              >
                <thead>
                  <tr>
                    <th className={baseStyles['u-width-160']}>{t('Title')}</th>
                    <th className={baseStyles['u-width-160']}>{t('Gross')}</th>
                    <th className={baseStyles['u-width-160']}>{t('Net')}</th>
                    <th className={baseStyles['u-width-56']}>{t('Count')}</th>
                    <th className={baseStyles['u-width-160']}>{t('Notes')}</th>
                    <th className={baseStyles['u-width-56']}>{t('Invoice')}</th>
                  </tr>
                </thead>
                <tbody>
                  {reservation.billing_info?.line_items?.map((item, idx) => {
                    let net = item.amount_net;
                    let calNet = '';
                    let calGross = '';
                    const count = item.count ? item.count : 0;

                    if (isResellAgent && item.amount_gross && item.amount_net) {
                      net = calculateResellNet(
                        item.amount_gross,
                        item.amount_net
                      );
                    }

                    if (net) {
                      calNet = currency(net).multiply(count).format();
                    }
                    if (item.amount_gross) {
                      calGross = currency(item.amount_gross)
                        .multiply(count)
                        .format();
                    }

                    return (
                      <tr key={idx}>
                        <td className={tableStyles['c-tableSmall__spTtl']}>
                          {item.title === 'fare adjustment'
                            ? t('Adjust')
                            : item.title}
                        </td>
                        <td data-text={t('Gross')}>
                          <p>
                            <b>{formattedCurrencyAmount(calGross)}</b>
                            <br />
                            {formattedCurrencyAmount(
                              currency(item.amount_gross || '').format()
                            )}{' '}
                            x {item.count}
                          </p>
                        </td>
                        <td data-text={t('Net')}>
                          <p>
                            <b>{formattedCurrencyAmount(calNet)}</b>
                            <br />
                            {formattedCurrencyAmount(
                              currency(net || '').format()
                            )}{' '}
                            x {item.count}
                          </p>
                        </td>
                        <td data-text={t('Count')}>
                          {item.count !== undefined ? `x${item.count}` : 'x0'}
                        </td>
                        <td data-text={t('Notes')}>
                          {item.notes ? item.notes : '-'}
                        </td>
                        <td data-text={t('Invoice')}>
                          {reservation.booking_source?.source_type !==
                            'DIRECT_WEB' && item.invoice_id !== undefined ? (
                            <Link to={`/invoices/${item.invoice_id}`}>
                              {item.invoice_id.slice(0, 8)}
                              ...
                            </Link>
                          ) : (
                            '-'
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {reservation.billing_info?.variable_markup_amount &&
                    currency(reservation.billing_info?.variable_markup_amount)
                      .intValue !== 0 && (
                      <tr>
                        <td className={tableStyles['c-tableSmall__spTtl']}>
                          {t('Taxes and Fees')}
                        </td>
                        <td>
                          <p>
                            {formattedCurrencyAmount(
                              currency(
                                reservation.billing_info
                                  ?.variable_markup_amount ?? ''
                              ).format()
                            )}
                          </p>
                        </td>
                        <td>-</td>
                        <td>x1</td>
                        <td />
                        <td />
                      </tr>
                    )}

                  <tr>
                    <td className={tableStyles['c-tableSmall__spTtl']}>
                      {t('Total')}
                    </td>
                    <td data-text={t('Gross')}>
                      <p>
                        <b>{formattedCurrencyAmount(grossTotal ?? '')}</b>
                      </p>
                    </td>
                    <td data-text={t('Net')}>
                      <p>
                        <b>{formattedCurrencyAmount(netTotal ?? '')}</b>
                      </p>
                    </td>
                    <td className={baseStyles['u-spHidden']}></td>
                    <td className={baseStyles['u-spHidden']}></td>
                    <td className={baseStyles['u-spHidden']}></td>
                  </tr>
                </tbody>
              </table>
            )}

            {reservation.billing_info?.amount_gross &&
              reservation?.initial_settlement_amount_gross &&
              reservation?.rebooked_from_reservation_amount_gross &&
              reservation?.rebooked_from_reservation_amount_gross !==
                reservation?.initial_settlement_amount_gross && (
                <>
                  <p className={styles['p-claim__item__ttl']}>
                    {t('Adjustment for change reservation')}
                  </p>
                  <table
                    className={clsx(
                      tableStyles['c-tableSmall'],
                      tableStyles['row']
                    )}
                  >
                    <thead>
                      <tr>
                        <th className={baseStyles['u-width-160']}>
                          {t('Total gross before change')}
                        </th>
                        {reservation?.billing_info?.amount_gross && (
                          <th className={baseStyles['u-width-160']}>
                            {t('Total gross after change')}
                          </th>
                        )}
                        {reservation?.rebooked_from_reservation_amount_gross && (
                          <th className={baseStyles['u-width-160']}>
                            {t('Adjustment')}
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {formattedCurrencyAmount(
                            reservation?.rebooked_from_reservation_amount_gross
                          )}
                        </td>

                        {reservation?.billing_info?.amount_gross && (
                          <td>
                            {formattedCurrencyAmount(
                              reservation?.initial_settlement_amount_gross
                            )}
                          </td>
                        )}
                        {reservation?.rebooked_from_reservation_amount_gross && (
                          <td>
                            {formattedCurrencyAmount(
                              currency(
                                reservation?.initial_settlement_amount_gross ||
                                  ''
                              )
                                .subtract(
                                  reservation?.rebooked_from_reservation_amount_gross ||
                                    ''
                                )
                                .format()
                            )}
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </>
              )}

            {reservation.billing_info?.card_payment_log && (
              <ReservationCardPaymentLog
                paymentLog={reservation.billing_info?.card_payment_log}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
});
