import React from 'react';
import { useTranslation } from 'react-i18next';

import { RestaurantOrder } from 'shared/models/swagger';
import { TranslateFuncType } from 'client/components/Translate';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';

import { ActiveOrderContext } from '../ActiveOrderContext';

import styles from './OrderDetails.module.css';

const getStatusText = (status: string, t: TranslateFuncType) => {
  switch (status) {
    case 'RECEIVED':
      return t('Received');
    case 'IN_PROGRESS':
      return t('In Progress');
    case 'READY':
      return t('Ready');
    case 'COMPLETE':
      return t('Complete');
    default:
      return status;
  }
};

interface OrderDetailsProps {
  order: RestaurantOrder;
}

export const OrderDetails: React.FC<OrderDetailsProps> = ({ order }) => {
  const { t } = useTranslation();
  const { setActiveOrderId } = React.useContext(ActiveOrderContext);

  return (
    <article className={styles.div}>
      <header className={styles.header}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1f588045fb1242b6161bcc5fbac0eb5db59240ff174373e39f3fd8e3b545791b?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
          className={styles.logo}
          alt="Company logo"
          onClick={() => setActiveOrderId('')}
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8e78fe0c9a136af6144df1dcd234b86ce040c291ecee12ead41905a277b2671?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
          className={styles.icon}
          alt="Menu icon"
          onClick={() => setActiveOrderId('')}
        />
      </header>
      <main className={styles.mainContent}>
        <section className={styles.orderDetails}>
          <div className={styles.customerInfo}>
            <div className={styles.customerHeader}>
              <div className={styles.customerName}>
                <h1
                  className={styles.name}
                >{`${order.given_name} ${order.family_name}`}</h1>
                <p className={styles.orderId}>{order.id}</p>
                <button className={styles.statusButton}>
                  <div className={styles.statusIndicator} />
                  <span className={styles.statusText}>
                    {order.status ? getStatusText(order.status, t) : ''}
                  </span>
                </button>
              </div>
              <button className={styles.issueButton}>
                {t('issue with order')}
              </button>
            </div>
            <hr className={styles.divider} />
            <h2 className={styles.itemCount}>
              {t('{{count}} items', { count: order.items?.length })}
            </h2>
            <hr className={styles.divider} />
            <ul className={styles.itemList}>
              {order.billing_info?.line_items?.map((item, index) => (
                <React.Fragment key={index}>
                  <li className={styles.itemRow}>
                    <span>
                      {item.quantity}x {item.name}
                    </span>
                    <span>
                      {formattedCurrencyAmount(item.total_price ?? '')}
                    </span>
                  </li>
                  {item.option_groups?.map((optionGroup) =>
                    optionGroup.option_names?.map((optionName) => (
                      <li key={optionName} className={styles.optionRow}>
                        <span>+ {optionName}</span>
                      </li>
                    ))
                  )}
                </React.Fragment>
              ))}
            </ul>
            <hr className={styles.divider} />
            <div className={styles.totalRow}>
              <span>{t('Total')}</span>
              <span>{order?.billing_info?.total}</span>
            </div>
            <hr className={styles.divider} />
          </div>
          <div className={styles.commentsSection}>
            <h3 className={styles.commentsHeader}>{t('Comments')}</h3>
            <p className={styles.commentsText}></p>
          </div>
        </section>
      </main>
      <footer className={styles.footer}>
        <div className={styles.iconWrapper}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/51929a5551264c2c44b0a06e4e60a484bc3d5837e01af5867891d81ccb0f9300?placeholderIfAbsent=true&apiKey=3d59b5c84d524ccda3d6150a37390818"
            className={styles.footerIcon}
            alt="Footer icon"
          />
        </div>
        <button className={styles.cancelButton}>{t('Cancel')}</button>
        <button className={styles.completedButton}>{t('Completed')}</button>
      </footer>
    </article>
  );
};
