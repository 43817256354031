import clsx from 'clsx';
import { CSSProperties } from 'react';

import styles from './Button.module.css';

type ButtonProps = {
  text?: string;
  type?: 'submit' | 'reset' | 'button';
  uiType?: 'bg' | 'link' | 'close';
  // Note: size icon is used to show only icon with no text
  size?: 'icon' | 'xs' | 'sm' | 'md' | 'lg';
  color?:
    | 'primary'
    | 'secondary'
    | 'secondarycolor'
    | 'red'
    | 'tertiaryred'
    | 'white'
    | 'gray'
    | 'tertiarygray'
    | 'tertiarycolor';
  useFullWidth?: boolean;
  iconBeforeText?: JSX.Element;
  iconAfterText?: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  form?: string; // Use to associate the button with a form element anywhere in document only if type is submit
  style?: CSSProperties; // Use to override any style
  shouldHide?: boolean; // Use this if we want to hide the button but preserve its spacing to prevent breaking UI
  name?: string;
};

export const Button = ({
  text,
  type = 'button',
  uiType = 'bg',
  size = 'md',
  color = 'primary',
  useFullWidth = false,
  iconBeforeText,
  iconAfterText,
  onClick,
  disabled = false,
  loading,
  form,
  style,
  shouldHide = false,
  name,
}: ButtonProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type={type}
      className={clsx(
        styles['c-button'],
        styles[uiType],
        styles[size],
        styles[color],
        useFullWidth && styles['full-width'],
        // If loading is true, loading icon will cover the text and button size is preserved
        loading && styles['loading']
      )}
      style={{ ...style, display: shouldHide ? 'none' : '' }}
      onClick={handleClick}
      disabled={disabled || loading}
      form={type === 'submit' ? form : undefined}
      name={name}
    >
      {iconBeforeText}
      {text ? <p>{text}</p> : null}
      {iconAfterText}
    </button>
  );
};
