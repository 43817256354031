export const createCancellableFetcher = () => {
  let controller = new AbortController();

  const fetcher = async (
    url: string,
    queryParamsString: string,
    headerString: string
  ) => {
    // Abort the previous request
    controller.abort();
    // Create a new controller for this request
    controller = new AbortController();

    try {
      const queryParams = JSON.parse(queryParamsString);
      const headers = JSON.parse(headerString);

      const searchParams = new URLSearchParams();
      Object.entries(queryParams).forEach(([key, value]) => {
        // If the value is an array, append each value to the search params
        if (Array.isArray(value)) {
          value.forEach((v) => searchParams.append(`${key}[]`, v));
        } else {
          searchParams.append(key, value as any);
        }
      });

      // Add query params to url
      const urlWithQueryParams = queryParams
        ? `${url}?${searchParams.toString()}`
        : url;

      const response = await fetch(urlWithQueryParams, {
        headers,
        signal: controller.signal,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    } catch (error: any) {
      if (error.name === 'AbortError') {
        // Request was aborted, ignore the error
        return;
      }
      throw error;
    }
  };

  return fetcher;
};
