import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { BackArrow } from 'client/components/BackArrow/BackArrow';
import { fetchProducts } from 'client/actions/products';
import baseStyles from 'client/base.module.css';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { PageHeaderOverrideContext } from 'client/contexts/PageHeaderOverrideContext';
import { ReduxState } from 'client/reducers';
import { searchReservations } from 'client/actions/reservationSearch';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import { reservationVisibleColumnsSelector } from 'client/reducers/reservationTableControls';
import { ReservationSearchCustomTableControlled } from 'client/pages/ReservationSearch/ReservationSearchTable/ReservationSearchCustomTableControlled';
import { ReservationSearchDownloadCSVModal } from 'client/pages/ReservationSearch/ReservationSearchDownloadCSVModal/ReservationSearchDownloadCSVModal';
import {
  csvColumnCandidatesSelector,
  csvInitialSelectedColumnsSelector,
} from 'client/pages/ReservationSearch/ReservationSearchDownloadCSVModal/util';

const blankSearchRequest = {
  presetKey: '',
  agentReference: '',
  agentIds: [],
  supplierReference: '',
  id: '',
  statuses: [],
  customerGivenName: '',
  customerFamilyName: '',
  customerPhone: '',
  customerEmail: '',
  bookingSourceTypes: [],
  groupIds: [],
  supplierIds: [],
  productIds: [],
  bookedDateFrom: '',
  bookedDateTo: '',
  participationDateFrom: '',
  participationDateTo: '',
  lastUpdatedDateFrom: '',
  lastUpdatedDateTo: '',
  dateFilterPreset: null,
  orderBy: '',
  supplierOrAgentReference: '',
  reservationLanguages: [],
  mostRecentEmailBounced: false,
  pickupCheckinLocationName: '',
  waiverCompletionStatuses: [],
  checkinStatuses: [],

  annualPassOnly: false,
  expirationPresetKey: '',
  expirationDateFrom: '',
  expirationDateTo: '',
  expirationDateFilterPreset: null,
  automaticContinuingStatus: null,

  partnershipReservationOnly: false,
};

export const GroupReservationList = () => {
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { setHeaderOverride } = React.useContext(PageHeaderOverrideContext);
  const visibleColumns = useSelector(reservationVisibleColumnsSelector);
  const csvLoading = useSelector(
    (state: ReduxState) => state.reservationSearch.csvLoading
  );
  const initialSelectedColumns = useSelector(csvInitialSelectedColumnsSelector);
  const columnCandidates = useSelector(csvColumnCandidatesSelector);
  const [rowCount, setRowCount] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);

  const groupBookingTemplates = useSelector(
    (state: ReduxState) => state.groupBookingTemplates.all
  );
  const template = groupBookingTemplates.find((template) => template.id === id);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const allReservations = useSelector(
    (state: ReduxState) => state.reservationSearch.all
  );
  const totalHits = useSelector(
    (state: ReduxState) => state.reservationSearch.totalHits
  );
  const reservationSummaries = React.useMemo(
    () => allReservations.map((r) => toReservationSummaryShape(r, locale, t)),
    [allReservations, locale, t]
  );

  const columns = React.useMemo(() => {
    const allColumns = getReservationTableColumns(t, locale);
    return ['edit', ...visibleColumns.filter((col) => col !== 'edit')].map(
      (c) => allColumns.find((col) => col.id === c) as any
    );
  }, [t, locale]);

  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [activeUserOrganization?.id]);

  React.useEffect(() => {
    if (template) {
      setHeaderOverride(
        t('Reservations - {{groupName}}', {
          groupName: template?.name,
        })
      );
    }

    return () => setHeaderOverride('');
  }, [template, t]);
  React.useEffect(() => {
    if (activeUserOrganization?.id) {
      dispatch(
        searchReservations(
          {
            group_ids: [id],
            statuses: [
              'CONFIRMED',
              'CANCELED_BY_AGENT',
              'CANCELED_BY_GUEST',
              'CANCELED_BY_SUPPLIER',
            ],
            supplier_ids: [activeUserOrganization?.id],
          },
          rowCount,
          rowCount * (currentPage - 1)
        )
      );
    }
  }, [id, currentPage, rowCount]);

  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <Box mb={2} display="flex">
          <Link to="/groups">
            <BackArrow />
          </Link>

          <Box ml="auto">
            <ReservationSearchDownloadCSVModal
              searchRequest={{
                ...blankSearchRequest,
                groupIds: [id],
                statuses: [
                  'CONFIRMED',
                  'CANCELED_BY_AGENT',
                  'CANCELED_BY_GUEST',
                  'CANCELED_BY_SUPPLIER',
                ],
                supplierIds: activeUserOrganization?.id
                  ? [activeUserOrganization?.id]
                  : [],
              }}
              initialSelectedColumns={initialSelectedColumns}
              columnCandidates={columnCandidates}
              trigger={
                <Button.Sub
                  loading={csvLoading}
                  content={<>{t('Download CSV')}</>}
                />
              }
            />
          </Box>
        </Box>
        <ReservationSearchCustomTableControlled
          reservations={reservationSummaries}
          totalHits={totalHits}
          visibleColumns={columns}
          rowCount={rowCount}
          onRowCountChange={(rowCount) => {
            setRowCount(rowCount);
          }}
          currentPage={currentPage}
          onCurrentPageChange={(currentPage) => {
            setCurrentPage(currentPage);
          }}
        />
      </div>
    </div>
  );
};
