import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { Message } from 'client/components/Message/Message';
import { Button, FieldWrapper, Select, TextArea } from 'client/components/Form';
import { PrivateMarketplaceSelect } from 'client/pages/PrivateMarketplace/PrivateMarketplaceSelect/PrivateMarketplaceSelect';
import { privateMarketplaceSelector } from 'client/reducers/privateMarketplace';
import { bookingWidgetProductSummariesSelector } from 'client/reducers/products';
import { updateRecommendedProductsPage } from 'client/actions/privateMarketplace';
import { DraggableSelect } from 'client/components/DraggableSelect/DraggableSelect';
import {
  fetchProducts,
  fetchPassthroughCandidateProducts,
} from 'client/actions/products';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';
import { isPartnershipAgent } from 'client/libraries/util/partnership';
import { activeUserOrganizationSelector } from 'client/reducers/user';

import styles from './PrivateMarketplaceRecommendedProducts.module.css';

export const PrivateMarketplaceRecommendedProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const privateMarketplace = useSelector(privateMarketplaceSelector);
  const products = useSelector(bookingWidgetProductSummariesSelector);
  const updateError = useSelector(
    (state: ReduxState) => state.organizations.error
  );
  const lastUpdatedOrganization = useSelector(
    (state: ReduxState) => state.organizations.lastUpdated
  );
  React.useEffect(() => {
    dispatch(fetchProducts());
    if (isPartnershipAgent(activeUserOrganization)) {
      dispatch(
        fetchPassthroughCandidateProducts({
          agent_id: activeUserOrganization?.corresponding_organization_id ?? '',
        })
      );
    }
  }, []);
  const [initialError] = React.useState<string>(updateError);
  const [initialLastUpdatedOrganization] = React.useState<any>(
    lastUpdatedOrganization
  );
  const [recommendedProductIds, setRecommendedProductIds] = React.useState<
    string[]
  >(
    privateMarketplace?.recommended_products_page?.recommended_product_ids ||
      _.times(5, () => '')
  );
  const [description, setDescription] = React.useState<string>(
    privateMarketplace?.recommended_products_page?.description || ''
  );
  const reset = React.useCallback(() => {
    setRecommendedProductIds(
      privateMarketplace?.recommended_products_page?.recommended_product_ids ||
        _.times(5, () => '')
    );
  }, [privateMarketplace?.domain]);
  React.useEffect(() => {
    reset();
  }, [reset]);
  const productOptions = products.map((product) => ({
    key: product.id || '',
    value: product.id || '',
    text: product.product_name || '',
  }));
  const error = updateError && updateError !== initialError ? updateError : '';
  const success =
    !updateError && lastUpdatedOrganization !== initialLastUpdatedOrganization
      ? true
      : false;
  return (
    <div className={baseStyles['base-main__body__box']}>
      <div className={baseStyles['base-main__body__box__body']}>
        <PrivateMarketplaceSelect />
        {error && <Message error header={t('Update failed')} content={error} />}
        {success && <Message success header={t('Update succeeded')} />}
        <FormTableBox>
          <table>
            <tbody>
              <tr>
                <th>{t('Page Description (meta description)')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <TextArea
                      value={description}
                      onChange={(event, { value }) => {
                        setDescription(value);
                      }}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>{t('Recommended Products')}</th>
                <td>
                  <div className={styles['input-box']}>
                    <Select
                      label={t('Number of Recommended Products')}
                      options={[5, 6, 7, 8, 9, 10].map((num) => ({
                        key: num,
                        value: `${num}`,
                        text: `${num}`,
                      }))}
                      value={`${recommendedProductIds.length}`}
                      onChange={(e, { value }) => {
                        setRecommendedProductIds(
                          _.times(
                            parseInt(value),
                            (idx) => recommendedProductIds[idx] || ''
                          )
                        );
                      }}
                    />
                  </div>
                  <div className={styles['input-box']}>
                    <FieldWrapper
                      label={t('Products (drag-and-drop to reorder)')}
                    >
                      <div className={styles['products-input-box']}>
                        {recommendedProductIds.map((productId, idx) => {
                          return (
                            <DraggableSelect
                              key={productId || idx}
                              value={productId}
                              options={
                                // Options are any product IDs not yet present
                                productOptions.filter(
                                  (p) =>
                                    p.key === productId ||
                                    !recommendedProductIds.includes(p.key)
                                )
                              }
                              index={idx}
                              onValueChange={(newValue) => {
                                const newProductIds = [
                                  ...recommendedProductIds,
                                ];
                                newProductIds[idx] = newValue;
                                setRecommendedProductIds(newProductIds);
                              }}
                              moveItem={(
                                dragIndex: number,
                                hoverIndex: number
                              ) => {
                                const draggingProductId =
                                  recommendedProductIds[dragIndex];
                                const newProductIds = [
                                  ...recommendedProductIds,
                                ];
                                newProductIds.splice(dragIndex, 1);
                                newProductIds.splice(
                                  hoverIndex,
                                  0,
                                  draggingProductId
                                );
                                setRecommendedProductIds(newProductIds);
                              }}
                            />
                          );
                        })}
                      </div>
                    </FieldWrapper>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </FormTableBox>

        <div className={baseStyles['base-main__box__body__bottomBtns']}>
          <Button style="gray" size="middle" onClick={reset}>
            {t('Discard')}
          </Button>
          <Button
            style="blue"
            size="middle"
            onClick={async () => {
              await dispatch(
                updateRecommendedProductsPage({
                  recommended_product_ids: recommendedProductIds,
                  description,
                })
              );
              scrollTo(0, 0);
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </div>
  );
};
