import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'client/components/v3/Common/Button';
import commonStyles from 'client/pages/v3/Availability/AvailabilityCommon.module.css';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import {
  SalesStatus,
  getSalesStatusOptions,
} from 'client/libraries/util/getProductSalesStatus';
import {
  setDispatchCrewFilter,
  setDispatchMiscResourceFilter,
  setDispatchVehicleFilter,
  setProductCalendarListProductFilter,
  setProductCalendarListSalesStatusFilter,
  setProductCalendarListTagFilter,
} from 'client/actions/productCalendarListControls';
import {
  dispatchCrewFilterSetSelector,
  dispatchMiscResourceFilterSetSelector,
  dispatchVehicleFilterSetSelector,
  productFilterSetSelector,
  salesStatusFilterSetSelector,
  tagFilterSetSelector,
} from 'client/pages/Availability/util';
import {
  allProductTagsSelector,
  summariesWithBookmarksSelector,
} from 'client/reducers/products';
import {
  allDispatchCrewMembersSelector,
  allDispatchMiscResourcesSelector,
  allDispatchVehiclesSelector,
} from 'client/reducers/dispatchSettings';

type Props = {
  text: string;
  children: JSX.Element;
};

const CollapsibleFilterBox = ({ text, children }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(true);

  return (
    <div className={commonStyles['p-filterPopup__body__box']}>
      <p
        className={commonStyles['p-filterPopup__body__box__ttl']}
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        {text}
        <i
          className={`${
            isActive
              ? 'c-icon-outline-arrows-chevron-up'
              : 'c-icon-outline-arrows-chevron-down'
          }`}
        ></i>
      </p>
      <ul
        className={clsx(
          commonStyles['p-filterPopup__body__box__main'],
          isActive && commonStyles['is-active']
        )}
      >
        {children}
      </ul>
    </div>
  );
};

export const FilterPopup = () => {
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [numberOfActiveFilters, setNumberOfActiveFilters] = useState<number>(0);
  const { t } = useTranslation();
  const productNameFilterSet = useSelector(productFilterSetSelector);
  const tagFilterSet = useSelector(tagFilterSetSelector);
  const salesStatusFilterSet = useSelector(salesStatusFilterSetSelector);
  const dispatch = useDispatch();
  const allTags = useSelector(allProductTagsSelector);
  const crews = useSelector(allDispatchCrewMembersSelector);
  const vehicles = useSelector(allDispatchVehiclesSelector);
  const miscResources = useSelector(allDispatchMiscResourcesSelector);
  const dispatchCrewFilterSet = useSelector(dispatchCrewFilterSetSelector);
  const dispatchVehicleFilterSet = useSelector(
    dispatchVehicleFilterSetSelector
  );
  const dispatchMiscResourceFilterSet = useSelector(
    dispatchMiscResourceFilterSetSelector
  );

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMenu]);

  useEffect(() => {
    setNumberOfActiveFilters(
      productNameFilterSet.length +
        tagFilterSet.length +
        salesStatusFilterSet.length +
        dispatchCrewFilterSet.length +
        dispatchVehicleFilterSet.length +
        dispatchMiscResourceFilterSet.length
    );
  }, [
    productNameFilterSet,
    tagFilterSet,
    salesStatusFilterSet,
    dispatchCrewFilterSet,
    dispatchVehicleFilterSet,
    dispatchMiscResourceFilterSet,
  ]);

  // If the products in filter are not found in options, clear the filter
  useEffect(() => {
    const matchesFound = productNameFilterSet.every((id) =>
      productOptions.some((option) => option.value === id)
    );
    if (!matchesFound) {
      dispatch(setProductCalendarListProductFilter([]));
    }
  }, [productNameFilterSet]);

  const allProducts = useSelector(summariesWithBookmarksSelector);
  const productOptions = useMemo(() => {
    return allProducts.map((product) => {
      return {
        value: product.id,
        key: product.id,
        text: product?.internal_product_name ?? '',
      };
    });
  }, [allProducts]);
  const tagOptions = useMemo(
    () =>
      allTags.map((tag) => ({
        key: tag,
        value: tag,
        text: tag,
      })) || [],
    [allTags]
  );

  return (
    <fieldset ref={fieldSetRef}>
      <Button
        text={t('Filter')}
        size="icon"
        color="secondary"
        onClick={() => {
          setShowMenu(!showMenu);
        }}
        iconBeforeText={<i className="c-icon-outline-general-filter-lines"></i>}
        iconAfterText={
          <>
            {numberOfActiveFilters > 0 && <span>{numberOfActiveFilters}</span>}
          </>
        }
        style={{ width: '160px' }}
      />
      <div
        className={clsx(
          commonStyles['p-filterPopup'],
          showMenu && commonStyles['is-active']
        )}
      >
        <div className={commonStyles['p-filterPopup__header']}>
          {t('Filter Conditions')}
        </div>
        <div className={commonStyles['p-filterPopup__body']}>
          <CollapsibleFilterBox text={t('Reservation Slot')}>
            <>
              <li>
                <MultiDropdown
                  label={t('Product Name')}
                  options={productOptions}
                  onChange={(value) => {
                    dispatch(setProductCalendarListProductFilter(value));
                  }}
                  selectedOptions={productNameFilterSet}
                  searchable={true}
                />
              </li>
              <li>
                <MultiDropdown
                  label={t('Product Status')}
                  options={getSalesStatusOptions(t)}
                  onChange={(value) => {
                    dispatch(
                      setProductCalendarListSalesStatusFilter(
                        value as SalesStatus[]
                      )
                    );
                  }}
                  selectedOptions={salesStatusFilterSet as string[]}
                />
              </li>
              <li>
                <MultiDropdown
                  label={t('Tags')}
                  options={tagOptions}
                  onChange={(value) => {
                    dispatch(setProductCalendarListTagFilter(value));
                  }}
                  selectedOptions={tagFilterSet}
                  searchable={true}
                />
              </li>
            </>
          </CollapsibleFilterBox>
        </div>
        <div className={commonStyles['p-filterPopup__body']}>
          <CollapsibleFilterBox text={t('Resource')}>
            <>
              <li>
                <MultiDropdown
                  label={t('Crew')}
                  options={crews.map((crew) => ({
                    text: crew.key ?? '',
                    value: crew.key ?? '',
                  }))}
                  onChange={(value) => {
                    dispatch(setDispatchCrewFilter(value));
                  }}
                  selectedOptions={dispatchCrewFilterSet}
                  searchable={true}
                />
              </li>
              <li>
                <MultiDropdown
                  label={t('Vehicle')}
                  options={vehicles.map((vehicle) => ({
                    text: vehicle.key ?? '',
                    value: vehicle.key ?? '',
                  }))}
                  onChange={(value) => {
                    dispatch(setDispatchVehicleFilter(value));
                  }}
                  selectedOptions={dispatchVehicleFilterSet}
                  searchable={true}
                />
              </li>
              <li>
                <MultiDropdown
                  label={t('Other')}
                  options={miscResources.map((resource) => ({
                    text: resource.key ?? '',
                    value: resource.key ?? '',
                  }))}
                  onChange={(value) => {
                    dispatch(setDispatchMiscResourceFilter(value));
                  }}
                  selectedOptions={dispatchMiscResourceFilterSet}
                  searchable={true}
                />
              </li>
            </>
          </CollapsibleFilterBox>
        </div>
      </div>
    </fieldset>
  );
};
