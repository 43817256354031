import * as React from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { toCustomerShape } from 'client/libraries/util/customerShape';
import { Box } from 'client/components/Box/Box';
import { getDateTimeDisplay } from 'client/libraries/util/util';
import type { ReduxState } from 'client/reducers';
import { ToggleButton } from 'client/components/Form';
import { updateCustomer } from 'client/actions/customers';
import { Loading } from 'client/pages/Loading';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import type { Reservation, CustomerFormFieldSet } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import pageStyles from 'client/pages/pages.module.css';
import { useCountries } from 'client/hooks/useCountries';
import { SwaggerAutoTag } from 'client/pages/v3/Customer/CustomerSettings/Tabs/TagSettings/FormValues';
import { Badge } from 'client/components/v3/Common/Badge';
import { v3CustomerTagColors } from 'client/libraries/util/colors';
import { fetchOrganizations } from 'client/actions/organizations';

import { EditTagsModal } from './EditTagsModal';
import { EditStaffMemoModal } from './EditStaffMemoModal';
import { EditCustomerInformationModal } from './EditCustomerInformationModal';
import { CustomerContext } from './CustomerContext';
import { CustomerReservationsContext } from './CustomerReservationsContext';
import styles from './CustomerDetails.module.css';

const getTotalAmount = (reservations: Reservation[] | null) => {
  if (reservations === null) {
    return '';
  }
  const totalAmount = reservations
    .filter((reservation) => {
      return (
        reservation.status === 'REQUESTED' || reservation.status === 'CONFIRMED'
      );
    })
    .reduce(
      (acc, reservation) => {
        let amount = '0';
        let currencyCode = '';
        if ((reservation?.billing_info?.amount_gross?.length ?? 0) > 3) {
          currencyCode =
            reservation?.billing_info?.amount_gross?.substring(0, 3) ?? '';
          amount =
            reservation?.billing_info?.amount_gross
              ?.substring(3)
              .replace(',', '') ?? '0';
        }

        return {
          currencyCode,
          amount: acc.amount + Number(amount),
        };
      },
      { currencyCode: '', amount: 0 }
    );

  return `${totalAmount.currencyCode}${totalAmount.amount}`;
};

export const CustomerInformation = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const customer = React.useContext(CustomerContext);
  const reservations = React.useContext(CustomerReservationsContext);
  const [shouldReceiveSpecialEmailOffers, setShouldReceiveSpecialEmailOffers] =
    React.useState<boolean>(
      customer?.should_receive_special_email_offers?.value ?? false
    );
  const [alertedCustomer, setAlertedCustomer] = React.useState<boolean>(
    customer?.alerted_customer?.value ?? false
  );
  const loading = useSelector((state: ReduxState) => state.customers.loading);
  const [showEditTagsModal, setShowEditTagsModal] = React.useState(false);
  const [editStaffMemoIndex, setEditStaffMemoIndex] = React.useState<number>(0);
  const [showEditStaffMemoModal, setShowEditStaffMemoModal] =
    React.useState(false);
  const [deleteStaffMemoIndex, setDeleteStaffMemoIndex] =
    React.useState<number>(0);
  const [showDeleteStaffMemoModal, setShowDeleteStaffMemoModal] =
    React.useState(false);
  const [showEditCustomerInfoModal, setShowEditCustomerInfoModal] =
    React.useState(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const autoTags = (activeUserOrganization?.customer_ledger_settings
    ?.customer_auto_tags ?? []) as SwaggerAutoTag[];

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const countries = useCountries();

  React.useEffect(() => {
    if (customer) {
      setShouldReceiveSpecialEmailOffers(
        customer.should_receive_special_email_offers?.value ?? false
      );
      setAlertedCustomer(customer.alerted_customer?.value ?? false);
    }
  }, [customer]);

  const customerShape = React.useMemo(() => {
    return toCustomerShape(customer, t, countries, i18n.language);
  }, [customer, t, countries, i18n.language]);

  const customerFormFieldSet = (
    activeUserOrganization?.customer_ledger_settings?.form_field_sets ?? []
  ).find(
    (fieldSet: CustomerFormFieldSet) =>
      fieldSet.content_language === (customer?.language || 'JA_JP')
  );

  const deleteStaffMemoHandler = (index: number) => {
    const newStaffMemos = [
      ...customerShape.staffMemos.filter((_, i) => i !== index),
    ];

    dispatch(
      updateCustomer(customer?.id ?? '', {
        ...customer,
        staff_memos: newStaffMemos.length > 0 ? newStaffMemos : [{}],
      })
    );
  };

  React.useEffect(() => {
    dispatch(fetchOrganizations());
  }, []);

  return (
    <div
      className={clsx(
        baseStyles['base-main__body__box'],
        baseStyles['scroll-target-pane']
      )}
      id="main"
    >
      <div className={clsx(baseStyles['base-main__body__box__header'])}>
        <div className={clsx(baseStyles['base-main__body__box__header__ttl'])}>
          {t('Customer Information')}
        </div>
        <div className={clsx(baseStyles['base-main__body__box__header__btn'])}>
          <div
            className={clsx(pageStyles['page-reservations__basicInfo__btn'])}
          >
            <EditIcon
              className={clsx(baseStyles['base-btn icon'], baseStyles['icon'])}
              onClick={() => setShowEditCustomerInfoModal(true)}
            />
          </div>
        </div>
      </div>

      <div className={clsx(baseStyles['base-main__body__box__body'])}>
        <div className={clsx(pageStyles['page-reservations__basicFrame'])}>
          <div>
            <table className={clsx(baseStyles['base-table'])}>
              <tbody>
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Customer Reference')}
                  </th>
                  <td>{customerShape.supplierReference}</td>
                </tr>
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Name')}
                  </th>
                  <td>{customerShape.customerName}</td>
                </tr>
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Email')}
                  </th>
                  <td>
                    <a
                      className={clsx(baseStyles['base-link'])}
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:${customerShape.email}`}
                    >
                      {customerShape.email}
                    </a>
                  </td>
                </tr>
                {customerFormFieldSet?.form_fields?.map((formField, idx) => {
                  const value = customerShape.formFieldResponses.find(
                    (response) => response.key === formField.key
                  )?.value;

                  return (
                    <tr key={idx}>
                      <th className={clsx(baseStyles['base-t-160'])}>
                        {formField.field_name ?? ''}
                      </th>
                      <td>{value}</td>
                    </tr>
                  );
                })}
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Credit Card')}
                  </th>
                  <td>{customerShape.creditCard}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table className={clsx(baseStyles['base-table'])}>
              <tbody>
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Total Reservations')}
                  </th>
                  <td>{customerShape.reservationCount}</td>
                </tr>
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Total Gross')}
                  </th>
                  <td>{getTotalAmount(reservations)}</td>
                </tr>
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Last Participation Date')}
                  </th>
                  <td>
                    {customerShape.lastParticipationDate
                      ? getDateTimeDisplay(
                          customerShape.lastParticipationDate,
                          locale
                        )
                      : ''}
                  </td>
                </tr>
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Last Participated Product Name')}
                  </th>
                  <td>{customerShape.lastParticipatedProductName}</td>
                </tr>
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Membership Registered')}
                  </th>
                  <td>
                    {customerShape.membershipRegistered
                      ? getDateTimeDisplay(
                          customerShape.membershipRegistered,
                          locale
                        )
                      : ''}
                  </td>
                </tr>
                <tr>
                  <th className={clsx(baseStyles['base-t-160'])}>
                    {t('Receive Emails')}
                  </th>
                  <td>
                    <ToggleButton
                      label={t('Receive special email offers')}
                      checked={shouldReceiveSpecialEmailOffers}
                      onChange={() => {
                        dispatch(
                          updateCustomer(customer?.id ?? '', {
                            ...customer,
                            should_receive_special_email_offers: {
                              value: !shouldReceiveSpecialEmailOffers,
                            },
                          })
                        );
                        setShouldReceiveSpecialEmailOffers(
                          !shouldReceiveSpecialEmailOffers
                        );
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <Box mt={2}>
          <table className={clsx(baseStyles['base-table'])}>
            <tbody>
              <tr>
                <th className={clsx(baseStyles['base-t-160'])}>
                  {t('Alerted')}
                </th>
                <td>
                  <ToggleButton
                    label={t('Flag customer as alerted')}
                    checked={alertedCustomer}
                    onChange={() => {
                      dispatch(
                        updateCustomer(customer?.id ?? '', {
                          ...customer,
                          alerted_customer: {
                            value: !alertedCustomer,
                          },
                        })
                      );
                      setAlertedCustomer(!alertedCustomer);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th className={clsx(baseStyles['base-t-160'])}>
                  {t('Manual Tags')}
                </th>
                <td>
                  <Box display="flex">
                    <ul className={styles['tag-list']}>
                      {customerShape.tags.map((tag) => '#' + tag).join(', ')}
                    </ul>

                    <EditIcon
                      className={clsx(
                        baseStyles['base-btn icon'],
                        baseStyles['icon']
                      )}
                      onClick={() => setShowEditTagsModal(true)}
                    />
                  </Box>
                  {showEditTagsModal && (
                    <EditTagsModal
                      onClose={() => setShowEditTagsModal(false)}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <th className={clsx(baseStyles['base-t-160'])}>
                  {t('Auto Tags')}
                </th>
                <td>
                  <Box display="flex">
                    <ul className={styles['autotag-list']}>
                      {customerShape.autotags?.map((tag) => {
                        const autoTag = autoTags.find(
                          (autoTag) => autoTag.name === tag
                        );
                        // If auto tag is not found (e.g. auto tag is already deleted or renamed), render nothing
                        if (!autoTag) {
                          return <></>;
                        }
                        const color = v3CustomerTagColors.find(
                          (tagColor) => tagColor.value === autoTag.color
                        );
                        return (
                          <li key={tag}>
                            <Badge
                              label={autoTag.name ?? ''}
                              backgroundColor={color?.backgroundColor}
                              borderColor={color?.borderColor}
                              textColor={color?.color}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </Box>
                </td>
              </tr>
              <tr>
                <th className={clsx(baseStyles['base-t-160'])}>
                  {t('Staff Memo')}
                </th>
                <td>
                  {customerShape.staffMemos.map((memo, index) => (
                    <Box display={'flex'} key={index}>
                      <div className={styles['staff-memo']}>
                        <p>{getDateTimeDisplay(memo.dateTime, locale)}</p>
                        <p>{memo.body}</p>
                      </div>

                      <EditIcon
                        className={clsx(
                          baseStyles['base-btn icon'],
                          baseStyles['icon']
                        )}
                        onClick={() => {
                          setEditStaffMemoIndex(index);
                          setShowEditStaffMemoModal(true);
                        }}
                      />
                      <DeleteIcon
                        className={clsx(
                          baseStyles['base-btn icon'],
                          baseStyles['icon']
                        )}
                        onClick={() => {
                          setDeleteStaffMemoIndex(index);
                          setShowDeleteStaffMemoModal(true);
                        }}
                      />
                    </Box>
                  ))}
                  <AddIcon
                    onClick={() => {
                      setEditStaffMemoIndex(
                        (customerShape.staffMemos ?? []).length
                      );
                      setShowEditStaffMemoModal(true);
                    }}
                  />
                  {showEditStaffMemoModal && (
                    <EditStaffMemoModal
                      index={editStaffMemoIndex}
                      staffMemo={
                        editStaffMemoIndex >=
                        (customerShape.staffMemos ?? []).length
                          ? null
                          : customerShape.staffMemos[editStaffMemoIndex]
                      }
                      onClose={() => setShowEditStaffMemoModal(false)}
                    />
                  )}
                  {showDeleteStaffMemoModal && (
                    <DeleteConfirmModal
                      header={t('Delete Staff Memo')}
                      content={t(
                        'Are you sure you want ot delete the memo at {{dateTime}}',
                        {
                          dateTime: getDateTimeDisplay(
                            customerShape.staffMemos[deleteStaffMemoIndex]
                              .dateTime,
                            locale
                          ),
                        }
                      )}
                      onClose={() => setShowDeleteStaffMemoModal(false)}
                      onConfirm={() => {
                        deleteStaffMemoHandler(deleteStaffMemoIndex);
                        setShowDeleteStaffMemoModal(false);
                      }}
                      open={showDeleteStaffMemoModal}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </div>
      {loading && <Loading />}
      {showEditCustomerInfoModal && (
        <EditCustomerInformationModal
          onClose={() => setShowEditCustomerInfoModal(false)}
        />
      )}
    </div>
  );
};
