import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReduxState } from 'client/reducers';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { setTheme } from 'client/actions/theme';

export const ThemeSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shouldUseUsaTheme =
    useSelector((state: ReduxState) => state.theme) === 'USA';

  return (
    <div>
      <Toggle
        label={t('Use USA theme')}
        checked={shouldUseUsaTheme}
        onChange={() => {
          dispatch(setTheme(shouldUseUsaTheme ? 'DEFAULT' : 'USA'));
        }}
      />
    </div>
  );
};
