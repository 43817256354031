import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { fetchProductByID } from 'client/actions/products';
import { fetchReservationByID } from 'client/actions/reservations';
import { ReduxState } from 'client/reducers';
import { CheckinContent } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/TicketRedemption/Checkin/CheckinContent';
import { ReservationGuestTypeRedemptionTableContent } from 'client/components/v3/ReservationGuestTypeRedemptionTable/ReservationGuestTypeRedemptionTableContent';
import { ReservationRedemptionCountTableContent } from 'client/components/v3/ReservationRedemptionCountTable/ReservationRedemptionCountTableContent';

interface Props {
  componentReservationId: string;
}

export const DynamicPackageComponentCheckin = ({
  componentReservationId,
}: Props) => {
  const dispatch = useDispatch();
  const reservationById = useSelector(
    (state: ReduxState) => state.reservations.byID
  );
  const productById = useSelector((state: ReduxState) => state.products.byID);

  const componentReservation = reservationById[componentReservationId];
  const product = productById[componentReservation?.product_id ?? ''];

  useEffect(() => {
    if (!componentReservation) {
      dispatch(fetchReservationByID(componentReservationId));
    }
  }, [componentReservation, componentReservationId]);

  useEffect(() => {
    if (!product && componentReservation) {
      dispatch(fetchProductByID(componentReservation.product_id ?? ''));
    }
  }, [componentReservation, product, componentReservationId]);

  if (!componentReservation || !product) {
    return null;
  }

  return (
    <>
      {product?.qr_checkin_settings
        ?.should_count_guests_for_checkin_with_guest_type ? (
        <ReservationGuestTypeRedemptionTableContent
          reservation={componentReservation}
          product={product}
        />
      ) : product?.qr_checkin_settings?.should_use_redemption_count ? (
        <ReservationRedemptionCountTableContent
          reservation={componentReservation}
          product={product}
        />
      ) : (
        <>
          <CheckinContent
            reservation={componentReservation}
            product={product}
          />
        </>
      )}
    </>
  );
};
