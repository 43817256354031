import React from 'react';

import styles from './ParkBusyChart.module.css';

interface BarProps {
  height: number;
  isHighlighted?: boolean;
  onClick?: () => void;
}

const Bar: React.FC<BarProps> = ({
  height,
  isHighlighted = false,
  onClick,
}) => (
  <div
    className={`${styles.bar} ${isHighlighted ? styles.highlightedBar : ''}`}
    style={{ height: `${Math.floor(height)}px` }}
    onClick={onClick}
  />
);

interface BarChartProps {
  data: number[];
  highlightedIndex: number;
  onClickBar: (index: number) => void;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  highlightedIndex,
  onClickBar,
}) => {
  const maxBarValue = Math.max(...data);

  const maxChartHeight = 100;

  const barHeightMultiplier = maxChartHeight / maxBarValue;

  return (
    <div className={styles.barChartContainer}>
      <div className={styles.barChart}>
        {data.map((height, index) => (
          <Bar
            key={index}
            height={height * barHeightMultiplier}
            isHighlighted={index === highlightedIndex}
            onClick={() => {
              onClickBar(index);
            }}
          />
        ))}
        <div className={styles.dashedLine} />
      </div>
    </div>
  );
};

export default BarChart;
