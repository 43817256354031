import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { SearchReservationsRequest } from 'client/pages/ReservationSearch/util';
import { activeUserSelector } from 'client/reducers/user';
import { setLastReservationSearchQuery } from 'client/actions/reservationSearch';
import { ReservationSearchQueryModal } from 'client/pages/ReservationSearch/ReservationSearchQueryModal/ReservationSearchQueryModal';
import { fetchProducts } from 'client/actions/products';
import baseStyles from 'client/base.module.css';
import editIcon from 'client/images/ic_edit.svg';
import { ReservationSearchQueryDisplayBox } from 'client/pages/ReservationSearch/ReservationSearchQueryDisplayBox/ReservationSearchQueryDisplayBox';

type Props = {
  appliedSearchCondition: SearchReservationsRequest;
};
export const Conditions = ({ appliedSearchCondition }: Props) => {
  const { t } = useTranslation();
  const activeUser = useSelector(activeUserSelector);
  const [searchCondition, setSearchCondition] =
    React.useState<SearchReservationsRequest>(appliedSearchCondition);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUser]);

  const onApply = () => {
    dispatch(setLastReservationSearchQuery(searchCondition));
  };

  const onReset = () => {
    setSearchCondition({
      presetKey: '',
      orderBy: 'last_updated_desc',
      agentReference: '',
      agentIds: [],
      supplierReference: '',
      id: '',
      statuses: [],
      customerGivenName: '',
      customerFamilyName: '',
      customerPhone: '',
      customerEmail: '',
      bookingSourceTypes: [],
      groupIds: [],
      supplierIds: [],
      productIds: [],
      bookedDateFrom: '',
      bookedDateTo: '',
      participationDateFrom: '',
      participationDateTo: '',
      lastUpdatedDateFrom: '',
      lastUpdatedDateTo: '',
      dateFilterPreset: null,
      supplierOrAgentReference: '',
      reservationLanguages: [],
      mostRecentEmailBounced: false,
      pickupCheckinLocationName: '',
      waiverCompletionStatuses: [],
      checkinStatuses: [],
      annualPassOnly: false,
      expirationPresetKey: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      expirationDateFilterPreset: null,
      automaticContinuingStatus: null,
      partnershipReservationOnly: false,
    });
  };

  return (
    <div>
      <b>{t('Search Conditions')}</b>
      <ReservationSearchQueryModal
        title={t('Search Conditions')}
        searchButtonText={t('Apply')}
        onReset={onReset}
        onSearch={onApply}
        searchCondition={searchCondition}
        setSearchCondition={setSearchCondition}
        trigger={
          <a className={clsx(baseStyles['base-margin-left-8'])}>
            <img src={editIcon} />
          </a>
        }
        note={t(
          '* Search conditions are also applied on the the reservation list.'
        )}
      />
      <Box mt={2} mb={6}>
        <ReservationSearchQueryDisplayBox
          inline
          searchFilters={appliedSearchCondition}
        />
      </Box>
    </div>
  );
};
