import * as React from 'react';

import styles from './WaitTimeManager.module.css';

interface WaitTimeOptionProps {
  value: number | string;
  isSelected: boolean;
  onClick: () => void;
}

export const WaitTimeNumericOption: React.FC<WaitTimeOptionProps> = ({
  value,
  isSelected,
  onClick,
}) => {
  const cardClassName = isSelected
    ? styles.optionCardSelected
    : styles.optionCard;

  return (
    <button className={cardClassName} onClick={onClick}>
      <span className={styles.optionValue}>{value}</span>
      <span className={styles.optionUnit}>min</span>
    </button>
  );
};
