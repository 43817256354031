import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import { createSelector } from 'reselect';

import { productCalendarListDatesSelector } from 'client/reducers/productCalendarListControls';
import type { ReduxState } from 'client/reducers';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import type { SalesStatus } from 'client/libraries/util/getProductSalesStatus';
import { getProductSalesStatus } from 'client/libraries/util/getProductSalesStatus';
import * as Swagger from 'shared/models/swagger';

export type ProductWithOccupancy = {
  id: string;
  supplierReference: string;
  name: string;
  timezone: string;
  startTimeAvailabilities: Swagger.StartTimeAvailability[];
  childProducts: ProductWithOccupancy[];
  allotmentSettings: Swagger.AllotmentSettings | null;
  shouldRejectRequestBookingsBeyondCapacity: boolean;
};
type ProductSharedAllotmentGroup = {
  parentProduct: Swagger.ProductSummary;
  childProducts: Swagger.ProductSummary[];
};

const pageSizeSelector = (state: ReduxState): number =>
  state.productCalendarListControls.pageSize;

const currentPageSelector = (state: ReduxState): number =>
  state.productCalendarListControls.currentPage;

export const productFilterSetSelector = createSelector(
  (state: ReduxState) => state.productCalendarListControls.productFilter,
  (productFilter: string[]): string[] => [...new Set(productFilter)]
);

export const tagFilterSetSelector = createSelector(
  (state: ReduxState) => state.productCalendarListControls.productTagFilter,
  (tagFilter: string[]): string[] => [...new Set(tagFilter)]
);

export const salesStatusFilterSetSelector = createSelector(
  (state: ReduxState) =>
    state.productCalendarListControls.productSalesStatusFilter,
  (salesStatusFilter: SalesStatus[]): SalesStatus[] => [
    ...new Set(salesStatusFilter),
  ]
);

export const dispatchCrewFilterSetSelector = createSelector(
  (state: ReduxState) => state.productCalendarListControls.dispatchCrewFilter,
  (dispatchCrewFilter: string[]): string[] => [...new Set(dispatchCrewFilter)]
);
export const dispatchVehicleFilterSetSelector = createSelector(
  (state: ReduxState) =>
    state.productCalendarListControls.dispatchVehicleFilter,
  (dispatchVehicleFilter: string[]): string[] => [
    ...new Set(dispatchVehicleFilter),
  ]
);
export const dispatchMiscResourceFilterSetSelector = createSelector(
  (state: ReduxState) =>
    state.productCalendarListControls.dispatchMiscResourceFilter,
  (dispatchMiscResourceFilter: string[]): string[] => [
    ...new Set(dispatchMiscResourceFilter),
  ]
);

const summariesSharedAllotmentGroupsSelector = createSelector(
  summariesSortedByBookmarkedSelector,
  (allProducts): ProductSharedAllotmentGroup[] => {
    const parentProducts = allProducts
      .filter(
        (p) =>
          !p.shared_allotment_references ||
          !p.shared_allotment_references.parent_product_id
      )
      .filter((p) => !p.is_dynamic_package_product?.value); // Filter out dynamic package products

    const productsById: Record<string, Swagger.ProductSummary> = {};

    for (const product of allProducts) {
      productsById[product.id] = product;
    }

    return parentProducts.map((parentProduct) => {
      const childIds =
        (parentProduct.shared_allotment_references &&
          parentProduct.shared_allotment_references.child_product_ids) ||
        [];
      const childProducts: Swagger.ProductSummary[] = [];

      for (const childId of childIds) {
        const childProduct = productsById[childId];

        if (childProduct) {
          childProducts.push(childProduct);
        }
      }

      return {
        parentProduct,
        childProducts,
      };
    });
  }
);

const productInSet = (
  product: Swagger.ProductSummary,
  productFilterSet: string[]
) => productFilterSet.includes(product.id ?? '');

const productHasTagInSet = (
  product: Swagger.ProductSummary,
  tagFilterSet: string[]
) =>
  (product.product_tags || []).some((tag) => tagFilterSet.includes(tag)) ||
  (product.internal_product_tags || []).some((tag) =>
    tagFilterSet.includes(tag)
  );

const productSalesStatusInSet = (
  product: Swagger.ProductSummary,
  salesStatusFilterSet: SalesStatus[]
) => salesStatusFilterSet.includes(getProductSalesStatus(product));

type DispatchField =
  | 'dispatch_crew'
  | 'dispatch_vehicles'
  | 'dispatch_misc_resources';

const filterAvailabilityByDispatch = (
  allReservations: Swagger.Reservation[],
  availabilities: Swagger.ProductAvailability[],
  dispatchFilterSet: string[],
  dispatchField: DispatchField
): Swagger.ProductAvailability[] => {
  return availabilities
    .filter((availability) => availability.start_time_availabilities)
    .map((availability) => ({
      ...availability,
      start_time_availabilities: availability.start_time_availabilities
        ?.filter(
          (startTimeAvailability) =>
            startTimeAvailability.date_availabilities &&
            startTimeAvailability.date_availabilities.some((dateAvailability) =>
              allReservations.some(
                (reservation) =>
                  reservation.product_instance_id ===
                    dateAvailability.product_instance_id &&
                  reservation[dispatchField] &&
                  reservation[dispatchField]?.some((dispatchValue: string) =>
                    dispatchFilterSet.includes(dispatchValue)
                  )
              )
            )
        )
        .map((startTimeAvailability) => ({
          ...startTimeAvailability,
          date_availabilities:
            startTimeAvailability.date_availabilities?.filter(
              (dateAvailability) =>
                allReservations.some(
                  (reservation) =>
                    reservation.product_instance_id ===
                      dateAvailability.product_instance_id &&
                    reservation[dispatchField] &&
                    reservation[dispatchField]?.some((dispatchValue: string) =>
                      dispatchFilterSet.includes(dispatchValue)
                    )
                )
            ),
        })),
    }));
};

const sharedAllotmentGroupsSelector = createSelector(
  summariesSharedAllotmentGroupsSelector,
  productFilterSetSelector,
  tagFilterSetSelector,
  salesStatusFilterSetSelector,
  (
    allGroups,
    productFilterSet,
    tagFilterSet,
    salesStatusFilterSet
  ): ProductSharedAllotmentGroup[] => {
    if (
      productFilterSet.length === 0 &&
      tagFilterSet.length === 0 &&
      salesStatusFilterSet.length === 0
    ) {
      return allGroups;
    }

    if (productFilterSet.length !== 0) {
      allGroups = allGroups.filter(
        (group) =>
          productInSet(group.parentProduct, productFilterSet) ||
          group.childProducts.some((childProduct) =>
            productInSet(childProduct, productFilterSet)
          )
      );
    }

    if (tagFilterSet.length !== 0) {
      allGroups = allGroups.filter(
        (group) =>
          productHasTagInSet(group.parentProduct, tagFilterSet) ||
          group.childProducts.some((childProduct) =>
            productHasTagInSet(childProduct, tagFilterSet)
          )
      );
    }

    if (salesStatusFilterSet.length !== 0) {
      allGroups = allGroups.filter(
        (group) =>
          productSalesStatusInSet(group.parentProduct, salesStatusFilterSet) ||
          group.childProducts.some((childProduct) =>
            productSalesStatusInSet(childProduct, salesStatusFilterSet)
          )
      );
    }

    return allGroups;
  }
);

const availabilitiesSelector = createSelector(
  (state: ReduxState) => state.reservationSearch.all,
  (state: ReduxState) => state.productAvailability.availabilities,
  dispatchCrewFilterSetSelector,
  dispatchVehicleFilterSetSelector,
  dispatchMiscResourceFilterSetSelector,
  (
    allReservations,
    availabilities,
    dispatchCrewFilterSet,
    dispatchVehicleFilterSet,
    dispatchMiscResourceFilterSet
  ): Swagger.ProductAvailability[] => {
    if (
      dispatchCrewFilterSet.length === 0 &&
      dispatchVehicleFilterSet.length === 0 &&
      dispatchMiscResourceFilterSet.length === 0
    ) {
      return availabilities;
    }

    if (dispatchCrewFilterSet.length !== 0) {
      availabilities = filterAvailabilityByDispatch(
        allReservations,
        availabilities,
        dispatchCrewFilterSet,
        'dispatch_crew'
      );
    }

    if (dispatchVehicleFilterSet.length !== 0) {
      availabilities = filterAvailabilityByDispatch(
        allReservations,
        availabilities,
        dispatchVehicleFilterSet,
        'dispatch_vehicles'
      );
    }

    if (dispatchMiscResourceFilterSet.length !== 0) {
      availabilities = filterAvailabilityByDispatch(
        allReservations,
        availabilities,
        dispatchMiscResourceFilterSet,
        'dispatch_misc_resources'
      );
    }

    return availabilities;
  }
);

export const productsWithOccupancySelector = createSelector(
  sharedAllotmentGroupsSelector,
  productCalendarListDatesSelector,
  availabilitiesSelector,
  (state: ReduxState) => state.productAvailability.loading,
  (productGroups, dates, availabilities) => {
    const toProductWithOccupancy = (
      product: Swagger.ProductSummary,
      childProducts: Swagger.ProductSummary[]
    ): ProductWithOccupancy => {
      const productAvailability = availabilities.find(
        (a) => a.product_id === product.id
      );

      return {
        id: product.id || '',
        supplierReference: product.supplier_reference || '',
        name: product.product_name || '',
        timezone: product.start_timezone || '',
        startTimeAvailabilities:
          productAvailability?.start_time_availabilities ?? [],
        childProducts: childProducts.map((childProduct) =>
          toProductWithOccupancy(childProduct, [])
        ),
        allotmentSettings: product.allotment_settings ?? null,
        shouldRejectRequestBookingsBeyondCapacity:
          productAvailability?.rejects_request_booking_beyond_capacity ?? false,
      };
    };

    return productGroups.map((productGroup) =>
      toProductWithOccupancy(
        productGroup.parentProduct,
        productGroup.childProducts
      )
    );
  }
);

export const visibleParentProductIdsSelector = createSelector(
  sharedAllotmentGroupsSelector,
  pageSizeSelector,
  currentPageSelector,
  (productGroups, pageSize, currentPage): string[] => {
    const startIndex = pageSize * currentPage;
    const endIndex = pageSize * (currentPage + 1);

    if (productGroups.length <= startIndex) {
      return [];
    }

    if (productGroups.length < endIndex) {
      return productGroups
        .slice(startIndex)
        .map((group) => group.parentProduct?.id);
    }

    return productGroups
      .slice(startIndex, endIndex)
      .map((group) => group.parentProduct?.id);
  }
);

type ColorClassName =
  | 'gray'
  | 'yellow'
  | 'red100'
  | 'red75'
  | 'red50'
  | 'red25'
  | '';

export const getAvailabilityBackgroundColorClass = (
  bookedSlots: number,
  totalSlots: number,
  isClosed: boolean
): ColorClassName => {
  if (isClosed) {
    return 'gray';
  }

  if (totalSlots === 0) {
    if (bookedSlots !== 0) {
      return 'yellow';
    }
  } else {
    if (bookedSlots >= totalSlots) {
      return 'red100';
    }

    const ratio = bookedSlots / totalSlots;

    if (ratio >= 0.75) {
      return 'red75';
    }

    if (ratio >= 0.5) {
      return 'red50';
    }

    if (ratio > 0) {
      return 'red25';
    }
  }

  return '';
};
export const getAvailabilityIconType = (
  bookedSlots: number,
  totalSlots: number,
  isClosed: boolean,
  shouldRejectRequestBookingsBeyondCapacity?: boolean,
  bookingDeadlines?: Swagger.ProductDateAvailability['booking_deadlines']
): 'STOP' | 'REQUEST' | 'NO_ICON' => {
  if (isClosed) {
    return 'STOP';
  }

  if (
    (totalSlots === 0 || bookedSlots >= totalSlots) &&
    !shouldRejectRequestBookingsBeyondCapacity
  ) {
    return 'REQUEST';
  } else if (productInstanceIsRequestOnly(bookingDeadlines)) {
    return 'REQUEST';
  }

  return 'NO_ICON';
};

const productInstanceIsRequestOnly = (
  bookingDeadlines: Swagger.ProductDateAvailability['booking_deadlines']
): boolean => {
  const instantDeadline = getBookingDeadline(bookingDeadlines, 'INSTANT');
  const requestDeadline = getBookingDeadline(bookingDeadlines, 'REQUEST');
  return (
    (instantDeadline === null || instantDeadline.isBefore(moment())) &&
    requestDeadline !== null &&
    requestDeadline.isAfter(moment())
  );
};

const getBookingDeadline = (
  bookingDeadlines: Swagger.ProductDateAvailability['booking_deadlines'],
  confirmationType: 'INSTANT' | 'REQUEST'
): Moment | null => {
  const deadline = (bookingDeadlines || []).find(
    (deadline) => deadline.confirmation_type === confirmationType
  );

  if (deadline) {
    return moment(deadline.date_time_utc);
  }

  return null;
};
