import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers/index';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import {
  setETicketDailyUsesVisibleColumns,
  setETicketDailyUsesDisplaySettings,
} from 'client/actions/eTicketDailyUsesSettings';
import { getCheckinRecordColumns } from 'client/pages/ETicket/ETicketRedemptionCountDailyUses/util';
import { Loading } from 'client/pages/Loading';
import { ETicketDailyUsesProductGroupTableList } from 'client/pages/ETicket/ETicketDailyUses/ETicketDailyUsesProductGroupTableList';
import { ETicketDailyUsesTableSettingsModal } from 'client/pages/ETicket/ETicketDailyUses/ETicketDailyUsesTableSettingsModal';
import { ETicketDailyUsesCSVDownloadModal } from 'client/pages/ETicket/ETicketDailyUses/ETicketDailyUsesCSVDownloadModal';
import { eTicketDailyUsesVisibleColumnsSelector } from 'client/reducers/eTicketDailyUsesSettings';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import { defaultVisibleColumns } from 'client/libraries/util/eTicketDailyUsesSettings';
import { ToggleButton } from 'client/components/Form';
import 'react-table/react-table.css';
import baseStyles from 'client/base.module.css';
import type {
  ETicketDailyUse,
  ETicketDailyUsesExportParams,
} from 'shared/models/swagger';

import styles from './ETicketRedemptionCountDailyUsesDetails.module.css';

type Props = {
  downloadParams: ETicketDailyUsesExportParams;
  eTicketDailyUsesList: ETicketDailyUse[];
};

export const ETicketRedemptionCountDailyUsesDetails = ({
  downloadParams,
  eTicketDailyUsesList,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(
    (state: ReduxState) => state.eTicketDailyUses.loading
  );
  const displaySettings = useSelector(
    (state: ReduxState) => state.eTicketDailyUsesSettings.displaySettings
  );
  const visibleColumns = useSelector(eTicketDailyUsesVisibleColumnsSelector);
  const summaries = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  const showByProductGroup = displaySettings.shownPerProductGroup;

  const allCheckinRecordColumns = getCheckinRecordColumns();
  React.useEffect(() => {
    dispatch(
      setETicketDailyUsesVisibleColumns(
        allCheckinRecordColumns.map((i) => i.id ?? '')
      )
    );
  }, [eTicketDailyUsesList]);

  const getCheckinRecords = () => {
    const shapingETicketDailyUses: Record<string, any>[] = [];

    eTicketDailyUsesList.forEach((dailyUseItem) => {
      const product = summaries.find(
        (summary) => summary.id === dailyUseItem.product_id
      );

      dailyUseItem?.checkin_info?.checkin_records?.forEach((record) => {
        const pushData = {
          productId: dailyUseItem.product_id ?? '',
          productName:
            dailyUseItem.internal_product_name ??
            dailyUseItem.product_name ??
            '',
          reservationId: dailyUseItem.reservation_id ?? '',
          dateTimeUtc: record.date_time_utc ?? '',
          reservationStartTimeZone:
            dailyUseItem.reservation_start_time_zone ?? '',
          reservationSupplierReference:
            dailyUseItem.reservation_supplier_reference ?? '',
          reservationStartDateTimeUtc:
            dailyUseItem.reservation_start_date_time_utc ?? '',
          reservationAgentReference:
            dailyUseItem.reservation_agent_reference ?? '',
          reservationDisplayName: dailyUseItem.reservation_display_name ?? '',
          stubKey: record.stub_key ?? '',
          presetRedemptionCountKey: record.preset_redemption_count_key ?? '',
          stubText:
            product?.qr_checkin_settings?.stubs?.find(
              (stub) => stub.key === record.stub_key
            )?.text ??
            record.stub_key ??
            '',
          presetRedemptionCountText:
            product?.qr_checkin_settings?.preset_redemption_counts?.find(
              (preset) => preset.key === record.preset_redemption_count_key
            )?.text ?? '',
          redemptionCount: record.redemption_count ?? '',
        };
        shapingETicketDailyUses.push(pushData);
      });
    });
    shapingETicketDailyUses.sort((a, b) => {
      const moment1 = moment(a.dateTimeUtc);
      const moment2 = moment(b.dateTimeUtc);

      if (moment1.diff(moment2) > 0) {
        return 1;
      }
      if (moment1.diff(moment2) < 0) {
        return -1;
      }
      return 0;
    });
    return shapingETicketDailyUses;
  };

  const getColumns = (columnMask: string[]): CustomTableColumn[] => {
    return ['EDIT', ...(columnMask.filter((c) => c !== 'EDIT') as any)]
      .map((c) => allCheckinRecordColumns.find((col) => col.id === c) as any)
      .filter((c): c is Exclude<typeof c, undefined> => {
        return c !== undefined;
      });
  };

  const getCandidateColumns = (columnMask: string[]): CustomTableColumn[] => {
    return [...(columnMask.filter((c) => c !== 'EDIT') as any)]
      .map((c) => allCheckinRecordColumns.find((col) => col.id === c) as any)
      .filter((c): c is Exclude<typeof c, undefined> => {
        return c !== undefined;
      });
  };

  return (
    <>
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1'],
            styles['manifest_detail__header__left']
          )}
        >
          <ToggleButton
            label={t('Show by product/group')}
            checked={showByProductGroup}
            onChange={() =>
              dispatch(
                setETicketDailyUsesDisplaySettings({
                  ...displaySettings,
                  shownPerProductGroup: !showByProductGroup,
                })
              )
            }
          />
        </div>
        <div
          className={clsx(
            baseStyles['base-main__body__header__right'],
            baseStyles['spOrder-2']
          )}
        >
          <ETicketDailyUsesCSVDownloadModal
            downloadParams={{
              ...downloadParams,
              columns: visibleColumns.filter(
                (c) => defaultVisibleColumns.indexOf(c) !== -1
              ) as any,
              sort_order_csv_header_column: visibleColumns.filter(
                (c) => c !== 'EDIT'
              ),
            }}
            redemptionCount={true}
          />
          <ETicketDailyUsesTableSettingsModal
            columnsToShow={getCandidateColumns(visibleColumns)}
            allCandidateColumns={allCheckinRecordColumns.filter(
              (c) => c.id !== 'EDIT'
            )}
          />
        </div>
      </div>
      {showByProductGroup ? (
        <ETicketDailyUsesProductGroupTableList
          items={getCheckinRecords()}
          columns={getColumns(visibleColumns)}
        />
      ) : (
        <CustomTable
          items={getCheckinRecords()}
          columns={getColumns(visibleColumns)}
          usePaging={true}
        />
      )}
      {loading && <Loading />}
    </>
  );
};
