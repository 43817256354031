import clsx from 'clsx';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, useFormState } from 'react-final-form';
import _ from 'lodash';

import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Box } from 'client/components/Box/Box';
import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  FieldWrapper,
  Input,
  TextArea,
  Select,
} from 'client/components/Form';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { getLanguageName } from 'client/libraries/i18n';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import { TranslatedField } from 'client/pages/ProductEditor/TranslatedField/TranslatedField';
import { config } from 'client/config';
import { DownloadItemInput } from 'client/pages/InstantWinList/DownloadItemInput';

import { FormValues, GiftFormValues } from './formValues';
import styles from './EditGuidanceStampRallyModal.module.css';

type Props = {
  open: boolean;
  onClose: () => void;
  name: string;
};

type ComponentFormProps = {
  name: string;
};

const EditStampRallyGiftDownloadForm = ({ name }: ComponentFormProps) => {
  return (
    <Box mb={2}>
      <Field name={`${name}.downloadItems`}>
        {({ input }) => (
          <DownloadItemInput
            mediaItems={input.value || []}
            onChange={(newValue) => {
              input.onChange(newValue);
            }}
          />
        )}
      </Field>
    </Box>
  );
};

const EditStampRallyGiftGiftForm = ({ name }: ComponentFormProps) => {
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      <Field name={`${name}.giftThumbnailUrl`}>
        {({ input, meta: { touched, error } }) => (
          <FieldWrapper label={t('Gift Thumbnail Image (jpg, png)')}>
            <ImageVideoAudioInput
              fileUrls={input.value ? [input.value] : []}
              onChange={(newValue) =>
                newValue.length > 0
                  ? input.onChange(newValue[0])
                  : input.onChange('')
              }
              maxFileCount={1}
              disableYoutubeVideos
              error={touched && error}
            />
          </FieldWrapper>
        )}
      </Field>
    </Box>
  );
};

export const EditDigitalGuidanceStampRallyGiftModal = ({
  open,
  onClose,
  name,
}: Props) => {
  const { t } = useTranslation();

  const defaultLanguage = useSelector(defaultProductLanguageSelector);

  const targetTranslationLanguage =
    useFormState<FormValues>().values.targetTranslationLanguage;
  const currentGift: GiftFormValues =
    _.get(useFormState<FormValues>().values, `${name}`) ?? [];
  const prizeType = currentGift.type;

  const getPrizeTypeOptions = (t: TFunction) => {
    return [
      {
        value: 'STAMP_RALLY_GIFT_TYPE_GIFT',
        text: t('Gifts'),
      },
      {
        value: 'STAMP_RALLY_GIFT_TYPE_DOWNLOAD',
        text: t('Download'),
      },
    ];
  };

  const stampRallyGiftTypes: {
    value: GiftFormValues['type'];
    component?: React.ReactNode;
  }[] = [
    {
      value: 'STAMP_RALLY_GIFT_TYPE_DOWNLOAD',
      component: <EditStampRallyGiftDownloadForm name={name} />,
    },
    {
      value: 'STAMP_RALLY_GIFT_TYPE_GIFT',
      component: <EditStampRallyGiftGiftForm name={name} />,
    },
  ];

  const getPrizeTypeComponent = (triggerType: GiftFormValues['type']) => {
    return stampRallyGiftTypes.find((trigger) => trigger.value === triggerType)
      ?.component;
  };

  return (
    <Modal
      title={t('Edit Stamp Rally Gift')}
      open={open}
      onClose={onClose}
      insertRoot
    >
      <Modal.Content>
        <Modal.Box>
          <FormTableBox>
            <table>
              {targetTranslationLanguage ? (
                <thead>
                  <tr className={clsx(styles['table-header'])}>
                    <th></th>
                    <td>{getLanguageName(defaultLanguage, t)}</td>
                    <td>{getLanguageName(targetTranslationLanguage, t)}</td>
                  </tr>
                </thead>
              ) : null}

              <tbody>
                <tr>
                  <th>{t('Stamp Rally Gift Title')}</th>
                  <TranslatedField name={`${name}.title`}>
                    {({ input, translationInput }) => (
                      <>
                        <td>
                          <Input
                            value={input.value}
                            onChange={(_, { value }) => input.onChange(value)}
                          />
                        </td>
                        {targetTranslationLanguage ? (
                          <td>
                            <Input
                              value={translationInput.value}
                              onChange={(_, { value }) =>
                                translationInput.onChange(value)
                              }
                              placeholder={getLanguageName(
                                targetTranslationLanguage,
                                t
                              )}
                            />
                          </td>
                        ) : null}
                      </>
                    )}
                  </TranslatedField>
                </tr>

                <tr>
                  <th>{t('Description')}</th>
                  <TranslatedField name={`${name}.description`}>
                    {({ input, translationInput }) => (
                      <>
                        <td>
                          <TextArea
                            value={input.value}
                            onChange={(_, { value }) => input.onChange(value)}
                          />
                        </td>
                        {targetTranslationLanguage ? (
                          <td>
                            <TextArea
                              value={translationInput.value}
                              onChange={(_, { value }) =>
                                translationInput.onChange(value)
                              }
                              placeholder={getLanguageName(
                                targetTranslationLanguage,
                                t
                              )}
                            />
                          </td>
                        ) : null}
                      </>
                    )}
                  </TranslatedField>
                </tr>
              </tbody>
            </table>
          </FormTableBox>
        </Modal.Box>

        <Box mt={2}>
          <Field name={`${name}.thumbnailUrl`}>
            {({ input, meta: { touched, error } }) => (
              <FieldWrapper label={t('Thumbnail Image (jpg, png)')}>
                <ImageVideoAudioInput
                  fileUrls={input.value ? [input.value] : []}
                  onChange={(newValue) =>
                    newValue.length > 0
                      ? input.onChange(newValue[0])
                      : input.onChange('')
                  }
                  maxFileCount={1}
                  disableYoutubeVideos
                  error={touched && error}
                />
              </FieldWrapper>
            )}
          </Field>
        </Box>

        <Box mt={2}>
          <Field name={`${name}.stampCountToRedeem`}>
            {({ input }) => (
              <FieldWrapper label={t('Stamp count to redeem')}>
                <Select
                  options={[...Array(10)].map((_, i) => {
                    return { text: `${i + 1}`, value: `${i + 1}` };
                  })}
                  value={input.value}
                  onChange={(e, { value }) => input.onChange(value)}
                />
              </FieldWrapper>
            )}
          </Field>
        </Box>

        {config.enableNpsSurvey && (
          <>
            <Box mt={2}>
              <Field name={`${name}.type`}>
                {({ input }) => (
                  <Select
                    label={t('Prize Type')}
                    options={getPrizeTypeOptions(t)}
                    value={input.value || 'STAMP_RALLY_GIFT_TYPE_GIFT'}
                    onChange={(_, { value }) => input.onChange(value)}
                    search={false}
                  />
                )}
              </Field>
            </Box>

            {getPrizeTypeComponent(prizeType)}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          size="middle"
          style="blue"
          type="submit"
          onClick={() => onClose()}
        >
          {t('Save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
