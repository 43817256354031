import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import queryString from 'query-string';
import { Auth } from '@aws-amplify/auth';

import { i18n } from 'client/libraries/i18n';
import { loadState, saveState } from 'client/libraries/localstorage';
import { cognitoTokenRefreshMiddleware } from 'client/libraries/cognito';
import App from 'client/pages/App';
// import registerServiceWorker from 'client/registerServiceWorker';
import { unregister } from 'client/registerServiceWorker';
import { rootReducer } from 'client/reducers';
import { login, loginSuccess } from 'client/actions/user';
import { isTouchBackend } from 'client/libraries/util/isTouchBackend';

// for datepicker
import 'react-datepicker/dist/react-datepicker.css';

// Semantic styles, used in 'core'
import 'semantic-ui-css/semantic.min.css';

import 'client/base.module.css';

// New v3 global styles
import 'client/v3-reset.css';
import 'client/v3-root.css';
import 'client/components/v3/Icon/v3-fontface.css';

export const run = async () => {
  // 'cognitoTokenRefreshMiddleware' must run before 'thunk' because it needs to be able
  // to recognize functional actions.
  const middleware = [cognitoTokenRefreshMiddleware, thunk];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(createLogger());
  }

  const store = createStore(
    rootReducer,
    loadState(),
    composeWithDevTools(applyMiddleware(...middleware))
  );

  // https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_throttle
  const throttle = (func, delay) => {
    let lastCall = 0;
    return (...args) => {
      const now = new Date().getTime();
      if (now - lastCall < delay) return;
      lastCall = now;
      return func(...args);
    };
  };

  store.subscribe(
    throttle(() => {
      saveState({
        // Persisted redux state variables
        dashTableControls: {
          visibleColumns: store.getState().dashTableControls.visibleColumns,
        },
        dispatchTableControls: {
          visibleColumns: store.getState().dispatchTableControls.visibleColumns,
        },
        manifestSettings: store.getState().manifestSettings,
        productTableControls: {
          visibleColumns: store.getState().productTableControls.visibleColumns,
        },
        invoiceTableControls: {
          visibleSummaryColumns:
            store.getState().invoiceTableControls.visibleSummaryColumns,
          visibleSummaryRows:
            store.getState().invoiceTableControls.visibleSummaryRows,
          visibleReservationColumns:
            store.getState().invoiceTableControls.visibleReservationColumns,
        },
        reservationTableControls: {
          visibleColumns:
            store.getState().reservationTableControls.visibleColumns,
          lastExecutedSearchCondition:
            store.getState().reservationTableControls
              .lastExecutedSearchCondition,
          defaultVisibleColumnsLoaded:
            store.getState().defaultVisibleColumnsLoaded,
        },
        reservationSearch: {
          lastQuery: store.getState().reservationSearch.lastQuery,
          annualPassLastQuery:
            store.getState().reservationSearch.annualPassLastQuery,
          shouldShowDetailedConditions:
            store.getState().reservationSearch.shouldShowDetailedConditions,
        },
        reviewTableControls: {
          visibleColumns: store.getState().reviewTableControls.visibleColumns,
        },
        waiverTableControls: {
          visibleColumns: store.getState().waiverTableControls.visibleColumns,
          lastExecutedSearchCondition:
            store.getState().waiverTableControls.lastExecutedSearchCondition,
        },
        manifestDisplaySettings: {
          lastManifestDisplaySettings:
            store.getState().manifestDisplaySettings
              .lastManifestDisplaySettings,
        },
        dashboardControls: {
          tab: store.getState().dashboardControls.tab,
        },
        checkinRecordTableControls: {
          lastExecutedSearchCondition:
            store.getState().checkinRecordTableControls
              .lastExecutedSearchCondition,
        },
        resourceAvailabilityControls: {
          startDate: store.getState().resourceAvailabilityControls.startDate,
          resourceKey:
            store.getState().resourceAvailabilityControls.resourceKey,
          resourceType:
            store.getState().resourceAvailabilityControls.resourceType,
          vehicleDepartureTimeKey:
            store.getState().resourceAvailabilityControls
              .vehicleDepartureTimeKey,
        },
        reservationDataCsvExportOrderTableControls: {
          visibleColumns:
            store.getState().reservationDataCsvExportOrderTableControls
              .visibleColumns,
        },
        customerListControls: {
          visibleColumns: store.getState().customerListControls.visibleColumns,
        },
        seatAssignmentControls: {
          date: store.getState().seatAssignmentControls.date,
          productId: store.getState().seatAssignmentControls.productId,
          productInstanceId:
            store.getState().seatAssignmentControls.productInstanceId,
        },
        annualPassReservationTableControls: {
          visibleColumns:
            store.getState().annualPassReservationTableControls.visibleColumns,
          defaultVisibleColumnsLoaded:
            store.getState().annualPassReservationTableControls
              .defaultVisibleColumnsLoaded,
        },
        productCalendarListControls: {
          productFilter:
            store.getState().productCalendarListControls.productFilter,
          productSalesStatusFilter:
            store.getState().productCalendarListControls
              .productSalesStatusFilter,
          productTagFilter:
            store.getState().productCalendarListControls.productTagFilter,
          reservationSlotVisibilitySetting:
            store.getState().productCalendarListControls
              .reservationSlotVisibilitySetting,
          resourceVisibilitySetting:
            store.getState().productCalendarListControls
              .resourceVisibilitySetting,
        },
        webJourneyCustomerListControls: {
          visibleColumns:
            store.getState().webJourneyCustomerListControls.visibleColumns,
          selectedPresetName:
            store.getState().webJourneyCustomerListControls.selectedPresetName,
          currentPage:
            store.getState().webJourneyCustomerListControls.currentPage,
          pageSize: store.getState().webJourneyCustomerListControls.pageSize,
          activeTab: store.getState().webJourneyCustomerListControls.activeTab,
        },
        newUIControls: {
          useNewUI: store.getState().newUIControls.useNewUI,
        },
        theme: store.getState().theme,
      });
    }, 2000)
  );

  try {
    const queryParams = queryString.parse(window.location.search);

    let username = '';
    let password = '';
    if (queryParams.username && typeof queryParams.username === 'string') {
      username = queryParams.username;
    }
    if (queryParams.password && typeof queryParams.password === 'string') {
      password = queryParams.password;
    }

    if (username && password) {
      await store.dispatch(login(username, password));
    } else {
      const user = await Auth.currentAuthenticatedUser();
      const userInfo = await Auth.currentUserInfo();

      if (user && userInfo) {
        store.dispatch(loginSuccess(user, userInfo.attributes));
      }
    }
  } catch (e) {}

  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <DndProvider backend={isTouchBackend() ? TouchBackend : HTML5Backend}>
          <App />
        </DndProvider>
      </Provider>
    </I18nextProvider>,
    document.getElementById('root')
  );

  // No service worker for now since caching causes some friction when testing.
  // TODO: re-enable
  unregister();
};
