import { Dimmer, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { ReservationBulkCancelMaxThresholdModal } from 'client/pages/v3/Reservation/ReservationList/ReservationListTable/BulkCancelDecline/ReservationBulkCancelMaxThresholdModal';
import { ReservationBulkCancelTimeOutModal } from 'client/pages/v3/Reservation/ReservationList/ReservationListTable/BulkCancelDecline/ReservationBulkCancelTimeOutModal';
import {
  ReservationBulkCancelModal,
  BULK_OPERATION,
} from 'client/pages/v3/Reservation/ReservationList/ReservationListTable/BulkCancelDecline/ReservationBulkCancelModal';
import { ReservationSearchCustomTable } from 'client/pages/v3/Reservation/ReservationList/ReservationListTable/ReservationSearchCustomTable';
import { ReservationListSearch } from 'client/pages/v3/Reservation/ReservationList/ReservationListSearch/ReservationListSearch';
import {
  searchReservations,
  setLastReservationSearchQuery,
} from 'client/actions/reservationSearch';
import { fetchProducts } from 'client/actions/products';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import { reservationVisibleColumnsSelector } from 'client/reducers/reservationTableControls';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import type { ReduxState } from 'client/reducers';
import type { ReservationListColumn } from 'client/libraries/util/getReservationTableColumns';
import { operationAllowed } from 'shared/models/access';
import {
  buildSearchReservationsRequest,
  SearchReservationsRequest,
} from 'client/pages/ReservationSearch/util';
import {
  setReservationCurrentPage,
  setReservationDefaultVisibleColumns,
} from 'client/actions/reservationTableControls';
import 'react-table/react-table.css';
import { fetchGroupBookingTemplates } from 'client/actions/groupBookingTemplates';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { Badge } from 'client/components/v3/Common/Badge';
import baseStyles from 'client/v3-base.module.css';
import { getBadgeColorForReservationStatus } from 'client/libraries/util/getBadgeColorForReservationStatus';

import styles from './ReservationListTable.module.css';

const MAX_CANCEL_THRESHOLD = 150;

interface Props {
  annualPassOnly?: boolean;
}

export const ReservationListTable = ({ annualPassOnly = false }: Props) => {
  const { t } = useTranslation();
  const scrollToRef = useRef<HTMLDivElement>(null);
  const loading = useSelector(
    (state: ReduxState) => state.reservationSearch.loading
  );
  const bulkCancelError = useSelector(
    (state: ReduxState) => state.reservations.bulkCancelError
  );
  const defaultVisibleColumnsLoaded = useSelector(
    (state: ReduxState) =>
      state.reservationTableControls.defaultVisibleColumnsLoaded
  );

  const dispatch = useDispatch();
  const allReservations = useSelector(
    (state: ReduxState) => state.reservationSearch.all
  );
  const totalSearchHits = useSelector(
    (state: ReduxState) => state.reservationSearch.totalHits
  );
  const productNameOptions = useSelector((state: ReduxState) => [
    ...new Set(state.reservations.summaries.map((r) => r.product_name || '')),
  ]);
  const [runSearch, setRunSearch] = useState<boolean>(false);

  const bookingSourceOptions = [
    ...new Set(
      allReservations.map(
        (r) =>
          (r.booking_source &&
            (r.booking_source.agent_name ||
              t(r.booking_source.source_type as any))) ||
          ''
      )
    ),
  ];
  const visibleColumns = useSelector(reservationVisibleColumnsSelector);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const reservationSummaries = useMemo(
    () => allReservations.map((r) => toReservationSummaryShape(r, locale, t)),
    [allReservations, locale, t]
  );

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  useEffect(() => {
    if (!defaultVisibleColumnsLoaded && activeUserOrganization) {
      const defaultColumns =
        activeUserOrganization?.account_reservation_search_settings
          ?.default_columns ?? [];
      if (defaultColumns.length > 0) {
        dispatch(
          setReservationDefaultVisibleColumns(
            defaultColumns.map((c) => c.toLowerCase())
          )
        );
      }
    }
  }, [dispatch, defaultVisibleColumnsLoaded, activeUserOrganization]);

  const [showThresholdModal, setShowThresholdModal] = useState<boolean>(false);
  const [showBulkCancelModal, setShowBulkCancelModal] =
    useState<boolean>(false);
  const [bulkCancelTimeOutModal, setBulkCancelTimeOutModal] =
    useState<boolean>(false);
  const [allCheck, setAllCheck] = useState<boolean>(false);
  const [cancelDeclineList, setCancelDeclineList] = useState<
    Record<string, BULK_OPERATION>
  >({});

  let cancelDeclineListCount = 0;
  Object.keys(cancelDeclineList).forEach((key) => {
    if (cancelDeclineList[key]) {
      cancelDeclineListCount++;
    }
  });

  const bulkCancelColumn: ReservationListColumn = {
    HeaderElement: (items: any) => {
      const confirmReservations = allReservations.filter(
        (r) => r.status === 'CONFIRMED'
      );
      const requestStandbyReservations = allReservations.filter(
        (r) => r.status === 'REQUESTED' || r.status === 'STANDBY'
      );
      const stillNotCheckedReservations = confirmReservations.filter(
        (r) => !cancelDeclineList[r.id]
      );
      const overThreshold =
        stillNotCheckedReservations.length +
          requestStandbyReservations.length +
          cancelDeclineListCount >
        MAX_CANCEL_THRESHOLD;
      return (
        <Checkbox
          label={t('Application Number')}
          checked={allCheck}
          onChange={() => {
            if (overThreshold) {
              setShowThresholdModal(true);
            } else {
              const newCancelList = {
                ...(items as Record<string, BULK_OPERATION>),
              };
              confirmReservations.forEach((r) => {
                newCancelList[r.id] = allCheck ? undefined : 'CANCEL';
              });
              requestStandbyReservations.forEach((r) => {
                newCancelList[r.id] = allCheck ? undefined : 'DECLINE';
              });
              setCancelDeclineList(newCancelList);
              setAllCheck(!allCheck);
            }
          }}
          size="md"
        />
      );
    },
    Header: '',
    id: 'bulkCancelAgentReference',
    width: 'long',
    th: true,
    Cell: (cellInfo: any) => {
      if (
        ['CONFIRMED', 'REQUESTED', 'STANDBY'].includes(cellInfo.original.status)
      ) {
        const disabled =
          cancelDeclineListCount >= MAX_CANCEL_THRESHOLD &&
          Boolean(!cancelDeclineList[cellInfo.original.id]);
        return (
          <div className={styles['first-column']}>
            <Checkbox
              checked={cancelDeclineList[cellInfo.original.id] !== undefined}
              onChange={() => {
                if (disabled) {
                  setShowThresholdModal(true);
                } else {
                  const newCancelList = { ...cancelDeclineList };
                  if (newCancelList[cellInfo.original.id]) {
                    newCancelList[cellInfo.original.id] = undefined;
                  } else {
                    newCancelList[cellInfo.original.id] =
                      cellInfo.original.status === 'CONFIRMED'
                        ? 'CANCEL'
                        : 'DECLINE';
                  }
                  setCancelDeclineList(newCancelList);
                }
              }}
              size="md"
            />
            <Link
              // TODO: for now we do not support annualPassMode
              // to={`${annualPassMode ? '/annualpasses' : '/reservations'}/${
              //   cellInfo.original.id
              // }`}
              // TODO: append v-3 to preview new reservation details for now, remove when not needed
              to={`/reservations-v3/${cellInfo.original.id}`}
              // to={`/reservations/${cellInfo.original.id}`}
            >
              {cellInfo.original.agent_reference}
            </Link>
          </div>
        );
      } else {
        // If canceled or declined, show just the application number
        return (
          <div className={clsx(styles['first-column'], styles['no-checkbox'])}>
            {/* TODO: append link with v3 for now, remove when not needed */}
            {/* <Link to={`/reservations/${cellInfo.original.id}`}>
              {cellInfo.original.agent_reference}
            </Link> */}
            <Link to={`/reservations-v3/${cellInfo.original.id}`}>
              {cellInfo.original.agent_reference}
            </Link>
          </div>
        );
      }
    },
  };

  // Status column is overridden here to avoid modifying src/client/libraries/util/getReservationTableColumns.tsx for now
  const statusColumn: ReservationListColumn = {
    Header: t('Status'),
    id: 'status',
    width: 'long',
    Cell: (cellInfo) => (
      <Badge
        label={t(cellInfo.original.status)}
        color={getBadgeColorForReservationStatus(cellInfo.original.status)}
      />
    ),
  };

  const getAllColumns = (): ReservationListColumn[] => {
    return getReservationTableColumns(t, locale);
  };

  const getColumns = (visibleColumns: string[]): ReservationListColumn[] => {
    // Filter out agent_reference because we show it separately in bulk cancel column
    const columns = visibleColumns
      .filter((visibleCol) => visibleCol !== 'agent_reference')
      .map((visibleCol) => {
        // Replace 'status' column with the new column
        if (visibleCol === 'status') {
          return statusColumn;
        }

        return allColumns.find(
          (col) => col.id === visibleCol
        ) as ReservationListColumn;
      });
    const newColumns = [bulkCancelColumn, ...columns];

    return newColumns;
  };

  const [allColumns, setAllColumns] = useState(getAllColumns());
  const [showColumns, setShowColumns] = useState(getColumns(visibleColumns));

  const rowCount = useSelector(
    (state: ReduxState) => state.reservationTableControls.rowCount
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.reservationTableControls.currentPage
  );

  useEffect(() => {
    if (bulkCancelError !== '') {
      setBulkCancelTimeOutModal(true);
    }
  }, [bulkCancelError]);
  useEffect(() => {
    if (invalidated) {
      setAllColumns(getAllColumns());
    }
  }, [invalidated, locale, productNameOptions, bookingSourceOptions]);
  useEffect(() => {
    setShowColumns(getColumns(visibleColumns));
  }, [visibleColumns, cancelDeclineList, allReservations, allCheck]);
  const lastQuery = useSelector(
    (state: ReduxState) => state.reservationSearch.lastQuery
  );
  const [searchCondition, setSearchCondition] =
    useState<SearchReservationsRequest>(lastQuery);
  useEffect(() => {
    setAllCheck(false);
    search();
  }, [activeUser, rowCount, currentPage]);
  useEffect(() => {
    const newSearchCondition = {
      ...searchCondition,
      annualPassOnly: annualPassOnly,
    };
    setSearchCondition(newSearchCondition);
    setRunSearch(true);
  }, [annualPassOnly]);
  useEffect(() => {
    // Fetch products for search modal and search display
    dispatch(fetchProducts());

    // Fetch group booking templates for search modal and search display
    dispatch(fetchGroupBookingTemplates());
  }, [t, activeUser]);
  useEffect(() => {
    // If user is a supplier, fetch contracted agents for search modal and search display
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  useEffect(() => {
    if (runSearch) {
      search();
      setRunSearch(false);
    }
  }, [runSearch]);

  const search = () => {
    if (!isNutmegAdmin) {
      let pageToFetch = currentPage;
      if (!_.isEqual(searchCondition, lastQuery)) {
        dispatch(setLastReservationSearchQuery(searchCondition as any));
        pageToFetch = 1;
        dispatch(setReservationCurrentPage(pageToFetch));
      }

      dispatch(
        searchReservations(
          buildSearchReservationsRequest({
            ...searchCondition,
            annualPassOnly: annualPassOnly,
          }),
          rowCount,
          rowCount * (pageToFetch - 1)
        )
      );
    }
  };

  const reset = () => {
    setSearchCondition({
      agentReference: '',
      agentIds: [],
      presetKey: '',
      supplierReference: '',
      id: '',
      statuses: [],
      customerGivenName: '',
      customerFamilyName: '',
      customerPhone: '',
      customerEmail: '',
      bookingSourceTypes: [],
      groupIds: [],
      supplierIds: [],
      productIds: [],
      bookedDateFrom: '',
      bookedDateTo: '',
      participationDateFrom: '',
      participationDateTo: '',
      lastUpdatedDateFrom: '',
      lastUpdatedDateTo: '',
      dateFilterPreset: null,
      orderBy: 'last_updated_desc',
      supplierOrAgentReference: '',
      reservationLanguages: [],
      mostRecentEmailBounced: false,
      pickupCheckinLocationName: '',
      waiverCompletionStatuses: [],
      checkinStatuses: [],
      annualPassOnly: false,
      expirationPresetKey: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      expirationDateFilterPreset: null,
      automaticContinuingStatus: null,
      partnershipReservationOnly: false,
    });
  };

  const scrollToLocation = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <>
      <div className={baseStyles['l-main__body']}>
        <ReservationListSearch
          onReset={reset}
          onSearch={async () => {
            await dispatch(setReservationCurrentPage(1));
            await search();
            await scrollToLocation();
          }}
          searchCondition={searchCondition}
          setSearchCondition={(condition) => setSearchCondition(condition)}
        />

        {/* Note: if dimmer props:page is not set, dimmer will not cover the whole page, only the top portion of screen,
        which will not be visible when we perform bulk cancel/decline on the lower part of the page. */}
        <Dimmer active={loading} page={true} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>

        {operationAllowed(activeUser, 'write', 'reservationConfirmation') && (
          <>
            {showBulkCancelModal && (
              <ReservationBulkCancelModal
                setShowModal={setShowBulkCancelModal}
                cancelDeclineList={cancelDeclineList}
                onCancel={() => {
                  setCancelDeclineList({});
                  setAllCheck(false);
                }}
              />
            )}
            {showThresholdModal && (
              <ReservationBulkCancelMaxThresholdModal
                setShowModal={setShowThresholdModal}
                maxThreshold={MAX_CANCEL_THRESHOLD}
              />
            )}
            {bulkCancelTimeOutModal && (
              <ReservationBulkCancelTimeOutModal
                setShowModal={setBulkCancelTimeOutModal}
              />
            )}
          </>
        )}

        {/* Scroll target on search reservation click */}
        <div ref={scrollToRef}></div>

        <ReservationSearchCustomTable
          reservations={reservationSummaries}
          totalHits={totalSearchHits}
          visibleColumns={showColumns}
          headerElementItems={Object.keys(cancelDeclineList).reduce(
            (acc, cur) => {
              if (cancelDeclineList[cur]) {
                acc[cur] = true;
              }
              return acc;
            },
            {} as Record<string, boolean>
          )}
          cancelDeclineListCount={cancelDeclineListCount}
          onResetCancelDeclineList={() => {
            setCancelDeclineList({});
            setAllCheck(false);
          }}
          onExecuteCancelDecline={() => {
            setShowBulkCancelModal(true);
          }}
        />
      </div>
    </>
  );
};
