import * as React from 'react';
import clsx from 'clsx';

import styles from './PageContent.module.css';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const PageContent = ({ children, className }: Props) => {
  return (
    <div className={clsx(styles['p-pageContent'], className)}>{children}</div>
  );
};
