import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import * as Swagger from 'shared/models/swagger';

import { LocationItem } from './LocationItem';
import styles from './PopularPinsGadget.module.css';

interface Props {
  title: string;
  map: Swagger.DigitalMap | null;
  activePinKey: string | null;
  setActivePinKey: (key: string | null) => void;
  data: Swagger.DigitalMapVisitorReportDataSet[] | null;
  isLoading: boolean;
}

export const PopularPinsGadget = ({
  title,
  map,
  activePinKey,
  setActivePinKey,
  data,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{title}</div>
          </div>
        </div>
        <div className={styles['body']}>
          <section className={styles.bodyContainer}>
            <table className={styles.listWrapper}>
              <thead className={styles.bodyHeader}>
                <tr>
                  <th className={styles.tableHeaderColumn}>{t('No.')}</th>
                  <th
                    className={clsx(
                      styles.tableHeaderColumn,
                      styles.locationHeader
                    )}
                  >
                    {t('Location')}
                  </th>
                  <th className={styles.tableHeaderColumn}>{t('Users')}</th>
                  <th className={styles.tableHeaderColumn}>{t('Wait Time')}</th>
                </tr>
              </thead>
              <tbody className={styles.locationList}>
                {data &&
                  data.length > 0 &&
                  data[0].totals?.totals_by_pin
                    ?.slice(0, 10)
                    ?.map((totalByPin, idx) => {
                      const pin = map?.pins?.find(
                        (p) => p.key === totalByPin.pin_key
                      );

                      return (
                        <LocationItem
                          onClick={() => setActivePinKey(pin?.key ?? null)}
                          key={pin?.key ?? ''}
                          number={`${idx + 1}`}
                          name={pin?.title ?? ''}
                          users={totalByPin.user_count ?? 0}
                          isActive={pin?.key === activePinKey}
                        />
                      );
                    })}
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </div>
  );
};
