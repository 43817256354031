import React from 'react';
import clsx from 'clsx';

import styles from './PopularTimes.module.css';

const barHeights = [55, 55, 60, 65, 65, 70, 78, 70, 60, 55, 45, 40, 40];
const timeLabels = ['9am', '12pm', '3pm', '6pm', '9pm'];

const Chart: React.FC = () => {
  return (
    <div className={styles.chartContainer}>
      <div className={styles.chart}>
        <div className={styles.barContainer}>
          {barHeights.map((height, index) => (
            <div
              key={index}
              className={clsx(styles.bar, index === 1 && styles.active)}
              style={{ height: `${height}px` }}
            >
              <div
                className={clsx(styles.barLabel, index === 1 && styles.active)}
              >
                {height}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.timeLabels}>
        {timeLabels.map((label) => (
          <div key={label} className={styles.timeLabel}>
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chart;
