// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  CardElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements';

import { currency as currencyjs } from 'shared/libraries/currency';

import { createFareAdjustmentForReservation } from 'client/actions/reservations';
import type {
  FareAdjustmentForReservationPatch,
  Reservation,
} from 'shared/models/swagger';
import { currencyAmountInputHelper } from 'client/libraries/util/coreutil';
import type { CurrencyAmountInputHelper } from 'client/libraries/util/coreutil';
import {
  FieldWrapper,
  Input,
  TextArea,
  Button,
  Checkbox,
} from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { Message } from 'client/components/Message/Message';

import baseStyles from 'client/base.module.css';

type State = {
  amount_gross: string,
  amount_net: string,
  amount_commission: string,
  amount_pay_in_advance: string,
  amount_pay_on_board: string,
  notes: string,
  is_refund: boolean,
  helper: CurrencyAmountInputHelper,
  cardFieldErrorMessage: string,
  cardSubmissionErrorMessage: string,
  cardInfoComplete: boolean,
  checkAgreement: boolean,
  supplierNotes: string,
};

type OwnProps = {
  header?: string,
  onClose: () => void,
  currency: string,
  id: string,
  savedPaymentInfoAvailable?: boolean,
  isDirectChargable?: boolean,
  isPIFAvailable?: boolean,
  reservation: Reservation,
};

type Props = {|
  ...OwnProps,
  ...ReactStripeElements.InjectedStripeProps,
|};

export const ReservationFareAdjustmentModal = injectStripe(
  ({
    header,
    onClose,
    currency,
    id,
    savedPaymentInfoAvailable,
    isDirectChargable,
    isPIFAvailable,
    stripe,
    reservation,
  }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [state, setState] = React.useState<State>({
      amount_gross: '0',
      amount_net: '0',
      amount_commission: '0',
      amount_pay_in_advance: '0',
      amount_pay_on_board: '0',
      notes: '',
      is_refund: false,
      helper: currencyAmountInputHelper(currency),
      cardFieldErrorMessage: '',
      cardSubmissionErrorMessage: '',
      cardInfoComplete: false,
      checkAgreement: false,
      supplierNotes: reservation?.supplier_notes ?? '',
    });

    const [fareAdjustStatus, setFareAdjustStatus] = React.useState<
      null | 'REQUESTED' | 'SUCCEEDED' | 'FAILED'
    >(null);

    const [amountGrossError, setAmountGrossError] =
      React.useState<boolean>(false);

    React.useEffect(() => {
      setFareAdjustStatus(null);
    }, []);

    React.useEffect(() => {
      setState({
        ...state,
        helper: currencyAmountInputHelper(currency),
      });
    }, [currency]);

    // This means that all inputs must be valid, with change in gross needing to be nonzero.
    const validateForm = () => {
      const {
        amount_gross,
        amount_net,
        amount_commission,
        amount_pay_in_advance,
        amount_pay_on_board,
        cardInfoComplete,
      } = state;
      const needsCardInfo =
        checkIfChargeable() && isDirectChargable && !savedPaymentInfoAvailable;
      return (
        parseFloat(amount_gross) !== 0 &&
        !state.helper.inputInvalid(state.helper.moneyInput(amount_gross)) &&
        !state.helper.inputInvalid(state.helper.moneyInput(amount_net)) &&
        !state.helper.inputInvalid(
          state.helper.moneyInput(amount_commission)
        ) &&
        !state.helper.inputInvalid(
          state.helper.moneyInput(amount_pay_in_advance)
        ) &&
        !state.helper.inputInvalid(
          state.helper.moneyInput(amount_pay_on_board)
        ) &&
        (needsCardInfo ? cardInfoComplete : true)
      );
    };

    const isCancelledReservation =
      reservation.status === 'CANCELED_BY_AGENT' ||
      reservation.status === 'CANCELED_BY_GUEST' ||
      reservation.status === 'CANCELED_BY_SUPPLIER';

    // Deliberately verbose here for clarity, we want to check if result is negative or zero separately
    const resultAmountIsNegative = (() => {
      const { is_refund, amount_gross, amount_net } = state;

      if (isCancelledReservation) {
        if (reservation?.billing_info?.amount_cancellation_fee_gross) {
          const cancellationGrossAmount = currencyjs(
            reservation?.billing_info?.amount_cancellation_fee_gross
          ).value;
          if (
            is_refund &&
            cancellationGrossAmount - parseFloat(amount_gross) < 0
          ) {
            return true;
          }
        }
        if (reservation?.billing_info?.amount_cancellation_fee_net) {
          const cancellationNetAmount = currencyjs(
            reservation?.billing_info?.amount_cancellation_fee_net
          ).value;
          if (is_refund && cancellationNetAmount - parseFloat(amount_net) < 0) {
            return true;
          }
        }
        return false;
      }

      if (reservation?.billing_info?.amount_gross) {
        const reservationGrossAmount = currencyjs(
          reservation?.billing_info?.amount_gross
        ).value;
        if (
          is_refund &&
          reservationGrossAmount - parseFloat(amount_gross) < 0
        ) {
          return true;
        }
      }
      if (reservation?.billing_info?.amount_net) {
        const reservationNetAmount = currencyjs(
          reservation?.billing_info?.amount_net
        ).value;
        if (is_refund && reservationNetAmount - parseFloat(amount_net) < 0) {
          return true;
        }
      }
      return false;
    })();
    const resultAmountIsZero = (() => {
      const { is_refund, amount_gross, amount_net } = state;

      if (isCancelledReservation) {
        if (reservation?.billing_info?.amount_cancellation_fee_gross) {
          const cancellationGrossAmount = currencyjs(
            reservation?.billing_info?.amount_cancellation_fee_gross
          ).value;
          if (
            is_refund &&
            cancellationGrossAmount - parseFloat(amount_gross) == 0
          ) {
            return true;
          }
        }
        if (reservation?.billing_info?.amount_cancellation_fee_net) {
          const cancellationNetAmount = currencyjs(
            reservation?.billing_info?.amount_cancellation_fee_net
          ).value;
          if (
            is_refund &&
            cancellationNetAmount - parseFloat(amount_net) == 0
          ) {
            return true;
          }
        }
        return false;
      }

      if (reservation?.billing_info?.amount_gross) {
        const reservationGrossAmount = currencyjs(
          reservation?.billing_info?.amount_gross
        ).value;
        if (
          is_refund &&
          reservationGrossAmount - parseFloat(amount_gross) == 0
        ) {
          return true;
        }
      }
      if (reservation?.billing_info?.amount_net) {
        const reservationNetAmount = currencyjs(
          reservation?.billing_info?.amount_net
        ).value;
        if (is_refund && reservationNetAmount - parseFloat(amount_net) == 0) {
          return true;
        }
      }
      return false;
    })();

    const checkIfChargeable = () => {
      const { amount_pay_in_advance, is_refund, helper } = state;
      return (
        !helper.inputInvalid(helper.moneyInput(amount_pay_in_advance)) &&
        amount_pay_in_advance &&
        amount_pay_in_advance !== '0' &&
        !is_refund
      );
    };

    const handleCardChange = ({ error, complete }) => {
      setState({
        ...state,
        cardFieldErrorMessage: error ? error.message : '',
        cardSubmissionErrorMessage: '',
        cardInfoComplete: complete,
      });
    };

    const handleConfirmFareAdjustment = async (event) => {
      event.preventDefault();
      const isChargeable = checkIfChargeable();
      if (
        isChargeable &&
        isDirectChargable &&
        stripe &&
        !savedPaymentInfoAvailable
      ) {
        stripe
          .createPaymentMethod('card', {
            billing_details: {},
          })
          .then(async (response) => {
            if (response.error) {
              setState({
                ...state,
                cardSubmissionErrorMessage: response.error.message || '',
              });
            } else {
              if (response.paymentMethod) {
                const paymentMethodId = response.paymentMethod.id;
                const fare_adjust_params: FareAdjustmentForReservationPatch = {
                  amount_gross: state.helper.moneyString(state.amount_gross),
                  amount_net: state.helper.moneyString(state.amount_net),
                  amount_pay_on_board: state.helper.moneyString(
                    state.amount_pay_on_board
                  ),
                  notes: state.notes,
                  supplier_notes: state.supplierNotes,
                  is_refund: state.is_refund,
                  ...(paymentMethodId && {
                    payment_profile_gateway_reference: paymentMethodId,
                  }),
                };

                let closable = true;
                try {
                  setFareAdjustStatus('REQUESTED');
                  await dispatch(
                    createFareAdjustmentForReservation(id, fare_adjust_params)
                  );
                  setFareAdjustStatus('SUCCEEDED');
                } catch (err) {
                  closable = false;
                  setFareAdjustStatus('FAILED');
                }

                setState({
                  ...state,
                  cardFieldErrorMessage: '',
                  cardSubmissionErrorMessage: '',
                  cardInfoComplete: false,
                });
                if (closable) {
                  onClose();
                }
              }
            }
          });
      } else {
        const fare_adjust_params: FareAdjustmentForReservationPatch = {
          amount_gross: state.helper.moneyString(state.amount_gross),
          amount_net: state.helper.moneyString(state.amount_net),
          amount_pay_on_board: state.helper.moneyString(
            state.amount_pay_on_board
          ),
          notes: state.notes,
          supplier_notes: state.supplierNotes,
          is_refund: state.is_refund,
        };

        let closable = true;
        try {
          setFareAdjustStatus('REQUESTED');
          await dispatch(
            createFareAdjustmentForReservation(id, fare_adjust_params)
          );
          setFareAdjustStatus('SUCCEEDED');
        } catch (err) {
          closable = false;
          setFareAdjustStatus('FAILED');
        }

        setState({
          ...state,
          notes: '',
          checkAgreement: false,
        });
        if (closable) {
          onClose();
        }
      }
    };

    const getCardErrorMessages = () => {
      const { cardFieldErrorMessage, cardSubmissionErrorMessage } = state;
      const isChargeable = checkIfChargeable();
      return (
        <>
          {isChargeable && cardFieldErrorMessage && (
            <>
              {t('Please review your card information')}
              <p>{t(cardFieldErrorMessage)}</p>
            </>
          )}
          {isChargeable && cardSubmissionErrorMessage && (
            <>
              {t('Could not process payment')}
              <p>{t(cardSubmissionErrorMessage)}</p>
            </>
          )}
        </>
      );
    };

    const cardElementStyle = {
      base: {
        fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#C7C7C7',
        },
      },
      invalid: {
        color: '#9f3a38',
        iconColor: '#9f3a38',
      },
    };

    const isChargeable = checkIfChargeable();
    const cardInputErrors = getCardErrorMessages();

    return (
      <Modal title={header} open={true} onClose={onClose}>
        <Modal.Content>
          <Modal.Box column="two">
            <Checkbox
              label={t('Charge')}
              name="radioGroup"
              value="Charge"
              checked={!state.is_refund}
              onChange={() => {
                setState({
                  ...state,
                  is_refund: false,
                });
              }}
            />
            <Checkbox
              label={t('Refund')}
              name="radioGroup"
              value="Refund"
              checked={state.is_refund}
              onChange={() => {
                setState({
                  ...state,
                  is_refund: true,
                });
              }}
            />
          </Modal.Box>

          <Modal.Box column="three">
            <Input
              label={t('Gross') + ` (${currency})`}
              value={state.amount_gross}
              error={amountGrossError ? t('Invalid') : ''}
              type="string"
              onChange={(e) => {
                const v = e.target.value;
                if (!state.helper.inputAllowed(v)) {
                  return;
                }
                const moneyInput = state.helper.moneyInput(v);
                if (
                  state.helper.inputInvalid(moneyInput) ||
                  parseFloat(moneyInput) === 0
                ) {
                  setAmountGrossError(true);
                } else {
                  setAmountGrossError(false);
                }
                if (isPIFAvailable) {
                  // If PIF is available, auto-fill PIF amount based on gross
                  setState({
                    ...state,
                    amount_net: state.helper.moneyInput(
                      state.helper.diff(moneyInput, state.amount_commission)
                    ),
                    amount_pay_in_advance: state.helper.moneyInput(
                      state.helper.diff(moneyInput, state.amount_pay_on_board)
                    ),
                    amount_gross: moneyInput,
                  });
                } else {
                  // If PIF is not available, auto-fill POB amount based on gross
                  setState({
                    ...state,
                    amount_net: state.helper.moneyInput(
                      state.helper.diff(moneyInput, state.amount_commission)
                    ),
                    amount_pay_on_board: state.helper.moneyInput(
                      state.helper.diff(moneyInput, state.amount_pay_in_advance)
                    ),
                    amount_gross: moneyInput,
                  });
                }
              }}
            />

            <Input
              label={t('Net') + ` (${currency})`}
              value={state.amount_net}
              error={
                state.helper.inputInvalid(state.amount_net) ? t('Invalid') : ''
              }
              type="string"
              onChange={(e) => {
                const v = e.target.value;
                if (!state.helper.inputAllowed(v)) {
                  return;
                }
                const moneyInput = state.helper.moneyInput(v);
                setState({
                  ...state,
                  amount_net: moneyInput,
                  amount_commission: state.helper.moneyInput(
                    state.helper.diff(state.amount_gross, moneyInput)
                  ),
                });
              }}
            />

            <Input
              label={t('Commission') + ` (${currency})`}
              value={state.amount_commission}
              error={
                state.helper.inputInvalid(state.amount_commission)
                  ? t('Invalid')
                  : ''
              }
              type="string"
              onChange={(e) => {
                const v = e.target.value;
                if (!state.helper.inputAllowed(v)) {
                  return;
                }
                const moneyInput = state.helper.moneyInput(v);
                setState({
                  ...state,
                  amount_commission: moneyInput,
                  amount_net: state.helper.moneyInput(
                    state.helper.diff(state.amount_gross, moneyInput)
                  ),
                });
              }}
            />
          </Modal.Box>

          <Modal.Box column="two">
            <Input
              disabled={!isPIFAvailable}
              label={t('Paid in advance') + ` (${currency})`}
              value={state.amount_pay_in_advance}
              error={
                state.helper.inputInvalid(state.amount_pay_in_advance)
                  ? t('Invalid')
                  : ''
              }
              type="string"
              onChange={(e) => {
                const v = e.target.value;
                if (!state.helper.inputAllowed(v)) {
                  return;
                }
                const moneyInput = state.helper.moneyInput(v);
                setState({
                  ...state,
                  amount_pay_in_advance: moneyInput,
                  amount_pay_on_board: state.helper.moneyInput(
                    state.helper.diff(state.amount_gross, moneyInput)
                  ),
                });
              }}
            />

            <Input
              disabled={!isPIFAvailable /* fill automatically */}
              label={t('Paid on board') + ` (${currency})`}
              value={state.amount_pay_on_board}
              error={
                state.helper.inputInvalid(state.amount_pay_on_board)
                  ? t('Invalid')
                  : ''
              }
              type="string"
              onChange={(e) => {
                const v = e.target.value;
                if (!state.helper.inputAllowed(v)) {
                  return;
                }
                const moneyInput = state.helper.moneyInput(v);
                setState({
                  ...state,
                  amount_pay_on_board: moneyInput,
                  amount_pay_in_advance: state.helper.moneyInput(
                    state.helper.diff(state.amount_gross, moneyInput)
                  ),
                });
              }}
            />
          </Modal.Box>

          {resultAmountIsNegative && (
            <p className={baseStyles['base-form-box__err']}>
              {t(
                'Adjustment amounts that result in negative gross or negative net are not allowed'
              )}
            </p>
          )}

          {/* Do not allow full refund for GMO, allow for other payment gateway */}
          {resultAmountIsZero &&
            reservation.payment_gateway &&
            reservation.payment_gateway === 'GMO' && (
              <p className={baseStyles['base-form-box__err']}>
                {t(
                  'Unable to make adjustments for a full refund. Please contact Nutmeg support team.'
                )}
              </p>
            )}

          {isDirectChargable && (
            <Modal.Box>
              {t(
                '* Amount in "Paid on board" will not be charged or refunded to credit card. To make an additional charge or refund to the credit card, please put the amount in "Paid in advance"'
              )}
            </Modal.Box>
          )}

          <Modal.Box>
            <TextArea
              label={t('Replies')}
              height="150px"
              value={state.supplierNotes}
              onChange={(e) => {
                setState({
                  ...state,

                  supplierNotes: e.target.value,
                });
              }}
            />
          </Modal.Box>
          <Modal.Box>
            <TextArea
              label={t('Adjustment Notes')}
              height="150px"
              value={state.notes}
              onChange={(e) => {
                setState({
                  ...state,

                  notes: e.target.value,
                });
              }}
            />
          </Modal.Box>
          {fareAdjustStatus === 'FAILED' && (
            <Message
              error
              header={t(
                'Failed to make an additional charge. Please contact guest to ask card issuer for the error details.'
              )}
            />
          )}
          {isChargeable && isDirectChargable && !savedPaymentInfoAvailable && (
            <>
              <FieldWrapper
                label={t('Credit or Debit Card Information')}
                error={cardInputErrors}
              />
              <CardElement
                onChange={handleCardChange}
                style={cardElementStyle}
                hidePostalCode={true}
              />
            </>
          )}
          {isChargeable && isDirectChargable && savedPaymentInfoAvailable && (
            <div style={{ marginTop: '15px' }}>
              <Checkbox
                label={t(
                  'Guest has agreed that additional charge will be made to the same credit card.'
                )}
                checked={state.checkAgreement}
                onChange={() => {
                  setState({
                    ...state,
                    checkAgreement: !state.checkAgreement,
                  });
                }}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button.Cancel
            onClick={() => {
              onClose();
            }}
          >
            {t('Close')}
          </Button.Cancel>
          <Button
            primary
            type="submit"
            style="blue"
            size="middle"
            loading={fareAdjustStatus === 'REQUESTED'}
            onClick={handleConfirmFareAdjustment}
            disabled={
              resultAmountIsNegative ||
              // Do not allow full refund for GMO, allow for other payment gateway
              (resultAmountIsZero &&
                reservation.payment_gateway &&
                reservation.payment_gateway === 'GMO') ||
              !validateForm() ||
              (isChargeable &&
                isDirectChargable &&
                savedPaymentInfoAvailable &&
                !state.checkAgreement)
            }
          >
            {t('Confirm')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
);
