import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ticketIcon from 'client/images/map/ic_ticket_white.svg';
import castleIcon from 'client/images/map/ic_castle_black.svg';
import shoppingCartIcon from 'client/images/map/ic_shopping_cart_black.svg';
import mobileOrderIcon from 'client/images/map/ic_fork_spoon_white.svg';
import mapIcon from 'client/images/map/ic_map_black.svg';
import {
  DigitalMap,
  DigitalMapGuestJourney,
  ProductSummary,
  Restaurant,
} from 'shared/models/swagger';
import { TranslateFuncType } from 'client/components/Translate';
import { ReduxState } from 'client/reducers';
import { summariesSelector } from 'client/reducers/products';

import styles from './SessionTimeline.module.css';

type Session = Exclude<DigitalMapGuestJourney['sessions'], undefined>[number];
type SessionEvent = Exclude<Session['events'], undefined>[number];

const getActionIcon = (sessionEvent: SessionEvent) => {
  switch (sessionEvent.event_type) {
    case 'page-view':
    case 'return-page':
      return mapIcon;
    case 'pin-view':
    case 'pin-details':
    case 'restaurant-view':
      return castleIcon;
    case 'reservation-thank-you':
    case 'mobile-order-purchase':
    case 'check-availability':
      return shoppingCartIcon;
  }
};

const getActionElement = (
  sessionEvent: SessionEvent,
  map: DigitalMap | undefined,
  restaurants: Restaurant[],
  products: ProductSummary[],
  t: TranslateFuncType
): React.ReactNode => {
  switch (sessionEvent.event_type) {
    case 'page-view':
    case 'return-page':
      return t('Open Map');
    case 'pin-view': {
      const pin = map?.pins?.find((pin) => pin.key === sessionEvent.pin_key);
      return t('Click {{pinName}} pin', { pinName: pin?.title });
    }
    case 'pin-details': {
      const pin = map?.pins?.find((pin) => pin.key === sessionEvent.pin_key);
      return t('See {{pinName}} Details', { pinName: pin?.title });
    }
    case 'restaurant-view': {
      return t('View Menu for {{restaurantName}}', {
        restaurantName: restaurants.find(
          (r) => r.id === sessionEvent.restaurant_id
        )?.name,
      });
    }
    case 'check-availability':
      return (
        <>
          <div>{t('Check Availability')}</div>
          <div>
            {t('{{productName}}: {{date}}', {
              productName: products.find(
                (p) => p.id === sessionEvent.product_id
              )?.product_name,
              date: moment
                .parseZone(sessionEvent.availability_date_time_local)
                .locale('en')
                .format('lll'),
            })}
          </div>
          <div>
            {t('{{guests}}', {
              guests: sessionEvent.availability_guests
                ?.filter((g) => g.guest_count)
                ?.map((g) => `${g.guest_count}x ${g.guest_type}`)
                .join(', '),
            })}
          </div>
        </>
      );
    case 'reservation-thank-you':
      return t('Purchased Ticket: {{productName}}', {
        productName: products.find((p) => p.id === sessionEvent.product_id)
          ?.product_name,
      });
    case 'mobile-order-purchase':
      return t('Completed Mobile Order from {{restaurantName}}', {
        restaurantName: restaurants.find(
          (r) => r.id === sessionEvent.restaurant_id
        )?.name,
      });
  }
};

interface Props {
  sessionEvent: SessionEvent;
}

export const SessionEvent = React.forwardRef<HTMLDivElement, Props>(
  ({ sessionEvent }, ref) => {
    const { t } = useTranslation();
    const { mapId } = useParams<{
      mapId: string;
    }>();

    const digitalMap = useSelector(
      (state: ReduxState) => state.digitalMaps.all
    );
    const selectedMap = digitalMap.find((map) => map.id === mapId);
    const restaurants = useSelector(
      (state: ReduxState) => state.restaurants.all
    );
    const products = useSelector(summariesSelector);

    const time = moment(sessionEvent.time_of_day_start, 'HH:mm')
      .locale('en')
      .format('h:mma');
    const icon = getActionIcon(sessionEvent);
    const actionElement = getActionElement(
      sessionEvent,
      selectedMap,
      restaurants,
      products,
      t
    );
    const isTicket = sessionEvent.event_type === 'reservation-thank-you';
    const isMobileOrder = sessionEvent.event_type === 'mobile-order-purchase';

    return (
      <div ref={ref} className={styles.timelineItem}>
        <div className={styles.timelineMarker} />
        <span className={styles.timelineTime}>{time}</span>
        <img src={icon} alt="" className={styles.timelineIcon} />
        <div className={styles.timelineAction}>{actionElement}</div>
        {isTicket && (
          <div className={clsx(styles.chip, styles.ticket)}>
            <img src={ticketIcon} alt="Completed" className={styles.chipIcon} />
          </div>
        )}
        {isMobileOrder && (
          <div className={clsx(styles.chip, styles.mobileOrder)}>
            <img
              src={mobileOrderIcon}
              alt="Completed"
              className={styles.chipIcon}
            />
          </div>
        )}
      </div>
    );
  }
);
