import { convertMediaUrlsToProductMediaItems } from 'client/components/NewProductEditor/util';
import { Weekday } from 'client/libraries/util/weekdays';
import * as Swagger from 'shared/models/swagger';
import { generateRandomString } from 'shared/models/util';

export interface FormValues {
  restaurantId: string;
  name: string;
  mediaUrls: string[];
  category: string;
  activeSchedules: OpenHourSchedule[];
  menuItems: MenuItem[];
}

export type ClosedDate = {
  date: string;
  repeatsAnnually: boolean;
};

export interface OpenHourSchedule {
  openFrom: string;
  openTo: string;
  schedule: Schedule;
}

export interface Schedule {
  dateRanges: {
    startDate: string;
    endDate: string;
  }[];
  weekdays: Weekday[];
  closedDates: ClosedDate[];
}

export interface MenuItem {
  name: string;
  description: string;
  price: string;
  mediaUrls?: string[];
  category: string;
  tags?: string[];
  isRecommended: boolean;
  key: string;
  optionGroups?: MenuItemOptionGroup[];
  allergens?: string[];
}

export interface MenuItemOptionGroup {
  name: string;
  key: string;
  isRequired: boolean;
  selectionLimit: string;
  options: MenuItemOption[];
}

export interface MenuItemOption {
  name: string;
  key: string;
  price: string;
  mediaUrls?: string[];
  isDefault: boolean;
}

export const getInitialValues = (
  existingRestaurant: Swagger.Restaurant | null,
  restaurantId: string
): FormValues => {
  const existingMenu = existingRestaurant?.menu;

  return {
    restaurantId: existingRestaurant?.id || restaurantId,
    name: existingMenu?.name ?? '',
    mediaUrls: existingMenu?.media_items?.map((m) => m.url) ?? [],
    category: existingMenu?.category ?? '',
    activeSchedules:
      existingMenu?.active_schedules?.map((sched) => ({
        openFrom: sched.start_time_local ?? '0:00',
        openTo: sched.end_time_local ?? '0:00',
        schedule: {
          dateRanges:
            sched.schedule?.date_ranges?.map((dateRange) => ({
              startDate: dateRange.start_date_local ?? '',
              endDate: dateRange.end_date_local ?? '',
            })) ?? [],
          weekdays: sched.schedule?.days_of_week ?? [],
          closedDates:
            sched.schedule?.closed_dates?.map((closedDate) => ({
              date: closedDate,
              repeatsAnnually: false,
            })) ?? [],
        },
      })) ?? [],
    menuItems:
      existingMenu?.menu_items?.map((menuItem) => ({
        key: menuItem.key || '',
        name: menuItem.name ?? '',
        description: menuItem.description ?? '',
        price:
          menuItem.price && menuItem.price.length > 3
            ? menuItem.price.substring(3)
            : '',
        mediaUrls:
          menuItem.media_items?.map((mediaItem) => mediaItem.url) ?? [],
        category: menuItem.category ?? '',
        tags: menuItem.tags ?? [],
        isRecommended: menuItem.is_recommended ?? false,
        allergens: menuItem.allergens ?? [],
        optionGroups: menuItem.option_groups?.map((optionGroup) => ({
          name: optionGroup.name ?? '',
          key: optionGroup.key || generateMenuItemKey(optionGroup.name ?? ''),
          isRequired: optionGroup.is_required ?? false,
          selectionLimit: `${optionGroup.selection_limit ?? 0}`,
          options:
            optionGroup.options?.map((option) => ({
              name: option.name ?? '',
              key: option.key || generateMenuItemKey(option.name ?? ''),
              price: option.price ? option.price.substring(3) : '',
              mediaUrls:
                option.media_items?.map((mediaItem) => mediaItem.url) ?? [],
              isDefault: option.is_default ?? false,
            })) ?? [],
        })),
      })) ?? [],
  };
};

export const convertFormValuesToSwaggerRestaurantPatch = (
  values: FormValues,
  currencyCode: string
): Swagger.Restaurant => {
  return {
    menu: {
      name: values.name,
      category: values.category,
      media_items: convertMediaUrlsToProductMediaItems(values.mediaUrls ?? []),
      active_schedules: values.activeSchedules.map((sched) => ({
        start_time_local: sched.openFrom,
        end_time_local: sched.openTo,
        schedule: {
          date_ranges: sched.schedule.dateRanges.map((dateRange) => ({
            start_date_local: dateRange.startDate,
            end_date_local: dateRange.endDate,
          })),
          days_of_week: sched.schedule.weekdays,
          closed_dates: sched.schedule.closedDates.map(
            (closedDate) => closedDate.date
          ),
        },
      })),
      menu_items: values.menuItems.map((menuItem) => ({
        name: menuItem.name,
        description: menuItem.description,
        price: menuItem.price ? `${currencyCode}${menuItem.price}` : '',
        category: menuItem.category,
        tags: menuItem.tags,
        media_items: convertMediaUrlsToProductMediaItems(
          menuItem.mediaUrls ?? []
        ),
        is_recommended: menuItem.isRecommended,
        key: menuItem.key || generateMenuItemKey(menuItem.name),
        allergens: menuItem.allergens ?? [],
        option_groups: menuItem.optionGroups?.map((optionGroup) => ({
          name: optionGroup.name,
          key: optionGroup.key || generateMenuItemKey(optionGroup.name ?? ''),
          is_required: optionGroup.isRequired,
          selection_limit: parseInt(optionGroup.selectionLimit ?? '0'),
          options: optionGroup.options?.map((option) => ({
            name: option.name,
            key: option.key || generateMenuItemKey(option.name ?? ''),
            price: option.price ? `${currencyCode}${option.price}` : '',
            media_items: convertMediaUrlsToProductMediaItems(
              option.mediaUrls ?? []
            ),
            is_default: option.isDefault,
          })),
        })),
      })),
    },
  };
};

export const generateMenuItemKey = (name: string): string => {
  return (
    name
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '')
      .substring(0, 9) + generateRandomString(3)
  );
};
