import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { Field, useForm } from 'react-final-form';
import clsx from 'clsx';

import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { getAvailabilityRuleScheduleText } from 'client/pages/ProductEditor/ReservationParametersEditor/FormValues';
import { ComponentProductTimeSlotEditor } from 'client/pages/ProductDetails/ProductContentsHeader/PackageSettingsModal/ComponentProductTimeSlotEditor';
import { FieldWrapper } from 'client/components/Form';
import baseStyles from 'client/base.module.css';
import styles from 'client/pages/ProductEditor/ProductEditor.module.css';
import type { Product } from 'shared/models/swagger';

import { UnitMappingsEditor } from './UnitMappingsEditor';
import { DynamicPackageToggleButton } from './DynamicPackageToggleButton';

type Props = {
  componentProducts: Product[];
};
export const PackageSettingsEditor = ({ componentProducts }: Props) => {
  const [activeScheduleIndex, setActiveScheduleIndex] =
    React.useState<number>(0);
  const { t } = useTranslation();
  const form = useForm();
  const showPickupDropoffOptions = componentProducts.some((product) =>
    Boolean(
      product.shared_allotment_references &&
        product.shared_allotment_references.passthrough_base_product_id
    )
  );
  const editingProduct = React.useContext(EditingProductContext);
  const showUnitMappingsEditor = editingProduct?.pricing?.some(
    (priceSchedule) =>
      priceSchedule.units.some((unit) => unit.method === 'PER_PARTICIPANT')
  );
  const activeSchedule = _.get(
    form.getState()?.values,
    `availabilitySchedules.${activeScheduleIndex}`
  );

  /* Hack?: DynamicPackageToggleButton from form.getState() is not updated. */
  /* Copy the handling from ComponentProductTimeSlotEditor.tsx */
  const { values } = form.getState();
  const isDynamicPackageProductValue = values.isDynamicPackageProduct;
  const [isDynamicPackageProduct, setIsDynamicPackageProduct] = React.useState(
    isDynamicPackageProductValue
  );
  React.useEffect(() => {
    setIsDynamicPackageProduct(isDynamicPackageProductValue);
  }, [isDynamicPackageProductValue]);

  return (
    <div>
      <FieldWrapper label={t('Start Time Mappings')} />

      <DynamicPackageToggleButton
        value={isDynamicPackageProduct}
        onChange={setIsDynamicPackageProduct}
      />

      {!isDynamicPackageProduct && (
        <div>
          <FieldArray name="availabilitySchedules">
            {({ fields }) => (
              <>
                <div className={styles['page-productsEdit__select']}>
                  <p className={styles['page-productsEdit__select__ttl']}>
                    {t('Select')}
                  </p>
                  <div className={styles['page-productsEdit__select__box']}>
                    <label
                      className={clsx(
                        baseStyles['base-form-select'],
                        baseStyles['base-maxwidth-800']
                      )}
                    >
                      <select
                        value={activeScheduleIndex}
                        onChange={(e) => {
                          setActiveScheduleIndex(parseInt(e.target.value));
                        }}
                      >
                        {fields.value.map((availabilityRule, idx) => (
                          <option key={idx} value={idx}>
                            {getAvailabilityRuleScheduleText(
                              availabilityRule,
                              t
                            )}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
                <div
                  key={activeScheduleIndex}
                  className={baseStyles['base-selectFrame']}
                >
                  <div className={baseStyles['base-selectFrame__header']}>
                    <p className={baseStyles['base-selectFrame__header__ttl']}>
                      {t('Selected Rule')}
                    </p>
                  </div>
                  <div className={baseStyles['base-selectFrame__body']}>
                    <p
                      className={styles['page-productsRegist__priceMain__ttl']}
                    >
                      {t('Package Settings')}
                    </p>
                    <FieldArray
                      name={`availabilitySchedules.${activeScheduleIndex}.startTimes`}
                    >
                      {({ fields: startTimeFields }) =>
                        startTimeFields.map((startTimeName, idx) => (
                          <div
                            key={idx}
                            className={styles['package-start-time-section']}
                          >
                            <Field name={startTimeName}>
                              {({ input }) => (
                                <div
                                  className={styles['package-start-time-label']}
                                >{`${t('Package Start Time: ')}${
                                  input.value.time
                                }`}</div>
                              )}
                            </Field>
                            <FieldArray
                              name={`${startTimeName}.packageComponentTimeSlots`}
                            >
                              {({ fields: componentTimeSlotFields }) =>
                                componentTimeSlotFields.map(
                                  (componentTimeSlotName, idx) => (
                                    <Field
                                      key={idx}
                                      name={componentTimeSlotName}
                                    >
                                      {({ input }) => {
                                        const componentProduct =
                                          componentProducts.find(
                                            (componentProduct) =>
                                              input.value.productId ===
                                              componentProduct.id
                                          );
                                        return (
                                          <ComponentProductTimeSlotEditor
                                            activeSchedule={activeSchedule}
                                            name={componentTimeSlotName}
                                            componentProduct={componentProduct}
                                            showPickupDropoffOptions={
                                              showPickupDropoffOptions
                                            }
                                          />
                                        );
                                      }}
                                    </Field>
                                  )
                                )
                              }
                            </FieldArray>
                          </div>
                        ))
                      }
                    </FieldArray>
                  </div>
                </div>
              </>
            )}
          </FieldArray>
        </div>
      )}

      {showUnitMappingsEditor && (
        <div className={baseStyles['base-margin-top-8']}>
          <FieldWrapper label={t('Unit Mappings')}>
            <UnitMappingsEditor componentProducts={componentProducts} />
          </FieldWrapper>
        </div>
      )}
    </div>
  );
};
