import { combineReducers } from 'redux';

import {
  CHECKIN_RESERVATION_SUCCESS,
  DOWNLOAD_MANIFEST_CSV_FAILURE,
  DOWNLOAD_MANIFEST_CSV_REQUEST,
  DOWNLOAD_MANIFEST_CSV_SUCCESS,
  DOWNLOAD_MANIFEST_PDF_FAILURE,
  DOWNLOAD_MANIFEST_PDF_REQUEST,
  DOWNLOAD_MANIFEST_PDF_SUCCESS,
  FETCH_MANIFEST_FAILURE,
  FETCH_MANIFEST_REQUEST,
  FETCH_MANIFEST_SUCCESS,
  SEND_MANIFEST_EMAIL_FAILURE,
  SEND_MANIFEST_EMAIL_REQUEST,
  SEND_MANIFEST_EMAIL_SUCCESS,
  UNDO_CHECKIN_RESERVATION_SUCCESS,
} from 'client/constants/ActionTypes';
import { Reservation } from 'shared/models/swagger';

const error = (state = '', action: any) => {
  switch (action.type) {
    case FETCH_MANIFEST_FAILURE:
      return action.error;

    case FETCH_MANIFEST_SUCCESS:
      return '';

    default:
      return state;
  }
};

const loading = (state = false, action: any) => {
  switch (action.type) {
    case FETCH_MANIFEST_REQUEST:
      return true;

    case FETCH_MANIFEST_FAILURE:
    case FETCH_MANIFEST_SUCCESS:
      return false;

    default:
      return state;
  }
};

const reservations = (
  state: Reservation[] = [],
  action: any
): Reservation[] => {
  switch (action.type) {
    case FETCH_MANIFEST_SUCCESS:
      return action.response.reservations;

    case FETCH_MANIFEST_FAILURE:
      return [];

    case CHECKIN_RESERVATION_SUCCESS:
    case UNDO_CHECKIN_RESERVATION_SUCCESS: {
      const newState = state.map((reservation) => {
        if (reservation.id === action.response.id) {
          return {
            ...action.response,
          };
        }
        return reservation;
      });
      return newState;
    }

    default:
      return state;
  }
};

const pdfStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case DOWNLOAD_MANIFEST_PDF_REQUEST:
      return 'IN_FLIGHT';

    case DOWNLOAD_MANIFEST_PDF_SUCCESS:
      return 'SUCCESS';

    case DOWNLOAD_MANIFEST_PDF_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

const csvStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case DOWNLOAD_MANIFEST_CSV_REQUEST:
      return 'IN_FLIGHT';

    case DOWNLOAD_MANIFEST_CSV_SUCCESS:
      return 'SUCCESS';

    case DOWNLOAD_MANIFEST_CSV_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

const emailStatus = (state = 'IDLE', action: any) => {
  switch (action.type) {
    case SEND_MANIFEST_EMAIL_REQUEST:
      return 'IN_FLIGHT';

    case SEND_MANIFEST_EMAIL_SUCCESS:
      return 'SUCCESS';

    case SEND_MANIFEST_EMAIL_FAILURE:
      return 'FAILURE';

    default:
      return state;
  }
};

const emailError = (state = '', action: any) => {
  switch (action.type) {
    case SEND_MANIFEST_EMAIL_REQUEST:
    case SEND_MANIFEST_EMAIL_SUCCESS:
      return '';

    case SEND_MANIFEST_EMAIL_FAILURE:
      return action.error;

    default:
      return state;
  }
};

export const manifests = combineReducers({
  csvStatus,
  emailError,
  emailStatus,
  error,
  loading,
  pdfStatus,
  reservations,
});
