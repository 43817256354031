import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { Box } from 'client/components/Box/Box';
import { FieldWrapper } from 'client/components/Form';
import { Button } from 'client/components/v3/Common/Button';
import { Modal } from 'client/components/v3/Form/Modal';
import {
  checkinReservation,
  undoCheckinReservation,
} from 'client/actions/reservations';
import { DeleteConfirmModal } from 'client/pages/v3/Manifest/ManifestDaily/Modal/Checkin/DeleteConfirmModal';
import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers';
import {
  isCheckinFully,
  noMoreAvailable,
  getStubText,
  getRemainingGuestsToCheckin,
} from 'client/libraries/checkin';
import checkFinishedIcon from 'client/images/ic_check_finished.svg';
import checkUnfinishedIcon from 'client/images/ic_check_unfinished.svg';
import checkInProgressIcon from 'client/images/ic_check_partial.svg';
import styles from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Radio } from 'client/components/v3/Form/Radio';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

const range = (start: number, stop: number): number[] => {
  return [...Array(stop - start + 1).keys()].map((item) => item + start);
};

const getPackageComponentProductDescription = (
  reservation: ManifestReservationShape,
  componentReservationId: string
): string => {
  const component = (reservation?.package_component_reservations ?? []).find(
    (componentSummary) =>
      componentSummary.reservation_id === componentReservationId
  );

  if (component == null) {
    return '';
  }

  let productCount = 0;

  for (const componentReservation of reservation?.package_component_reservations ??
    []) {
    if (component.product_id === componentReservation.product_id) {
      productCount++;
    }
  }

  if (productCount > 1) {
    return `[${component.formatted_start_time}] ${
      component.product_name ?? ''
    }`;
  }

  return component.product_name ?? '';
};

type FormValues = {
  guestCount: number;
  packageComponentReservationId: string;
  email: string;
  stubKey: string;
};
type Props = {
  reservation: ManifestReservationShape;
  trigger: React.ReactElement<any>;
};
export const CheckinReservationModal = ({ reservation, trigger }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const loading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );
  const checkinRecords = reservation.checkin_info?.checkin_records ?? [];
  const checkinStatus =
    reservation.checkin_info?.checkin_status ?? 'NOT_CHECKED_IN';
  let remainingGuestsToCheckin = reservation.guests.length;
  const shouldCountGuests =
    reservation.checkin_info?.should_count_guests_for_checkin ?? false;
  const packageComponentOptions = (
    reservation?.package_component_reservations ?? []
  ).map((reservationSummary) => ({
    value: reservationSummary.reservation_id,
    text: getPackageComponentProductDescription(
      reservation,
      reservationSummary.reservation_id
    ),
  }));
  const stubs = reservation.checkin_info?.stubs ?? [];

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: () => setShowModal(true),
      })}
      <Form
        initialValues={{
          guestCount: remainingGuestsToCheckin,
          packageComponentReservationId: '',
          stubKey: stubs.length > 0 ? stubs[0].key : '',
        }}
        onSubmit={async (values: FormValues) => {
          await dispatch(
            checkinReservation(reservation.id ?? '', {
              email: values.email,
              guest_count: Number(values.guestCount),
              package_component_reservation_id:
                values.packageComponentReservationId,
              stub_key: values.stubKey || undefined,
            })
          );

          await setShowModal(false);
          // Close modal programmatically if set state does not work
          if (document) {
            const closeButton = document.querySelector(
              'button[name="checkinReservationModalClose"]'
            ) as HTMLButtonElement;
            if (closeButton) {
              await closeButton.click();
            }
          }
        }}
      >
        {({ handleSubmit, values, form }) => {
          let checkinDisabledReason = '';
          if (checkinStatus === 'CHECKED_IN') {
            checkinDisabledReason = t('Checkin already completed');
          } else if (values.stubKey) {
            // if the product is a stub, need to check the availability
            if (
              isCheckinFully(
                values.stubKey ?? '',
                reservation?.checkin_info,
                (reservation?.guests ?? []).length
              )
            ) {
              checkinDisabledReason = t(
                'The facility checkin already completed.'
              );
            } else if (
              noMoreAvailable(values.stubKey ?? '', reservation?.checkin_info)
            ) {
              checkinDisabledReason = t(
                'Since the maximum usage limit has been reached, checkin to different facilities is not allowed.'
              );
            }
          }

          return (
            <form onSubmit={handleSubmit} id="checkinReservationForm">
              <Modal
                useCloseButton={true}
                title={t('Checkin')}
                open={showModal}
                onClose={() => setShowModal(false)}
                insertAtRoot={true}
                rightActionChildren={
                  <>
                    <Button
                      text={t('Delete Checkin Records')}
                      disabled={checkinRecords.length === 0}
                      color="white"
                      loading={loading}
                      onClick={() => setShowDeleteModal(true)}
                    />
                    <Button
                      text={t('Checkin')}
                      disabled={
                        checkinStatus === 'CHECKED_IN' ||
                        Boolean(checkinDisabledReason)
                      }
                      loading={loading}
                      type="submit"
                      form="checkinReservationForm"
                    />
                  </>
                }
                closeButtonName="checkinReservationModalClose"
              >
                <>
                  {showDeleteModal && (
                    <DeleteConfirmModal
                      header={t('Delete Checkin Records')}
                      loading={loading}
                      content={t(
                        'Are you sure you want to delete checkin records?'
                      )}
                      onConfirm={async () => {
                        await dispatch(
                          undoCheckinReservation(reservation.id, {})
                        );
                        await setShowModal(false);
                      }}
                      onClose={() => setShowDeleteModal(false)}
                    />
                  )}
                  <FieldWrapper label={t('Checkin Records')}>
                    {checkinStatus === 'NOT_CHECKED_IN' && (
                      <Box display="flex" alignItems="center">
                        <img src={checkUnfinishedIcon} />
                        <Box ml={2}>{t('Not yet checked in')}</Box>
                      </Box>
                    )}
                    {(checkinStatus === 'CHECKED_IN' ||
                      checkinStatus === 'IN_PROGRESS') && (
                      <Box display="flex" alignItems="center">
                        {checkinStatus === 'CHECKED_IN' && (
                          <img src={checkFinishedIcon} />
                        )}
                        {checkinStatus === 'IN_PROGRESS' && (
                          <img src={checkInProgressIcon} />
                        )}
                        {
                          <Box ml={2}>
                            {checkinRecords.map((record, idx) => (
                              <div key={idx}>
                                {packageComponentOptions.length > 0
                                  ? `${moment(record.date_time_utc).format(
                                      'YYYY/MM/DD HH:mm'
                                    )}   ${t(
                                      '{{count}} guest checked in ({{packageDescription}})',
                                      {
                                        count: record.guest_count,
                                        packageDescription:
                                          record.package_component_reservation_id
                                            ? getPackageComponentProductDescription(
                                                reservation,
                                                record.package_component_reservation_id
                                              )
                                            : t('checkin all at once'),
                                      }
                                    )}`
                                  : `${moment(record.date_time_utc).format(
                                      'YYYY/MM/DD HH:mm'
                                    )}  ${getStubText(
                                      record.stub_key ?? '',
                                      reservation?.checkin_info
                                    )} ${t('{{count}} guest checked in', {
                                      count: record.guest_count,
                                    })}`}
                              </div>
                            ))}
                          </Box>
                        }
                      </Box>
                    )}
                  </FieldWrapper>
                  {checkinStatus !== 'CHECKED_IN' && stubs.length > 0 && (
                    <Box mt={3}>
                      <FieldWrapper label={t('Usage')}>
                        <Field name="stubKey">
                          {({ input }) => (
                            <SingleDropdown
                              width={200}
                              options={stubs.map((stub) => ({
                                value: stub.key ?? '',
                                text: stub.text ?? '',
                              }))}
                              selectedOption={input.value}
                              onChange={(value) => {
                                input.onChange(value);

                                remainingGuestsToCheckin =
                                  getRemainingGuestsToCheckin(
                                    value ?? '',
                                    reservation?.checkin_info,
                                    (reservation?.guests ?? []).length
                                  );

                                if (
                                  values.guestCount > remainingGuestsToCheckin
                                ) {
                                  form.change(
                                    'guestCount',
                                    remainingGuestsToCheckin
                                  );
                                }
                              }}
                            />
                          )}
                        </Field>
                      </FieldWrapper>
                    </Box>
                  )}
                  {checkinStatus !== 'CHECKED_IN' && (
                    <Box mt={3}>
                      <FieldWrapper label={t('Checkin Guests')}>
                        {shouldCountGuests ? (
                          <Field name="guestCount">
                            {({ input }) => (
                              <SingleDropdown
                                width={200}
                                options={
                                  remainingGuestsToCheckin
                                    ? range(1, remainingGuestsToCheckin).map(
                                        (x) => ({
                                          value: x as any,
                                          text: `${x}`,
                                        })
                                      )
                                    : [{ value: 0, text: '0' }]
                                }
                                selectedOption={input.value}
                                onChange={(value) => {
                                  input.onChange(value);
                                }}
                              />
                            )}
                          </Field>
                        ) : (
                          (reservation?.guests ?? []).length
                        )}
                      </FieldWrapper>
                    </Box>
                  )}
                  {checkinStatus !== 'CHECKED_IN' &&
                    packageComponentOptions.length > 0 && (
                      <Box mt={3}>
                        <FieldWrapper label={t('Package')}>
                          <Field name="packageComponentReservationId">
                            {({ input }) => (
                              <ul
                                className={
                                  styles[
                                    'p-manifestDaily__checkinModal__package__ul'
                                  ]
                                }
                              >
                                <li>
                                  <Radio
                                    label={t(
                                      'Check-in to all component products'
                                    )}
                                    checked={input.value === ''}
                                    onChange={() => {
                                      input.onChange('');
                                    }}
                                    size="sm"
                                  />
                                </li>
                                {packageComponentOptions.map((option, idx) => (
                                  <li key={idx}>
                                    <Radio
                                      label={option.text}
                                      checked={input.value === option.value}
                                      onChange={() => {
                                        input.onChange(option.value);
                                      }}
                                      size="sm"
                                    />
                                  </li>
                                ))}
                              </ul>
                            )}
                          </Field>
                        </FieldWrapper>
                      </Box>
                    )}
                  {checkinDisabledReason && (
                    <div className={baseStyles['u-error-msg']}>
                      {checkinDisabledReason}
                    </div>
                  )}
                </>
              </Modal>
            </form>
          );
        }}
      </Form>
    </>
  );
};
