import * as React from 'react';
import moment from 'moment-timezone';
import { WithTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PageContent } from 'client/components/v3/Page/PageContent';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { ReduxState } from 'client/reducers';
import { fetchDigitalMaps } from 'client/actions/digitalMaps';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { fetchRestaurants } from 'client/actions/restaurants';
import { fetchProducts } from 'client/actions/products';

import { useDigitalMapGuestJourney } from '../DigitalMapGuestJourneyList/useDigitalMapGuestJourney';

import styles from './DigitalMapGuestJourneyDetails.module.css';
import { Gadget } from './Gadget';
import { DigitalJourneyGadget } from './DigitalJourneyGadget/DigitalJourneyGadget';
import { OnsiteJourneyGadget } from './OnsiteJourneyGadget/OnsiteJourneyGadget';

const formattedDuration = (
  durationInMinutes: number,
  t: WithTranslation['t']
) => {
  if (durationInMinutes < 60) {
    return `${durationInMinutes}${t('min')}`;
  }
  return `${Math.floor(durationInMinutes / 60)}${t('h')}${
    durationInMinutes % 60
  }${t('m')}`;
};

export const DigitalMapGuestJourneyDetails = () => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const { mapId, journeyId } = useParams<{
    mapId: string;
    journeyId: string;
  }>();

  const [analyticsCustomerId, date] = journeyId.split('_');

  const [time, setTime] = React.useState(0);
  const dispatch = useDispatch();

  const { data: journey, isLoading } = useDigitalMapGuestJourney(
    mapId,
    analyticsCustomerId,
    date
  );

  React.useEffect(() => {
    dispatch(fetchDigitalMaps());
    dispatch(fetchRestaurants());
    dispatch(fetchProducts());
  }, []);

  const digitalMaps = useSelector((state: ReduxState) => state.digitalMaps.all);

  const selectedMap = digitalMaps.find((map) => map.id === mapId);

  return (
    <V3Page>
      <PageHeader
        title={t('Guest Journeys > {{id}}', {
          id: analyticsCustomerId.slice(0, 8),
        })}
        description={t("Dive into the details of your guest's visit")}
      />
      <PageContent>
        <div className={styles['gadget-row']}>
          <Gadget header={t('User')} loading={isLoading}>
            {journey?.customer_display_name ?? '-'}
          </Gadget>
          <Gadget header={t('Visit Date')}>
            {moment(date).locale(locale).format('ll')}
          </Gadget>
          <Gadget
            header={t('Visit Duration')}
            tooltipText={t('Tooltip')}
            loading={isLoading}
          >
            {formattedDuration(journey?.visit_duration_minutes ?? 0, t)}
          </Gadget>
          <Gadget
            header={t('Avg Session')}
            tooltipText={t('Tooltip')}
            loading={isLoading}
          >
            {journey?.session_count
              ? t('{{duration}} minutes', {
                  duration: (
                    (journey?.sessions?.reduce(
                      (acc, session) => acc + (session.duration_seconds ?? 0),
                      0
                    ) ?? 0) /
                    60 /
                    journey.session_count
                  ).toFixed(1),
                })
              : '-'}
          </Gadget>
          <Gadget
            header={t('Spending')}
            tooltipText={t('Tooltip')}
            loading={isLoading}
          >
            {journey?.gross_sales
              ? formattedCurrencyAmount(journey.gross_sales)
              : '-'}
          </Gadget>
        </div>
        <div className={styles['gadget-row']}>
          <DigitalJourneyGadget time={time} journeyData={journey} />
          <OnsiteJourneyGadget
            journeyData={journey}
            map={selectedMap ?? null}
            time={time}
            setTime={setTime}
          />
        </div>
      </PageContent>
    </V3Page>
  );
};
