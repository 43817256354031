import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';

import {
  activeUserOrganizationSelector,
  bookingWidgetPMPSupportedLanguagesSelector,
} from 'client/reducers/user';
import { ContentLanguageSelector } from 'client/components/v3/ContentLanguageSelector/ContentLanguageSelector';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateOrganization } from 'client/actions/organizations';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { updateTranslations } from 'client/actions/translations';
import { SourceLanguage } from 'shared/models/swagger';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { Snackbar } from 'client/components/v3/Common/Snackbar';

import styles from './BasicSettings.module.css';
import {
  getInitialValues,
  convertFormValuesToSwagger,
  FormValues,
  getDefaultFormField,
} from './formValues';
import { CustomerFormField } from './CustomerFormField';
import { EditSupportedLanguageTextModal } from './EditSupportedLanguageTextModal';

const focusOnError: any = createDecorator();

export const BasicSettings = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [contentLanguage, setContentLanguage] =
    React.useState<SourceLanguage | null>(null);

  const [editPropName, setEditPropName] = React.useState<{
    name: string;
    title: string;
  } | null>(null);

  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);

  const activeOrganization = useSelector(activeUserOrganizationSelector);

  const defaultLanguage = useSelector(defaultProductLanguageSelector);

  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );

  const initialValues = React.useMemo(() => {
    if (activeOrganization) {
      return getInitialValues(activeOrganization, supportedLanguages);
    }
    return {};
  }, [activeOrganization, supportedLanguages, t]);

  if (!activeOrganization) {
    return null;
  }

  return (
    <div>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          const translations = [];
          if (
            Object.values(values.translation?.privacyPolicy ?? {}).some(
              (translatedText) => Boolean(translatedText)
            )
          ) {
            const translation = {
              source_language: defaultLanguage,
              [defaultLanguage.toLowerCase()]: values.privacyPolicy,
            };

            Object.keys(values.translation?.privacyPolicy ?? {}).forEach(
              (lang) => {
                translation[lang.toLowerCase()] =
                  values.translation?.privacyPolicy?.[lang] ?? '';
              }
            );

            translations.push(translation);
          }

          if (
            Object.values(values.translation?.termsAndConditions ?? {}).some(
              (translatedText) => Boolean(translatedText)
            )
          ) {
            const translation = {
              source_language: defaultLanguage,
              [defaultLanguage.toLowerCase()]: values.termsAndConditions,
            };

            Object.keys(values.translation?.termsAndConditions ?? {}).forEach(
              (lang) => {
                translation[lang.toLowerCase()] =
                  values.translation?.termsAndConditions?.[lang] ?? '';
              }
            );
            translations.push(translation);
          }

          const organizationPatch = convertFormValuesToSwagger(
            values,
            activeOrganization
          );

          try {
            if (translations.length > 0) {
              await Promise.all([
                dispatch(
                  updateOrganization(
                    activeOrganization?.id ?? '',
                    'SUPPLIER',
                    organizationPatch
                  )
                ),
                dispatch(updateTranslations(translations)),
              ]);
            } else {
              await dispatch(
                updateOrganization(
                  activeOrganization?.id ?? '',
                  'SUPPLIER',
                  organizationPatch
                )
              );
            }
            setSaveSucceeded(true);
          } catch (e) {
            console.error(e);
          }
        }}
        decorators={[focusOnError]}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => (
          <div className={baseStyles['base-main__body__box']}>
            <div className={baseStyles['base-main__body__box__body']}>
              <form onSubmit={handleSubmit}>
                <table
                  className={tableStyles['c-tableSmall']}
                  style={{ overflow: 'hidden' }}
                >
                  <tbody>
                    <tr>
                      <th className={baseStyles['u-width-208']}>
                        {t('Basic Settings')}
                      </th>
                      <td>
                        <div className={styles['p-settings__checkbox']}>
                          <Field name="isEnabled" type="checkbox">
                            {({ input }) => (
                              <Toggle
                                label={t(
                                  'Enable membership feature on booking website'
                                )}
                                checked={input.checked}
                                onChange={() => {
                                  input.onChange(!input.checked);
                                }}
                              />
                            )}
                          </Field>
                          <Field
                            name="customerRegistrationRequiredForBooking"
                            type="checkbox"
                          >
                            {({ input }) => (
                              <Toggle
                                label={t(
                                  'Membership registration is required for booking'
                                )}
                                checked={input.checked}
                                onChange={() => {
                                  input.onChange(!input.checked);
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Terms & Conditions')}</th>
                      <td>
                        <Button
                          text={t('Edit Terms & Conditions')}
                          onClick={() => {
                            setEditPropName({
                              name: 'termsAndConditions',
                              title: t('Terms & Conditions'),
                            });
                          }}
                          iconBeforeText={
                            <i className="c-icon-outline-general-edit-05"></i>
                          }
                          color="white"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Privacy Policy')}</th>
                      <td>
                        <Button
                          text={t('Edit Privacy Policy')}
                          onClick={() => {
                            setEditPropName({
                              name: 'privacyPolicy',
                              title: t('Privacy Policy'),
                            });
                          }}
                          iconBeforeText={
                            <i className="c-icon-outline-general-edit-05"></i>
                          }
                          color="white"
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Customer Information')}</th>
                      <td>
                        <div
                          className={styles['p-settings__customerInfo__item']}
                        >
                          <ContentLanguageSelector
                            contentLanguage={contentLanguage}
                            onChange={setContentLanguage}
                          />
                        </div>

                        <div
                          className={styles['p-settings__customerInfo__item']}
                        >
                          <Field
                            name={`formFieldSets.${contentLanguage}.customerNameFormat`}
                          >
                            {({ input }) => (
                              <>
                                <p style={{ marginBottom: '4px' }}>
                                  {t('Select text format of customer name')}
                                </p>
                                <SingleDropdown
                                  options={[
                                    {
                                      value: 'ALPHABET',
                                      text: t('Alphabet characters or spaces'),
                                    },
                                    {
                                      value: 'KANA',
                                      text: t(
                                        'Kana characters or full-width spaces'
                                      ),
                                    },
                                  ]}
                                  selectedOption={input.value}
                                  onChange={(value) => {
                                    input.onChange(value);
                                  }}
                                />
                              </>
                            )}
                          </Field>
                        </div>

                        <div
                          className={styles['p-settings__customerInfo__item']}
                        >
                          <ul>
                            <FieldArray
                              name={`formFieldSets.${contentLanguage}.formFields`}
                              key={contentLanguage}
                            >
                              {({ fields }) => (
                                <>
                                  {fields.length === 0 ? (
                                    <>
                                      <div className={baseStyles['u-mt-2']}>
                                        <a
                                          className={
                                            styles['p-settings__actions__add']
                                          }
                                          onClick={() => {
                                            (fields as any).insertAt(
                                              0,
                                              getDefaultFormField()
                                            );
                                          }}
                                        >
                                          <i className="c-icon-outline-general-plus-circle"></i>
                                          {t('Add Form Item')}
                                        </a>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {fields.map((name, index) => (
                                        <li
                                          key={name}
                                          className={baseStyles['u-mt-2']}
                                        >
                                          <CustomerFormField
                                            name={name}
                                            idx={index}
                                            onDeleteClick={() =>
                                              (fields as any).remove(index)
                                            }
                                          />
                                        </li>
                                      ))}
                                      <div className={baseStyles['u-mt-4']}>
                                        <a
                                          className={
                                            styles['p-settings__actions__add']
                                          }
                                          onClick={() => {
                                            if (fields.length) {
                                              (fields as any).insertAt(
                                                fields.length + 1,
                                                getDefaultFormField()
                                              );
                                            }
                                          }}
                                        >
                                          <i className="c-icon-outline-general-plus-circle"></i>
                                          {t('Add Form Item')}
                                        </a>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </FieldArray>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Reservations')}</th>
                      <td>
                        <div className={styles['p-settings__checkbox']}>
                          <Field name="allowViewingReservationDetails">
                            {({ input }) => (
                              <Checkbox
                                label={t('View reservation details')}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                                size="sm"
                              />
                            )}
                          </Field>
                          <Field name="allowUpdatingReservationInfo">
                            {({ input }) => (
                              <Checkbox
                                label={t('Update hotel, form answers')}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                                size="sm"
                              />
                            )}
                          </Field>
                          <Field name="allowChangingReservationParameters">
                            {({ input }) => (
                              <Checkbox
                                label={t(
                                  'Change reservations (Participation date, time, participants, add-on, transportation)'
                                )}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                                size="sm"
                              />
                            )}
                          </Field>
                          <Field name="allowCancellingReservation">
                            {({ input }) => (
                              <Checkbox
                                label={t('Cancel')}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                                size="sm"
                              />
                            )}
                          </Field>
                          <Field name="allowIssuingDownloadingReceiptPDF">
                            {({ input }) => (
                              <Checkbox
                                label={t('Issue receipt/download')}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                                size="sm"
                              />
                            )}
                          </Field>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className={baseStyles['u-mt-4']}>
                  <div className={styles['p-settings__actions']}>
                    <div>
                      {submitError && (
                        <p className={baseStyles['u-error-msg']}>
                          {submitError}
                        </p>
                      )}
                    </div>
                    <Button
                      text={t('Save Settings')}
                      disabled={submitting}
                      loading={submitting}
                      type="submit"
                    />
                  </div>
                  {saveSucceeded && (
                    <Snackbar
                      text={t('Settings Saved')}
                      color="success"
                      shouldShow={saveSucceeded}
                    />
                  )}
                </div>

                {editPropName && (
                  <EditSupportedLanguageTextModal
                    name={editPropName.name}
                    title={editPropName.title}
                    open={Boolean(editPropName)}
                    onClose={() => setEditPropName(null)}
                  />
                )}
              </form>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
