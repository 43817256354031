import * as React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';

import { config } from 'client/config';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import {
  activeUserOrganizationSelector,
  bookingWidgetPMPSupportedLanguagesSelector,
} from 'client/reducers/user';
import { ToggleButton, Checkbox, Button, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { Edit as EditIcon } from 'client/components/Icons/Edit';
import { ContentLanguageSelector } from 'client/components/ContentLanguageSelector/ContentLanguageSelector';
import { Add as AddIcon } from 'client/components/Icons/Add';
import { getArrayMutators } from 'client/libraries/util/form';
import { Message } from 'client/components/Message/Message';
import { updateOrganization } from 'client/actions/organizations';
import { defaultProductLanguageSelector } from 'client/reducers/organizations';
import { updateTranslations } from 'client/actions/translations';
import { SourceLanguage } from 'shared/models/swagger';
import baseStyles from 'client/base.module.css';
import { ToggleNewUI } from 'client/components/v3/ToggleNewUI/ToggleNewUI';

import styles from './CustomerSettings.module.css';
import {
  getInitialValues,
  convertFormValuesToSwagger,
  FormValues,
  getDefaultFormField,
} from './formValues';
import { CustomerFormField } from './CustomerFormField';
import { EditSupportedLanguageTextModal } from './EditSupportedLanguageTextModal';

const focusOnError: any = createDecorator();

export const CustomerSettings = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const checkboxLabelStyle = {
    background: '#fff',
    fontSize: '14px',
  };

  const [contentLanguage, setContentLanguage] =
    React.useState<SourceLanguage | null>(null);

  const [editPropName, setEditPropName] = React.useState<{
    name: string;
    title: string;
  } | null>(null);

  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);

  const activeOrganization = useSelector(activeUserOrganizationSelector);

  const defaultLanguage = useSelector(defaultProductLanguageSelector);

  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );

  const initialValues = React.useMemo(() => {
    if (activeOrganization) {
      return getInitialValues(activeOrganization, supportedLanguages);
    }
    return {};
  }, [activeOrganization, supportedLanguages, t]);

  const transparentStyle = {
    backgroundColor: 'transparent',
  };
  if (!activeOrganization) {
    return null;
  }

  return (
    <div>
      {(config.enableUIRevamp ||
        config.enableUIRevampForDemo ||
        config.enableUIRevampForRelease) && (
        <ToggleNewUI origin="CUSTOMER_SETTINGS" />
      )}
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormValues) => {
          const translations = [];
          if (
            Object.values(values.translation?.privacyPolicy ?? {}).some(
              (translatedText) => Boolean(translatedText)
            )
          ) {
            const translation = {
              source_language: defaultLanguage,
              [defaultLanguage.toLowerCase()]: values.privacyPolicy,
            };

            Object.keys(values.translation?.privacyPolicy ?? {}).forEach(
              (lang) => {
                translation[lang.toLowerCase()] =
                  values.translation?.privacyPolicy?.[lang] ?? '';
              }
            );

            translations.push(translation);
          }

          if (
            Object.values(values.translation?.termsAndConditions ?? {}).some(
              (translatedText) => Boolean(translatedText)
            )
          ) {
            const translation = {
              source_language: defaultLanguage,
              [defaultLanguage.toLowerCase()]: values.termsAndConditions,
            };

            Object.keys(values.translation?.termsAndConditions ?? {}).forEach(
              (lang) => {
                translation[lang.toLowerCase()] =
                  values.translation?.termsAndConditions?.[lang] ?? '';
              }
            );
            translations.push(translation);
          }

          const organizationPatch = convertFormValuesToSwagger(
            values,
            activeOrganization
          );

          try {
            if (translations.length > 0) {
              await Promise.all([
                dispatch(
                  updateOrganization(
                    activeOrganization?.id ?? '',
                    'SUPPLIER',
                    organizationPatch
                  )
                ),
                dispatch(updateTranslations(translations)),
              ]);
            } else {
              await dispatch(
                updateOrganization(
                  activeOrganization?.id ?? '',
                  'SUPPLIER',
                  organizationPatch
                )
              );
            }
            setSaveSucceeded(true);
          } catch (e) {
            console.error(e);
          }
        }}
        decorators={[focusOnError]}
        mutators={getArrayMutators()}
        keepDirtyOnReinitialize={true}
      >
        {({ handleSubmit, submitError, submitting }) => (
          <div className={baseStyles['base-main__body__box']}>
            <div className={baseStyles['base-main__body__box__body']}>
              <form onSubmit={handleSubmit}>
                <FormTableBox>
                  <table>
                    <tbody>
                      <tr>
                        <th>{t('Basic Settings')}</th>
                        <td>
                          <Box mb={1}>
                            <Field name="isEnabled" type="checkbox">
                              {({ input }) => (
                                <ToggleButton
                                  label={t(
                                    'Enable membership feature on booking website'
                                  )}
                                  checked={input.checked}
                                  onChange={() => {
                                    input.onChange(!input.checked);
                                  }}
                                />
                              )}
                            </Field>
                          </Box>
                          <Field
                            name="customerRegistrationRequiredForBooking"
                            type="checkbox"
                          >
                            {({ input }) => (
                              <ToggleButton
                                label={t(
                                  'Membership registration is required for booking'
                                )}
                                checked={input.checked}
                                onChange={() => {
                                  input.onChange(!input.checked);
                                }}
                              />
                            )}
                          </Field>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Terms & Conditions')}</th>
                        <td>
                          <Box display="flex" alignItems="center">
                            <EditIcon
                              onClick={() => {
                                setEditPropName({
                                  name: 'termsAndConditions',
                                  title: t('Terms & Conditions'),
                                });
                              }}
                            />
                            <Box ml={2}>{t('Edit Terms & Conditions')}</Box>
                          </Box>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Privacy Policy')}</th>
                        <td>
                          <Box display="flex" alignItems="center">
                            <EditIcon
                              onClick={() => {
                                setEditPropName({
                                  name: 'privacyPolicy',
                                  title: t('Privacy Policy'),
                                });
                              }}
                            />
                            <Box ml={2}>{t('Edit Privacy Policy')}</Box>
                          </Box>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Customer Information')}</th>
                        <td>
                          <ContentLanguageSelector
                            contentLanguage={contentLanguage}
                            onChange={setContentLanguage}
                          />

                          <Box mt={2}>
                            <Field
                              name={`formFieldSets.${contentLanguage}.customerNameFormat`}
                            >
                              {({ input }) => (
                                <Select
                                  label={t(
                                    'Select text format of customer name'
                                  )}
                                  maxWidth={320}
                                  options={[
                                    {
                                      value: 'ALPHABET',
                                      text: t('Alphabet characters or spaces'),
                                    },
                                    {
                                      value: 'KANA',
                                      text: t(
                                        'Kana characters or full-width spaces'
                                      ),
                                    },
                                  ]}
                                  value={input.value}
                                  onChange={(_, { value: newValue }) => {
                                    input.onChange(newValue);
                                  }}
                                  style={transparentStyle}
                                />
                              )}
                            </Field>
                          </Box>

                          <Box mt={2}>
                            <FieldArray
                              name={`formFieldSets.${contentLanguage}.formFields`}
                              key={contentLanguage}
                            >
                              {({ fields }) => (
                                <div className={styles['c-table-list']}>
                                  {fields.length === 0 ? (
                                    <AddIcon
                                      onClick={() =>
                                        (fields as any).insertAt(
                                          0,
                                          getDefaultFormField()
                                        )
                                      }
                                    />
                                  ) : (
                                    <>
                                      {fields.map((name, index) => (
                                        <CustomerFormField
                                          key={name}
                                          name={name}
                                          idx={index}
                                          onAddClick={() =>
                                            (fields as any).insertAt(
                                              index + 1,
                                              getDefaultFormField()
                                            )
                                          }
                                          onDeleteClick={() =>
                                            (fields as any).remove(index)
                                          }
                                        />
                                      ))}
                                    </>
                                  )}
                                </div>
                              )}
                            </FieldArray>
                          </Box>
                        </td>
                      </tr>
                      <tr>
                        <th>{t('Reservations')}</th>
                        <td>
                          <Field name="allowViewingReservationDetails">
                            {({ input }) => (
                              <Checkbox
                                labelStyle={checkboxLabelStyle}
                                label={t('View reservation details')}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                              />
                            )}
                          </Field>
                          <Field name="allowUpdatingReservationInfo">
                            {({ input }) => (
                              <Checkbox
                                labelStyle={checkboxLabelStyle}
                                label={t('Update hotel, form answers')}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                              />
                            )}
                          </Field>
                          <Field name="allowChangingReservationParameters">
                            {({ input }) => (
                              <Checkbox
                                labelStyle={checkboxLabelStyle}
                                label={t(
                                  'Change reservations (Participation date, time, participants, add-on, transportation)'
                                )}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                              />
                            )}
                          </Field>
                          <Field name="allowCancellingReservation">
                            {({ input }) => (
                              <Checkbox
                                labelStyle={checkboxLabelStyle}
                                label={t('Cancel')}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                              />
                            )}
                          </Field>
                          <Field name="allowIssuingDownloadingReceiptPDF">
                            {({ input }) => (
                              <Checkbox
                                labelStyle={checkboxLabelStyle}
                                label={t('Issue receipt/download')}
                                checked={input.value}
                                onChange={() => input.onChange(!input.value)}
                              />
                            )}
                          </Field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </FormTableBox>

                <div>
                  <div
                    className={baseStyles['base-main__box__body__bottomBtns']}
                  >
                    {submitError && (
                      <p className={baseStyles['base-form-box__err']}>
                        {submitError}
                      </p>
                    )}
                    <Button
                      disabled={submitting}
                      loading={submitting}
                      type="submit"
                      style="blue"
                      size="small"
                    >
                      {t('Save Settings')}
                    </Button>
                  </div>
                  {saveSucceeded && (
                    <Message success header={t('Settings Saved')} />
                  )}
                </div>

                {editPropName && (
                  <EditSupportedLanguageTextModal
                    name={editPropName.name}
                    title={editPropName.title}
                    open={Boolean(editPropName)}
                    onClose={() => setEditPropName(null)}
                  />
                )}
              </form>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};
