import * as React from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import * as Swagger from 'shared/models/swagger';
import { httpRequestHeadersSelector } from 'client/reducers/accounts';
import { createCancellableFetcher } from 'client/libraries/util/createCancellableFetcher';

interface DateRange {
  start: string;
  end: string;
}

export const useMapVisitorReportData = (
  dateRange: DateRange,
  selectedMapId: string,
  comparisonDateRange?: DateRange | null
): {
  data: Swagger.DigitalMapVisitorReportDataSet[] | null;
  isLoading: boolean;
  error: string;
} => {
  const fetcherRef = React.useRef(createCancellableFetcher());
  const headers = useSelector(httpRequestHeadersSelector);

  const queryParams = convertParamsToMapVisitorReportQueryParams(
    dateRange,
    comparisonDateRange
  );

  const { data: swrData, error } =
    useSWR<Swagger.GetDigitalMapVisitorReportDataResponse>(
      [
        selectedMapId
          ? `/api/digitalmaps/${selectedMapId}/visitorreportdata`
          : '',
        JSON.stringify(queryParams),
        JSON.stringify(headers),
      ],
      fetcherRef.current
    );

  React.useEffect(() => {
    // Cleanup function to abort any pending requests when the component unmounts
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      fetcherRef.current('', '', '').catch(() => {});
    };
  }, []);
  return {
    data: swrData?.sets ?? null,
    isLoading: Boolean(!error && !swrData),
    error: error,
  };
};

const convertParamsToMapVisitorReportQueryParams = (
  dateRange: DateRange,
  comparisonDateRange?: DateRange | null
): {
  date_ranges: string[];
} => {
  const ranges = [`${dateRange.start},${dateRange.end}`];
  if (comparisonDateRange) {
    ranges.push(`${comparisonDateRange.start},${comparisonDateRange.end}`);
  }

  return {
    date_ranges: ranges,
  };
};
