import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

import * as Swagger from 'shared/models/swagger';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { Button } from 'client/components/v3/Common/Button';
import { PagedGenericTable } from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import {
  deleteSiteControlWidget,
  fetchSiteControlWidgets,
} from 'client/actions/siteControlWidgets';
import { ReduxState } from 'client/reducers';
import { ColumnType } from 'client/components/v3/GenericTable/GenericTable';
import { Modal } from 'client/components/v3/Form/Modal';
import { TranslateFuncType } from 'client/components/Translate';
import { Badge, BadgeColor } from 'client/components/v3/Common/Badge';

import styles from './SiteControlWidgetList.module.css';
import { InstallEmbedCodeModal } from './InstallEmbedCodeModal';

export const EditButton = ({
  siteControlWidget,
}: {
  siteControlWidget: Swagger.SiteControlWidget;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Link
        to={`/sitecontrolwidgets/${siteControlWidget.id}/edit`}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <div className={styles['p-siteControlWidget__action']}>
          <i className="c-icon-outline-general-edit-05"></i>
          <p>{t('Edit')}</p>
        </div>
      </Link>
    </>
  );
};

export const DeleteButton = ({
  siteControlWidget,
}: {
  siteControlWidget: Swagger.SiteControlWidget;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <div
      className={clsx(styles['p-siteControlWidget__action'], styles['delete'])}
      onClick={() => {
        setShowDeleteModal(true);
      }}
    >
      <i className="c-icon-outline-general-trash-03"></i>
      <p>{t('Delete')}</p>
      {showDeleteModal && (
        <Modal
          onClose={closeModal}
          insertAtRoot
          title={t('Delete Site Hacker')}
          open={true}
          rightActionChildren={
            <>
              <Button text={t('Cancel')} color="white" onClick={closeModal} />
              <Button
                text={t('Delete')}
                onClick={async () => {
                  await dispatch(
                    deleteSiteControlWidget(siteControlWidget?.id ?? '')
                  );
                  setShowDeleteModal(false);
                }}
              />
            </>
          }
        >
          <div>
            {t('Are you sure you want to delete "{{name}}"?', {
              name: siteControlWidget.name,
            })}
          </div>
        </Modal>
      )}
    </div>
  );
};

const translateStatus = (status: string, t: TranslateFuncType) => {
  switch (status) {
    case 'DRAFT':
      return t('Draft');
    case 'ACTIVE':
      return t('Active');
    default:
      return t('Draft');
  }
};

const getBadgeColorForStatus = (status: string): BadgeColor => {
  switch (status) {
    case 'ACTIVE':
      return 'success';
    case 'DRAFT':
      return 'warning';
    default:
      return 'gray';
  }
};

const useColumns = (): ColumnType<Swagger.SiteControlWidget>[] => {
  const { t } = useTranslation();
  const widgets = useSelector(
    (state: ReduxState) => state.siteControlWidgets.all
  );

  return [
    {
      Header: t('Name'),
      id: 'name',
      accessor: (siteControlWidget: Swagger.SiteControlWidget) => (
        <div style={{ whiteSpace: 'pre-line' }}>
          {siteControlWidget.name ?? ''}
        </div>
      ),
    },
    {
      Header: t('Status'),
      id: 'status',
      accessor: (siteControlWidget: Swagger.SiteControlWidget) => (
        <Badge
          label={translateStatus(siteControlWidget.status ?? '', t)}
          color={getBadgeColorForStatus(siteControlWidget.status ?? '')}
        />
      ),
      width: 150,
    },
    {
      Header: t('View'),
      id: 'view',
      accessor: (siteControlWidget: Swagger.SiteControlWidget) => {
        const widget = widgets.find((w) => w.id === siteControlWidget.id);
        if (widget && widget.status !== 'DRAFT') {
          return (
            <div>
              <Link to={`/sitecontrolwidgets/${widget.id}/report`}>
                <a>{t('Report')}</a>
              </Link>
            </div>
          );
        }

        return '-';
      },
    },
    {
      Header: '',
      id: 'edit',
      accessor: (siteControlWidget: Swagger.SiteControlWidget) => (
        <EditButton siteControlWidget={siteControlWidget} />
      ),
      width: 200,
    },
    {
      Header: '',
      id: 'delete',
      accessor: (siteControlWidget: Swagger.SiteControlWidget) => (
        <DeleteButton siteControlWidget={siteControlWidget} />
      ),
      width: 200,
    },
  ];
};

export const SiteControlWidgetList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const columns = useColumns();
  const loading = useSelector(
    (state: ReduxState) => state.siteControlWidgets.loading
  );
  const siteControlWidgets = useSelector(
    (state: ReduxState) => state.siteControlWidgets.all
  );
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(fetchSiteControlWidgets());
  }, []);

  return (
    <V3Page>
      <PageHeader title={t('Site Hacker List')} hideNewUIToggle={true}>
        <Button
          text={t('Install Embed Code')}
          onClick={() => {
            setShowModal(true);
          }}
        />
        <Link to="/sitecontrolwidgets/new">
          <Button text={t('Create New Site Hacker')} />
        </Link>
      </PageHeader>
      <PageContent>
        <div className={styles['p-siteControlWidget']}>
          <PagedGenericTable
            loading={loading}
            columns={columns}
            allItems={siteControlWidgets}
          />
        </div>
      </PageContent>
      {showModal && (
        <InstallEmbedCodeModal
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </V3Page>
  );
};
