import * as React from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button } from 'client/components/Form';
import { Modal } from 'client/components/Modal/Modal';
import { Box } from 'client/components/Box/Box';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import baseStyles from 'client/base.module.css';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { convertToBookingWidgetUrlLangCode } from 'client/libraries/util/convertToBookingWidgetUrlLangCode';

interface Props {
  open: boolean;
  onClose: () => void;
  surveyId: string;
}

export const QrCodeModal = ({ open, onClose, surveyId }: Props) => {
  const { t, i18n } = useTranslation();
  const organization = useSelector(activeUserOrganizationSelector);
  const language = convertToBookingWidgetUrlLangCode(i18n.language);

  const surveyUrl = `${getBookingWidgetApiKeyUrl(
    organization?.booking_widget_api_key ?? ''
  )}/survey?lng=${language}&s=${surveyId}`;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('Show QR Code')}
      insertRoot={true}
    >
      <Modal.Content>
        <a
          className={baseStyles['base-link']}
          href={surveyUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {surveyUrl}
        </a>
        <Box mt={2} mb={2}>
          <QRCode value={surveyUrl} />
        </Box>
      </Modal.Content>

      <Modal.Actions>
        <Button style="gray" size="middle" onClick={onClose}>
          {t('Close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
