import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';

import { CollapsibleBox } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/CollapsibleBox';

import styles from './OptionGroupsEditor.module.css';
import { OptionGroupEditor } from './OptionGroupEditor';

const getDefaultOptionGroup = () => {
  return {
    name: '',
    key: '',
    isRequired: false,
    selectionLimit: '0',
    options: [],
  };
};

export const OptionGroupsEditor = ({ name }: { name: string }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['title']}>{t('Option Groups')}</div>
      </div>
      <FieldArray name={name}>
        {({ fields }) => (
          <>
            {fields.length !== 0 &&
              fields.map((fieldName, idx) => (
                <CollapsibleBox
                  key={fieldName}
                  title={fields.value[idx].name}
                  onDelete={() => fields.remove(idx)}
                >
                  <OptionGroupEditor name={fieldName} />
                </CollapsibleBox>
              ))}
            <a
              className={styles['add-new']}
              onClick={() =>
                (fields as any).insertAt(
                  fields.length ?? 0,
                  getDefaultOptionGroup()
                )
              }
            >
              <i className="c-icon-outline-general-plus-circle"></i>
              {t('Add Option Group')}
            </a>
          </>
        )}
      </FieldArray>
    </div>
  );
};
