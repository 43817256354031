import * as React from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import * as Swagger from 'shared/models/swagger';
import { httpRequestHeadersSelector } from 'client/reducers/accounts';
import { createCancellableFetcher } from 'client/libraries/util/createCancellableFetcher';

export const useDigitalMapGuestJourney = (
  selectedMapId: string,
  journeyId: string,
  date: string
): {
  data: Swagger.DigitalMapGuestJourney | null;
  isLoading: boolean;
  error: string;
} => {
  const fetcherRef = React.useRef(createCancellableFetcher());
  const headers = useSelector(httpRequestHeadersSelector);

  const queryParams = convertParamsToMapGuestJourneyQueryParams(date);

  const { data: swrData, error } =
    useSWR<Swagger.GetDigitalMapGuestJourneyResponse>(
      [
        selectedMapId
          ? `/api/digitalmaps/${selectedMapId}/guestjourneys/${journeyId}`
          : '',
        JSON.stringify(queryParams),
        JSON.stringify(headers),
      ],
      fetcherRef.current
    );

  React.useEffect(() => {
    // Cleanup function to abort any pending requests when the component unmounts
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      fetcherRef.current('', '', '').catch(() => {});
    };
  }, []);

  return {
    data: swrData?.guest_journey ?? null,
    isLoading: Boolean(!error && !swrData),
    error: error,
  };
};

const convertParamsToMapGuestJourneyQueryParams = (
  date: string
): {
  date: string;
} => {
  return {
    date,
  };
};
