import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DigitalMapGuestJourney } from 'shared/models/swagger';

import { getTime } from '../util';

import { SessionComponent } from './SessionComponent';
import styles from './DigitalJourneyGadget.module.css';

const padLeadingZero = (value: string) => {
  const [hours, minutes] = value.split(':');
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

interface Props {
  time: number;
  journeyData: DigitalMapGuestJourney | null;
}

export const DigitalJourneyGadget = ({ time, journeyData }: Props) => {
  const { t } = useTranslation();

  const [expandedSessionIdx, setExpandedSessionIdx] = React.useState<
    number | null
  >(null);

  const absoluteTime = getTime(
    time,
    journeyData?.sessions?.[0]?.time_of_day_start ?? '00:00'
  );

  const sessions = journeyData?.sessions ?? [];

  let highlightedSessionIdx = -1;
  for (let i = 0; i < sessions.length; i++) {
    if (
      padLeadingZero(absoluteTime) >=
        padLeadingZero(sessions[i].time_of_day_start ?? '') &&
      padLeadingZero(absoluteTime) <=
        padLeadingZero(sessions[i]?.time_of_day_end ?? '')
    ) {
      highlightedSessionIdx = i;
      break;
    }
  }

  return (
    <div className={styles['container']}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{t('Digital Journey')}</div>
          </div>
        </div>
        <div className={styles['body']}>
          <div className={styles['body-inner']}>
            {sessions.map((session, index) => (
              <SessionComponent
                sessionName={t('Session {{index}}', { index: index + 1 })}
                highlighted={highlightedSessionIdx === index}
                key={index}
                session={session}
                expanded={expandedSessionIdx === index}
                onClick={() =>
                  setExpandedSessionIdx(
                    expandedSessionIdx === index ? null : index
                  )
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
