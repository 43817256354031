import clsx from 'clsx';
import moment from 'moment-timezone';

import styles from './PackageComponentReservationTab.module.css';

interface R {
  start_date_time_utc?: string;
  reservation_id?: string;
}

interface Props {
  reservations: R[];
  value: string;
  onChange: (id: string) => void;
  startTimezone?: string;
  children?: React.ReactNode;
}

export const PackageComponentReservationTab = ({
  reservations,
  value,
  onChange,
  startTimezone,
  children,
}: Props) => {
  return (
    <div className={styles['c-pacakgeComponentReservation__customize']}>
      <div
        className={styles['c-pacakgeComponentReservation__customize__header']}
      >
        <ul
          className={
            styles['c-pacakgeComponentReservation__customize__header__tab']
          }
        >
          {reservations.map((reservation, idx) => (
            <li
              key={idx}
              className={clsx(
                value === reservation.reservation_id && styles['is-active']
              )}
              onClick={() => {
                onChange(reservation.reservation_id ?? '');
              }}
            >
              {moment
                .tz(reservation.start_date_time_utc, startTimezone ?? 'UTC')
                .format('lll')}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['c-pacakgeComponentReservation__customize__body']}>
        {children}
      </div>
    </div>
  );
};
