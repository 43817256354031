import * as React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, FieldRenderProps, Form, useForm } from 'react-final-form';
import { FalconUIRenderingContext, Section } from '@nutmeglabs/falcon-ui';

import { Edit } from 'client/components/Icons/Edit';
import { Modal } from 'client/components/Modal/Modal';
import {
  Button,
  FieldWrapper,
  Input,
  Select,
  ToggleButton,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { ProductListInput } from 'client/components/ProductListInput';
import { getBookingWidgetProductSummaries } from 'client/libraries/util/getBookingWidgetProductSummaries';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { summariesWithPartnershipProductSelector } from 'client/reducers/products';

import { SectionTitleEditor } from './SectionTitleEditor';
import { SectionBackgroundEditor } from './SectionBackgroundEditor';
import { SectionThemeColorEditor } from './SectionThemeColorEditor';

interface Props {
  name: string;
}

export const EditProductListButton = ({ name }: Props) => {
  const [showModal, setShowModal] = React.useState(false);

  const { t } = useTranslation();
  const apiProducts = useSelector(summariesWithPartnershipProductSelector);

  const org = useSelector(activeUserOrganizationSelector);
  const { language } = React.useContext(FalconUIRenderingContext);

  const allProducts = getBookingWidgetProductSummaries(
    org,
    apiProducts,
    language
  );

  const form = useForm();

  const {
    products,
    showAllProducts,
    title,
    titleStyle,
    useBackgroundImage,
    backgroundColor,
    backgroundImageUrl,
    productCardButtonText,
    productListStyle,
    themePrimaryColor,
    themeSecondaryColor,
    themeContentBackgroundColor,
  } = _.get(form.getState().values, name) as Section;

  const styleOptions: {
    value: NonNullable<Section['productListStyle']>;
    text: string;
  }[] = [
    {
      value: 'WAVY',
      text: t('1-per-row'),
    },
    {
      value: 'STAGGERED',
      text: t('2-per-row'),
    },
    {
      value: 'ROW_THREE_LARGE',
      text: t('3-per-row (Large)'),
    },
    {
      value: 'ROW_THREE_MEDIUM',
      text: t('3-per-row (Medium)'),
    },
    {
      value: 'ROW_THREE_PACIFIC',
      text: t('3-per-row (Fun)'),
    },
    {
      value: 'ROW_FOUR',
      text: t('4-per-row'),
    },
  ];

  return (
    <>
      <Edit onClick={() => setShowModal(true)} />
      {showModal && (
        <Modal
          title={t('Edit Product List')}
          onClose={() => setShowModal(false)}
          open={true}
          insertRoot
        >
          <Form
            initialValues={{
              productIds: products?.map((p) => p.productId) ?? [],
              showAllProducts: showAllProducts ?? false,
              title,
              titleStyle,
              useBackgroundImage,
              backgroundColor,
              backgroundImageUrl,
              productCardButtonText,
              productListStyle,
              themePrimaryColor,
              themeSecondaryColor,
              themeContentBackgroundColor,
            }}
            onSubmit={(values) => {
              form.change(name, {
                type: 'PRODUCT_LIST',
                showAllProducts: values.showAllProducts,
                products: showAllProducts
                  ? []
                  : values.productIds.map((productId: string) => ({
                      productId,
                    })),
                title: values.title,
                titleStyle: values.titleStyle,
                useBackgroundImage: values.useBackgroundImage,
                backgroundColor: values.backgroundColor,
                backgroundImageUrl: values.backgroundImageUrl,
                productCardButtonText: values.productCardButtonText,
                productListStyle: values.productListStyle,
                themePrimaryColor: values.themePrimaryColor,
                themeSecondaryColor: values.themeSecondaryColor,
                themeContentBackgroundColor: values.themeContentBackgroundColor,
              });
              setShowModal(false);
            }}
          >
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <SectionTitleEditor />
                  <SectionBackgroundEditor />
                  <SectionThemeColorEditor />
                  <Field name="productListStyle">
                    {({ input }: FieldRenderProps<string>) => {
                      return (
                        <Select
                          label={t('List Style')}
                          options={styleOptions}
                          value={input.value}
                          onChange={(e, { value }) => input.onChange(value)}
                        />
                      );
                    }}
                  </Field>
                  {(values?.productListStyle === 'WAVY' ||
                    values?.productListStyle === 'STAGGERED') && (
                    <Box mt={2} mb={2}>
                      <Field name="productCardButtonText">
                        {({ input }) => (
                          <Input label={t('Button Text')} {...input} />
                        )}
                      </Field>
                    </Box>
                  )}
                  <FieldWrapper label={t('Products')} />
                  <Box mb={2}>
                    <Field name="showAllProducts">
                      {({ input }) => (
                        <ToggleButton
                          label={t('Show All Products')}
                          checked={input.value}
                          onChange={() => input.onChange(!input.value)}
                        />
                      )}
                    </Field>
                  </Box>
                  {!values.showAllProducts && (
                    <Field name="productIds">
                      {({ input }) => (
                        <ProductListInput
                          label=""
                          candidateProducts={allProducts}
                          products={input.value?.map((productId: string) =>
                            allProducts.find(
                              (product) => product.id === productId
                            )
                          )}
                          onChange={(newSelectedProducts) =>
                            input.onChange(
                              newSelectedProducts.map((p) => p?.id ?? '')
                            )
                          }
                        />
                      )}
                    </Field>
                  )}
                </Modal.Content>
                <Modal.Actions>
                  <Button.Update type="submit" size="middle">
                    {t('Update')}
                  </Button.Update>
                </Modal.Actions>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};
