import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import moment from 'moment-timezone';

import { getSourceLanguageOptions } from 'client/libraries/util/getSourceLanguageOptions';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
  agentOptionsSelector,
} from 'client/reducers/user';
import { getBookingSourceOptions } from 'client/libraries/util/getBookingSourceOptions';
import { Button } from 'client/components/v3/Common/Button';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import {
  operationAllowed,
  presetSearchConditionsEnabledForAccount,
} from 'shared/models/access';
import {
  SalesStatus,
  getProductSalesStatus,
} from 'client/libraries/util/getProductSalesStatus';
import {
  summariesWithBookmarksSelector,
  supplierOptionsSelector,
} from 'client/reducers/products';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import {
  SearchReservationsRequest,
  SearchReservationsRequestKey,
} from 'client/pages/ReservationSearch/util';
import { Radio } from 'client/components/v3/Form/Radio';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import { SearchAccordion } from 'client/components/v3/ReservationList/SearchAccordion';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { SavedSearchConditionsList } from 'client/components/v3/ReservationList/SavedSearchConditionsList';
import { ReduxState } from 'client/reducers';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { useOrderByOptions } from 'client/hooks/useOrderByOptions';
import {
  DateFilterPreset,
  getDateFilterPresetOptions,
  getExpirationFilterPresetOptions,
} from 'client/libraries/util/searchReservations';
import { getPresetSearchReservationsRequest } from 'client/libraries/util/getPresetSearchReservationsRequest';

import styles from './ReservationListSearch.module.css';

type Props = {
  onReset: () => void;
  onSearch: () => void;
  searchCondition: SearchReservationsRequest;
  setSearchCondition: (arg0: SearchReservationsRequest) => void;
  title?: string;
  searchButtonText?: string;
  annualPassOnly?: boolean;
  note?: string;
};

export const ReservationListSearch = ({
  onReset,
  onSearch,
  searchCondition,
  setSearchCondition,
  title,
  searchButtonText,
  annualPassOnly,
  note,
}: Props) => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  // Initialize value for form input fields
  const orderByOptions = useOrderByOptions();

  const [productStatus, setProductStatus] = useState<SalesStatus | ''>(
    'ON_SALE'
  );
  const sourceLanguages = getSourceLanguageOptions(t);

  const expirationFilterPresetOptions = [
    {
      value: 'NONE',
      text: t('None'),
    },
    ...getExpirationFilterPresetOptions(t),
  ];
  const dateFilterPresetOptions = [
    {
      value: 'NONE',
      text: t('None'),
    },
    ...getDateFilterPresetOptions(t),
  ];

  const allProducts = useSelector(summariesWithBookmarksSelector);
  const productOptions = useMemo(() => {
    let salesStatusProducts;
    if (productStatus && presetSearchConditionsEnabledForAccount(activeUser)) {
      salesStatusProducts = allProducts.filter((product) => {
        return (
          getProductSalesStatus(product) === productStatus ||
          (searchCondition.productIds &&
            searchCondition.productIds.includes(product.id))
        );
      });
    } else {
      salesStatusProducts = allProducts;
    }

    return salesStatusProducts
      .filter((product) => {
        return (
          (annualPassOnly ?? false) ===
          (product?.annual_pass_mode_settings?.is_enabled ?? false)
        );
      })
      .map((product) => {
        return {
          value: product.id,
          key: product.id,
          text: presetSearchConditionsEnabledForAccount(activeUser)
            ? `${
                product.internal_product_name || product.product_name || ''
              }  (${product.supplier_reference})`
            : getVerboseDisplayProductName(product),
        };
      });
  }, [allProducts, productStatus]);

  const bookingStatusOptions = getBookingStatusOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const bookingSourceOptions = getBookingSourceOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const agentOptions = useSelector(agentOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const groupBookingTemplates = useSelector(
    (state: ReduxState) => state.groupBookingTemplates.all
  );
  const groupOptions = groupBookingTemplates.map((groupBookingTemplate) => ({
    value: groupBookingTemplate.id ?? '',
    text: groupBookingTemplate.name ?? '',
  }));

  const supplierOptions = useSelector(supplierOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });

  const waiverStatusOptions = [
    {
      value: 'NOT_APPLICABLE',
      text: t('Waiver Not Required'),
    },
    {
      value: 'UNSIGNED',
      text: t('Not Started'),
    },
    {
      value: 'PARTIALLY_SIGNED',
      text: t('Partially Complete'),
    },
    {
      value: 'COMPLETED',
      text: t('Completed'),
    },
  ];

  const checkinStatusOptions = [
    { value: 'NOT_CHECKED_IN', text: t('Checkin Not Started') },
    { value: 'IN_PROGRESS', text: t('Partially Checked-in') },
    { value: 'CHECKED_IN', text: t('Checkin Complete') },
  ];

  // Handlers when form input fields are changed
  const changePreset = (preset: string) => {
    const presetSearchCondition = getPresetSearchReservationsRequest(
      activeUserOrganization,
      preset,
      moment()
    );
    if (presetSearchCondition) {
      setSearchCondition(presetSearchCondition);
      // When preset is selected, make it as if search button is clicked
      onSearch();
    }
  };

  const createDropdownChangeHandler =
    (field: SearchReservationsRequestKey) => (selectedOption: string) =>
      setSearchCondition({ ...searchCondition, [field]: selectedOption });
  const createMultiDropdownChangeHandler =
    (field: SearchReservationsRequestKey) => (selectedOptions: string[]) =>
      setSearchCondition({ ...searchCondition, [field]: [...selectedOptions] });
  const createFieldChangeHandler =
    (field: SearchReservationsRequestKey) => (value: string) =>
      setSearchCondition({ ...searchCondition, [field]: value });
  const createDateRangeInputChangeHandler =
    (
      dateFromField: SearchReservationsRequestKey,
      dateToField: SearchReservationsRequestKey
    ) =>
    (dates: [string | null, string | null]) => {
      const [startDate, endDate] = dates;
      setSearchCondition({
        ...searchCondition,
        [dateFromField]: startDate,
        [dateToField]: endDate,
        dateFilterPreset: null,
      });
    };

  const changeOrderBy = createDropdownChangeHandler('orderBy');
  const changeProductId = createMultiDropdownChangeHandler('productIds');
  const changeParticipationDate = createDateRangeInputChangeHandler(
    'participationDateFrom',
    'participationDateTo'
  );
  const changeBookedDate = createDateRangeInputChangeHandler(
    'bookedDateFrom',
    'bookedDateTo'
  );
  const changeLastUpdatedDate = createDateRangeInputChangeHandler(
    'lastUpdatedDateFrom',
    'lastUpdatedDateTo'
  );
  const changeExpirationDate = createDateRangeInputChangeHandler(
    'expirationDateFrom',
    'expirationDateTo'
  );
  const changeReservationStatus = createMultiDropdownChangeHandler('statuses');
  const changeSupplierOrAgentReference = createFieldChangeHandler(
    'supplierOrAgentReference'
  );
  const changeAgentReference = createFieldChangeHandler('agentReference');
  const changeSupplierReference = createFieldChangeHandler('supplierReference');
  const changeCustomerGivenName = createFieldChangeHandler('customerGivenName');
  const changeCustomerFamilyName =
    createFieldChangeHandler('customerFamilyName');
  const changeCustomerEmail = createFieldChangeHandler('customerEmail');
  const changeCustomerPhone = createFieldChangeHandler('customerPhone');
  const changeBookingSource =
    createMultiDropdownChangeHandler('bookingSourceTypes');
  const changeAgentIds = createMultiDropdownChangeHandler('agentIds');
  const changeGroupIds = createMultiDropdownChangeHandler('groupIds');
  const changeSupplierIds = createMultiDropdownChangeHandler('supplierIds');
  const changeReservationLanguage = createMultiDropdownChangeHandler(
    'reservationLanguages'
  );
  const toggleMostRecentEmailBounced = () => {
    setSearchCondition({
      ...searchCondition,
      mostRecentEmailBounced: !searchCondition.mostRecentEmailBounced,
    });
  };
  const changePickupCheckinLocationName = createFieldChangeHandler(
    'pickupCheckinLocationName'
  );
  const changeWaiverCompletionStatuses = createMultiDropdownChangeHandler(
    'waiverCompletionStatuses'
  );
  const changeCheckinStatuses =
    createMultiDropdownChangeHandler('checkinStatuses');

  return (
    <section className={styles['g-section']}>
      <div className={styles['p-search']}>
        <div className={styles['p-search__header']}>
          <h2 className={styles['p-search__header__title']}>
            {title || t('Search Conditions')}
          </h2>

          {!annualPassOnly && (
            <>
              {presetSearchConditionsEnabledForAccount(activeUser) &&
                (
                  activeUserOrganization?.account_reservation_search_settings
                    ?.presets ?? []
                ).length > 0 && (
                  <div className={styles['p-search__header__actions']}>
                    <SavedSearchConditionsList
                      selectedOption={searchCondition.presetKey}
                      onChange={changePreset}
                    />
                  </div>
                )}
            </>
          )}
        </div>
        <div className={styles['p-search__body']}>
          <div className={styles['p-search__body__display']}>
            {/* TODO: this functionality is not yet implemented in PROD, so we hide for now */}
            {/* <div className={styles['p-search__body__item']}>
              <SearchKeyword
                label={t('Keyword')}
                searchResults={[
                  {
                    reservationStatus: '予約確定',
                    applicationNumber: 'MM245DX',
                    productName: 'サンプル商品1',
                    guestName: 'ナツメグイチロウ',
                    participationDateTime: '2024/04/07',
                  },
                ]}
              />
            </div> */}

            <div className={styles['p-search__body__item']}>
              <SingleDropdown
                label={t('Order By')}
                options={orderByOptions}
                selectedOption={searchCondition.orderBy}
                onChange={changeOrderBy}
              />
            </div>

            {presetSearchConditionsEnabledForAccount(activeUser) ? (
              <>
                <div className={styles['p-search__body__item']}>
                  <div className={styles['p-search__body__item__flex']}>
                    <div className={styles['p-search__body__item__radiotitle']}>
                      {t('Product Status')}
                    </div>
                    <Radio
                      label={t('On Sale')}
                      checked={productStatus === 'ON_SALE'}
                      onChange={() => {
                        setProductStatus('ON_SALE');
                      }}
                      size="sm"
                    />
                    <Radio
                      label={t('Not On Sale')}
                      checked={productStatus === 'LIST_ONLY'}
                      onChange={() => {
                        setProductStatus('LIST_ONLY');
                      }}
                      size="sm"
                    />
                    <Radio
                      label={t('Not Listed')}
                      checked={productStatus === 'NOT_LISTED'}
                      onChange={() => {
                        setProductStatus('NOT_LISTED');
                      }}
                      size="sm"
                    />
                  </div>
                </div>
                <div className={styles['p-search__body__item']}>
                  <MultiDropdown
                    label={t('Product')}
                    options={productOptions}
                    onChange={changeProductId}
                    selectedOptions={searchCondition.productIds}
                    searchable={true}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={styles['p-search__body__item']}>
                  <MultiDropdown
                    label={t('Product')}
                    options={productOptions}
                    onChange={changeProductId}
                    selectedOptions={searchCondition.productIds}
                    searchable={true}
                  />
                </div>
              </>
            )}
          </div>

          <SearchAccordion>
            <div className={styles['p-search__body__item']}>
              {annualPassOnly ? (
                <>
                  <SingleDropdown
                    label={t('Date Preset')}
                    options={expirationFilterPresetOptions}
                    selectedOption={
                      searchCondition.expirationDateFilterPreset ?? 'NONE'
                    }
                    onChange={(value) => {
                      setSearchCondition({
                        ...searchCondition,
                        expirationDateFilterPreset:
                          value === 'NONE' ? null : (value as DateFilterPreset),
                        expirationDateFrom: '',
                        expirationDateTo: '',
                      });
                    }}
                  />
                </>
              ) : (
                <>
                  <SingleDropdown
                    label={t('Date Preset')}
                    options={dateFilterPresetOptions}
                    selectedOption={searchCondition.dateFilterPreset ?? 'NONE'}
                    onChange={(value) => {
                      setSearchCondition({
                        ...searchCondition,
                        dateFilterPreset:
                          value === 'NONE' ? null : (value as DateFilterPreset),
                        lastUpdatedDateFrom: '',
                        lastUpdatedDateTo: '',
                        bookedDateFrom: '',
                        bookedDateTo: '',
                        participationDateFrom: '',
                        participationDateTo: '',
                      });
                    }}
                  />
                </>
              )}
            </div>

            {annualPassOnly ? (
              <div className={styles['p-search__body__item']}>
                <DateRangeInput
                  label={t('Expiration Date')}
                  onChange={changeExpirationDate}
                  dateFrom={searchCondition.expirationDateFrom}
                  dateTo={searchCondition.expirationDateTo}
                />
              </div>
            ) : (
              <div className={styles['p-search__body__item']}>
                <DateRangeInput
                  label={t('Participation Date')}
                  onChange={changeParticipationDate}
                  dateFrom={searchCondition.participationDateFrom}
                  dateTo={searchCondition.participationDateTo}
                />
                <DateRangeInput
                  label={t('Booked Date')}
                  onChange={changeBookedDate}
                  dateFrom={searchCondition.bookedDateFrom}
                  dateTo={searchCondition.bookedDateTo}
                />
                <DateRangeInput
                  label={t('Last Updated Date')}
                  onChange={changeLastUpdatedDate}
                  dateFrom={searchCondition.lastUpdatedDateFrom}
                  dateTo={searchCondition.lastUpdatedDateTo}
                />
              </div>
            )}

            {presetSearchConditionsEnabledForAccount(activeUser) ? (
              <>
                <div className={styles['p-search__body__item']}>
                  <MultiDropdown
                    label={t('Booking Status')}
                    options={bookingStatusOptions}
                    onChange={changeReservationStatus}
                    selectedOptions={searchCondition.statuses}
                  />
                  <TextField
                    label={t('Confirmation/Application Number')}
                    onChange={changeSupplierOrAgentReference}
                    value={searchCondition.supplierOrAgentReference}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={styles['p-search__body__item']}>
                  <MultiDropdown
                    label={t('Booking Status')}
                    options={bookingStatusOptions}
                    onChange={changeReservationStatus}
                    selectedOptions={searchCondition.statuses}
                  />
                </div>
                <div className={styles['p-search__body__item']}>
                  <TextField
                    label={t('Application Number')}
                    onChange={changeAgentReference}
                    value={searchCondition.agentReference}
                  />
                  <TextField
                    label={t('Confirmation Number')}
                    onChange={changeSupplierReference}
                    value={searchCondition.supplierReference}
                  />
                  <TextField
                    label="#"
                    onChange={changeSupplierOrAgentReference}
                    value={searchCondition.id}
                  />
                </div>
              </>
            )}

            <div className={styles['p-search__body__item']}>
              {locale === 'ja' ? (
                <>
                  <TextField
                    label={t('Family Name')}
                    onChange={changeCustomerFamilyName}
                    value={searchCondition.customerFamilyName}
                  />
                  <TextField
                    label={t('Given Name')}
                    onChange={changeCustomerGivenName}
                    value={searchCondition.customerGivenName}
                  />
                </>
              ) : (
                <>
                  <TextField
                    label={t('Given Name')}
                    onChange={changeCustomerGivenName}
                    value={searchCondition.customerGivenName}
                  />
                  <TextField
                    label={t('Family Name')}
                    onChange={changeCustomerFamilyName}
                    value={searchCondition.customerFamilyName}
                  />
                </>
              )}
              <TextField
                type="email"
                label={t('Email')}
                onChange={changeCustomerEmail}
                value={searchCondition.customerEmail}
              />
              <TextField
                label={t('Phone')}
                onChange={changeCustomerPhone}
                value={searchCondition.customerPhone}
              />
            </div>
            <div className={styles['p-search__body__item']}>
              {operationAllowed(
                activeUser,
                'write',
                'reservationConfirmation'
              ) ? (
                <>
                  <MultiDropdown
                    label={t('Booking Source')}
                    options={bookingSourceOptions}
                    onChange={changeBookingSource}
                    selectedOptions={searchCondition.bookingSourceTypes}
                  />
                  <MultiDropdown
                    label={t('Agent')}
                    options={agentOptions}
                    onChange={changeAgentIds}
                    selectedOptions={searchCondition.agentIds}
                    searchable={true}
                  />
                  <MultiDropdown
                    label={t('Group')}
                    options={groupOptions}
                    onChange={changeGroupIds}
                    selectedOptions={searchCondition.groupIds}
                    searchable={true}
                  />
                </>
              ) : (
                <>
                  <MultiDropdown
                    label={t('Supplier')}
                    options={supplierOptions}
                    onChange={changeSupplierIds}
                    selectedOptions={searchCondition.supplierIds}
                    searchable={true}
                  />
                </>
              )}
            </div>

            <>
              {presetSearchConditionsEnabledForAccount(activeUser) && (
                <>
                  <div className={styles['p-search__body__item']}>
                    <MultiDropdown
                      label={t('Languages')}
                      options={sourceLanguages}
                      onChange={changeReservationLanguage}
                      selectedOptions={searchCondition.reservationLanguages}
                    />
                  </div>
                  <div className={styles['p-search__body__item']}>
                    <Checkbox
                      label={t('Error when sending emails')}
                      checked={searchCondition.mostRecentEmailBounced}
                      onChange={toggleMostRecentEmailBounced}
                      size="sm"
                    />
                  </div>
                </>
              )}
            </>

            <div className={styles['p-search__body__item']}>
              <>
                <div className={styles['p-search__body__item__flex']}>
                  <div className={styles['p-search__body__item__radiotitle']}>
                    {t('Automatic Continuing Status')}
                  </div>
                  <Radio
                    label={t('None')}
                    name="productStatus"
                    checked={!searchCondition.automaticContinuingStatus}
                    onChange={() => {
                      setSearchCondition({
                        ...searchCondition,
                        automaticContinuingStatus: null,
                      });
                    }}
                    size="sm"
                  />
                  <Radio
                    label={t('ON')}
                    name="productStatus"
                    checked={searchCondition.automaticContinuingStatus === 'ON'}
                    onChange={() => {
                      setSearchCondition({
                        ...searchCondition,
                        automaticContinuingStatus: 'ON',
                      });
                    }}
                    size="sm"
                  />
                  <Radio
                    label={t('OFF')}
                    name="productStatus"
                    checked={
                      searchCondition.automaticContinuingStatus === 'OFF'
                    }
                    onChange={() => {
                      setSearchCondition({
                        ...searchCondition,
                        automaticContinuingStatus: 'OFF',
                      });
                    }}
                    size="sm"
                  />
                </div>
              </>
            </div>

            <div className={styles['p-search__body__item']}>
              {!annualPassOnly && (
                <TextField
                  label={t('Pickup/Checkin Location')}
                  onChange={changePickupCheckinLocationName}
                  value={searchCondition.pickupCheckinLocationName}
                />
              )}

              {hasSubscription(
                activeUserOrganization,
                'feature-digital-waiver'
              ) &&
                !isSubscriptionCancelled(
                  activeUserOrganization,
                  'feature-digital-waiver'
                ) && (
                  <MultiDropdown
                    label={t('Waiver Status')}
                    options={waiverStatusOptions}
                    onChange={changeWaiverCompletionStatuses}
                    selectedOptions={searchCondition.waiverCompletionStatuses}
                  />
                )}

              {(hasSubscription(activeUserOrganization, 'feature-qr-checkin') ||
                hasSubscription(
                  activeUserOrganization,
                  'feature-e-ticket'
                )) && (
                <MultiDropdown
                  label={t('Checkin Status')}
                  options={checkinStatusOptions}
                  onChange={changeCheckinStatuses}
                  selectedOptions={searchCondition.checkinStatuses}
                />
              )}
            </div>

            <>
              {note && (
                <div className={styles['p-search__body__item']}>{note}</div>
              )}
            </>
          </SearchAccordion>
        </div>
        <div className={clsx(styles['p-search__bottom'], styles['center'])}>
          <Button
            text={t('Clear conditions')}
            uiType="bg"
            size="md"
            color="tertiarygray"
            onClick={onReset}
          />
          <Button
            type="submit"
            text={searchButtonText || t('Search reservations')}
            uiType="bg"
            size="md"
            color="primary"
            onClick={onSearch}
          />
        </div>
      </div>
    </section>
  );
};
