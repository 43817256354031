import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { Box } from 'client/components/Box/Box';
import { PagedGenericTable } from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import { ColumnType } from 'client/components/v3/GenericTable/GenericTable';
import { Button } from 'client/components/v3/Common/Button';
import { Badge, BadgeColor } from 'client/components/v3/Common/Badge';
import { TranslateFuncType } from 'client/components/Translate';
import {
  fetchSupplierSettings,
  putSupplierSettings,
} from 'client/actions/supplierSettings';
import { journeyAnalyticsAutoTagSelector } from 'client/reducers/supplierSettings';
import * as Swagger from 'shared/models/swagger';
import { ReduxState } from 'client/reducers';
import { Modal } from 'client/components/v3/Form/Modal';

import styles from './JourneyAnalyticsSettings.module.css';
import { EditTagModal } from './EditTagModal';

type SwaggerAutotag = Exclude<
  Swagger.JourneyAnalyticsSettings['autotags'],
  undefined
>[0];

type SwaggerAutotagConditionGroup = Exclude<
  SwaggerAutotag['condition_groups'],
  undefined
>[0];

const getTimePeriodText = (
  conditionGroup: SwaggerAutotagConditionGroup,
  t: TranslateFuncType
) => {
  switch (conditionGroup.date_range_preset) {
    case 'LAST_24_HOURS':
      return t('Last 24 Hours');
    case 'LAST_1_WEEK':
      return t('Last 1 Week');
    case 'LAST_4_WEEKS':
      return t('Last 4 Weeks');
    case 'CUSTOM':
      return `${conditionGroup.date_range?.start_date} - ${conditionGroup.date_range?.end_date}`;
  }

  return '';
};

const getDimensionText = (
  conditionGroup: SwaggerAutotagConditionGroup,
  t: TranslateFuncType
) => {
  switch (conditionGroup.dimension_type) {
    case 'PAGE_VIEW_COUNT_GTE':
      return `${t('Page Views:')} ${t('{{pageViewLowerBound}} or more', {
        pageViewLowerBound:
          conditionGroup.dimension_comparison_value?.lower_bound,
      })}`;
    case 'PAGE_VIEW_COUNT_LT':
      return `${t('Page Views:')} ${t('Less than {{pageViewUpperBound}}', {
        pageViewUpperBound:
          conditionGroup.dimension_comparison_value?.upper_bound,
      })}`;
    case 'PAGE_VIEW_COUNT_BETWEEN':
      return `${t('Page Views:')} ${t(
        'Between {{pageViewLowerBound}} and {{pageViewUpperBound}}',
        {
          pageViewLowerBound:
            conditionGroup.dimension_comparison_value?.lower_bound,
          pageViewUpperBound:
            conditionGroup.dimension_comparison_value?.upper_bound,
        }
      )}`;
    case 'SESSION_COUNT_GTE':
      return `${t('Sessions:')} ${t('{{sessionLowerBound}} or more', {
        sessionLowerBound:
          conditionGroup.dimension_comparison_value?.lower_bound,
      })}`;
    case 'SESSION_COUNT_LT':
      return `${t('Sessions:')} ${t('Less than {{sessionUpperBound}}', {
        sessionUpperBound:
          conditionGroup.dimension_comparison_value?.upper_bound,
      })}`;
    case 'SESSION_COUNT_BETWEEN':
      return `${t('Sessions:')} ${t(
        'Between {{sessionLowerBound}} and {{sessionUpperBound}}',
        {
          sessionLowerBound:
            conditionGroup.dimension_comparison_value?.lower_bound,
          sessionUpperBound:
            conditionGroup.dimension_comparison_value?.upper_bound,
        }
      )}`;
    case 'TOTAL_TIME_SECONDS_GTE':
      return `${t('Time Spent:')} ${t('{{timeLowerBound}} or more', {
        timeLowerBound: conditionGroup.dimension_comparison_value?.lower_bound,
      })}`;
    case 'TOTAL_TIME_SECONDS_LT':
      return `${t('Time Spent:')} ${t('Less than {{timeUpperBound}}', {
        timeUpperBound: conditionGroup.dimension_comparison_value?.upper_bound,
      })}`;
    case 'TOTAL_TIME_SECONDS_BETWEEN':
      return `${t('Time Spent:')} ${t(
        'Between {{timeLowerBound}} and {{timeUpperBound}}',
        {
          timeLowerBound:
            conditionGroup.dimension_comparison_value?.lower_bound,
          timeUpperBound:
            conditionGroup.dimension_comparison_value?.upper_bound,
        }
      )}`;
  }

  return '';
};

const getConditionGroupDescription = (
  conditionGroup: SwaggerAutotagConditionGroup,
  t: TranslateFuncType
): string => {
  const conditionStrings: string[] = [];

  if (conditionGroup.date_range_preset !== '') {
    conditionStrings.push(getTimePeriodText(conditionGroup, t));
  }

  if (conditionGroup.classification === 'BOOKED') {
    conditionStrings.push(t('Booked'));
  }
  if (conditionGroup.classification === 'NOT_BOOKED') {
    conditionStrings.push(t('Not Booked'));
  }

  switch (conditionGroup.gender) {
    case 'MALE':
      conditionStrings.push(t('Male'));
      break;
    case 'FEMALE':
      conditionStrings.push(t('Female'));
      break;
    case 'NO_RESPONSE':
      conditionStrings.push(t('No Response'));
      break;
  }

  switch (conditionGroup.age_group) {
    case 'UNDER_18':
      conditionStrings.push(
        t('Age: {{ageRange}}', {
          ageRange: '~ 18',
        })
      );
      break;
    case '18_TO_24':
      conditionStrings.push(
        t('Age: {{ageRange}}', {
          ageRange: '18 ~ 24',
        })
      );
      break;
    case '25_TO_34':
      conditionStrings.push(
        t('Age: {{ageRange}}', {
          ageRange: '25 to 34',
        })
      );
      break;
    case '35_TO_44':
      conditionStrings.push(
        t('Age: {{ageRange}}', {
          ageRange: '35 to 44',
        })
      );
      break;
    case '45_TO_54':
      conditionStrings.push(
        t('Age: {{ageRange}}', {
          ageRange: '45 to 54',
        })
      );
      break;
    case '55_TO_64':
      conditionStrings.push(
        t('Age: {{ageRange}}', {
          ageRange: '55 to 64',
        })
      );
      break;
    case '65_PLUS':
      conditionStrings.push(
        t('Age: {{ageRange}}', {
          ageRange: '65+',
        })
      );
      break;
    case 'CUSTOM':
      return t('Age: {{ageRange}}', {
        ageRange: `${conditionGroup.age_range?.lower_bound} ~ ${conditionGroup.age_range?.upper_bound}`,
      });
  }

  switch (conditionGroup.device) {
    case 'DESKTOP':
      conditionStrings.push(t('Desktop'));
      break;
    case 'MOBILE':
      conditionStrings.push(t('Mobile'));
      break;
    case 'TABLET':
      conditionStrings.push(t('Tablet'));
      break;
  }

  if ((conditionGroup.referral_paths?.length ?? 0) > 0) {
    conditionStrings.push(
      t('Landing Source: {{path}}', {
        path: conditionGroup.referral_paths?.join(', '),
      })
    );
  }
  if (conditionGroup.dimension_type) {
    conditionStrings.push(getDimensionText(conditionGroup, t));
  }

  return conditionStrings.join(', ');
};

const getConditionDescription = (
  tag: SwaggerAutotag,
  t: TranslateFuncType
): string => {
  return (
    tag.condition_groups
      ?.map((group) => getConditionGroupDescription(group, t))
      .join(' OR ') ?? ''
  );
};

export const Edit = ({ tag }: { tag: SwaggerAutotag }) => {
  const [showEditTagModal, setShowEditTagModal] = React.useState(false);

  const tags = useSelector(journeyAnalyticsAutoTagSelector);

  const tagIdx = tags.findIndex((t) => t.tag_name === tag.tag_name);

  const { t } = useTranslation();

  return (
    <div>
      <div
        className={styles['p-availability__select__menu__item']}
        onClick={() => {
          setShowEditTagModal(true);
        }}
      >
        <i className="c-icon-outline-general-edit-05"></i>
        <p>{t('Edit')}</p>
      </div>
      {showEditTagModal && (
        <EditTagModal
          tagIdx={tagIdx}
          onClose={() => setShowEditTagModal(false)}
        />
      )}
    </div>
  );
};

export const Delete = ({ tag }: { tag: SwaggerAutotag }) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const dispatch = useDispatch();

  const tags = useSelector(journeyAnalyticsAutoTagSelector);
  const existingSettings = useSelector(
    (state: ReduxState) => state.supplierSettings.journeyAnalytics
  );

  const { t } = useTranslation();

  return (
    <div>
      <div
        className={clsx(
          styles['p-availability__select__menu__item'],
          styles['red']
        )}
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <i className="c-icon-outline-general-trash-03"></i>
        <p>{t('Delete')}</p>
      </div>
      {showDeleteModal && (
        <Modal
          onClose={() => setShowDeleteModal(false)}
          insertAtRoot
          title={t('Delete Tag')}
          open={true}
          rightActionChildren={
            <>
              <Button
                text={t('Cancel')}
                size="md"
                color="white"
                onClick={() => {
                  setShowDeleteModal(false);
                }}
              />
              <Button
                text={t('Delete')}
                onClick={async () => {
                  await dispatch(
                    putSupplierSettings('JOURNEY_ANALYTICS', {
                      journey_analytics_settings: {
                        ...existingSettings,
                        autotags: tags.filter(
                          (t) => t.tag_name !== tag.tag_name
                        ),
                      },
                    })
                  );
                  setShowDeleteModal(false);
                }}
              />
            </>
          }
        >
          <div>
            {t('Are you sure you want to delete "{{tagName}}"?', {
              tagName: tag.tag_name,
            })}
          </div>
        </Modal>
      )}
    </div>
  );
};

const useColumns = (): ColumnType<SwaggerAutotag>[] => {
  const { t } = useTranslation();

  return [
    {
      Header: t('Tag'),
      id: 'tag',
      width: 200,
      accessor: (tag) => (
        <Badge color={tag.tag_color as BadgeColor} label={tag.tag_name ?? ''} />
      ),
    },
    {
      Header: t('Condition'),
      id: 'condition',
      accessor: (tag) => getConditionDescription(tag, t),
    },
    {
      Header: '',
      id: 'edit',
      width: 200,
      accessor: (tag) => <Edit tag={tag} />,
    },
    {
      Header: '',
      id: 'delete',
      width: 200,
      accessor: (tag) => <Delete tag={tag} />,
    },
  ];
};

export const JourneyAnalyticsSettings = () => {
  const { t } = useTranslation();
  const [editingTagIdx, setEditingTagIdx] = React.useState<number | null>(null);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchSupplierSettings('JOURNEY_ANALYTICS'));
  }, []);

  const autotags = useSelector(journeyAnalyticsAutoTagSelector);

  const columns = useColumns();

  const loading = useSelector(
    (state: ReduxState) => state.supplierSettings.loading
  );

  return (
    <V3Page>
      <PageHeader
        title={t('Settings')}
        description={t('Set tags to display for customers')}
        hideNewUIToggle={true}
      />
      <PageContent>
        <Box mt={4}>
          <PagedGenericTable
            columns={columns}
            allItems={autotags}
            hideScrollButtons
            headerBar={
              <div className={styles['header-bar']}>
                <div className={styles['buttons']}>
                  <Button
                    onClick={() => setEditingTagIdx(autotags.length)}
                    text={t('Create Tag')}
                    uiType="bg"
                    size="md"
                    color="primary"
                  />
                </div>
              </div>
            }
            loading={loading}
          />
        </Box>
      </PageContent>
      {editingTagIdx != null && (
        <EditTagModal
          onClose={() => setEditingTagIdx(null)}
          tagIdx={editingTagIdx}
        />
      )}
    </V3Page>
  );
};
