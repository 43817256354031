import * as React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CustomWaitTime.module.css';

interface CustomWaitTimeProps {
  onSubmit: (waitTime: number) => void;
  onClose: () => void;
}

const CustomWaitTime: React.FC<CustomWaitTimeProps> = ({
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();
  const modalRef = React.useRef<HTMLDivElement>(null);
  const [waitTime, setWaitTime] = React.useState<string>('');

  // On click outside, close the modal
  React.useEffect(() => {
    const handleOutsideClick = ({ target }: Event) => {
      if (target instanceof Node && !modalRef?.current?.contains(target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick, {
      capture: true,
    });
    document.addEventListener('touchstart', handleOutsideClick, {
      capture: true,
    });
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, {
        capture: true,
      });
      document.removeEventListener('touchstart', handleOutsideClick, {
        capture: true,
      });
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const time = parseInt(waitTime, 10);
    if (!isNaN(time)) {
      onSubmit(time);
    }
  };

  return (
    <div className={styles.overlay}>
      <section className={styles.container} ref={modalRef}>
        <h2 className={styles.title}>{t('Custom Wait Time')}</h2>
        <p className={styles.subtitle}>{t('Please enter custom wait time.')}</p>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputWrapper}>
            <label
              htmlFor="waitTimeInput"
              className={styles['visually-hidden']}
            >
              {t('Enter wait time in minutes')}
            </label>
            <input
              id="waitTimeInput"
              className={styles.input}
              type="number"
              value={waitTime}
              onChange={(e) => setWaitTime(e.target.value)}
              aria-label="Enter wait time in minutes"
            />
            <span className={styles.unit}>{t('min')}</span>
          </div>
          <div className={styles.buttonWrapper}>
            <button type="submit" className={styles.button}>
              {t('OK')}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default CustomWaitTime;
