import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { config } from 'client/config';
import {
  loggedInAccountIsNutmegAdminSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { Button } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import type { SubscriptionKey } from 'shared/models/swagger';
import styles from 'client/pages/v3/SystemFee/SystemFeeSettings/SystemFeeSettings.module.css';

import { SubscriptionCancelModal } from './SubscriptionCancelModal';
import { AddSubscriptionModal } from './AddSubscriptionModal';
import { SubscriptionDisplay } from './SubscriptionDisplay';
import { SubscriptionPriceDisplay } from './SubscriptionPriceDisplay';

export const SubscriptionsEditor = () => {
  const { t } = useTranslation();
  const [subscriptionIdToCancel, setSubscriptionIdToCancel] =
    React.useState('');
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const hasPaymentMethod =
    activeUserOrganization?.payment_profile_card_info != null;

  const isNutmegAdmin = useSelector(loggedInAccountIsNutmegAdminSelector);

  interface SubscriptionTypeItem {
    key: SubscriptionKey;
    title: string;
  }
  const subscriptionTypes: SubscriptionTypeItem[] = [
    {
      key: 'feature-review',
      title: t('Feature: Reviews'),
    },
    {
      key: 'feature-email-customization' as SubscriptionKey,
      title: t('Feature: Email Customization'),
    },
    {
      key: 'feature-digital-waiver' as SubscriptionKey,
      title: t('Feature: Digital Waiver'),
    },
    {
      key: 'feature-qr-checkin' as SubscriptionKey,
      title: t('Feature: QR Checkin'),
    },
    {
      key: 'feature-guest-my-page' as SubscriptionKey,
      title: t('Feature: My Page for Guest'),
    },
    {
      key: 'feature-guide-account' as SubscriptionKey,
      title: t('Feature: Shift Management'),
    },
    {
      key: 'feature-image-download' as SubscriptionKey,
      title: t('Feature: Image Download'),
    },

    {
      key: 'feature-digital-guidance' as SubscriptionKey,
      title: t('Feature: Digital Guidance'),
    },
    {
      key: 'feature-inquiry' as SubscriptionKey,
      title: t('Feature: Inquiry'),
    },
    {
      key: 'feature-e-ticket' as SubscriptionKey,
      title: t('Feature: E-ticket'),
    },
    {
      key: 'feature-resource-management' as SubscriptionKey,
      title: t('Feature: Resource Management'),
    },
    ...(config.enableResourceManagementVehicle
      ? [
          {
            key: 'feature-vehicle-crew-resource-management' as SubscriptionKey,
            title: t('Feature: Vehicle, Crew, Resource Management'),
          },
        ]
      : []),
    {
      key: 'feature-survey' as SubscriptionKey,
      title: t('Feature: Survey'),
    },
    {
      key: 'feature-customer-ledger' as SubscriptionKey,
      title: t('Feature: Customer Membership & Newsletter'),
    },
    ...(config.enableSeatManagement
      ? [
          {
            key: 'feature-seat-management' as SubscriptionKey,
            title: t('Feature: Seat Management'),
          },
        ]
      : []),
    {
      key: 'feature-custom-top-page' as SubscriptionKey,
      title: t('Feature: Custom TOP Page'),
    },
    ...(config.enableChannelManager
      ? [
          {
            key: 'feature-channel-manager' as SubscriptionKey,
            title: t('Feature: Channel Manager'),
          },
        ]
      : []),
    {
      key: 'feature-annual-pass' as SubscriptionKey,
      title: t('Feature: Annual Pass'),
    },
    {
      key: 'feature-journey-analytics' as SubscriptionKey,
      title: t('Feature: Journey Analytics'),
    },
    {
      key: 'feature-marketing-automation' as SubscriptionKey,
      title: t('Feature: Marketing Automation'),
    },
    ...(isNutmegAdmin
      ? [
          {
            key: 'feature-digital-map' as SubscriptionKey,
            title: t('Feature: Map'),
          },
        ]
      : []),
    ...(isNutmegAdmin
      ? [
          {
            key: 'feature-group-ticketing' as SubscriptionKey,
            title: t('Feature: Group Ticketing'),
          },
        ]
      : []),
    ...(isNutmegAdmin && config.enableSiteControlWidget
      ? [
          {
            key: 'feature-site-hacker' as SubscriptionKey,
            title: t('Feature: Site Hacker'),
          },
        ]
      : []),
    {
      key: 'standard-plan' as SubscriptionKey,
      title: t('Standard Plan: Private Marketplace and Marketing Automation'),
    },
  ];
  const isLegacyStandardPlanSubscriber =
    (activeUserOrganization?.private_marketplaces?.length ?? 0) > 0 &&
    !activeUserOrganization?.subscriptions?.find(
      (subscription) => subscription.key === 'standard-plan'
    );
  return (
    <>
      <ul className={styles['p-general-list']}>
        {subscriptionTypes.map((subscriptionType) => {
          const existingSubscription =
            activeUserOrganization?.subscriptions?.find(
              (subscription) => subscription.key === subscriptionType.key
            );
          const isMonitorSubscription =
            existingSubscription?.current_period_end_date_time_utc &&
            existingSubscription?.current_period_end_date_time_utc >
              moment().add(1, 'years').format();

          return (
            <li
              key={`${subscriptionType.key}:${existingSubscription?.id}`}
              className={styles['p-general-list__item']}
            >
              <div className={styles['p-general-list__item__ttl']}>
                <div className={styles['p-general-list__item__ttl__txt']}>
                  <div>{subscriptionType.title}</div>
                </div>
              </div>
              <div className={styles['p-general-list__item__body']}>
                <div
                  className={styles['p-general-products__section__body__item']}
                >
                  {isMonitorSubscription &&
                  existingSubscription?.status !== 'CANCELED' ? (
                    <>
                      <Box mt={2} mb={2}>
                        {t(
                          'Please contact support@nutmeglabs.com for details about your subscription.'
                        )}
                        {isNutmegAdmin && (
                          <>
                            <Button
                              style="green"
                              size="middle"
                              onClick={() =>
                                setSubscriptionIdToCancel(
                                  existingSubscription.id ?? ''
                                )
                              }
                            >
                              {t('Cancel Manual Subscription')}
                            </Button>
                          </>
                        )}
                      </Box>
                    </>
                  ) : existingSubscription?.status === 'ACTIVE' ? (
                    <SubscriptionDisplay subscription={existingSubscription} />
                  ) : (
                    <div>
                      {existingSubscription?.status === 'CANCELED' && (
                        <Box mt={2} mb={2}>
                          <div
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            {t('Subscription is Cancelled')}
                          </div>
                        </Box>
                      )}
                      {isLegacyStandardPlanSubscriber &&
                      subscriptionType.key === 'standard-plan' ? (
                        t(
                          'Please contact support@nutmeglabs.com for details about your subscription.'
                        )
                      ) : (
                        <>
                          <Box mb={2}>
                            <SubscriptionPriceDisplay
                              subscriptionKey={subscriptionType.key}
                            />
                          </Box>
                          <Box display="flex">
                            <AddSubscriptionModal
                              subscriptionKey={subscriptionType.key}
                              disabled={!hasPaymentMethod}
                            />
                            {isNutmegAdmin && (
                              <Box ml={2}>
                                <AddSubscriptionModal
                                  subscriptionKey={subscriptionType.key}
                                  isManualSubscription={true}
                                />
                              </Box>
                            )}
                          </Box>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {subscriptionIdToCancel && (
        <SubscriptionCancelModal
          subscriptionId={subscriptionIdToCancel}
          onClose={() => setSubscriptionIdToCancel('')}
        />
      )}
    </>
  );
};

//<div className={styles['base-form-box__err']}>
//  {!hasPaymentMethod &&
//    t('Add a payment method first to be able to add new subscriptions')}
//</div>
