import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import { TextField } from 'client/components/v3/Form/TextField';
import { TextArea } from 'client/components/v3/Form/TextArea';
import { getValidators } from 'shared/libraries/validate/validator';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';

import styles from './MenuItemEditor.module.css';
import { TagModal } from './TagModal';
import { OptionGroupsEditor } from './OptionGroupsEditor';

interface Props {
  name: string;
}

export const MenuItemEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  const [editingTagIdx, setEditingTagIdx] = React.useState<number | null>(null);
  const { required } = getValidators(t);
  const org = useSelector(activeUserOrganizationSelector);
  const currencyCode = org?.default_currency ?? '';
  const form = useForm();

  const allergyOptions = [
    {
      value: 'fish',
      text: t('Fish'),
    },
    {
      value: 'nuts',
      text: t('Nuts'),
    },
    {
      value: 'alcohol',
      text: t('Alcohol'),
    },
    {
      value: 'vegan',
      text: t('Vegan'),
    },
    {
      value: 'vegetarian',
      text: t('Vegetarian'),
    },
    {
      value: 'gluten-free',
      text: t('Gluten-Free'),
    },
  ];

  return (
    <div className={styles.container}>
      <section className={styles.details}>
        <h2 className={styles.sectionTitle}>{t('Details')}</h2>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.name`} validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextField
                  label={t('Name')}
                  onChange={input.onChange}
                  value={input.value}
                  error={Boolean(touched && error)}
                  helperText={touched ? error : undefined}
                />
              )}
            </Field>
          </div>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.category`} validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextField
                  label={t('Category')}
                  onChange={input.onChange}
                  value={input.value}
                  error={Boolean(touched && error)}
                  helperText={touched ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.price`} validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextField
                  label={t('Price ({{currency}})', {
                    currency: currencyCode,
                  })}
                  onChange={(newValue) => {
                    if (!currencyInputAllowed(currencyCode, newValue)) return;

                    input.onChange(newValue);
                  }}
                  value={input.value}
                  error={Boolean(touched && error)}
                  helperText={touched ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.description`} validate={required}>
              {({ input, meta: { touched, error } }) => (
                <TextArea
                  label={t('Description')}
                  onChange={input.onChange}
                  value={input.value}
                  error={Boolean(touched && error)}
                  helperText={touched ? error : undefined}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.isRecommended`}>
              {({ input }) => (
                <Toggle
                  label={t('Recommended')}
                  checked={input.value}
                  onChange={() => input.onChange(!input.value)}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field<string[]> name={`${name}.tags`}>
              {({ input }) => (
                <MultiDropdown
                  label={t('Tags')}
                  options={[
                    ...((input.value || []).map((tag) => ({
                      value: tag,
                      text: tag,
                    })) ?? []),
                    { value: 'ADD_NEW', text: t('Create New') },
                  ]}
                  onChange={(options) => {
                    if (options.some((option) => option === 'ADD_NEW')) {
                      setEditingTagIdx(input.value?.length || 0);
                    } else {
                      input.onChange(options);
                    }
                  }}
                  renderOption={(option) => {
                    if (option.value === 'ADD_NEW') {
                      return (
                        <div className={styles['add-new']}>
                          <i className="c-icon-outline-general-plus-circle"></i>
                          <p>{option.text}</p>
                        </div>
                      );
                    } else {
                      return option.text;
                    }
                  }}
                  selectedOptions={input.value || []}
                />
              )}
            </Field>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.inputWrapper}>
            <Field name={`${name}.allergens`}>
              {({ input }) => (
                <MultiDropdown
                  label={t('Allergens')}
                  options={allergyOptions}
                  onChange={input.onChange}
                  selectedOptions={input.value}
                />
              )}
            </Field>
          </div>
        </div>
        <OptionGroupsEditor name={`${name}.optionGroups`} />
        {editingTagIdx != null && (
          <TagModal
            name={`${name}.tags.${editingTagIdx}`}
            onClose={() => {
              const tags: any[] = form.getState().values.tags || [];
              if (tags.length > 0) {
                form.change(
                  `${name}.tags`,
                  tags.filter((_, idx) => idx !== editingTagIdx)
                );
              }
              setEditingTagIdx(null);
            }}
            onSave={() => setEditingTagIdx(null)}
          />
        )}
      </section>

      <section className={styles.profilePictures}>
        <h2 className={styles.sectionTitle}>{t('Photos')}</h2>
        <Field name={`${name}.mediaUrls`}>
          {({ input }) => (
            <ImageVideoAudioInput
              fileTypes={['image/jpeg', 'image/png', 'video/mp4']}
              fileUrls={input.value || []}
              onChange={(newValue) => input.onChange(newValue)}
            />
          )}
        </Field>
      </section>
    </div>
  );
};
