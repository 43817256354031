import * as React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ManifestReservationShape } from 'client/libraries/util/manifestReservationShape';
import type { ReduxState } from 'client/reducers/index';
import type { ResourceType } from 'client/libraries/util/resourceType';
import {
  fetchManifest,
  fetchManifestPDF,
  sendManifestEmail,
  fetchManifestCSV,
} from 'client/actions/manifests';
import { fetchProducts } from 'client/actions/products';
import {
  allManifestViewsSelector,
  manifestFuseStartTimesSelector,
  manifestExcludedFormFieldsSelector,
  manifestProductGroupsSelector,
  manifestShowReservationsOnAllOperatingDaysSelector,
  manifestCustomizedColumnNamesSelector,
} from 'client/reducers/manifestSettings';
import { SendEmailsButton } from 'client/pages/Manifest/SendEmailButton';
import { StartTimeManifestReservationsTableList } from 'client/components/StartTimeManifestReservationsTableList/StartTimeManifestReservationsTableList';
import { ManifestReservationsTable } from 'client/components/ManifestReservationsTable/ManifestReservationsTable';
import { ToggleButton, Button } from 'client/components/Form';
import {
  getDriverManifestView,
  getReservationSortMethodFromReservationColumn,
  getVisibleColumns,
  getOrderByColumns,
  getColumnsParam,
} from 'client/reducers/manifestDefaults';
import { Loading } from 'client/pages/Loading';
import { ManifestSettingsModal } from 'client/pages/Manifest/ManifestSettingsModal';
import { EditReservationResourceAssignmentModal } from 'client/components/EditReservationResourceAssignmentModal/EditReservationResourceAssignmentModal';
import { fetchGuideSingleDaySchedules } from 'client/actions/guides';
import { ResourceBulkUpdateModal } from 'client/components/ResourceBulkUpdateModal/ResourceBulkUpdateModal';
import { ResourceReservationListModal } from 'client/components/ResourceReservationListModal/ResourceReservationListModal';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { summariesSortedByBookmarkedSelector } from 'client/reducers/products';
import 'react-table/react-table.css';
import anotherIcon from 'client/images/ic_another.svg';
import baseStyles from 'client/base.module.css';
import thIcon from 'client/images/ic_th.svg';
import type { ManifestProductGroup, Product } from 'shared/models/swagger';

import { ManifestProductGroupTableList } from './ManifestProductGroupTableList';
import styles from './ManifestDetails.module.css';

type Props = {
  reservations: ManifestReservationShape[];
  products: Product[];
  startTimeHhMmFilters: string[];
  manifestType?: string;
  participationDate?: string;
  rootResource?: {
    id: string;
    type: 'PRODUCT' | 'GROUP' | 'ALL_PRODUCTS';
  };
  showCheckinCheckout: boolean;
  showDispatchColumn: boolean;
  showByProductGroup: boolean;
  onVisualFlagsChange: (arg0: {
    showCheckinCheckout: boolean;
    showDispatchColumn: boolean;
    showByProductGroup: boolean;
  }) => void;
};
export const ManifestDetails = ({
  reservations,
  startTimeHhMmFilters,
  manifestType,
  participationDate,
  rootResource,
  showCheckinCheckout,
  showDispatchColumn,
  showByProductGroup,
  onVisualFlagsChange,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allManifestViews = useSelector(allManifestViewsSelector);
  const guideSingleDaySchedules = useSelector(
    (state: ReduxState) => state.guides.singleDaySchedules
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const loading = useSelector(
    (state: ReduxState) =>
      state.manifests.loading ||
      state.products.loading ||
      state.reservations.loading
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const manifestCsvStatus = useSelector(
    (state: ReduxState) => state.manifests.csvStatus
  );
  const manifestCustomizedColumnNames = useSelector(
    manifestCustomizedColumnNamesSelector
  );
  const manifestEmailStatus = useSelector(
    (state: ReduxState) => state.manifests.emailStatus
  );
  const manifestExcludedFormFieldKeys = useSelector(
    manifestExcludedFormFieldsSelector
  );
  const manifestPdfStatus = useSelector(
    (state: ReduxState) => state.manifests.pdfStatus
  );
  const manifestProductGroups = useSelector(manifestProductGroupsSelector);
  const showReservationsOnAllOperatingDays = useSelector(
    manifestShowReservationsOnAllOperatingDaysSelector
  );
  const splitStartTimes = useSelector(
    (state: ReduxState) => !manifestFuseStartTimesSelector(state)
  );
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);

  const productSummaries = useSelector(summariesSortedByBookmarkedSelector);

  const activeUser = useSelector(activeUserSelector);

  const [
    editResourceTargetReservationIds,
    setEditResourceTargetReservationIds,
  ] = React.useState<string[]>([]);
  const [openBulkUpdateModal, setOpenBulkUpdateModal] =
    React.useState<boolean>(false);
  const [openSendEmailModal, setOpenSendEmailModal] =
    React.useState<boolean>(false);
  const [showAnotherButtons, setShowAnotherButtons] =
    React.useState<boolean>(false);
  const [showResourceTarget, setShowResourceTarget] = React.useState<{
    key: string;
    name?: string;
    resourceType: ResourceType;
  } | null>(null);
  const [productIds, setProductIds] = React.useState<string[]>([]);
  const manifestView =
    allManifestViews.find((view) => view.key === manifestType) ||
    getDriverManifestView();
  let productGroup: ManifestProductGroup | null = null;

  if (rootResource?.type === 'GROUP') {
    const productGroupKey = rootResource?.id;

    if (productGroupKey) {
      productGroup =
        manifestProductGroups.find((group) => group.key === productGroupKey) ??
        null;
    }
  }

  let manifestProductIds: string[] = [];

  if (productGroup && productGroup.product_ids) {
    manifestProductIds = productGroup.product_ids;
  } else {
    if (rootResource?.type === 'PRODUCT') {
      const productId = rootResource?.id;

      if (productId) {
        manifestProductIds = [productId];
      }
    }
  }

  const visibleColumns = getVisibleColumns(manifestView);
  const formattedDate =
    participationDate && moment(participationDate).locale(locale).format('LL');
  const totalPax = reservations.reduce((p, c) => {
    return p + (c.guests || []).length;
  }, 0);

  const sortedReservations = _.orderBy(
    reservations,
    (getOrderByColumns(manifestView, splitStartTimes) || []).map((column) => {
      return getReservationSortMethodFromReservationColumn(column);
    })
  );

  let manifestNote = '';

  if (productGroup && productGroup.key) {
    manifestNote = productGroup.key;
  } else {
    if (rootResource?.type === 'PRODUCT') {
      const productId = rootResource?.id;

      if (productId) {
        const product = productSummaries.find((p) => p.id === productId);

        if (product) {
          manifestNote = product.product_name || '';
        }
      }
    }
  }

  React.useEffect(() => {
    const newProductIds = _.sortBy([
      ...new Set(reservations.map((r) => r.product_id)),
    ]);

    if (!_.isEqual(productIds, newProductIds)) {
      setProductIds(newProductIds);
    }
  }, [reservations]);
  React.useEffect(() => {
    dispatch(fetchGuideSingleDaySchedules());
  }, []);
  React.useEffect(() => {
    fetchTargetManifest();
  }, [
    invalidated,
    participationDate,
    rootResource,
    manifestType,
    showCheckinCheckout,
  ]);

  React.useEffect(() => {
    if (!isNutmegAdmin) {
      dispatch(fetchProducts());
    }
  }, [isNutmegAdmin]);

  const fetchTargetManifest = () => {
    dispatch(
      fetchManifest({
        date: participationDate,
        product_ids: productGroup ? [] : manifestProductIds,
        exclude_checkin_checkout: !showCheckinCheckout,
        include_multiday_continuations: showReservationsOnAllOperatingDays,
        product_group_key: productGroup ? productGroup.key : undefined,
      })
    );
  };

  const downloadCSV = () => {
    dispatch(
      fetchManifestCSV({
        date: participationDate,
        product_ids: productGroup ? [] : manifestProductIds,
        columns: [
          'START_TIME',
          ...getColumnsParam(
            manifestView,
            splitStartTimes,
            showReservationsOnAllOperatingDays
          ),
        ],
        order_by_columns: [
          'START_TIME',
          ...getOrderByColumns(manifestView, splitStartTimes),
        ],
        exclude_reservation_form_field_keys: manifestExcludedFormFieldKeys,
        timezone: moment.tz.guess(),
        exclude_checkin_checkout: !showCheckinCheckout,
        start_times_local: startTimeHhMmFilters,
        include_multiday_continuations: showReservationsOnAllOperatingDays,
        customized_column_names: manifestCustomizedColumnNames,
        product_group_key: productGroup ? productGroup.key : undefined,
      })
    );
  };

  const downloadPDF = () => {
    dispatch(
      fetchManifestPDF({
        date: participationDate,
        product_ids: productGroup ? [] : manifestProductIds,
        columns: getColumnsParam(
          manifestView,
          splitStartTimes,
          showReservationsOnAllOperatingDays
        ),
        exclude_reservation_form_field_keys: manifestExcludedFormFieldKeys,
        order_by_columns: getOrderByColumns(manifestView, splitStartTimes),
        exclude_checkin_checkout: !showCheckinCheckout,
        start_times_local: startTimeHhMmFilters,
        timezone: moment.tz.guess(),
        include_multiday_continuations: showReservationsOnAllOperatingDays,
        customized_column_names: manifestCustomizedColumnNames,
        fuse_start_times: {
          value: !splitStartTimes,
        },
        product_group_key: productGroup ? productGroup.key : undefined,
        should_show_by_product_group: showByProductGroup ?? undefined,
      })
    );
  };

  const sendEmail = (subject: string, toAddresses: string[]) => {
    dispatch(
      sendManifestEmail({
        date: participationDate,
        note: manifestNote,
        product_ids: productGroup ? [] : manifestProductIds,
        columns: getColumnsParam(
          manifestView,
          splitStartTimes,
          showReservationsOnAllOperatingDays
        ),
        exclude_reservation_form_field_keys: manifestExcludedFormFieldKeys,
        order_by_columns: getOrderByColumns(manifestView, splitStartTimes),
        exclude_checkin_checkout: !showCheckinCheckout,
        start_times_local: startTimeHhMmFilters,
        timezone: moment.tz.guess(),
        subject,
        to_addresses: toAddresses,
        include_multiday_continuations: showReservationsOnAllOperatingDays,
        customized_column_names: manifestCustomizedColumnNames,
        fuse_start_times: {
          value: !splitStartTimes,
        },
        product_group_key: productGroup ? productGroup.key : undefined,
      })
    );
  };

  const handleToggleShowCheckinCheckout = () => {
    onVisualFlagsChange({
      showCheckinCheckout: !showCheckinCheckout,
      showDispatchColumn: showDispatchColumn,
      showByProductGroup: showByProductGroup,
    });
  };

  const handleToggleShowDispatchColumn = () => {
    onVisualFlagsChange({
      showCheckinCheckout: showCheckinCheckout,
      showDispatchColumn: !showDispatchColumn,
      showByProductGroup: showByProductGroup,
    });
  };

  const handleToggleShowByProductGroup = () => {
    onVisualFlagsChange({
      showCheckinCheckout: showCheckinCheckout,
      showDispatchColumn: showDispatchColumn,
      showByProductGroup: !showByProductGroup,
    });
  };

  const handleShowResourceTarget = (
    key: string,
    resourceType: ResourceType,
    name: string
  ) => {
    setShowResourceTarget({
      key,
      name,
      resourceType,
    });
  };

  const setEditResourceTarget = (reservationId: string) => {
    setEditResourceTargetReservationIds([reservationId]);
  };

  if (!participationDate || !manifestView) {
    return null;
  }

  return (
    <>
      <div className={clsx(baseStyles['base-main__body__header'])}>
        <div
          className={clsx(
            baseStyles['base-main__body__header__left'],
            baseStyles['spOrder-1'],
            styles['manifest_detail__header__left']
          )}
        >
          <ToggleButton
            label={t('Show "checkin/checkout only" reservations')}
            checked={showCheckinCheckout}
            onChange={handleToggleShowCheckinCheckout}
          />
          <ToggleButton
            label={t('Show dispatch resources')}
            checked={showDispatchColumn}
            onChange={handleToggleShowDispatchColumn}
          />
          <ToggleButton
            label={t('Show by product/group')}
            checked={showByProductGroup}
            onChange={handleToggleShowByProductGroup}
          />
        </div>

        <div
          className={clsx(
            baseStyles['base-main__body__header__right'],
            baseStyles['spOrder-2']
          )}
        >
          <div
            className={clsx(
              baseStyles['base-main__body__header__right__another'],
              showAnotherButtons
                ? baseStyles['is-open']
                : baseStyles['is-close']
            )}
          >
            <ul>
              {hasCustomUserRoleWritePermissions(
                activeUser,
                'MANIFEST.DAILY'
              ) && (
                <li>
                  <a
                    className={clsx(
                      baseStyles['base-btn'],
                      baseStyles['middle'],
                      baseStyles['blue']
                    )}
                    onClick={() => {
                      setOpenBulkUpdateModal(true);
                    }}
                  >
                    {t('Bulk assign')}
                  </a>
                </li>
              )}
              <li>
                <Button
                  size={'middle'}
                  style={'gray'}
                  onClick={downloadPDF}
                  loading={manifestPdfStatus === 'IN_FLIGHT'}
                >
                  {t('Download as PDF')}
                </Button>
              </li>
              <li>
                <Button
                  size={'middle'}
                  style={'gray'}
                  onClick={downloadCSV}
                  loading={manifestCsvStatus === 'IN_FLIGHT'}
                >
                  {t('Download as CSV')}
                </Button>
              </li>
              <li>
                <Button
                  size={'middle'}
                  style={'gray'}
                  onClick={() => {
                    setOpenSendEmailModal(true);
                  }}
                  loading={manifestEmailStatus === 'IN_FLIGHT'}
                >
                  {t('Send Emails')}
                </Button>
              </li>
            </ul>
            <a
              onClick={() => {
                setShowAnotherButtons(!showAnotherButtons);
              }}
            >
              <img src={anotherIcon} />
            </a>
          </div>

          {/* NOTE(goro) need to put this part out of base-main__body__header__right__another to avoid design collapsed of the modal*/}
          <SendEmailsButton
            defaultSubject={
              formattedDate
                ? t('Manifest - {{formattedDate}}', {
                    formattedDate,
                  })
                : t('Manifest')
            }
            open={openSendEmailModal}
            onClick={(subject: string, toAddresses: string[]) =>
              sendEmail(subject, toAddresses)
            }
            onClose={() => {
              setOpenSendEmailModal(false);
            }}
          />

          <ManifestSettingsModal
            trigger={
              <a
                className={clsx(
                  baseStyles['base-btn'],
                  baseStyles['square'],
                  baseStyles['gray']
                )}
              >
                <img src={thIcon} />
              </a>
            }
          />
        </div>
      </div>

      {showByProductGroup ? (
        <>
          <ManifestProductGroupTableList
            excludedFormFieldKeys={manifestExcludedFormFieldKeys}
            loading={loading}
            products={productSummaries}
            reservations={sortedReservations}
            visibleColumns={visibleColumns}
            open={true}
            customizedColumnNames={manifestCustomizedColumnNames}
            onEditResourceButtonClick={setEditResourceTarget}
            onResourceTextClick={handleShowResourceTarget}
            showDispatchColumn={showDispatchColumn}
            splitStartTimes={splitStartTimes}
            manifestDate={participationDate}
            rootResource={rootResource}
          />
        </>
      ) : (
        <>
          {splitStartTimes ? (
            <StartTimeManifestReservationsTableList
              manifestDate={participationDate}
              excludedFormFieldKeys={manifestExcludedFormFieldKeys}
              products={productSummaries}
              reservations={sortedReservations}
              visibleColumns={visibleColumns as any}
              customizedColumnNames={manifestCustomizedColumnNames}
              onEditResourceButtonClick={setEditResourceTarget}
              onResourceTextClick={handleShowResourceTarget}
              showDispatchColumn={showDispatchColumn}
            />
          ) : (
            <>
              <ManifestReservationsTable
                excludedFormFieldKeys={manifestExcludedFormFieldKeys}
                loading={loading}
                products={productSummaries}
                reservations={sortedReservations}
                visibleColumns={visibleColumns}
                totalPax={totalPax}
                open={true}
                customizedColumnNames={manifestCustomizedColumnNames}
                onEditResourceButtonClick={setEditResourceTarget}
                onResourceTextClick={handleShowResourceTarget}
                showDispatchColumn={showDispatchColumn}
              />
            </>
          )}
        </>
      )}

      <ResourceBulkUpdateModal
        reservations={sortedReservations}
        visibleColumns={visibleColumns}
        excludedFormFieldKeys={manifestExcludedFormFieldKeys}
        products={productSummaries}
        open={openBulkUpdateModal}
        onClose={() => {
          setOpenBulkUpdateModal(false);
        }}
        onResourceAssignmentButtonChange={setEditResourceTargetReservationIds}
      />

      <ResourceReservationListModal
        open={showResourceTarget !== null}
        resourceType={showResourceTarget?.resourceType || 'crew'}
        resourceKey={showResourceTarget?.key || ''}
        visibleColumns={visibleColumns}
        onClose={() => {
          setShowResourceTarget(null);
        }}
        reservations={sortedReservations}
        title={showResourceTarget?.name || ''}
      />

      <EditReservationResourceAssignmentModal
        open={editResourceTargetReservationIds.length > 0}
        reservations={[
          ...sortedReservations.filter((reservation) => {
            return editResourceTargetReservationIds.includes(reservation.id);
          }),
        ]}
        participationDate={participationDate}
        onClose={() => {
          setEditResourceTargetReservationIds([]);
          setOpenBulkUpdateModal(false);
        }}
        guideSingleDaySchedules={guideSingleDaySchedules || []}
      />
      {loading && <Loading />}
    </>
  );
};
