import { useState } from 'react';

import { PackageComponentReservationTab } from 'client/components/v3/PackageComponentReservationTab/PackageComponentReservationTab';
import type { Reservation } from 'shared/models/swagger';

import { DynamicPackageComponentCheckin } from './DynamicPackageComponentCheckin';

interface Props {
  reservation?: Reservation;
}

export const DynamicPackageComponentCheckinTab = ({ reservation }: Props) => {
  const [selectedComponentReservationId, setSelectedComponentReservationId] =
    useState<string>(
      reservation?.package_component_reservation_summaries?.[0]
        .reservation_id ?? ''
    );

  return (
    <PackageComponentReservationTab
      reservations={reservation?.package_component_reservation_summaries ?? []}
      value={selectedComponentReservationId}
      onChange={(newValue) => setSelectedComponentReservationId(newValue)}
      startTimezone={reservation?.start_timezone}
    >
      <DynamicPackageComponentCheckin
        componentReservationId={selectedComponentReservationId}
      />
    </PackageComponentReservationTab>
  );
};
