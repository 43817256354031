import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import type { ReduxState } from 'client/reducers';
import { isKanaWord } from 'client/libraries/util/isKanaWord';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';
import { ETicketFootprint } from 'shared/models/swagger';

export const useFootprintColumns = (): CustomTableColumn[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  // Need to change the order of given name and family name when the
  // reservation form requires kana name
  const getGuestName = (givenName: string, familyName: string): string => {
    if (isKanaWord(givenName) && isKanaWord(familyName)) {
      return `${familyName} ${givenName}`;
    }
    return `${givenName} ${familyName}`;
  };

  return [
    {
      Header: '',
      width: 'short',
      Cell: (cellInfo: any) => (
        <Link
          to={`${getReservationDetailsPath(cellInfo.original)}`}
          className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
          data-text={t('Detail')}
        >
          <img src={reservationDetailIcon} />
        </Link>
      ),
    },
    {
      Header: t('Redemption Date'),
      width: 'middle',
      accessor: (row) =>
        moment
          .tz(row.date_time_utc, row.reservation_start_timezone ?? 'UTC')
          .locale(locale)
          .format('lll'),
    },
    {
      Header: t('Nearest Location Name') + ' / ' + t('Ticket Usage'),
      width: 'middle',
      accessor: (row) => {
        if (row.reservation_is_free_start_date_time && row.stub_text) {
          let text = t('Free Pass');
          if (row.stub_text) {
            text += `(${row.stub_text})`;
          }
          return text;
        }

        return row.nearest_location_name;
      },
    },
    {
      Header: t('Latitude') + ' / ' + t('Longitude'),
      width: 'long',
      Cell: (cellInfo) => (
        <>
          {cellInfo.original.latitude && cellInfo.original.longitude && (
            <a
              href={`https://www.google.com/maps?q=${cellInfo.original.latitude},${cellInfo.original.longitude}`}
              target="_blank"
              rel="noreferrer"
            >
              {cellInfo.original.latitude},{cellInfo.original.longitude}
            </a>
          )}
        </>
      ),
    },
    {
      Header: t('Application Number'),
      accessor: 'reservation_agent_reference',
      Cell: (cellInfo) => (
        <Link
          to={`${getReservationDetailsPath(cellInfo.original)}`}
          target="_blank"
        >
          {cellInfo.original.reservation_agent_reference}
        </Link>
      ),
    },
    {
      Header: t('Confirmation Number'),
      accessor: 'reservation_supplier_reference',
    },
    {
      Header: t('Customer'),
      accessor: (row) =>
        row.reservation_guest_given_name && row.reservation_guest_family_name
          ? getGuestName(
              row.reservation_guest_given_name,
              row.reservation_guest_family_name
            )
          : '',
    },
    {
      Header: t('Product'),
      accessor: (row) => row.internal_product_name ?? row.product_name,
    },
    {
      Header: t('Participation'),
      accessor: (row) =>
        moment
          .tz(
            row.reservation_date_from,
            row.reservation_start_timezone ?? 'UTC'
          )
          .locale(locale)
          .format('lll'),
    },
  ];
};

const getReservationDetailsPath = (
  etickeFootprint: ETicketFootprint
): string => {
  if (
    !etickeFootprint.is_partnership_reservation &&
    etickeFootprint.is_dynamic_package_component_reservation
  ) {
    return `/reservations/${etickeFootprint.package_reservation_id}`;
  }
  return `/reservations/${etickeFootprint.reservation_id}`;
};
