import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { config } from 'client/config';
import {
  ButtonMenu,
  ButtonMenuItem,
} from 'client/pages/ProductDetails/ProductContentsHeader/EditorButtonsRow/ButtonMenu';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import {
  hasSubscription,
  isSubscriptionCancelled,
} from 'client/libraries/util/subscriptions';
import {
  activeUserSelector,
  activeUserIsNutmegAdminSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { operationAllowed } from 'shared/models/access';
import { NtaProductSettingsModal } from 'client/components/ESaas/Nta/NtaProductSettingsModal/NtaProductSettingsModal';
import { isNta } from 'client/components/ESaas/Nta/utils';
import type { Product } from 'shared/models/swagger';
import anotherIcon from 'client/images/ic_another.svg';

import { BookingWidgetVisibilityEditorModal } from '../BookingWidgetVisibilityEditorModal';
import { BookingWidgetSelectableParticipantRulesEditorModal } from '../BookingWidgetSelectableParticipantRulesEditorModal';
import { PMPSettingsEditorModal } from '../PMPSettingsEditorModal';
import { PackageSettingsModal } from '../PackageSettingsModal/PackageSettingsModal';
import { ProductGuestMyPageSettingsModal } from '../ProductGuestMyPageSettingsModal/ProductGuestMyPageSettingsModal';
import { LocationEditorModal } from '../LocationEditorModal';
import { SharedAvailabilityAllotmentEditorModal } from '../SharedAvailabilityAllotmentEditorModal/SharedAvailabilityAllotmentEditorModal';
import { HTMLTextsEditorModal } from '../HTMLTextsEditorModal/HTMLTextsEditorModal';
import { ImportModal } from '../ImportModal';
import { AgentBlacklistWhitelistEditorModal } from '../AgentBlacklistWhitelistEditorModal/AgentBlacklistWhitelistEditorModal';
import { QRCheckinSettingsModal } from '../QRCheckinSettingsModal/QRCheckinSettingsModal';
import { ETicketSettingsModal } from '../ETicketSettingsModal/ETicketSettingsModal';
import { PaymentTypeModal } from '../PaymentTypeModal';
import { FreeStartDateTimeModal } from '../FreeStartDateTimeModal';
import { ActimPlanTimeZoneIdEditor } from '../ActimPlanTimeZoneIdEditor';
import { LineMessageContentModal } from '../LineMessageContentModal/LineMessageContentModal';
import { LotteryModeSettingsModal } from '../LotteryModeSettingsModal';
import { AnnualPassModeSettingsModal } from '../AnnualPassModeSettingsModal';
import { AutomaticAvailabilityAdjustmentModal } from '../AutomaticAvailabilityAdjustmentModal/AutomaticAvailabilityAdjustmentModal';
import { ProductSeatManagementSettingsModal } from '../ProductSeatManagementSettingsModal';
import { AutomaticPriceAdjustmentModal } from '../AutomaticPriceAdjustmentModal/AutomaticPriceAdjustmentModal';
import { ProductCustomerLedgerSettingsModal } from '../ProductCustomerLedgerSettingsModal';

import styles from './EditorButtonsRow.module.css';

type Props = {
  product: Product;
};
export const EditorButtonsRow = ({ product }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [expandedOnMobile, setExpandedOnMobile] =
    React.useState<boolean>(false);
  const [activeModal, setActiveModal] = React.useState<
    | 'VISIBILITY'
    | 'MIN_MAX_PAX'
    | 'PMP_SETTINGS'
    | 'LOCATION'
    | 'SHARE_AVAILABILITY_ALLOTMENT'
    | 'AVAILABILITY_ADJUSTMENT'
    | 'PRICE_ADJUSTMENT'
    | 'PACKAGE_SETTINGS'
    | 'IMPORT'
    | 'HTML_TEXTS'
    | 'BLACKLIST_WHITELIST'
    | 'NTA_SETTINGS'
    | 'QR_CHECKIN_SETTINGS'
    | 'GUEST_MY_PAGE'
    | 'PAYMENT_TYPE'
    | 'FREE_START_DATE_TIME'
    | 'ACTIM_SETTING'
    | 'E_TICKET_SETTINGS'
    | 'LINE_MESSAGE_CONTENT'
    | 'LOTTERY_MODE'
    | 'ANNUAL_PASS_MODE'
    | 'SEAT_MANAGEMENT'
    | 'CUSTOMER_LEDGER'
    | null
  >(null);
  const isPackage =
    (product?.shared_allotment_references?.package_component_product_ids ?? [])
      .length > 0;
  const hasAgents = (product?.agents ?? []).length > 0;
  const activeUser = useSelector(activeUserSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const showPrivateMarketplaceSettings =
    (activeUserOrganization?.private_marketplaces?.length || 0) > 0 &&
    !isSubscriptionCancelled(activeUserOrganization, 'standard-plan');
  // When the user uses liff (not mini app), its means that the user is using LINE official account to communicate with customers.
  const showLineMessageEditor = Boolean(
    activeUserOrganization?.liff_app_domain_name
  );
  return (
    <EditingProductContext.Provider value={product}>
      <div
        className={clsx(
          styles['buttons-row'],
          expandedOnMobile && styles['expanded-on-mobile']
        )}
      >
        <a
          className={styles['mobile-expand-button']}
          onClick={() => setExpandedOnMobile(!expandedOnMobile)}
        >
          <img src={anotherIcon} />
        </a>
        {activeModal === 'VISIBILITY' && (
          <BookingWidgetVisibilityEditorModal
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'MIN_MAX_PAX' && (
          <BookingWidgetSelectableParticipantRulesEditorModal
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'PAYMENT_TYPE' && (
          <PaymentTypeModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'FREE_START_DATE_TIME' && (
          <FreeStartDateTimeModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'PMP_SETTINGS' && (
          <PMPSettingsEditorModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'LOCATION' && (
          <LocationEditorModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'SHARE_AVAILABILITY_ALLOTMENT' && (
          <SharedAvailabilityAllotmentEditorModal
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'AVAILABILITY_ADJUSTMENT' && (
          <AutomaticAvailabilityAdjustmentModal
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'PRICE_ADJUSTMENT' && (
          <AutomaticPriceAdjustmentModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'PACKAGE_SETTINGS' && (
          <PackageSettingsModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'HTML_TEXTS' && (
          <HTMLTextsEditorModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'IMPORT' && (
          <ImportModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'BLACKLIST_WHITELIST' && (
          <AgentBlacklistWhitelistEditorModal
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'NTA_SETTINGS' && (
          <NtaProductSettingsModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'QR_CHECKIN_SETTINGS' && (
          <QRCheckinSettingsModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'E_TICKET_SETTINGS' && (
          <ETicketSettingsModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'LINE_MESSAGE_CONTENT' && (
          <LineMessageContentModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'GUEST_MY_PAGE' && (
          <ProductGuestMyPageSettingsModal
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'ACTIM_SETTING' && (
          <ActimPlanTimeZoneIdEditor onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'LOTTERY_MODE' && (
          <LotteryModeSettingsModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'ANNUAL_PASS_MODE' && (
          <AnnualPassModeSettingsModal onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'SEAT_MANAGEMENT' && (
          <ProductSeatManagementSettingsModal
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'CUSTOMER_LEDGER' && (
          <ProductCustomerLedgerSettingsModal
            onClose={() => setActiveModal(null)}
          />
        )}
        <div className={styles['buttons-wrapper']}>
          {operationAllowed(activeUser, 'write', 'productContents') && (
            <div className={styles['button-container']}>
              <ButtonMenu label={t('Website')}>
                <ButtonMenuItem
                  text={t('Product Status')}
                  onClick={() => setActiveModal('VISIBILITY')}
                />
                <ButtonMenuItem
                  text={t('Selectable Participant Rules')}
                  onClick={() => setActiveModal('MIN_MAX_PAX')}
                />
                <ButtonMenuItem
                  text={t('Payment Types')}
                  onClick={() => setActiveModal('PAYMENT_TYPE')}
                />
                {showPrivateMarketplaceSettings && (
                  <ButtonMenuItem
                    text={t('PMP Settings')}
                    onClick={() => setActiveModal('PMP_SETTINGS')}
                  />
                )}
              </ButtonMenu>
            </div>
          )}
          {operationAllowed(activeUser, 'write', 'productContents') && (
            <div className={styles['button-container']}>
              <ButtonMenu label={t('Other Settings')}>
                <ButtonMenuItem
                  text={t('Location')}
                  onClick={() => setActiveModal('LOCATION')}
                />
                <ButtonMenuItem
                  text={t('Share Availability/Allotment')}
                  onClick={() => setActiveModal('SHARE_AVAILABILITY_ALLOTMENT')}
                />
                {!isPackage &&
                  !product?.shared_allotment_references?.parent_product_id &&
                  !product?.shared_allotment_references
                    ?.passthrough_base_product_id && (
                    <ButtonMenuItem
                      text={t('Auto Availability Adjustment')}
                      onClick={() => setActiveModal('AVAILABILITY_ADJUSTMENT')}
                    />
                  )}
                <ButtonMenuItem
                  text={t('Auto Price Adjustment')}
                  onClick={() => setActiveModal('PRICE_ADJUSTMENT')}
                />

                {isPackage && (
                  <ButtonMenuItem
                    text={t('Package Settings')}
                    onClick={() => setActiveModal('PACKAGE_SETTINGS')}
                  />
                )}
                <ButtonMenuItem
                  text={t('Import')}
                  onClick={() => setActiveModal('IMPORT')}
                />
                {!isNta(activeUserOrganization) && (
                  <ButtonMenuItem
                    text={t('HTML Texts')}
                    onClick={() => setActiveModal('HTML_TEXTS')}
                  />
                )}
                {config.enableNtaProductSettings &&
                  isNta(activeUserOrganization) && (
                    <ButtonMenuItem
                      text={t('NTA商品設定')}
                      onClick={() => setActiveModal('NTA_SETTINGS')}
                    />
                  )}
                {hasSubscription(
                  activeUserOrganization,
                  'feature-qr-checkin'
                ) && (
                  <ButtonMenuItem
                    text={t('QR Checkin')}
                    onClick={() => setActiveModal('QR_CHECKIN_SETTINGS')}
                  />
                )}
                {hasSubscription(
                  activeUserOrganization,
                  'feature-e-ticket'
                ) && (
                  <ButtonMenuItem
                    text={t('E-ticket')}
                    onClick={() => setActiveModal('E_TICKET_SETTINGS')}
                  />
                )}
                {showLineMessageEditor && (
                  <ButtonMenuItem
                    text={t('LINE Message')}
                    onClick={() => setActiveModal('LINE_MESSAGE_CONTENT')}
                  />
                )}
                {hasSubscription(
                  activeUserOrganization,
                  'feature-guest-my-page'
                ) && (
                  <ButtonMenuItem
                    text={t('My Page for Guest')}
                    onClick={() => setActiveModal('GUEST_MY_PAGE')}
                  />
                )}
                <ButtonMenuItem
                  text={t('Lucky Draw')}
                  onClick={() => setActiveModal('LOTTERY_MODE')}
                />
                {hasSubscription(
                  activeUserOrganization,
                  'feature-annual-pass'
                ) && (
                  <ButtonMenuItem
                    text={t('Annual Pass')}
                    onClick={() => setActiveModal('ANNUAL_PASS_MODE')}
                  />
                )}
                {hasSubscription(
                  activeUserOrganization,
                  'feature-seat-management'
                ) && (
                  <ButtonMenuItem
                    text={t('Seat Management')}
                    onClick={() => setActiveModal('SEAT_MANAGEMENT')}
                  />
                )}
                {config.enableMemberLimitedProduct &&
                  hasSubscription(
                    activeUserOrganization,
                    'feature-customer-ledger'
                  ) && (
                    <ButtonMenuItem
                      text={t('Members')}
                      onClick={() => setActiveModal('CUSTOMER_LEDGER')}
                    />
                  )}
              </ButtonMenu>
            </div>
          )}
          {hasAgents &&
            operationAllowed(activeUser, 'write', 'productContents') && (
              <div className={styles['button-container']}>
                <ButtonMenu label={t('Agent')}>
                  <ButtonMenuItem
                    text={t('Blacklist/Whitelist')}
                    onClick={() => setActiveModal('BLACKLIST_WHITELIST')}
                  />
                  {activeUserOrganization?.actim_api_key && (
                    <ButtonMenuItem
                      text={t('Edit Actim Plan ID')}
                      onClick={() => setActiveModal('ACTIM_SETTING')}
                    />
                  )}
                </ButtonMenu>
              </div>
            )}
          {(isNutmegAdmin ||
            operationAllowed(activeUser, 'write', 'reverseMappings')) && (
            <div className={styles['button-container']}>
              <ButtonMenu label={t('Admin')}>
                <ButtonMenuItem
                  text={t('Reverse Mapping')}
                  onClick={() =>
                    history.push(
                      `/products/${product?.id ?? ''}/editReverseMappings`
                    )
                  }
                />
              </ButtonMenu>
            </div>
          )}
        </div>
      </div>
    </EditingProductContext.Provider>
  );
};
