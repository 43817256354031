import { Link } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { config } from 'client/config';
import type { CustomTableColumn } from 'client/components/CustomTable/CustomTable';
import type { ReduxState } from 'client/reducers';
import { isKanaWord } from 'client/libraries/util/isKanaWord';
import reservationDetailIcon from 'client/images/ic_reservationsDetail.svg';
import baseStyles from 'client/base.module.css';
import { CheckinRecord } from 'shared/models/swagger';

export const useCheckinRecordColumns = (): CustomTableColumn[] => {
  const { t } = useTranslation();
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const allProduct = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  // Need to change the order of given name and family name when the
  // reservation form requires kana name
  const getGuestName = (givenName: string, familyName: string): string => {
    if (isKanaWord(givenName) && isKanaWord(familyName)) {
      return `${familyName} ${givenName}`;
    }
    return `${givenName} ${familyName}`;
  };

  return [
    {
      Header: '',
      width: 'short',
      Cell: (cellInfo: any) => (
        <Link
          to={`${getReservationDetailsPath(cellInfo.original)}`}
          className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
          data-text={t('Detail')}
        >
          <img src={reservationDetailIcon} />
        </Link>
      ),
    },
    {
      Header: t('Redemption Date'),
      width: 'middle',
      accessor: (row) =>
        moment
          .tz(row.date_time_utc, row.reservation_start_timezone ?? 'UTC')
          .locale(locale)
          .format('lll'),
    },
    {
      Header: t('Ticket Usage'),
      width: 'middle',
      accessor: (row) => {
        let text = '';
        if (row.reservation_is_free_start_date_time) {
          text = t('Free Pass');
          if (row.stub_text) {
            text += `(${row.stub_text})`;
          }
        } else {
          if (row.stub_text) {
            text = row.stub_text;
          } else {
            text = t('Redeem Ticket');
          }
        }
        return text;
      },
    },
    {
      Header: t('Ticket Usage Option'),
      width: 'middle',
      accessor: (row) => {
        return row.stub_option_text;
      },
    },
    ...(config.enableETicketPresetRedemptionCount
      ? [
          {
            Header: t('Nearest Location Name'),
            width: 'middle',
            accessor: (row: any) => {
              return row.nearest_location_name;
            },
          },
          {
            Header: t('Redemption Place'),
            width: 'middle',
            accessor: (row: any) => {
              const product = allProduct.find(
                (product) => product.id === row.product_id
              );
              const presetRedemptionCount =
                product?.qr_checkin_settings?.preset_redemption_counts?.find(
                  (preset) => preset.key === row.preset_redemption_count_key
                );
              return presetRedemptionCount?.text;
            },
          },
        ]
      : []),
    {
      Header: t('Redeemed Tickets'),
      width: 'middle',
      accessor: (row) => {
        return row.redemption_count || row.display_guest_type_counts;
      },
    },
    {
      Header: t('Application Number'),
      accessor: 'reservation_agent_reference',
      Cell: (cellInfo) => (
        <Link
          to={`${getReservationDetailsPath(cellInfo.original)}`}
          target="_blank"
        >
          {cellInfo.original.reservation_agent_reference}
        </Link>
      ),
    },
    {
      Header: t('Confirmation Number'),
      accessor: 'reservation_supplier_reference',
    },
    {
      Header: t('Customer'),
      accessor: (row) =>
        row.reservation_guest_given_name && row.reservation_guest_family_name
          ? getGuestName(
              row.reservation_guest_given_name,
              row.reservation_guest_family_name
            )
          : '',
    },
    {
      Header: t('Product'),
      accessor: (row) => row.internal_product_name ?? row.product_name,
    },
    {
      Header: t('Participation'),
      accessor: (row) =>
        moment
          .tz(
            row.reservation_date_from,
            row.reservation_start_timezone ?? 'UTC'
          )
          .locale(locale)
          .format('lll'),
    },
  ];
};

const getReservationDetailsPath = (checkinRecord: CheckinRecord): string => {
  if (!checkinRecord) {
    return '';
  }
  if (
    !checkinRecord.is_partnership_reservation &&
    checkinRecord.is_dynamic_package_component_reservation
  ) {
    return `/reservations/${checkinRecord.package_reservation_id}`;
  }
  return `/reservations/${checkinRecord.reservation_id}`;
};
