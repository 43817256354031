import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-final-form';
import { TopPage } from '@nutmeglabs/falcon-ui';

import {
  fetchProducts,
  fetchPassthroughCandidateProducts,
} from 'client/actions/products';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { FalconUIContextProvider } from 'client/components/FalconUIContextProvider/FalconUIContextProvider';
import { SourceLanguage } from 'shared/models/swagger';
import { isPartnershipAgent } from 'client/libraries/util/partnership';

import { TemplateContent } from './TemplateContent/TemplateContent';

interface Props {
  editMode?: boolean;
  language: SourceLanguage;
}

export const CustomTopPageTemplate = ({ editMode, language }: Props) => {
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  React.useEffect(() => {
    dispatch(fetchProducts());
    if (isPartnershipAgent(activeUserOrganization)) {
      dispatch(
        fetchPassthroughCandidateProducts({
          agent_id: activeUserOrganization?.corresponding_organization_id ?? '',
        })
      );
    }
  }, [activeUserOrganization]);

  const form = useForm();
  const pageData = form.getState().values;

  return (
    <FalconUIContextProvider language={language}>
      <TemplateContent editMode={editMode} pageData={pageData as TopPage} />
    </FalconUIContextProvider>
  );
};
