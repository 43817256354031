import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Box } from 'client/components/Box/Box';
import { CustomTable } from 'client/components/CustomTable/CustomTable';
import { fetchProducts } from 'client/actions/products';
import {
  deleteSurveyTemplate,
  fetchSurveyTemplates,
} from 'client/actions/surveyTemplates';
import { Delete as DeleteIcon } from 'client/components/Icons/Delete';
import { Copy as CopyIcon } from 'client/components/Icons/Copy';
import { Tabs } from 'client/components/Tabs/Tabs';
import type { ReduxState } from 'client/reducers';
import { getBookingWidgetApiKeyUrl } from 'client/libraries/util/getBookingWidgetUrl';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
} from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { DeleteConfirmModal } from 'client/components/DeleteConfirmModal/DeleteConfirmModal';
import { getLanguageName } from 'client/libraries/i18n';
import { SourceLanguage, SurveyTemplate } from 'shared/models/swagger';
import editIcon from 'client/images/ic_edit.svg';
import baseStyles from 'client/base.module.css';
import { Badge, BadgeColor } from 'client/components/v3/Common/Badge';
import { TranslateFuncType } from 'client/components/Translate';
import { config } from 'client/config';
import { QRCode as QRCodeIcon } from 'client/components/Icons/QRCode';

import styles from './SurveyTemplateList.module.css';
import { QrCodeModal } from './QrCodeModal';

const EditDeleteCell = ({
  surveyTemplate,
}: {
  surveyTemplate: SurveyTemplate;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const activeUser = useSelector(activeUserSelector);
  const bookingWebsiteUrl = getBookingWidgetApiKeyUrl(
    activeUserOrganization?.booking_widget_api_key ?? ''
  );

  return (
    <Box display="flex" alignItems="center">
      {hasCustomUserRoleWritePermissions(activeUser, 'SURVEY.SURVEYS') && (
        <>
          <Box>
            <Link
              to={`/surveys/templates/${surveyTemplate.id}/edit`}
              className={clsx(baseStyles['base-btn'], baseStyles['icon'])}
              data-text={t('Edit')}
            >
              <img src={editIcon} />
            </Link>
          </Box>
          <Box ml={2}>
            <DeleteIcon onClick={() => setShowDeleteModal(true)} />
          </Box>
          <Link
            to={{
              pathname: '/surveys/templates/new',
              state: { existingSurveyTemplate: surveyTemplate },
            }}
          >
            <CopyIcon />
          </Link>
        </>
      )}
      {showDeleteModal && (
        <DeleteConfirmModal
          header={t('Delete Survey')}
          content={
            <>
              {t('Are you sure you want to delete survey?')}
              <Box mt={2}>
                {t(
                  'All questions and answers in the survey will also be deleted.'
                )}
              </Box>
            </>
          }
          onConfirm={async () => {
            await dispatch(deleteSurveyTemplate(surveyTemplate.id ?? ''));
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          insertRoot
        />
      )}
      <div className={styles['actions']}>
        <Box ml={2}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${bookingWebsiteUrl}/surveypreview?id=${surveyTemplate.id}`}
            className={clsx(baseStyles['base-btn'])}
          >
            {t('Preview')}
          </a>
        </Box>
        <Box ml={2}>
          <Link
            to={`/surveys/templates/${surveyTemplate.id}/results`}
            className={clsx(baseStyles['base-btn'])}
          >
            {t('Results')}
          </Link>
        </Box>
      </div>
    </Box>
  );
};

export type ColumnType<T> = {
  Header: string;
  translatedColumnName?: string;
  id: string;
  accessor?: keyof T | ((row: T) => string);
  width?: 'short' | 'middle' | 'long';
  Cell?: (cellInfo: { original: T }) => any;
  th?: boolean;
};

const translateFormat = (isNpsSurvey: boolean, t: TranslateFuncType) => {
  if (isNpsSurvey) {
    return t('NPS');
  }
  return t('Normal');
};

const getBadgeColorForFormat = (isNpsSurvey: boolean): BadgeColor => {
  if (isNpsSurvey) {
    return 'success';
  }
  return 'warning';
};

export const SurveyTemplateList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUser = useSelector(activeUserSelector);

  const surveyTemplates = useSelector(
    (state: ReduxState) => state.surveyTemplates.all
  );
  const loading = useSelector(
    (state: ReduxState) => state.surveyTemplates.loading
  );

  const [showQrCodeModal, setShowQrCodeModal] = React.useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = React.useState('');

  const useColumns = (): ColumnType<SurveyTemplate>[] => {
    const { t } = useTranslation();
    const products = useSelector(
      (state: ReduxState) => state.products.summaries
    );

    return [
      {
        Header: '',
        width: 'long',
        Cell: (cellInfo) => (
          <EditDeleteCell surveyTemplate={cellInfo.original} />
        ),
        id: 'edit',
      },
      ...(config.enableNpsSurvey
        ? [
            {
              Header: t('QR code'),
              id: 'qrCode',
              width: 'middle',
              Cell: (cellInfo) => (
                <>
                  {cellInfo.original.status === 'SURVEY_TEMPLATE_PUBLISHED' && (
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                      <QRCodeIcon
                        onClick={() => {
                          setSelectedSurveyId(cellInfo.original.id ?? '');
                          setShowQrCodeModal(true);
                        }}
                      />
                    </div>
                  )}
                </>
              ),
            } as ColumnType<SurveyTemplate>,
          ]
        : []),
      {
        Header: t('Name'),
        width: 'long',
        accessor: 'template_name',
        id: 'templateName',
      },
      ...(config.enableNpsSurvey
        ? [
            {
              Header: t('Survey Format'),
              id: 'format',
              Cell: (cellInfo) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Badge
                    label={translateFormat(
                      cellInfo.original.is_nps_survey ? true : false,
                      t
                    )}
                    color={getBadgeColorForFormat(
                      cellInfo.original.is_nps_survey ? true : false
                    )}
                  />
                </div>
              ),
            } as ColumnType<SurveyTemplate>,
          ]
        : []),
      {
        Header: t('Title'),
        width: 'long',
        accessor: 'title',
        id: 'title',
      },
      {
        Header: t('Products'),
        width: 'long',
        Cell: (cellInfo) => (
          <div>
            <ul>
              {(cellInfo.original.product_ids ?? []).map((productId) => {
                const product = products.find((p) => p.id === productId);
                return product ? (
                  <li key={productId}>{getDisplayProductName(product)}</li>
                ) : null;
              })}
            </ul>
          </div>
        ),
        id: 'products',
      },
      {
        Header: t('Language'),
        width: 'short',
        accessor: (row) =>
          getLanguageName(row.source_language as SourceLanguage, t),
        id: 'language',
      },
      {
        Header: t('Translated Languages'),
        width: 'middle',
        accessor: (row) =>
          (
            row.translated_languages?.map((lang) =>
              getLanguageName(lang as SourceLanguage, t)
            ) ?? []
          ).join(', '),
        id: 'translatedLanguages',
      },
    ];
  };
  const columns = useColumns();

  React.useEffect(() => {
    dispatch(fetchSurveyTemplates());
    dispatch(fetchProducts());
  }, []);

  const tabPanes = React.useMemo(() => {
    const publishedSurveyTemplates = surveyTemplates.filter(
      (surveyTemplate) => surveyTemplate.status === 'SURVEY_TEMPLATE_PUBLISHED'
    );
    const unpublishedSurveyTemplates = surveyTemplates.filter(
      (surveyTemplate) => surveyTemplate.status !== 'SURVEY_TEMPLATE_PUBLISHED'
    );

    return [
      {
        header: t('Published'),
        content: () =>
          publishedSurveyTemplates.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Published Surveys Found')}
            </div>
          ) : (
            <CustomTable
              items={publishedSurveyTemplates}
              columns={columns}
              usePaging
            />
          ),
      },
      {
        header: t('Unpublished'),
        content: () =>
          unpublishedSurveyTemplates.length === 0 ? (
            <div className={baseStyles['base-form-box__err']}>
              {t('No Unpublished Surveys Found')}
            </div>
          ) : (
            <CustomTable
              items={unpublishedSurveyTemplates}
              columns={columns}
              usePaging
            />
          ),
      },
    ];
  }, [surveyTemplates, columns]);

  return (
    <div>
      {loading && (
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
      )}
      {hasCustomUserRoleWritePermissions(activeUser, 'SURVEY.SURVEYS') && (
        <Link to="/surveys/templates/new">
          <button
            className={clsx(
              baseStyles['base-btn'],
              baseStyles['middle'],
              baseStyles['green']
            )}
          >
            {t('Create new survey')}
          </button>
        </Link>
      )}

      <Box mt={2}>
        <Tabs panes={tabPanes} />
      </Box>
      <QrCodeModal
        open={showQrCodeModal}
        onClose={() => setShowQrCodeModal(false)}
        surveyId={selectedSurveyId}
      />
    </div>
  );
};
