import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import type { Action } from 'redux';
import _ from 'lodash';

import {
  CREATE_PRODUCT_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  FETCH_PRODUCTS_BY_ID_FAILURE,
  FETCH_PRODUCTS_BY_ID_REQUEST,
  FETCH_PRODUCTS_BY_ID_SUCCESS,
  FETCH_PRODUCT_FOR_EDITING_FAILURE,
  FETCH_PRODUCT_FOR_EDITING_SUCCESS,
  FETCH_PRODUCTS_CANCELED,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_FAILURE,
  FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_REQUEST,
  FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_SUCCESS,
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  SET_PRODUCT_RESPONSIBLE_AGENT_ID_SUCCESS,
  UPDATE_PRODUCT_BLACKLIST_WHITELIST_FAILURE,
  UPDATE_PRODUCT_BLACKLIST_WHITELIST_REQUEST,
  UPDATE_PRODUCT_BLACKLIST_WHITELIST_SUCCESS,
  SET_PRODUCT_AGENT_UNIT_MAPPINGS_FAILURE,
  SET_PRODUCT_AGENT_UNIT_MAPPINGS_REQUEST,
  SET_PRODUCT_AGENT_UNIT_MAPPINGS_SUCCESS,
  FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_FAILURE,
  FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_REQUEST,
  FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_SUCCESS,
} from 'client/constants/ActionTypes';
import {
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import type { ReduxState } from 'client/reducers';
import { sortedByBookmark } from 'client/libraries/util/sortedByBookmark';
import { getVerboseDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import type {
  GetProductResponse,
  ListProductsResponse,
  ProductSummary,
  Product,
} from 'shared/models/swagger';

export const summariesSelector = (state: ReduxState) =>
  state.products.summaries;

export const bookmarksSetSelector = createSelector(
  activeUserSelector,
  (user) => {
    const productIDs = user && user.bookmarks && user.bookmarks.split(',');
    return new Set(productIDs || []);
  }
);

export type ProductSummaryWithBookmark = ProductSummary & {
  bookmarked: boolean;
};
export const summariesWithBookmarksSelector = createSelector(
  summariesSelector,
  bookmarksSetSelector,
  activeUserOrganizationSelector,
  (summaries, bookmarks, org) => {
    return sortedByBookmark(summaries, bookmarks, org);
  }
);

export const summariesSortedByBookmarkedSelector = createSelector(
  summariesWithBookmarksSelector,
  (products): ProductSummary[] => {
    return products.map((product: ProductSummaryWithBookmark) => {
      const { bookmarked: _removed, ...summaries } = product;
      return summaries;
    });
  }
);

export const passthroughCandidateSummariesSelector = (state: ReduxState) =>
  state.products.passthroughCandidates;

export const visiblePartnershipProductSummariesSelector = createSelector(
  passthroughCandidateSummariesSelector,
  activeUserOrganizationSelector,
  (summaries, org) => {
    const visibleProductIds =
      org?.partnership_settings?.supplier_products?.flatMap(
        (supplierProduct) =>
          supplierProduct.products?.map((product) => product.product_id) || []
      ) || [];

    return summaries.filter((summary: ProductSummary) =>
      visibleProductIds.includes(summary.id)
    );
  }
);

export const summariesWithPartnershipProductSelector = createSelector(
  summariesSelector,
  visiblePartnershipProductSummariesSelector,
  (summaries, visiblePartnershipProductSummaries) => {
    return summaries.concat(visiblePartnershipProductSummaries);
  }
);

export const bookingWidgetProductSummariesSelector = createSelector(
  summariesSortedByBookmarkedSelector,
  visiblePartnershipProductSummariesSelector,
  (summaries, visiblePartnershipProductSummaries) =>
    summaries
      .filter(
        (summary: ProductSummary) =>
          summary?.booking_widget_settings?.is_visible
      )
      .concat(visiblePartnershipProductSummaries)
);

export const productOptionsSelector = createSelector(
  summariesWithBookmarksSelector,
  (
    products
  ): {
    key: string;
    text: string;
    value: string;
  }[] => {
    return products.map((product: ProductSummaryWithBookmark) => ({
      key: product.id,
      value: product.id,
      text: getVerboseDisplayProductName(product),
    }));
  }
);

export const allProductTagsSelector = createSelector(
  summariesSelector,
  (products) => {
    // 1.internal product tags, by tag name string order
    const duplicatesInternalTags = products
      .map((p) => [...(p.internal_product_tags ?? [])])
      .reduce((acc: string[], val: string[]) => acc.concat(val), []);
    const internalProductTags = _.sortBy([...new Set(duplicatesInternalTags)]);

    // 2.Product tags, ordered by tag name string
    const duplicatesProductTags = products
      .map((p) => [...(p.product_tags ?? [])])
      .reduce((acc: string[], val: string[]) => acc.concat(val), []);
    const productTags = _.sortBy([...new Set(duplicatesProductTags)]);

    return internalProductTags.concat(productTags);
  }
);

export const supplierOptionsSelector = createSelector(
  (state: ReduxState) => state.products.summaries,
  (
    products
  ): {
    key: string;
    text: string;
    value: string;
  }[] => {
    const supplierNamesById: Record<string, string> = {};

    for (const product of products) {
      supplierNamesById[product.supplier_id || ''] =
        product.supplier_name || '';
    }

    return Object.keys(supplierNamesById).map((supplierId) => ({
      key: supplierId,
      text: supplierNamesById[supplierId],
      value: supplierId,
    }));
  }
);

// Reducers
const loading = (state = false, action: any) => {
  switch (action.type) {
    // These actions always trigger the loading screen
    case CREATE_PRODUCT_REQUEST:
    case UPDATE_PRODUCT_REQUEST:
    case FETCH_PRODUCTS_REQUEST:
    case FETCH_PRODUCTS_BY_ID_REQUEST:
    case DELETE_PRODUCT_REQUEST:
    case UPDATE_PRODUCT_BLACKLIST_WHITELIST_REQUEST:
    case SET_PRODUCT_AGENT_UNIT_MAPPINGS_REQUEST:
    case FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_REQUEST:
      return true;

    // These actions always remove the loading screen
    case CREATE_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
    case FETCH_PRODUCTS_BY_ID_SUCCESS:
    case CREATE_PRODUCT_FAILURE:
    case UPDATE_PRODUCT_FAILURE:
    case FETCH_PRODUCTS_BY_ID_FAILURE:
    case DELETE_PRODUCT_SUCCESS:
    case DELETE_PRODUCT_FAILURE:
    case FETCH_PRODUCTS_SUCCESS:
    case FETCH_PRODUCTS_FAILURE:
    case FETCH_PRODUCTS_CANCELED:
    case UPDATE_PRODUCT_BLACKLIST_WHITELIST_SUCCESS:
    case UPDATE_PRODUCT_BLACKLIST_WHITELIST_FAILURE:
    case SET_PRODUCT_AGENT_UNIT_MAPPINGS_SUCCESS:
    case SET_PRODUCT_AGENT_UNIT_MAPPINGS_FAILURE:
    case FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_SUCCESS:
    case FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_FAILURE:
      return false;

    default:
      return state;
  }
};

const productsLoading = (state = false, action: any) => {
  switch (action.type) {
    // These actions always trigger the loading screen
    case FETCH_PRODUCTS_REQUEST:
      return true;

    // These actions always remove the loading screen
    case FETCH_PRODUCTS_SUCCESS:
    case FETCH_PRODUCTS_FAILURE:
    case FETCH_PRODUCTS_CANCELED:
      return false;

    default:
      return state;
  }
};

const lastUpdateStatus = (state = '', action: any) => {
  switch (action.type) {
    case UPDATE_PRODUCT_REQUEST:
      return 'REQUESTED';

    case UPDATE_PRODUCT_SUCCESS:
      return 'SUCCEEDED';

    case UPDATE_PRODUCT_FAILURE:
      return 'FAILED';

    default:
      return state;
  }
};

const error = (state = '', action: any) => {
  switch (action.type) {
    case CREATE_PRODUCT_FAILURE:
    case FETCH_PRODUCTS_FAILURE:
    case FETCH_PRODUCTS_BY_ID_FAILURE:
    case UPDATE_PRODUCT_FAILURE:
    case FETCH_PRODUCT_FOR_EDITING_FAILURE:
    case DELETE_PRODUCT_FAILURE:
    case UPDATE_PRODUCT_BLACKLIST_WHITELIST_FAILURE:
    case SET_PRODUCT_AGENT_UNIT_MAPPINGS_FAILURE:
    case FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_FAILURE:
      return action.error;

    case CREATE_PRODUCT_SUCCESS:
    case FETCH_PRODUCTS_SUCCESS:
    case FETCH_PRODUCTS_BY_ID_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
    case FETCH_PRODUCT_FOR_EDITING_SUCCESS:
    case DELETE_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT_BLACKLIST_WHITELIST_SUCCESS:
    case SET_PRODUCT_AGENT_UNIT_MAPPINGS_SUCCESS:
    case FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_SUCCESS:
      return '';

    default:
      return state;
  }
};

const byID = (state: Record<string, Product> = {}, action: any) => {
  switch (action.type) {
    case FETCH_PRODUCTS_BY_ID_SUCCESS: {
      const responses = action.responses as any as GetProductResponse[];
      const productsByID: Record<string, Product> = {};
      responses.forEach((r) => {
        productsByID[r.id] = r;
      });
      return { ...state, ...productsByID };
    }

    case SET_PRODUCT_AGENT_UNIT_MAPPINGS_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
      return { ...state, [action.response.id]: action.response };

    case UPDATE_PRODUCT_BLACKLIST_WHITELIST_SUCCESS: {
      const result = { ...state };
      result[action.response.id] = {
        ...result[action.response.id],
        blacklisted_agents: action.response.blacklisted_agents,
        whitelisted_agents: action.response.whitelisted_agents,
      };
      return result;
    }

    case DELETE_PRODUCT_SUCCESS: {
      const result = { ...state };
      delete result[action.id];
      return result;
    }
    default:
      return state;
  }
};

const ids = (state: string[] = [], action: any): string[] => {
  switch (action.type) {
    case FETCH_PRODUCTS_BY_ID_SUCCESS: {
      const responses = action.responses as any as GetProductResponse[];
      return [...new Set([...state, ...responses.map((r) => r.id)])];
    }

    /* TODO
    case UPDATE_PRODUCT_SUCCESS: {
      const response = ((action.response: any): UpdateProductResponse);
       return _.uniq([...state, response.id]);
    }
    */
    case DELETE_PRODUCT_SUCCESS:
      return state.filter((id) => id !== action.id);

    default:
      return state;
  }
};

const lastCreatedProduct = (state = null, action: any) => {
  switch (action.type) {
    case CREATE_PRODUCT_REQUEST:
      return null;

    case CREATE_PRODUCT_SUCCESS: {
      return action.response;
    }

    default:
      return state;
  }
};

const summaries = (
  state: ProductSummary[] = [],
  action: any
): Array<ProductSummary> => {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS: {
      const resp = action.response as any as ListProductsResponse;
      return resp.products || [];
    }
    case DELETE_PRODUCT_SUCCESS:
      return state.filter((i) => i.id !== action.id);

    case SET_PRODUCT_RESPONSIBLE_AGENT_ID_SUCCESS:
      // Reset responsible agent id for product that succeeded
      return state.map((p) =>
        p.id === action.productID
          ? { ...p, responsible_agent_id: action.responsibleAgentID }
          : p
      );

    default:
      return state;
  }
};

const passthroughCandidatesLoading = (state = false, action: any): boolean => {
  switch (action.type) {
    case FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_REQUEST:
      return true;

    case FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_FAILURE:
    case FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_SUCCESS:
      return false;

    default:
      return state;
  }
};

const passthroughCandidates = (
  state: ProductSummary[] = [],
  action: any
): Array<ProductSummary> => {
  switch (action.type) {
    case FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_SUCCESS: {
      const resp = action.response as any as ListProductsResponse;
      return resp.products || [];
    }
    case FETCH_PASSTHROUGH_CANDIDATE_PRODUCTS_REQUEST:
      return [];

    default:
      return state;
  }
};

const partnershipPackageProducts = (
  state: ProductSummary[] = [],
  action: any
): Array<ProductSummary> => {
  switch (action.type) {
    case FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_SUCCESS: {
      const resp = action.response as any as ListProductsResponse;
      return resp.products || [];
    }
    case FETCH_PARTNERSHIP_PACKAGE_PRODUCTS_REQUEST:
      return [];

    default:
      return state;
  }
};

const reducer = combineReducers({
  error,
  lastUpdateStatus,
  byID,
  ids,
  loading,
  productsLoading,
  summaries,
  lastCreatedProduct,
  passthroughCandidates,
  passthroughCandidatesLoading,
  partnershipPackageProducts,
});

export const products = (
  state: any,
  action: Action
): ReturnType<typeof reducer> => {
  // Reset data to initial values when impersonating or logging out
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
