import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState, useContext } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import {
  Account,
  Product,
  ProductInstance,
  Reservation,
  ReservationReceipt,
} from 'shared/models/swagger';
import StatusRelatedButtons from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsHeader/StatusRelatedButtons';
import { Button } from 'client/components/v3/Common/Button';
import { SendReservationEmailModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsHeader/SendReservationEmailModal';
import { sendReservationStatusEmail } from 'client/actions/reservations';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
} from 'client/libraries/util/util';
import {
  downloadReservationReceiptPDF,
  fetchReservationReceipt,
  issueReservationReceipt,
  resetIssueReservationReceiptStatus,
  sendReservationReceiptEmail,
} from 'client/actions/reservationReceipts';
import { ReceiptModal } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsHeader/ReceiptModal';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { ReduxState } from 'client/reducers';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { isPartnershipComponentReservation } from 'client/libraries/util/partnership';

import styles from './ReservationDetailsHeader.module.css';

type Props = {
  reservation: Reservation;
  reservationReceipt?: ReservationReceipt;
  product: Product;
  productInstance: ProductInstance;
  activeUser: Account | null;
};

export const ReservationDetailsHeader = ({
  reservation,
  reservationReceipt,
  product,
  productInstance,
  activeUser,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showVoucherEmailModal, setShowVoucherEmailModal] = useState(false);
  const [showCancellationEmailModal, setShowCancellationEmailModal] =
    useState(false);
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const [
    reservationVoucherEmailSendingStatus,
    setReservationVoucherEmailSendingStatus,
  ] = useState<'SUCCEEDED' | 'REQUESTED' | 'FAILED' | null>(null);
  const [
    reservationCancellationEmailSendingStatus,
    setReservationCancellationEmailSendingStatus,
  ] = useState<'SUCCEEDED' | 'REQUESTED' | 'FAILED' | null>(null);
  const [receiptPdfSendingStatus, setReceiptPdfSendingStatus] = useState<
    'SUCCEEDED' | 'REQUESTED' | 'FAILED' | null
  >(null);
  const { partnershipMode } = useContext(PartnershipModeContext);
  const isPartnershipComponent = isPartnershipComponentReservation(reservation);
  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMenu]);

  const paths = [
    { text: t('Reservations'), url: '/reservations-v3#root' }, // Note: without root, history via back button won't be correct
    { text: reservation.agent_reference ?? '', url: '' },
  ];

  const currentStatus = getCurrentStatus(reservation);
  const useHTMLVoucher = currentStatus === 'CONFIRMED';
  const useReceipt = currentStatus === 'CONFIRMED' && !partnershipMode;

  const showSendCancellationEmailButton =
    currentStatus === 'CANCELED_BY_AGENT' ||
    currentStatus === 'CANCELED_BY_SUPPLIER' ||
    currentStatus === 'CANCELED_BY_GUEST';

  const email = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'email'
  )?.response;

  const isChangeReservationAvailable = () => {
    if (reservation?.is_partnership_reservation || partnershipMode) {
      const now = moment().tz(reservation?.start_timezone ?? '');
      const changeFromStartTime = moment.tz(
        reservation?.start_date_time_utc,
        reservation?.start_timezone ?? ''
      );
      return changeFromStartTime.isSameOrAfter(now, 'month');
    }
    if (activeUser?.organization_type === 'AGENT') {
      return false;
    }

    let createReservationOrganizationId = '';
    if (reservation?.status_history?.length > 0) {
      createReservationOrganizationId =
        reservation?.status_history?.[0]?.action_source?.entity_id ?? '';
    }

    if (
      reservation?.booking_source?.source_type === 'AGENT' &&
      activeUser?.organization_id !== createReservationOrganizationId
    ) {
      return false;
    }

    if (reservation?.payment_type === 'PAID_PARTIALLY') {
      return false;
    }

    if (!hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST')) {
      return false;
    }

    if (reservation?.is_dynamic_package_reservation) {
      return false;
    }

    const now = moment().tz(reservation?.start_timezone ?? '');
    const changeFromStartTime = moment.tz(
      reservation?.start_date_time_utc,
      reservation?.start_timezone ?? ''
    );
    return changeFromStartTime.isSameOrAfter(now, 'month');
  };

  const organization = useSelector(activeUserOrganizationSelector);
  const receiptProviso =
    organization?.supplier_guest_receipt_settings?.receipt_proviso ?? '';

  const reservationReceiptPdfStatus = useSelector(
    (state: ReduxState) => state.reservationReceipts.pdfStatus
  );
  const reservationReceiptEmailStatus = useSelector(
    (state: ReduxState) => state.reservationReceipts.emailStatus
  );
  const reservationLanguage = reservation?.field_responses?.find(
    (fieldResponse) => fieldResponse.key === 'preferred_language_iso2'
  )?.response;

  return (
    <PageHeader title={t('Reservation Details')} breadcrumbPaths={paths}>
      <>
        {useReceipt && !isPartnershipComponent && (
          <>
            <Button
              text={
                reservationReceipt && reservationReceipt?.receiver_name
                  ? t('Receipt PDF')
                  : t('Issue Receipt')
              }
              color="primary"
              loading={
                reservationReceiptPdfStatus === 'IN_FLIGHT' ||
                reservationReceiptEmailStatus === 'IN_FLIGHT'
              }
              onClick={() => {
                // Reset action status for issue each time the modal is opened
                dispatch(resetIssueReservationReceiptStatus());
                setShowPdfModal(true);
              }}
            />
          </>
        )}
      </>
      <>
        {useHTMLVoucher && !isPartnershipComponent && (
          <Link
            to={`/reservations/${reservation.id}/voucher`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              text={t('Voucher')}
              uiType="bg"
              size="md"
              color="white"
              iconAfterText={
                <i className="c-icon-outline-general-link-external-02"></i>
              }
            />
          </Link>
        )}
      </>
      {!isPartnershipComponent ? (
        <StatusRelatedButtons
          readOnly={false} // TODO: check later
          reservation={reservation}
          product={product}
          productInstance={productInstance}
        />
      ) : (
        <></>
      )}
      <>
        {!isTerminalReservationStatus(currentStatus) &&
          isChangeReservationAvailable() &&
          !isPartnershipComponent && (
            <>
              <Link
                // TODO: remove v3 from url later
                // Transition to booking form page instead of create new reservation
                to={{
                  pathname: `${
                    partnershipMode ? '/partnership' : ''
                  }/products/${product.id}/instances/${
                    productInstance.id
                  }/book-v3`,
                  // Use state to persist to location
                  state: {
                    productId: reservation.product_id,
                    productInstanceId: reservation.product_instance_id,
                    reservation: reservation,
                    isChangeReservation: true,
                  },
                }}
              >
                <Button
                  text={t('Change Reservation')}
                  size="md"
                  color="secondary"
                />
              </Link>
            </>
          )}
      </>
      {!isPartnershipComponent ? (
        <fieldset ref={fieldSetRef} className={styles['p-primary__select']}>
          <div>
            <Button
              uiType="bg"
              size="icon"
              color="white"
              iconBeforeText={
                <i className="c-icon-solid-general-dots-vertical"></i>
              }
              onClick={() => setShowMenu(!showMenu)}
            />
          </div>
          <ul
            className={clsx(
              styles['p-primary__select__menu'],
              showMenu && styles['is-active']
            )}
          >
            {reservation.rebooked_from_reservation_id && (
              <li className={styles['p-primary__select__menu__item']}>
                <Link
                  to={{
                    pathname: `/reservations/${reservation.rebooked_from_reservation_id}`,
                  }}
                >
                  {t('See the reservation before the change')}
                </Link>
              </li>
            )}
            {reservation.changed_to_reservation_id && (
              <li className={styles['p-primary__select__menu__item']}>
                <Link
                  to={{
                    pathname: `/reservations/${reservation.changed_to_reservation_id}`,
                  }}
                >
                  {t('See the reservation after the change')}
                </Link>
              </li>
            )}
            {useHTMLVoucher && (
              <li
                className={styles['p-primary__select__menu__item']}
                onClick={() => setShowVoucherEmailModal(true)}
              >
                <p>{t('Send Voucher Email')}</p>
              </li>
            )}
            {showSendCancellationEmailButton && (
              <li
                className={styles['p-primary__select__menu__item']}
                onClick={() => setShowCancellationEmailModal(true)}
              >
                <p>{t('Send Cancellation Email')}</p>
              </li>
            )}
            <li className={styles['p-primary__select__menu__item']}>
              <Link
                // TODO: remove v3 from url later
                to={{
                  pathname: `/reservations/new-v3`,
                  // Use state to persist to location
                  state: { reservation, isReservationWithSameGuests: true },
                }}
              >
                {t('Create new reservation with same guests')}
              </Link>
            </li>
            {/* TODO: this will be implemented later, hide for now */}
            {/* <li
              className={styles['p-primary__select__menu__item']}
              id="modal-btn-11"
            >
              <p>予約ステータスの履歴を確認</p>
            </li> */}
          </ul>
        </fieldset>
      ) : (
        <></>
      )}

      <>
        {showVoucherEmailModal && (
          <SendReservationEmailModal
            initialToAddress={email}
            title={t('Send Voucher Email')}
            onSubmit={async (email?: string) => {
              // Use await here, otherwise the execution order will not be sequential
              try {
                await setReservationVoucherEmailSendingStatus('REQUESTED');
                setShowMenu(false);
                await dispatch(
                  sendReservationStatusEmail(reservation?.id ?? '', email)
                );
                await setReservationVoucherEmailSendingStatus('SUCCEEDED');
                await setShowVoucherEmailModal(false);
              } catch (e) {
                setReservationVoucherEmailSendingStatus('FAILED');
              }
            }}
            onClose={() => setShowVoucherEmailModal(false)}
          />
        )}
        {showCancellationEmailModal && (
          <SendReservationEmailModal
            initialToAddress={email}
            title={t('Send Cancellation Email')}
            onSubmit={async (email?: string) => {
              // Use await here, otherwise the execution order will not be sequential
              try {
                await setReservationCancellationEmailSendingStatus('REQUESTED');
                setShowMenu(false);
                await dispatch(
                  sendReservationStatusEmail(reservation?.id ?? '', email)
                );
                await setReservationCancellationEmailSendingStatus('SUCCEEDED');
                await setShowCancellationEmailModal(false);
              } catch (e) {
                setReservationCancellationEmailSendingStatus('FAILED');
              }
            }}
            onClose={() => setShowCancellationEmailModal(false)}
          />
        )}
        {showPdfModal && (
          <ReceiptModal
            initialReceiptProviso={
              receiptProviso
                ? receiptProviso
                : reservationReceipt?.receipt_proviso
            }
            initialToAddress={email}
            reservation={reservation}
            reservationReceipt={reservationReceipt}
            title={t('Receipt')}
            onClose={() => setShowPdfModal(false)}
            onDownload={() => {
              dispatch(downloadReservationReceiptPDF(reservation?.id ?? ''));
            }}
            onIssue={async (receiverName?: string, receiptProviso?: string) => {
              await dispatch(
                issueReservationReceipt({
                  reservation_id: reservation?.id ?? '',
                  supplier_id: organization?.id ?? '',
                  receiver_name: receiverName,
                  receipt_proviso: receiptProviso,
                  language: reservationLanguage,
                  supplier_receipt_business_name:
                    organization?.supplier_guest_receipt_settings
                      ?.receipt_business_name,
                  supplier_business_registration_number:
                    organization?.supplier_guest_receipt_settings
                      ?.business_registration_number,
                  supplier_consumption_tax_calculation:
                    organization?.supplier_guest_receipt_settings
                      ?.consumption_tax_calculation,
                  supplier_address: organization?.office_mailing_address,
                  supplier_phone_number: organization?.office_phone,
                })
              );
              await dispatch(fetchReservationReceipt(reservation?.id ?? ''));
            }}
            onSendEmail={async (email?: string) => {
              try {
                await setReceiptPdfSendingStatus('REQUESTED');
                await dispatch(
                  sendReservationReceiptEmail(reservation?.id ?? '', email)
                );
                await setReceiptPdfSendingStatus('SUCCEEDED');
              } catch (e) {
                setReceiptPdfSendingStatus('FAILED');
              }
            }}
          />
        )}
        {(reservationVoucherEmailSendingStatus === 'SUCCEEDED' ||
          reservationCancellationEmailSendingStatus === 'SUCCEEDED' ||
          receiptPdfSendingStatus === 'SUCCEEDED') && (
          <Snackbar
            text={t('Send Successful')}
            color="success"
            shouldShow={true}
          />
        )}
        {(reservationVoucherEmailSendingStatus === 'FAILED' ||
          reservationCancellationEmailSendingStatus === 'FAILED' ||
          receiptPdfSendingStatus === 'FAILED') && (
          <Snackbar text={t('Send Failed')} color="error" shouldShow={true} />
        )}
      </>
    </PageHeader>
  );
};
