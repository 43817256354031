import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';
import { currency as currencyJs } from 'shared/libraries/currency';
import { Button } from 'client/components/Form';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';
import { Box } from 'client/components/Box/Box';
import { InfoTooltipWithPortal } from 'client/components/InfoTooltipWithPortal/InfoTooltipWithPortal';
import { loggedInAccountIsNutmegAdminSelector } from 'client/reducers/user';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import { ConfirmModal } from 'client/components/ConfirmModal/ConfirmModal';
import {
  markSystemFeeInvoicePaid,
  reReadyForConfirmationSystemFeeInvoice,
} from 'client/actions/systemFeeInvoices';
import componentStyles from 'client/components/components.module.css';
import baseStyles from 'client/base.module.css';
import { SystemFeeInvoice } from 'shared/models/swagger';

import { SystemFeeFareAdjustmentModal } from './SystemFeeFareAdjustmentModal/SystemFeeFareAdjustmentModal';
import styles from './SystemFeeInvoiceSummary.module.css';

interface Props {
  invoice: SystemFeeInvoice;
}

export const SystemFeeInvoiceSummary = ({ invoice }: Props) => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.systemFeeInvoices.loading
  );
  const [
    showSystemFeeFareAdjustmentModal,
    setShowSystemFeeFareAdjustmentModal,
  ] = React.useState(false);
  const [showPaymentCompleteModal, setShowPaymentCompleteModal] =
    React.useState(false);
  const [showStatusRevertModal, setShowStatusRevertModal] =
    React.useState(false);
  const currency = useSelector(defaultProductCurrencySelector);
  const isNutmegAdmin = useSelector(loggedInAccountIsNutmegAdminSelector);
  const dispatch = useDispatch();
  const showPaymentCompleteButton =
    ((invoice?.amount_due_by_nutmeg_final &&
      currencyJs(invoice?.amount_due_by_nutmeg_final).value > 0) ||
      (invoice?.amount_due_by_supplier_final &&
        currencyJs(invoice?.amount_due_by_supplier_final).value > 0)) &&
    invoice?.status === 'SYSTEM_FEE_CONFIRMED';

  return (
    <>
      <Box display="flex" width="100%">
        <Box ml="auto">
          {t('Currency Code: {{currency}}', {
            currency,
          })}
        </Box>
      </Box>
      <div className={clsx(componentStyles['c-table-nowrap'])}>
        <table>
          <tbody>
            <tr>
              <th className={clsx(baseStyles['base-t-112'])}>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Gross')}
                </Box>
              </th>
              <th className={clsx(baseStyles['base-t-112'])}>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Cancel Fee')}
                </Box>
              </th>
              <th className={clsx(baseStyles['base-t-112'])}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <div>
                    <Box width="100%" display="flex" justifyContent="center">
                      {t('PIF')}
                    </Box>
                    <Box width="100%" display="flex" justifyContent="center">
                      {t('Booking Fee')}
                    </Box>
                  </div>
                  <Box ml={1}>
                    <InfoTooltipWithPortal
                      tooltipStyle={{
                        width: '210px',
                      }}
                      text={
                        <Box>
                          <div>
                            {t('PIF Booking Fee is total of the following:')}
                          </div>
                          <div>{t(' - PIF Gross * booking fee rate')}</div>
                          <div>{t(' - PIF Cancel Fee * booking fee rate')}</div>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </th>
              <th className={clsx(baseStyles['base-t-112'])}>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('PIF')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Credit Card Fee')}
                </Box>
              </th>
              <th className={clsx(baseStyles['base-t-112'])}>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('POB')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Gross')}
                </Box>
              </th>
              <th className={clsx(baseStyles['base-t-112'])}>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('POB')}
                </Box>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Cancel Fee')}
                </Box>
              </th>
              <th className={clsx(baseStyles['base-t-112'])}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <div>
                    <Box width="100%" display="flex" justifyContent="center">
                      {t('POB')}
                    </Box>
                    <Box width="100%" display="flex" justifyContent="center">
                      {t('Booking Fee')}
                    </Box>
                  </div>
                  <Box ml={1}>
                    <InfoTooltipWithPortal
                      tooltipStyle={{
                        width: '210px',
                      }}
                      text={
                        <Box>
                          <div>
                            {t('POB Booking Fee is total of the following:')}
                          </div>
                          <div>{t(' - POB Gross * booking fee rate')}</div>
                          <div>{t(' - POB Cancel Fee * booking fee rate')}</div>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </th>
              <th className={clsx(baseStyles['base-t-112'])}>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Adjusted Amount')}
                </Box>
              </th>

              <th className={clsx(baseStyles['base-t-112'])}>
                <Box width="100%" display="flex" justifyContent="center">
                  {t('Billing Amount')}
                </Box>
              </th>
            </tr>
            <tr>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.pif_gross_amount)}
              </td>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.pif_cancellation_fee_amount)}
              </td>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.pif_booking_fee_amount)}
              </td>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.pif_credit_card_fee_amount)}
              </td>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.pob_gross_amount)}
              </td>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.pob_cancellation_fee_amount)}
              </td>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.pob_booking_fee_amount)}
              </td>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {getFormattedAmount(invoice?.adjusted_amount)}
              </td>
              <td
                className={clsx(
                  baseStyles['base-t-112'],
                  styles['right-align']
                )}
              >
                {invoice?.amount_due_by_nutmeg_final ? (
                  getFormattedAmount(invoice?.amount_due_by_nutmeg_final)
                ) : (
                  <div className={clsx(styles['red'], styles['right-align'])}>
                    {getFormattedAmount(invoice?.amount_due_by_supplier_final)}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {isNutmegAdmin && (
        <>
          <Box mt={2} display="flex" justifyContent="flex-end">
            {invoice?.status !== 'SYSTEM_FEE_CONFIRMED' ? (
              <Button
                style="yellow"
                size="small"
                onClick={() => setShowSystemFeeFareAdjustmentModal(true)}
              >
                {t('Fare Adjustment')}
              </Button>
            ) : (
              <>
                {!invoice?.payment_has_been_processed && (
                  <Button
                    style="yellow"
                    size="small"
                    onClick={() => setShowStatusRevertModal(true)}
                    loading={loading}
                  >
                    {t('Revert Status')}
                  </Button>
                )}
              </>
            )}
            {showPaymentCompleteButton && (
              <Box ml={1}>
                <Button
                  style="yellow"
                  size="small"
                  disabled={invoice?.payment_has_been_processed}
                  onClick={() => setShowPaymentCompleteModal(true)}
                >
                  {t('Payment Complete')}
                </Button>
              </Box>
            )}
          </Box>
          {showStatusRevertModal && (
            <ConfirmModal
              header={t('Change Status')}
              content={t(
                'Are you sure you want change the invoice status back to "Confirmation Required"?'
              )}
              onConfirm={async () => {
                await dispatch(
                  reReadyForConfirmationSystemFeeInvoice(invoice?.id ?? '')
                );
              }}
              onClose={() => setShowStatusRevertModal(false)}
              open={showStatusRevertModal}
              insertRoot={true}
            />
          )}
          {showPaymentCompleteModal && (
            <ConfirmModal
              header={t('Process Payment')}
              content={t(
                'Notification email will be sent to the supplier. Are you sure you want to complete the payment process?'
              )}
              onConfirm={async () => {
                await dispatch(markSystemFeeInvoicePaid(invoice?.id ?? ''));
              }}
              onClose={() => setShowPaymentCompleteModal(false)}
              open={showPaymentCompleteModal}
              insertRoot={true}
            />
          )}
          {showSystemFeeFareAdjustmentModal && (
            <SystemFeeFareAdjustmentModal
              systemFeeInvoice={invoice}
              onClose={() => setShowSystemFeeFareAdjustmentModal(false)}
            />
          )}
        </>
      )}
    </>
  );
};
