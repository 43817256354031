import moment from 'moment-timezone';
import { matchPath, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReduxState } from 'client/reducers';

import { HubspotChatWidget } from './HubspotChatWidget';
import { ChannelIO } from './ChannelIO';

export const ChatWidget = () => {
  const timezone = moment.tz.guess();
  const location = useLocation();
  const shouldUseUsaTheme =
    useSelector((state: ReduxState) => state.theme) === 'USA';

  const excludedRoutes = ['/maps/waittimesmanager'];

  const isExcludedRoute = excludedRoutes.some((route) =>
    matchPath(location.pathname, {
      path: route,
      exact: true,
    })
  );

  if (isExcludedRoute || shouldUseUsaTheme) {
    return null;
  }

  if (timezone === 'Asia/Tokyo') {
    return <ChannelIO />;
  }

  return <HubspotChatWidget />;
};
