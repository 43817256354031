// @flow

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { config } from 'client/config';
import { Loading } from 'client/pages/Loading';
import { FormTableBox } from 'client/components/FormTableBox/FormTableBox';
import type { ReduxState } from 'client/reducers';
import baseStyles from 'client/base.module.css';

import { PerEmailMessageAndSubjectEditor } from './PerEmailMessageAndSubjectEditor';

export const PerEmailMessagesTab = () => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  return (
    <div className={baseStyles['base-main__body__box']}>
      {loading && <Loading />}
      <div className={baseStyles['base-main__body__box__body']}>
        {/* saveSucceeded && <Message success header={t('Settings Saved')} /> */}
        <FormTableBox>
          <table>
            <tr>
              <th>{t('Received request reservation')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_AWAITING_CONFIRMATION" />
              </td>
            </tr>
            <tr>
              <th>{t('Reservation confirmed')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_CONFIRMED" />
              </td>
            </tr>
            <tr>
              <th>{t('Reservation confirmed (Lottery mode)')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_CONFIRMED_LOTTERY" />
              </td>
            </tr>
            <tr>
              <th>{t('Reservation rejected')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_REJECTED" />
              </td>
            </tr>
            {config.enableEmailCustomizationForLotteryMode && (
              <tr>
                <th>{t('Reservation rejected  (Lottery mode)')}</th>
                <td>
                  <PerEmailMessageAndSubjectEditor emailType="RESERVATION_REJECTED_LOTTERY" />
                </td>
              </tr>
            )}
            <tr>
              <th>{t('Reservation put on standby')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_WAITLISTED" />
              </td>
            </tr>
            <tr>
              <th>{t('Reservation changed')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_CHANGED" />
              </td>
            </tr>
            <tr>
              <th>{t('Reservation canceled')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_CANCELED_BY_SUPPLIER" />
              </td>
            </tr>
            <tr>
              <th>{t('Reminder')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_REMINDER" />
              </td>
            </tr>
            <tr>
              <th>{t('Reservation updated')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_UPDATED_BY_SUPPLIER" />
              </td>
            </tr>
            <tr>
              <th>{t('Reservation confirmed (Payment required)')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_CONFIRMED_PAYMENT_REQUIRED" />
              </td>
            </tr>
            <tr>
              <th>{t('Payment completed')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_CONFIRMED_PAYMENT_COMPLETE" />
              </td>
            </tr>
            <tr>
              <th>{t('Receipt')}</th>
              <td>
                <PerEmailMessageAndSubjectEditor emailType="RESERVATION_RECEIPT" />
              </td>
            </tr>
          </table>
        </FormTableBox>
      </div>
    </div>
  );
};
