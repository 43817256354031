import clsx from 'clsx';
import moment from 'moment-timezone';
import { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import baseStyles from 'client/v3-base.module.css';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import type {
  ReservationLocationWithTime,
  Reservation,
  Account,
} from 'shared/models/swagger';
import { Button } from 'client/components/v3/Common/Button';
import { CheckinEditButton } from 'client/pages/ReservationDetails/CheckinEditButton';
import { GuestHotelEditButton } from 'client/pages/ReservationDetails/GuestHotelEditButton';
import { LanguageISO } from 'shared/libraries/i18n';
import { PartnershipModeContext } from 'client/contexts/PartnershipModeContext';
import { PickupDropoffEditButton } from 'client/pages/ReservationDetails/PickupDropoffEditButton';
import { ReduxState } from 'client/reducers';
import { RequestedPickupDropoffEditButton } from 'client/pages/ReservationDetails/RequestedPickupDropoffEditButton';
import { TransportRoutePanel } from 'client/pages/v3/Reservation/ReservationDetails/DefaultReservation/ReservationDetailsSection/DetailInformation/TransportRoutePanel';
import { fetchProductByID } from 'client/actions/products';
import { fetchReservationByID } from 'client/actions/reservations';
import {
  getCurrentStatus,
  isTerminalReservationStatus,
  reservationIsCheckinCheckoutOnly,
} from 'client/libraries/util/util';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { operationAllowed } from 'shared/models/access';

import styles from '../ReservationDetailsSection.module.css';

interface DynamicPackageComponentDetailedInfomationProps {
  reservation: Reservation;
  componentReservationId: string;
  locale: LanguageISO;
  activeUser: Account | null;
  readOnly: boolean;
}

export const DynamicPackageComponentDetailedInfomation = ({
  reservation,
  componentReservationId,
  locale,
  activeUser,
  readOnly,
}: DynamicPackageComponentDetailedInfomationProps) => {
  const dispatch = useDispatch();
  const reservationById = useSelector(
    (state: ReduxState) => state.reservations.byID
  );
  const productById = useSelector((state: ReduxState) => state.products.byID);
  const currentStatus = getCurrentStatus(reservation);

  const componentReservation = reservationById[componentReservationId];
  const product = productById[componentReservation?.product_id ?? ''];

  useEffect(() => {
    if (!componentReservation) {
      dispatch(fetchReservationByID(componentReservationId));
    }
  }, [componentReservation, componentReservationId]);

  useEffect(() => {
    if (!product && componentReservation) {
      dispatch(fetchProductByID(componentReservation.product_id ?? ''));
    }
  }, [componentReservation, product, componentReservationId]);

  const { t } = useTranslation();

  const isCheckinCheckoutOnly = componentReservation
    ? reservationIsCheckinCheckoutOnly(componentReservation)
    : false;

  const userIsPassthroughSupplier =
    reservation.supplier_side_passthrough_reservation_id &&
    activeUser?.organization_type === 'SUPPLIER';

  const userIsPassthroughAgent =
    reservation.agent_side_passthrough_reservation_id &&
    activeUser?.organization_type === 'AGENT';

  const userIsPassthroughOrg =
    userIsPassthroughAgent || userIsPassthroughSupplier;

  const { partnershipMode } = useContext(PartnershipModeContext);

  const userCanEditPickupDropoff =
    !userIsPassthroughOrg &&
    !readOnly &&
    !isTerminalReservationStatus(currentStatus) &&
    operationAllowed(activeUser, 'write', 'reservationPickupDropoff') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !partnershipMode;

  const userCanEditRequestedPickupDropoff =
    !userIsPassthroughOrg &&
    !readOnly &&
    !isTerminalReservationStatus(currentStatus) &&
    !operationAllowed(activeUser, 'write', 'reservationPickupDropoff') &&
    hasCustomUserRoleWritePermissions(activeUser, 'RESERVATION.LIST') &&
    !partnershipMode;

  const LocationWithTime = ({
    label,
    locationWithTime,
  }: {
    label: string;
    locationWithTime: ReservationLocationWithTime;
  }) => {
    return (
      <div className={styles['p-secondary__item']}>
        <p className={styles['p-secondary__item__ttl']}>{label}</p>
        <table
          className={tableStyles['c-tableSmall']}
          style={{ overflow: 'hidden' }}
        >
          <tbody>
            <tr>
              <th className={baseStyles['u-width-176']}>{t('Time')}</th>
              <td>
                {locationWithTime && locationWithTime.date_time_utc
                  ? moment
                      .tz(
                        locationWithTime.date_time_utc,
                        product.start_timezone ?? ''
                      )
                      .locale(locale)
                      .format('lll')
                  : t('TBD')}
              </td>
            </tr>
            <tr>
              <th className={baseStyles['u-width-176']}>{t('Location')}</th>
              <td>
                {locationWithTime && locationWithTime.location_name
                  ? locationWithTime.location_name
                  : t('TBD')}
              </td>
            </tr>
            <tr>
              <th className={baseStyles['u-width-176']}>{t('Details')}</th>
              <td>
                {locationWithTime && locationWithTime.location_description
                  ? locationWithTime.location_description
                  : t('TBD')}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const defaultLocationWithTime: ReservationLocationWithTime = { id: '' };

  if (!componentReservation || !product) {
    return null;
  }

  return (
    <div>
      <div className={styles['p-reservationsDetail__actions']}>
        {userCanEditPickupDropoff ? (
          isCheckinCheckoutOnly ? (
            <CheckinEditButton
              reservationID={componentReservation.id}
              trigger={
                <Button
                  text={t('Edit')}
                  uiType="bg"
                  size="sm"
                  color="white"
                  iconBeforeText={
                    <i className="c-icon-outline-general-edit-05"></i>
                  }
                />
              }
            />
          ) : (
            <PickupDropoffEditButton
              reservationID={componentReservation.id}
              trigger={
                <Button
                  text={t('Edit')}
                  uiType="bg"
                  size="sm"
                  color="white"
                  iconBeforeText={
                    <i className="c-icon-outline-general-edit-05"></i>
                  }
                />
              }
            />
          )
        ) : userCanEditRequestedPickupDropoff ? (
          isCheckinCheckoutOnly ? (
            <GuestHotelEditButton
              reservation={componentReservation}
              trigger={
                <Button
                  text={t('Edit')}
                  uiType="bg"
                  size="sm"
                  color="white"
                  iconBeforeText={
                    <i className="c-icon-outline-general-edit-05"></i>
                  }
                />
              }
            />
          ) : (
            <RequestedPickupDropoffEditButton
              reservation={componentReservation}
              trigger={
                <Button
                  text={t('Edit')}
                  uiType="bg"
                  size="sm"
                  color="white"
                  iconBeforeText={
                    <i className="c-icon-outline-general-edit-05"></i>
                  }
                />
              }
            />
          )
        ) : null}
      </div>

      <div style={{ display: 'flex' }}>
        {isCheckinCheckoutOnly ? (
          <>
            <LocationWithTime
              label={t('Checkin')}
              locationWithTime={
                componentReservation?.checkin || defaultLocationWithTime
              }
            />
            <LocationWithTime
              label={t('Checkout')}
              locationWithTime={
                componentReservation?.checkout || defaultLocationWithTime
              }
            />
          </>
        ) : (
          <>
            <LocationWithTime
              label={t('Pickup')}
              locationWithTime={
                componentReservation?.pickup || defaultLocationWithTime
              }
            />
            <LocationWithTime
              label={t('Dropoff')}
              locationWithTime={
                componentReservation?.dropoff || defaultLocationWithTime
              }
            />
          </>
        )}
      </div>

      <div className={styles['p-secondary']}>
        <div className={clsx(styles['p-secondary__item'], styles['max'])}>
          <p className={styles['p-secondary__item__ttl']}>
            {t('Customer Hotel')}
          </p>
          <table
            className={tableStyles['c-tableSmall']}
            style={{ overflow: 'hidden' }}
          >
            <tbody>
              <tr>
                <th className={baseStyles['u-width-176']}>
                  {t('Customer Hotel')}
                </th>
                <td>
                  {(componentReservation?.guest_hotel?.location_name === 'TBD'
                    ? t('TBD')
                    : componentReservation?.guest_hotel?.location_name) ||
                    t('(none)')}
                </td>
              </tr>
              <tr>
                <th className={baseStyles['u-width-176']}>
                  {t('Desired Pickup Location')}
                </th>
                <td>
                  {(componentReservation.requested_pickup_location
                    ?.location_name === 'TBD'
                    ? t('TBD')
                    : componentReservation.requested_pickup_location
                        ?.location_name) || t('(none)')}
                  {userCanEditRequestedPickupDropoff && (
                    <RequestedPickupDropoffEditButton
                      reservation={componentReservation}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <th className={baseStyles['u-width-176']}>
                  {t('Desired Dropoff Location')}
                </th>
                <td>
                  {(componentReservation.requested_dropoff_location
                    ?.location_name === 'TBD'
                    ? t('TBD')
                    : componentReservation.requested_dropoff_location
                        ?.location_name) || t('(none)')}
                  {userCanEditRequestedPickupDropoff && (
                    // RequestedPickupDropoffEditButton
                    <RequestedPickupDropoffEditButton
                      reservation={componentReservation}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* No need to show transport routes if there is only 1 route defined */}
        {componentReservation?.transport_route &&
          componentReservation?.transport_route.length > 1 && (
            <TransportRoutePanel reservation={componentReservation} />
          )}
      </div>
    </div>
  );
};
