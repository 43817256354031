import { forwardRef } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { LanguageISO } from 'shared/libraries/i18n';
import { Account, Product, Reservation } from 'shared/models/swagger';
import {
  TutorialPopup,
  TutorialPopupContent,
  TutorialPopupDescription,
  TutorialPopupHeader,
} from 'client/components/TutorialPopup/TutorialPopup';
import baseStyles from 'client/v3-base.module.css';

import styles from '../ReservationDetailsSection.module.css';

import { DynamicPackageComponentDetailedInfomationTab } from './DynamicPackageComponentDetailedInfomationTab';

type Props = {
  reservation: Reservation;
  product: Product;
  locale: LanguageISO;
  activeUser: Account | null;
  readOnly: boolean;
};

export const DynamicPackageDetailedInformation = forwardRef<any, Props>(
  (props: Props) => {
    const { reservation, product, locale, activeUser, readOnly } = props;

    const { t } = useTranslation();

    return (
      <section
        id="detail"
        className={clsx(styles['g-section'], baseStyles['u-mt-6'])}
      >
        <TutorialPopup
          name="detail"
          content={
            <TutorialPopupContent>
              <TutorialPopupDescription
                text={t(
                  'Each tab shows detailed information about the reservation. You can find the necessary information by switching tabs, so please check if necessary.'
                )}
              />
              <TutorialPopupHeader text={t('Detail Information')} />
              <TutorialPopupDescription
                text={t(
                  'View details about your booking. In addition to reservation status, information like checkin or pickup information is also displayed.'
                )}
              />
              <TutorialPopupHeader text={t('Guest Information')} />
              <TutorialPopupDescription
                text={t(
                  'Reservation form information received for each participant is shown here.'
                )}
              />
              <TutorialPopupHeader text={t('Billing Info')} />
              <TutorialPopupDescription
                text={t(
                  'Shows a breakdown of the price amounts for the reservation. Please make adjustments like additional charges and refunds here.'
                )}
              />
            </TutorialPopupContent>
          }
        />

        <div className={styles['p-reservationsDetail']}>
          {/* Header */}
          <div className={styles['p-reservationsDetail__header']}>
            <p className={styles['p-reservationsDetail__ttl']}>
              {t('Detail Information')}
            </p>
          </div>

          {/* Content */}
          <div className={styles['p-reservationsDetail__body']}>
            <DynamicPackageComponentDetailedInfomationTab
              reservation={reservation}
              product={product}
              locale={locale}
              activeUser={activeUser}
              readOnly={readOnly}
            />
          </div>
        </div>
      </section>
    );
  }
);
