import * as React from 'react';

import { WaitTime } from 'shared/models/swagger';

import LiveInfo from './LiveInfo';
import Chart from './Chart';
import styles from './PopularTimes.module.css';

const PopularTimes = ({ waitTime }: { waitTime: WaitTime }) => {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <p className={styles.subtitle}>
          People usually spend <span className={styles.bold}>1-1.5 hours</span>{' '}
          here.
        </p>
      </header>
      <div className={styles.timeChart}>
        <Chart />
        <LiveInfo waitTime={waitTime} />
      </div>
    </div>
  );
};

export default PopularTimes;
