import * as React from 'react';
import clsx from 'clsx';

import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Loading } from 'client/components/v3/Common/Loading';

import styles from './Gadget.module.css';

interface Props {
  header: string;
  children?: React.ReactNode;
  tooltipText?: string;
  className?: string;
  loading?: boolean;
}

export const Gadget = ({
  header,
  children,
  tooltipText,
  className,
  loading,
}: Props) => {
  return (
    <div className={clsx(styles['container'], className)}>
      <div className={styles['container-inner']}>
        <div className={styles['header']}>
          <div className={styles['header-inner']}>
            <div>{header}</div>
            {tooltipText && (
              <Tooltip text={tooltipText}>
                <i className="c-icon-outline-general-info-circle"></i>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles['body']}>
          <div className={styles['body-inner']}>
            {loading ? <Loading size="md" /> : children}
          </div>
        </div>
      </div>
    </div>
  );
};
