import * as React from 'react';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { AccordionItem } from 'client/components/Accordion/Accordion';
import { ParticipationDate } from 'client/components/ParticipationDate/ParticipationDate';
import { Loading } from 'client/pages/Loading';
import { ETicketRedemptionCountDailyUsesDetails } from 'client/pages/ETicket/ETicketRedemptionCountDailyUses/ETicketRedemptionCountDailyUsesDetails';
import { ETicketRedemptionCountDailyUsesSummary } from 'client/pages/ETicket/ETicketRedemptionCountDailyUses/ETicketRedemptionCountDailyUsesSummary';
import { Select } from 'client/components/Form';
import {
  productOptionsSelector,
  summariesSortedByBookmarkedSelector,
} from 'client/reducers/products';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchProductsByID } from 'client/actions/products';
import { fetchETicketDailyUses } from 'client/actions/eTicketDailyUses';
import { setETicketDailyUsesDisplaySettings } from 'client/actions/eTicketDailyUsesSettings';
import { manifestProductGroupsSelector } from 'client/reducers/manifestSettings';
import baseStyles from 'client/base.module.css';
import componentStyles from 'client/components/components.module.css';
import type {
  ETicketDailyUsesExportParams,
  ProductSummary,
} from 'shared/models/swagger';

import styles from './ETicketRedemptionCountDailyUses.module.css';

export const ETicketRedemptionCountDailyUses = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const summaries = useSelector(summariesSortedByBookmarkedSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const eTicketDailyUsesList = useSelector(
    (state: ReduxState) => state.eTicketDailyUses.all
  );

  const filteredETicketDailyUsesList = React.useMemo(() => {
    return eTicketDailyUsesList.filter(
      (e) =>
        summaries.find((s) => s.id === e.product_id)?.qr_checkin_settings
          ?.should_use_redemption_count
    );
  }, [eTicketDailyUsesList, summaries]);

  const displaySettings = useSelector(
    (state: ReduxState) => state.eTicketDailyUsesSettings.displaySettings
  );
  const loading = useSelector(
    (state: ReduxState) => state.eTicketDailyUses.loading
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const productOptions = useSelector(productOptionsSelector);
  const productGroups = useSelector(manifestProductGroupsSelector);
  const selectedProduct = displaySettings.productId;
  const selectedProductGroup = displaySettings.selectedProductGroup;
  const selectedDate =
    displaySettings.selectedDate == ''
      ? moment()
          .tz(activeUserOrganization?.default_timezone ?? 'UTC')
          .format('YYYY-MM-DD')
      : displaySettings.selectedDate;
  const [showAllHeader, setShowAllHeader] = React.useState<boolean>(false);
  const [showSummary, setShowSummary] = React.useState<boolean>(true);
  const [downloadCSVParams, setDownloadCSVParams] =
    React.useState<ETicketDailyUsesExportParams>({
      product_ids: [],
      checkin_info_date_time_utc_from: '',
      unit_columns: [],
      columns: [],
      csv_format: 'SUMMARY',
      timezone: activeUserOrganization?.default_timezone ?? 'UTC',
    });

  const selectedProductGroupIds: string[] =
    productGroups.find((g) => selectedProductGroup === g.key)?.product_ids ??
    [];

  const utc = moment
    .tz(selectedDate, activeUserOrganization?.default_timezone ?? 'UTC')
    .utc()
    .format();

  let productIds: string[] = [];
  if (selectedProductGroup !== '' && selectedProduct === '') {
    productIds = selectedProductGroupIds;
  } else {
    productIds = selectedProduct === '' ? [] : [selectedProduct];
  }
  React.useEffect(() => {
    dispatch(
      fetchETicketDailyUses({
        product_ids: productIds,
        checkin_info_date_time_utc_from: utc,
      })
    );
  }, [selectedDate, selectedProduct, selectedProductGroup]);

  React.useEffect(() => {
    let desiredSortProductIds: string[] = [...productIds];
    if (desiredSortProductIds.length === 0) {
      desiredSortProductIds = [
        ...new Set(filteredETicketDailyUsesList.map((e) => e.product_id ?? '')),
      ];
    }
    desiredSortProductIds.sort((a, b) => {
      const tmp_a = summaries.find((p) => p?.id == a);
      const tmp_b = summaries.find((p) => p?.id == b);

      if (
        summaries.indexOf(tmp_a ?? ({} as ProductSummary)) <
        summaries.indexOf(tmp_b ?? ({} as ProductSummary))
      ) {
        return -1;
      } else if (
        summaries.indexOf(tmp_a ?? ({} as ProductSummary)) >
        summaries.indexOf(tmp_b ?? ({} as ProductSummary))
      ) {
        return 1;
      }

      return 0;
    });
    setDownloadCSVParams({
      ...downloadCSVParams,
      product_ids: desiredSortProductIds,
      checkin_info_date_time_utc_from: utc,
    });
    if (filteredETicketDailyUsesList.length) {
      dispatch(
        fetchProductsByID([
          ...new Set(
            filteredETicketDailyUsesList.map((item) => item.product_id ?? '')
          ),
        ])
      );
    }
  }, [filteredETicketDailyUsesList]);

  const toggleShowAllHeader = () => {
    setShowAllHeader(!showAllHeader);
  };

  const productGroupOptions = productGroups.map((p) => ({
    text: p.key ?? '',
    key: p.key ?? '',
    value: p.key ?? '',
  }));

  return (
    <>
      <div className={clsx(componentStyles['c-headline-search'])}>
        <div
          className={clsx(
            componentStyles['c-headline-search__item'],
            styles['search-item-z-index']
          )}
        >
          <div className={baseStyles['base-form-box__header']}>
            {t('Select Date')}
          </div>
          <ParticipationDate
            value={selectedDate}
            onChange={(date: string) => {
              dispatch(
                setETicketDailyUsesDisplaySettings({
                  ...displaySettings,
                  selectedDate: date,
                })
              );
            }}
            locale={locale}
          />
        </div>
        <div className={clsx(componentStyles['c-headline-search__item'])}>
          <Select
            label={t('Select Product Group')}
            search
            placeholder={t('All Products')}
            value={selectedProductGroup}
            options={[
              {
                text: t('All Products') as string,
                key: 'ALL_PRODUCTS',
                value: '',
              },
              ...productGroupOptions,
            ]}
            onChange={(e, { value }) => {
              dispatch(
                setETicketDailyUsesDisplaySettings({
                  ...displaySettings,
                  selectedProductGroup: value,
                  productId: '',
                })
              );
            }}
          />
        </div>
        <div
          className={clsx(
            componentStyles['c-headline-search__item'],
            showAllHeader ? '' : baseStyles['base-t-spHidden']
          )}
          style={{ zIndex: 3 }}
        >
          <Select
            label={t('Select Product')}
            search
            placeholder={t('All Products')}
            value={selectedProduct}
            options={[
              {
                text: t('All Products') as string,
                key: 'ALL_PRODUCTS',
                value: '',
              },
              ...productOptions.filter((o) => {
                if (selectedProductGroup === '') {
                  return true;
                }
                if (selectedProductGroupIds.indexOf(o.key) !== -1) {
                  return true;
                }
                return false;
              }),
            ]}
            onChange={(e, { value }) => {
              dispatch(
                setETicketDailyUsesDisplaySettings({
                  ...displaySettings,
                  productId: value,
                })
              );
            }}
          />
        </div>
        <a
          className={clsx(
            componentStyles['c-headline-search_spMore'],
            showAllHeader ? componentStyles['arrowUp'] : ''
          )}
          onClick={toggleShowAllHeader}
        >
          {showAllHeader ? t('Close') : t('See more')}
        </a>
      </div>

      <div>
        <AccordionItem
          header={t('Summary')}
          open={showSummary}
          onButtonClick={() => setShowSummary(!showSummary)}
        >
          {() => {
            return (
              <ETicketRedemptionCountDailyUsesSummary
                eTicketDailyUsesList={filteredETicketDailyUsesList}
              />
            );
          }}
        </AccordionItem>
      </div>
      <ETicketRedemptionCountDailyUsesDetails
        eTicketDailyUsesList={filteredETicketDailyUsesList}
        downloadParams={downloadCSVParams}
      />
      {loading && <Loading />}
    </>
  );
};
