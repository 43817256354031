import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import { productOptionsSelector } from 'client/reducers/products';
import { Add } from 'client/components/Icons/Add';
import { Delete } from 'client/components/Icons/Delete';
import { Box } from 'client/components/Box/Box';
import { FieldWrapper, Select } from 'client/components/Form';
import { getBookingSourceOptions } from 'client/libraries/util/getBookingSourceOptions';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import { getValidators } from 'shared/libraries/validate/validator';
import { ReduxState } from 'client/reducers';
import { getBookingWidgetPmpSupportLanguages } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getLanguageName } from 'client/libraries/i18n';

import { CustomerAutoTag } from '../v3/Customer/CustomerSettings/Tabs/TagSettings/FormValues';

import { Condition, getDefaultCondition } from './formValues';
import { DateFilterEditor } from './DateFilterEditor';
import { OptionFilterEditor } from './OptionFilterEditor';
import { CountFilterEditor } from './CountFilterEditor';
import { CustomFieldFilterEditor } from './CustomFieldFilterEditor';

interface Props {
  name: string;
}

export const ConditionGroupEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  const productOptions = useSelector(productOptionsSelector);
  const bookingSourceOptions = getBookingSourceOptions(t);
  const bookingStatusOptions = getBookingStatusOptions(t);
  const customerTags = useSelector((state: ReduxState) => state.customers.tags);
  const org = useSelector(activeUserOrganizationSelector);
  const autoTags = (org?.customer_ledger_settings?.customer_auto_tags ??
    []) as CustomerAutoTag[];

  const { required } = getValidators(t);

  const languageOptions = getBookingWidgetPmpSupportLanguages(org).map(
    (lang) => ({
      value: lang,
      text: getLanguageName(lang, t),
    })
  );

  const filterCategoryOptions = [
    {
      text: t('Reservation'),
      value: 'RESERVATION',
    },
    {
      text: t('Registered Member'),
      value: 'REGISTERED_MEMBER',
    },
    {
      text: t('Cart Drop'),
      value: 'CART_DROP',
    },
  ];

  const filterCategoryByReservationOptions = [
    {
      text: t('Participation Date'),
      value: 'PARTICIPATION_DATE',
    },
    {
      text: t('Booked Date'),
      value: 'BOOKED_DATE',
    },
    {
      text: t('Product'),
      value: 'PRODUCT',
    },
    {
      text: t('Booking Status'),
      value: 'BOOKING_STATUS',
    },
    {
      text: t('Booking Source'),
      value: 'BOOKING_SOURCE',
    },
    {
      text: t('Booking Language'),
      value: 'BOOKING_LANGUAGE',
    },
  ];

  const filterCategoryByRegisteredMember = [
    {
      text: t('Registration Date'),
      value: 'REGISTRATION_DATE',
    },
    {
      text: t('Last Participation Date'),
      value: 'LAST_PARTICIPATION_DATE',
    },
    {
      text: t('Number of Participations'),
      value: 'PARTICIPATION_COUNT',
    },
    {
      text: t('Age'),
      value: 'AGE',
    },
    {
      text: t('Gender'),
      value: 'GENDER',
    },
    {
      text: t('Manual Tag'),
      value: 'TAG',
    },
    {
      text: t('Auto Tag'),
      value: 'AUTO_TAG',
    },
    {
      text: t('Language'),
      value: 'LANGUAGE',
    },
    {
      text: t('Custom Field'),
      value: 'CUSTOM_FIELD',
    },
  ];

  const filterCategoryByCartDrop = [
    {
      text: t('Cart Drop Date'),
      value: 'CART_DROP_DATE',
    },
    {
      text: t('Participation Date'),
      value: 'PARTICIPATION_DATE',
    },
    {
      text: t('Product'),
      value: 'PRODUCT',
    },
  ];

  return (
    <FieldArray<Condition> name={`${name}.conditions`}>
      {({ fields }) => {
        return (
          <table>
            <colgroup>
              <col />
              <col style={{ width: '80px' }} />
            </colgroup>
            <tbody>
              {fields.map((name, idx) => {
                return (
                  <tr key={name}>
                    <td>
                      <FieldWrapper
                        label={idx === 0 ? t('Filter By') : t('AND Filter By')}
                      />
                      <Box display="flex">
                        <Box mr={1}>
                          <Field
                            name={`${name}.filterCategory`}
                            validate={required}
                          >
                            {({ input, meta: { error, touched } }) => (
                              <Select
                                width={180}
                                value={input.value}
                                onChange={(e, { value }) =>
                                  input.onChange(value)
                                }
                                options={filterCategoryOptions}
                                error={touched && error}
                              />
                            )}
                          </Field>
                        </Box>
                        {fields.value?.[idx]?.filterCategory ===
                          'RESERVATION' && (
                          <Field
                            name={`${name}.reservationFilter.filterType`}
                            validate={required}
                          >
                            {({ input, meta: { touched, error } }) => (
                              <Select
                                width={180}
                                value={input.value}
                                onChange={(e, { value }) =>
                                  input.onChange(value)
                                }
                                options={filterCategoryByReservationOptions}
                                error={touched && error}
                              />
                            )}
                          </Field>
                        )}
                        {fields.value?.[idx]?.filterCategory ===
                          'REGISTERED_MEMBER' && (
                          <Field
                            name={`${name}.registeredMemberFilter.filterType`}
                            validate={required}
                          >
                            {({ input, meta: { touched, error } }) => (
                              <Select
                                width={180}
                                value={input.value}
                                onChange={(e, { value }) =>
                                  input.onChange(value)
                                }
                                options={filterCategoryByRegisteredMember}
                                error={touched && error}
                              />
                            )}
                          </Field>
                        )}
                        {fields.value?.[idx]?.filterCategory ===
                          'CART_DROP' && (
                          <Field
                            name={`${name}.cartDropFilter.filterType`}
                            validate={required}
                          >
                            {({ input, meta: { touched, error } }) => (
                              <Select
                                width={180}
                                value={input.value}
                                onChange={(_, { value }) =>
                                  input.onChange(value)
                                }
                                options={filterCategoryByCartDrop}
                                error={touched && error}
                              />
                            )}
                          </Field>
                        )}
                      </Box>
                      {fields.value?.[idx]?.filterCategory === 'RESERVATION' &&
                        fields.value?.[idx]?.reservationFilter?.filterType ===
                          'PARTICIPATION_DATE' && (
                          <DateFilterEditor
                            name={`${name}.reservationFilter.participationDateFilter`}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory === 'RESERVATION' &&
                        fields.value?.[idx]?.reservationFilter?.filterType ===
                          'BOOKED_DATE' && (
                          <DateFilterEditor
                            name={`${name}.reservationFilter.bookedDateFilter`}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory === 'RESERVATION' &&
                        fields.value?.[idx]?.reservationFilter?.filterType ===
                          'PRODUCT' && (
                          <OptionFilterEditor
                            name={`${name}.reservationFilter.productFilter`}
                            options={productOptions}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory === 'RESERVATION' &&
                        fields.value?.[idx]?.reservationFilter?.filterType ===
                          'BOOKING_STATUS' && (
                          <OptionFilterEditor
                            name={`${name}.reservationFilter.bookingStatusFilter`}
                            options={bookingStatusOptions}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory === 'RESERVATION' &&
                        fields.value?.[idx]?.reservationFilter?.filterType ===
                          'BOOKING_SOURCE' && (
                          <OptionFilterEditor
                            name={`${name}.reservationFilter.bookingSourceFilter`}
                            options={bookingSourceOptions}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory === 'RESERVATION' &&
                        fields.value?.[idx]?.reservationFilter?.filterType ===
                          'BOOKING_LANGUAGE' && (
                          <OptionFilterEditor
                            name={`${name}.reservationFilter.bookingLanguageFilter`}
                            options={languageOptions}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory ===
                        'REGISTERED_MEMBER' &&
                        fields.value?.[idx]?.registeredMemberFilter
                          ?.filterType === 'REGISTRATION_DATE' && (
                          <DateFilterEditor
                            name={`${name}.registeredMemberFilter.registrationDateFilter`}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory ===
                        'REGISTERED_MEMBER' &&
                        fields.value?.[idx]?.registeredMemberFilter
                          ?.filterType === 'LAST_PARTICIPATION_DATE' && (
                          <DateFilterEditor
                            name={`${name}.registeredMemberFilter.lastParticipationDateFilter`}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory ===
                        'REGISTERED_MEMBER' &&
                        fields.value?.[idx]?.registeredMemberFilter
                          ?.filterType === 'PARTICIPATION_COUNT' && (
                          <CountFilterEditor
                            name={`${name}.registeredMemberFilter.participationCountFilter`}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory ===
                        'REGISTERED_MEMBER' &&
                        fields.value?.[idx]?.registeredMemberFilter
                          ?.filterType === 'AGE' && (
                          <OptionFilterEditor
                            name={`${name}.registeredMemberFilter.ageFilter`}
                            options={[
                              {
                                text: t('20s'),
                                value: '20s',
                              },
                              {
                                text: t('30s'),
                                value: '30s',
                              },
                              {
                                text: t('40s'),
                                value: '40s',
                              },
                              {
                                text: t('50s'),
                                value: '50s',
                              },
                              {
                                text: t('60+'),
                                value: '60+',
                              },
                            ]}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory ===
                        'REGISTERED_MEMBER' &&
                        fields.value?.[idx]?.registeredMemberFilter
                          ?.filterType === 'GENDER' && (
                          <OptionFilterEditor
                            name={`${name}.registeredMemberFilter.genderFilter`}
                            options={[
                              {
                                value: 'NO_RESPONSE',
                                text: t('No Response'),
                              },
                              {
                                value: 'MALE',
                                text: t('Male'),
                              },
                              {
                                value: 'FEMALE',
                                text: t('Female'),
                              },
                            ]}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory ===
                        'REGISTERED_MEMBER' &&
                        fields.value?.[idx]?.registeredMemberFilter
                          ?.filterType === 'LANGUAGE' && (
                          <OptionFilterEditor
                            name={`${name}.registeredMemberFilter.languageFilter`}
                            options={languageOptions}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory ===
                        'REGISTERED_MEMBER' &&
                        fields.value?.[idx]?.registeredMemberFilter
                          ?.filterType === 'TAG' && (
                          <OptionFilterEditor
                            name={`${name}.registeredMemberFilter.tagFilter`}
                            options={customerTags.map((tag) => ({
                              value: tag,
                              text: tag,
                            }))}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory ===
                        'REGISTERED_MEMBER' &&
                        fields.value?.[idx]?.registeredMemberFilter
                          ?.filterType === 'AUTO_TAG' && (
                          <OptionFilterEditor
                            name={`${name}.registeredMemberFilter.autoTagFilter`}
                            options={autoTags.map((tag) => ({
                              value: tag.name,
                              text: tag.name,
                            }))}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory ===
                        'REGISTERED_MEMBER' &&
                        fields.value?.[idx]?.registeredMemberFilter
                          ?.filterType === 'CUSTOM_FIELD' && (
                          <CustomFieldFilterEditor
                            name={`${name}.registeredMemberFilter.customFieldFilter`}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory === 'CART_DROP' &&
                        fields.value?.[idx]?.cartDropFilter?.filterType ===
                          'CART_DROP_DATE' && (
                          <DateFilterEditor
                            name={`${name}.cartDropFilter.cartDropDateFilter`}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory === 'CART_DROP' &&
                        fields.value?.[idx]?.cartDropFilter?.filterType ===
                          'PARTICIPATION_DATE' && (
                          <DateFilterEditor
                            name={`${name}.cartDropFilter.participationDateFilter`}
                          />
                        )}
                      {fields.value?.[idx]?.filterCategory === 'CART_DROP' &&
                        fields.value?.[idx]?.cartDropFilter?.filterType ===
                          'PRODUCT' && (
                          <OptionFilterEditor
                            name={`${name}.cartDropFilter.productFilter`}
                            options={productOptions}
                          />
                        )}
                    </td>
                    <td>
                      <Box display="flex">
                        <Add
                          onClick={() =>
                            (fields as any).insertAt(
                              idx + 1,
                              getDefaultCondition()
                            )
                          }
                        />
                        {(fields?.length || 0) > 1 && (
                          <Delete onClick={() => (fields as any).remove(idx)} />
                        )}
                      </Box>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      }}
    </FieldArray>
  );
};
