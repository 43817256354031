import React from 'react';
import moment from 'moment';
import clsx from 'clsx';

import styles from './TimeSlider.module.css';

const timeLabels = [0, 4, 8, 12];

interface SliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
}

export const TimeSlider: React.FC<SliderProps> = ({
  min,
  max,
  value,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value));
  };

  return (
    <div className={styles.container}>
      <div className={styles.sliderContainer}>
        <input
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={handleChange}
          className={styles.slider}
        />
      </div>

      <div className={styles.timelineContainer}>
        <div className={styles.timelineDividers}>
          {timeLabels.map((label, index) => (
            <div
              className={styles.dividerContainer}
              style={{
                left: `${(label * 100) / 14}%`,
              }}
              key={index}
            >
              <div className={clsx(styles.divider)} />
              <div key={index} className={clsx(styles.timeLabel)}>
                {moment()
                  .locale('en')
                  .hour(label + 8)
                  .minute(0)
                  .format('ha')}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
