import axios from 'axios';
import { ThunkDispatch } from 'redux-thunk';

import {
  FETCH_ON_SITE_ANALYTICS_CUSTOMERS_SUCCESS,
  FETCH_ON_SITE_ANALYTICS_CUSTOMERS_FAILURE,
  FETCH_ON_SITE_ANALYTICS_CUSTOMERS_REQUEST,
  FETCH_ON_SITE_ANALYTICS_CUSTOMER_SUCCESS,
  FETCH_ON_SITE_ANALYTICS_CUSTOMER_FAILURE,
  FETCH_ON_SITE_ANALYTICS_CUSTOMER_REQUEST,
} from 'client/constants/ActionTypes';
import type { ReduxState } from 'client/reducers';

import { SearchConditionGroup } from './analyticsCustomers';

import { getHTTPRequestHeaders } from '.';

type Dispatch = ThunkDispatch<any, any, any>;

const fetchOnSiteAnalyticsCustomersRequest = () => ({
  type: FETCH_ON_SITE_ANALYTICS_CUSTOMERS_REQUEST,
});

const fetchOnSiteAnalyticsCustomersSuccess = (response: any) => ({
  type: FETCH_ON_SITE_ANALYTICS_CUSTOMERS_SUCCESS,
  response,
});

const fetchOnSiteAnalyticsCustomersFailure = (error: any) => ({
  type: FETCH_ON_SITE_ANALYTICS_CUSTOMERS_FAILURE,
  error,
});

let fetchOnSiteAnalyticsCustomersCancel: () => void | undefined;
export const fetchOnSiteAnalyticsCustomers =
  (conditionGroups: SearchConditionGroup[]) =>
  (dispatch: Dispatch, getState: () => ReduxState) => {
    fetchOnSiteAnalyticsCustomersCancel?.();
    dispatch(fetchOnSiteAnalyticsCustomersRequest());
    axios
      .post(
        '/api/onsiteanalyticscustomers/search',
        {
          condition_groups: conditionGroups,
        },
        {
          headers: getHTTPRequestHeaders(getState()),
          cancelToken: new axios.CancelToken(function executor(c) {
            fetchOnSiteAnalyticsCustomersCancel = c;
          }),
        }
      )
      .then((result) => {
        dispatch(fetchOnSiteAnalyticsCustomersSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchOnSiteAnalyticsCustomersFailure('canceled'));
        dispatch(fetchOnSiteAnalyticsCustomersFailure(error));
      });
  };

const fetchOnSiteAnalyticsCustomerRequest = () => ({
  type: FETCH_ON_SITE_ANALYTICS_CUSTOMER_REQUEST,
});

const fetchOnSiteAnalyticsCustomerSuccess = (response: any) => ({
  type: FETCH_ON_SITE_ANALYTICS_CUSTOMER_SUCCESS,
  response,
});

const fetchOnSiteAnalyticsCustomerFailure = (error: any) => ({
  type: FETCH_ON_SITE_ANALYTICS_CUSTOMER_FAILURE,
  error,
});

export const fetchOnSiteAnalyticsCustomer =
  (id: string) => (dispatch: Dispatch, getState: () => ReduxState) => {
    dispatch(fetchOnSiteAnalyticsCustomerRequest());
    axios
      .get(`/api/onsiteanalyticscustomers/${id}`, {
        headers: getHTTPRequestHeaders(getState()),
      })
      .then((result) => {
        dispatch(fetchOnSiteAnalyticsCustomerSuccess(result.data));
      })
      .catch((error) => {
        if (axios.isCancel(error))
          dispatch(fetchOnSiteAnalyticsCustomerFailure('canceled'));
        else dispatch(fetchOnSiteAnalyticsCustomerFailure(error));
      });
  };
