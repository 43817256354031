import { SET_UI_THEME } from 'client/constants/ActionTypes';

type ExtranetTheme = 'DEFAULT' | 'USA';

// Reducers
export const theme = (
  state: ExtranetTheme = 'DEFAULT',
  action: any
): ExtranetTheme => {
  switch (action.type) {
    case SET_UI_THEME:
      return action.theme;

    default:
      return state;
  }
};
