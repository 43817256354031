import {
  SET_MAP_DASHBOARD_SELECTED_MAP_ID,
  SET_MAP_DASHBOARD_BASE_DATE_RANGE,
  SET_MAP_DASHBOARD_COMPARISON_DATE_RANGE,
} from 'client/constants/ActionTypes';
import type { DateRange } from 'client/reducers/mapDashboardControls';

export const setMapDashboardSelectedMapId = (selectedMapId: string) => ({
  type: SET_MAP_DASHBOARD_SELECTED_MAP_ID,
  selectedMapId,
});

export const setMapDashboardBaseDateRange = (baseDateRange: DateRange) => ({
  type: SET_MAP_DASHBOARD_BASE_DATE_RANGE,
  baseDateRange,
});

export const setMapDashboardComparisonDateRange = (
  comparisonDateRange: DateRange | null
) => ({
  type: SET_MAP_DASHBOARD_COMPARISON_DATE_RANGE,
  comparisonDateRange,
});

export type MapDashboardControlsAction =
  | {
      type: typeof SET_MAP_DASHBOARD_SELECTED_MAP_ID;
      selectedMapId: string;
    }
  | {
      type: typeof SET_MAP_DASHBOARD_BASE_DATE_RANGE;
      baseDateRange: DateRange;
    }
  | {
      type: typeof SET_MAP_DASHBOARD_COMPARISON_DATE_RANGE;
      comparisonDateRange: DateRange | null;
    };
