import * as React from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import * as Swagger from 'shared/models/swagger';
import { httpRequestHeadersSelector } from 'client/reducers/accounts';
import { createCancellableFetcher } from 'client/libraries/util/createCancellableFetcher';

interface TimeOfDayRange {
  start: string;
  end: string;
}

interface DateRange {
  start: string;
  end: string;
}

export const useMapLocationReportData = (
  dateRange: DateRange,
  timeOfDayRange: TimeOfDayRange,
  selectedMapId: string
): {
  data: Swagger.GetDigitalMapLocationReportDataResponse | null;
  isLoading: boolean;
  error: string;
} => {
  const fetcherRef = React.useRef(createCancellableFetcher());
  const headers = useSelector(httpRequestHeadersSelector);

  const queryParams = convertParamsToMapLocationReportQueryParams(
    dateRange,
    timeOfDayRange
  );

  const { data, error } =
    useSWR<Swagger.GetDigitalMapLocationReportDataResponse>(
      [
        selectedMapId
          ? `/api/digitalmaps/${selectedMapId}/locationreportdata`
          : '',
        JSON.stringify(queryParams),
        JSON.stringify(headers),
      ],
      fetcherRef.current
    );

  React.useEffect(() => {
    // Cleanup function to abort any pending requests when the component unmounts
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      fetcherRef.current('', '', '').catch(() => {});
    };
  }, []);

  return {
    data: data ?? null,
    isLoading: Boolean(!error && !data),
    error: error,
  };
};

const convertParamsToMapLocationReportQueryParams = (
  dateRange: DateRange,
  timeOfDayRange: TimeOfDayRange
): {
  date_range: string;
  time_of_day_range: string;
} => {
  return {
    date_range: `${dateRange.start},${dateRange.end}`,
    time_of_day_range: `${timeOfDayRange.start},${timeOfDayRange.end}`,
  };
};
