import { useState } from 'react';

import { LanguageISO } from 'shared/libraries/i18n';
import { PackageComponentReservationTab } from 'client/components/v3/PackageComponentReservationTab/PackageComponentReservationTab';
import type { Reservation, Product, Account } from 'shared/models/swagger';

import { DynamicPackageComponentDetailedInfomation } from './DynamicPackageComponentDetailedInfomation';

interface DynamicPackageComponentDetailedInfomationProps {
  reservation: Reservation;
  product: Product;
  locale: LanguageISO;
  activeUser: Account | null;
  readOnly: boolean;
}

export const DynamicPackageComponentDetailedInfomationTab = ({
  reservation,
  locale,
  activeUser,
  readOnly,
}: DynamicPackageComponentDetailedInfomationProps) => {
  const [selectedComponentReservationId, setSelectedComponentReservationId] =
    useState<string>(
      reservation.package_component_reservation_summaries?.[0].reservation_id ??
        ''
    );

  return (
    <PackageComponentReservationTab
      reservations={reservation.package_component_reservation_summaries ?? []}
      value={selectedComponentReservationId}
      onChange={(newValue) => setSelectedComponentReservationId(newValue)}
      startTimezone={reservation.start_timezone}
    >
      <DynamicPackageComponentDetailedInfomation
        reservation={reservation}
        componentReservationId={selectedComponentReservationId}
        locale={locale}
        activeUser={activeUser}
        readOnly={readOnly}
      />
    </PackageComponentReservationTab>
  );
};
