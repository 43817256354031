import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import * as Swagger from 'shared/models/swagger';
import { currency } from 'shared/libraries/currency';
import { PageContent } from 'client/components/v3/Page/PageContent';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { ComparisonDateInput } from 'client/components/v3/ComparisonDateInput/ComparisonDateInput';
import { ReduxState } from 'client/reducers';
import { QuantityGadget } from 'client/components/v3/DashboardGadgets/QuantityGadget';
import {
  PagedGenericTable,
  ColumnType,
} from 'client/components/v3/PagedGenericTable/PagedGenericTable';
import { formattedCurrencyAmount } from 'client/libraries/util/formattedCurrencyAmount';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { fetchDigitalMaps } from 'client/actions/digitalMaps';
import {
  setMapDashboardBaseDateRange,
  setMapDashboardComparisonDateRange,
  setMapDashboardSelectedMapId,
} from 'client/actions/mapDashboardControls';

import { useMapVisitorReportData } from '../DigitalMapDashboard/useMapVisitorReportData';

import styles from './DigitalMapGuestJourneyList.module.css';
import { useMapGuestJourneys } from './useDigitalMapGuestJourneys';

export const DigitalMapGuestJourneyList = () => {
  const { selectedMapId, baseDateRange, comparisonDateRange } = useSelector(
    (state: ReduxState) => state.mapDashboardControls
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const org = useSelector(activeUserOrganizationSelector);
  const currencyCode = org?.default_currency ?? 'USD';
  const formattedCurrencySymbol =
    currencyCode === 'USD'
      ? '$'
      : currencyCode === 'JPY' && locale === 'ja'
      ? '円'
      : currencyCode;
  const { t } = useTranslation();

  const { data: visitorReportData, isLoading: visitorReportIsLoading } =
    useMapVisitorReportData(
      baseDateRange,
      selectedMapId ?? '',
      comparisonDateRange
    );

  const { data: guestJourneysData, isLoading: guestJourneysIsLoading } =
    useMapGuestJourneys(baseDateRange, selectedMapId ?? '');

  const baseDataSet = visitorReportData?.[0];

  const baseUserCount = baseDataSet?.totals?.unique_user_count ?? 0;
  const baseSessionCount = baseDataSet?.totals?.session_count ?? 0;
  const baseTotalVisitDuration =
    baseDataSet?.totals?.total_visit_duration_minutes ?? 0;
  const baseGrossSales = baseDataSet?.totals?.gross_sales ?? 0;
  const baseUniqueUserDaysCount =
    baseDataSet?.totals?.unique_user_days_count ?? 0;
  const baseAvgSessionsPerDay = baseUniqueUserDaysCount
    ? baseSessionCount / baseUniqueUserDaysCount
    : 0;
  const baseAvgVisitDuration = baseUniqueUserDaysCount
    ? baseTotalVisitDuration / baseUniqueUserDaysCount
    : 0;

  const comparisonDataSet =
    visitorReportData && visitorReportData.length > 1
      ? visitorReportData[1]
      : null;

  const comparisonUserCount = comparisonDataSet?.totals?.unique_user_count ?? 0;
  const comparisonSessionCount = comparisonDataSet?.totals?.session_count ?? 0;
  const comparisonTotalVisitDuration =
    comparisonDataSet?.totals?.total_visit_duration_minutes ?? 0;
  const comparisonGrossSales = comparisonDataSet?.totals?.gross_sales ?? 0;
  const comparisonUniqueUserDaysCount =
    comparisonDataSet?.totals?.unique_user_days_count ?? 0;
  const comparisonAvgSessionsPerDay = comparisonUniqueUserDaysCount
    ? comparisonSessionCount / comparisonUniqueUserDaysCount
    : 0;
  const comparisonAvgVisitDuration = comparisonUniqueUserDaysCount
    ? comparisonTotalVisitDuration / comparisonUniqueUserDaysCount
    : 0;

  const columns: ColumnType<Swagger.DigitalMapGuestJourneySummary>[] = [
    {
      Header: t('ID'),
      id: 'id',
      width: 100,
      accessor: (item) => (
        <Link
          to={`/maps/${selectedMapId}/guestjourneys/${item.id}_${item.visit_date}`}
          className={styles['link']}
        >
          {item.id?.substring(0, 8)}
        </Link>
      ),
    },
    {
      Header: t('Name'),
      id: 'name',
      width: 200,
      accessor: (item) =>
        item.customer_display_name ? (
          <Link
            to={`/customers/${item.customer_id}`}
            className={styles['link']}
          >
            {item.customer_display_name}
          </Link>
        ) : (
          <div className={styles['unknown']}>{t('Unknown')}</div>
        ),
    },
    {
      Header: t('Visit Date'),
      id: 'visitDate',
      width: 100,
      accessor: (item) => moment(item.visit_date).format('MM/DD/YYYY'),
    },
    {
      Header: t('Visit Duration'),
      id: 'duration',
      width: 100,
      accessor: (item) => `${item.visit_duration_minutes} ${t('Min')}`,
    },
    {
      Header: t('Sessions'),
      id: 'sessionCount',
      width: 100,
      accessor: (item) => item.session_count,
    },
    {
      Header: t('Spending'),
      id: 'sales',
      width: 100,
      accessor: (item) =>
        item.gross_sales ? formattedCurrencyAmount(item.gross_sales) : '-',
    },
    {
      Header: t('Tickets'),
      id: 'ticketCount',
      width: 100,
      accessor: (item) => item.ticket_count ?? 0,
    },
    {
      Header: t('Mobile Orders'),
      id: 'mobileOrderCount',
      width: 100,
      accessor: (item) => item.mobile_order_count ?? 0,
    },
    {
      Header: t('Member'),
      id: 'isMember',
      width: 100,
      accessor: (item) => (item.is_member ? t('Yes') : t('No')),
    },
    {
      Header: t('Visitor Type'),
      id: 'isRepeat',
      width: 100,
      accessor: (item) => (item.is_repeat ? t('Repeat') : t('First Time')),
    },
    {
      Header: '',
      id: 'actions',
      width: 100,
      accessor: (item) => (
        <div className={styles['actions']}>
          <Link
            to={`/maps/${selectedMapId}/guestjourneys/${item.id}_${item.visit_date}`}
          >
            <button className={styles['view-button']}>{t('View')}</button>
          </Link>
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchDigitalMaps());
  }, [dispatch]);

  const digitalMaps = useSelector((state: ReduxState) => state.digitalMaps.all);

  React.useEffect(() => {
    if (digitalMaps.length > 0 && !selectedMapId) {
      dispatch(setMapDashboardSelectedMapId(digitalMaps[0].id ?? ''));
    }
  }, [digitalMaps, dispatch, selectedMapId]);

  return (
    <V3Page>
      <PageHeader
        title={t('Guest Journeys')}
        description={t("Dive into the details of your guest's visit")}
      />
      <PageContent className={styles['page-content-custom']}>
        <div className={styles['container']}>
          <div className={styles['controls']}>
            <div className={styles['map-selector']}>
              <SingleDropdown
                label={t('Select Map')}
                options={digitalMaps.map((map) => ({
                  text: map.name ?? '',
                  value: map.id ?? '',
                }))}
                onChange={(newOption) =>
                  dispatch(setMapDashboardSelectedMapId(newOption))
                }
                selectedOption={selectedMapId ?? ''}
              />
            </div>
            <div className={styles['date-range-container']}>
              <ComparisonDateInput
                baseDateRange={baseDateRange}
                setBaseDateRange={(newDateRange) =>
                  dispatch(setMapDashboardBaseDateRange(newDateRange))
                }
                comparisonDateRange={comparisonDateRange}
                setComparisonDateRange={(newDateRange) =>
                  dispatch(setMapDashboardComparisonDateRange(newDateRange))
                }
                menuStyle={{ right: 0, left: 'auto' }}
              />
            </div>
          </div>

          <div className={styles['gadget-row']}>
            <QuantityGadget
              header={t('Users')}
              unit={locale === 'ja' ? '人' : ''}
              amount={baseUserCount}
              changeAmount={
                comparisonDataSet ? comparisonUserCount - baseUserCount : null
              }
              loading={visitorReportIsLoading}
            />
            <QuantityGadget
              header={t('Avg Usage')}
              unit={t('Times Per Day')}
              amount={baseAvgSessionsPerDay}
              changeAmount={
                comparisonDataSet
                  ? comparisonAvgSessionsPerDay - baseAvgSessionsPerDay
                  : null
              }
              loading={visitorReportIsLoading}
              numberFormatter={(amount) => amount.toFixed(1)}
            />
            <QuantityGadget
              header={t('Avg Visit Duration')}
              unit={t('Min')}
              amount={baseAvgVisitDuration}
              changeAmount={
                comparisonDataSet
                  ? comparisonAvgVisitDuration - baseAvgVisitDuration
                  : null
              }
              loading={visitorReportIsLoading}
              numberFormatter={(amount) => amount.toFixed(0)}
            />
            <QuantityGadget
              header={t('Sales')}
              unit={formattedCurrencySymbol}
              isPrefixUnit={formattedCurrencySymbol === '$'}
              amount={currency(baseGrossSales)?.value}
              changeAmount={
                comparisonDataSet
                  ? currency(comparisonGrossSales)?.value -
                    currency(baseGrossSales)?.value
                  : null
              }
              loading={visitorReportIsLoading}
            />
          </div>

          <PagedGenericTable
            columns={columns}
            allItems={guestJourneysData ?? []}
            loading={guestJourneysIsLoading}
          />
        </div>
      </PageContent>
    </V3Page>
  );
};
