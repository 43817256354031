import React from 'react';
import { useTranslation } from 'react-i18next';

import { WaitTime } from 'shared/models/swagger';
import liveIcon from 'client/images/map/ic_live.svg';

import styles from './PopularTimes.module.css';

const LiveInfo = ({ waitTime }: { waitTime: WaitTime }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.liveInfo}>
      <img src={liveIcon} className={styles.liveIcon} alt="" />

      <p>
        <span className={`${styles.bold} ${styles.highlight}`}>Live: </span>
        {waitTime.is_closed ? (
          <span className={styles.bold}>{t('Closed')}</span>
        ) : (
          <>
            Current wait time is{' '}
            <span className={styles.bold}>
              {waitTime.wait_time_minutes} minutes
            </span>
            .
          </>
        )}
      </p>
    </div>
  );
};

export default LiveInfo;
