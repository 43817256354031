import { useTranslation } from 'react-i18next';
import {
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Cell,
} from 'recharts';

type Props = {
  data: {
    name: string;
    value: number;
  }[];
  colors: string[];
};

export const DonutChart = ({ data, colors }: Props) => {
  const { t } = useTranslation();
  const mql = window.matchMedia('(max-width: 768px)');

  const totalDataCount = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <ResponsiveContainer width="100%" minHeight={250} debounce={300}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius="90%"
          innerRadius="70%"
          paddingAngle={0}
          startAngle={0}
          endAngle={360}
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend
          iconSize={10}
          iconType="circle"
          width={120}
          layout="vertical"
          verticalAlign={mql.matches ? 'bottom' : 'middle'}
          align={mql.matches ? 'center' : 'right'}
          wrapperStyle={{
            fontSize: '12px',
            paddingTop: mql.matches ? '8px' : undefined,
          }}
          formatter={(value: string) => {
            return <span style={{ color: '#18181B' }}>{value}</span>;
          }}
        />
        <Tooltip
          formatter={(value: any, name: any) => {
            const percentage = (value / totalDataCount) * 100;
            const percentageString = Number.isInteger(percentage)
              ? percentage.toFixed(0)
              : percentage.toFixed(2);

            return [
              `${t('{{count}} person', {
                count: value,
              })} (${percentageString}%)`,
              name,
            ];
          }}
          cursor={{ fill: '#F4F4F5' }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
