import _ from 'lodash';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';

import { TextField } from 'client/components/v3/Form/TextField';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { currencyInputAllowed } from 'client/libraries/util/coreutil';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';

import styles from './OptionGroupEditor.module.css';

const getDefaultOption = () => ({
  name: '',
  price: '',
  mediaUrls: [],
});

export const OptionGroupEditor = ({ name }: { name: string }) => {
  const { t } = useTranslation();

  const org = useSelector(activeUserOrganizationSelector);
  const currencyCode = org?.default_currency ?? '';

  const optionGroup = _.get(useFormState().values, name);

  return (
    <div>
      <div className={styles['field-wrapper']}>
        <Field name={`${name}.name`}>
          {({ input }) => (
            <TextField
              label={t('Name')}
              value={input.value}
              onChange={input.onChange}
            />
          )}
        </Field>
      </div>
      <div className={styles['field-wrapper']}>
        <Field name={`${name}.isRequired`}>
          {({ input }) => (
            <Toggle
              label={t('Required')}
              checked={input.value}
              onChange={input.onChange}
            />
          )}
        </Field>
      </div>
      <div className={styles['field-wrapper']}>
        <Field name={`${name}.selectionLimit`}>
          {({ input }) => (
            <SingleDropdown
              label={t('Selection Limit')}
              selectedOption={input.value}
              options={[
                {
                  text: t('None'),
                  value: '0',
                },
                ..._.times(optionGroup.options.length - 1, (i) => ({
                  text: `${i + 1}`,
                  value: `${i + 1}`,
                })),
              ]}
              onChange={input.onChange}
            />
          )}
        </Field>
      </div>
      <div className={styles['options-container']}>
        <div className={styles['options-header']}>{t('Options')}</div>
        <FieldArray name={`${name}.options`}>
          {({ fields }) => (
            <div>
              {fields.map((field, idx) => (
                <div key={field} className={styles['option-container']}>
                  <div className={styles['option-actions']}>
                    <button
                      type="button"
                      className={styles['option-delete']}
                      onClick={() => fields.remove(idx)}
                    >
                      <i className="c-icon-outline-general-trash-03"></i>
                    </button>
                  </div>
                  <Field name={`${field}.name`}>
                    {({ input }) => (
                      <div className={styles['field-wrapper']}>
                        <TextField
                          label={t('Name')}
                          value={input.value}
                          onChange={input.onChange}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name={`${field}.price`}>
                    {({ input, meta: { touched, error } }) => (
                      <div className={styles['field-wrapper']}>
                        <TextField
                          label={t('Price ({{currency}})', {
                            currency: currencyCode,
                          })}
                          onChange={(newValue) => {
                            if (!currencyInputAllowed(currencyCode, newValue))
                              return;

                            input.onChange(newValue);
                          }}
                          value={input.value}
                          error={Boolean(touched && error)}
                          helperText={touched ? error : undefined}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name={`${field}.mediaUrls`}>
                    {({ input }) => (
                      <div className={styles['field-wrapper']}>
                        <div>{t('Photos')}</div>
                        <ImageVideoAudioInput
                          fileTypes={['image/jpeg', 'image/png', 'video/mp4']}
                          fileUrls={input.value || []}
                          onChange={(newValue) => input.onChange(newValue)}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name={`${field}.isDefault`}>
                    {({ input }) => (
                      <div className={styles['field-wrapper']}>
                        <Toggle
                          label={t('Selected By Default')}
                          checked={input.value}
                          onChange={input.onChange}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              ))}

              <a
                className={styles['add-new']}
                onClick={() =>
                  (fields as any).insertAt(
                    fields.length ?? 0,
                    getDefaultOption()
                  )
                }
              >
                <i className="c-icon-outline-general-plus-circle"></i>
                {t('Add New Option')}
              </a>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};
